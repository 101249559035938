import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Disclosure, DisclosureButton, DisclosurePanel, Transition, TransitionChild } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FAQ } from '../models/FAQ';

interface Props {
  isOpen: boolean;
  closeModal(open: boolean): void;
  faqs: FAQ[];
}

const FAQModal: React.FC<Props> = ({ isOpen, closeModal, faqs }) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog as="div" static className="fixed z-10 inset-0" open={isOpen} onClose={closeModal}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-2 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-200 sm:max-w-3xl sm:w-full sm:p-6">
            <div>
              <div className="divide-y divide-gray-900/10">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <h2 className="text-base leading-6 font-medium text-gray-900">Frequently asked questions</h2>
                  </div>
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                    onClick={(): void => closeModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <dl className="mt-3 space-y-3 divide-y divide-gray-900/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-3">
                      {({ open }): React.ReactElement => (
                        <>
                          <dt>
                            <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base leading-6 font-medium text-gray-900">{faq.question}</span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </DisclosureButton>
                          </dt>
                          <DisclosurePanel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                          </DisclosurePanel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);

export default FAQModal;

import React, { Fragment, useRef } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { JobAssetSummary } from '../models/JobAssetSummary';

type AssetInspectionChartProps = {
  isOpen: boolean;
  closeModal(open: boolean): void;
  jobAssetSummary: JobAssetSummary;
};

const AssetInspectionChartModal: React.FC<AssetInspectionChartProps> = ({ isOpen, closeModal, jobAssetSummary }) => {
  const cancelButtonRef = useRef(null);
  Chart.register(ArcElement, Title, Tooltip, Legend);
  const options = {
    elements: {
      arc: {
        borderWidth: 3,
      },
    },
    animation: {
      animateRotate: true,
      render: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  const data = {
    maintainAspectRatio: false,
    responsive: true,
    labels: ['To Be Inspected', 'Inspected', 'Completed', 'Not Required'],
    datasets: [
      {
        label: ' Assets',
        data: [
          jobAssetSummary.toBeInspectedAssets,
          jobAssetSummary.inspectedAssets,
          jobAssetSummary.completedAssets,
          jobAssetSummary.notRequiredAssets,
        ],
        fill: false,
        backgroundColor: ['#f87171', '#facc15', '#4ade80', '#9ca3af'],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" onClose={closeModal} initialFocus={cancelButtonRef} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              onClick={(): void => closeModal(false)}
              onKeyDown={(): void => closeModal(false)}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="px-4 py-4 inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="relative">
                <div className="flex flex-row justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Asset Inspection Status</h3>
                  <button
                    type="button"
                    className="rounded-md bg-white  text-gray-400 hover:text-red-700"
                    onClick={(): void => closeModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="bg-white rounded-md sm:rounded-md my-2 p-4 w-full h-[60vh]">
                  <Doughnut data={data} options={options} />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AssetInspectionChartModal;

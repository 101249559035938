import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { Controller, useForm } from 'react-hook-form';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { useApi } from '../../contexts/ApiContext';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, percentFormat } from '../../Format';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ComponentAssumptionsWithHierarchy } from '../../models/ComponentAssumptions';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClassId: number;
  assetTypeId: number;
  assetSubTypeId: number;
  componentNameId: number;
  componentTypeId: number;
  componentSubTypeId: number;
};

const ComponentAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const {
    componentAssumptions,
    componentAssumptionsSearchText,
    getComponentAssumptions,
    componentAssumptionsCurrentPage,
    setComponentAssumptionsCurrentPage,
    isSearchingComponentAssumptions,
    assetClassList,
    tableComponentAssumptionsPageSize,
    setComponentAssumptionsTablePageSize,
  } = useAssumptionsSearch();

  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentNameList, setComponentNameList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentTypeList, setComponentTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentSubTypeList, setComponentSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);

  const [selectedAssetClass, setSelectedAssetClass] = useState<number>(0);
  const [selectedAssetType, setSelectedAssetType] = useState<number>(0);
  const [selectedAssetSubType, setSelectedAssetSubType] = useState<number>(0);
  const [selectedComponentName, setSelectedComponentName] = useState<number>(0);
  const [selectedComponentType, setSelectedComponentType] = useState<number>(0);

  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<FormData>();

  const columns: Column<ComponentAssumptionsWithHierarchy>[] = React.useMemo(
    () => [
      {
        Header: 'Asset Class',
        headerClassName: 'px-2 py-3',
        accessor: 'assetClass',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Type',
        headerClassName: 'px-2 py-3',
        accessor: 'assetType',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Sub Type',
        headerClassName: 'px-2 py-3',
        accessor: 'assetSubType',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Component Name',
        headerClassName: 'px-2 py-3',
        accessor: 'componentName',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Component Type',
        headerClassName: 'px-2 py-3',
        accessor: 'componentType',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link font-medium">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Component Sub Type',
        headerClassName: 'px-2 py-3',
        accessor: 'componentSubType',
        className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link font-medium">
            {value}
          </Link>
        ),
      },
      {
        Header: '# of Components',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'componentsCount',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Unit Rate',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'unitRate',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'Locality Factor',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'localityFactorPct',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Long Life Split',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'longLifePct',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },

      {
        Header: 'UL Short',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'ulShortMin',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): string => `${value} - ${row.original.ulShortMax} yrs`,
      },
      {
        Header: 'RV Short / Long',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'rvPctShort',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): string => `${percentFormat.format(value)} / ${percentFormat.format(row.original.rvPctLong)}`,
      },
      {
        Header: 'UL Long',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'ulLong',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      // {
      //   Header: 'Residual Value Long',
      //   headerClassName: 'px-2 py-3 text-right',
      //   accessor: 'rvPctLong',
      //   className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
      //   Cell: ({ value }): string => `${percentFormat.format(value)} / ${percentFormat.format(value)}`,
      // },
      {
        Header: 'Depreciation Policy',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'depreciationPolicy',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Obsolescence Profile',
        headerClassName: 'px-2 py-3 text-right',
        accessor: 'obsolescenceProfile',
        className: 'px-2 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      {
        Header: '',
        accessor: 'edit ',
        className: 'px-2 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ row }) =>
          !roles.includes(Roles.Viewer) &&
          checkAssetClassEditable(row.original.assetClass) && (
            <NavLink className="btn-link" to={`/componentassumptions/${row.original.id}/edit`}>
              edit
            </NavLink>
          ),
      },
    ],
    [readOnlyAssetClasses]
  );

  const onSubmit = handleSubmit(
    ({ searchText, assetClassId, assetTypeId, assetSubTypeId, componentNameId, componentTypeId, componentSubTypeId }) => {
      getComponentAssumptions(
        searchText,
        assetClassId,
        assetTypeId,
        assetSubTypeId,
        componentNameId,
        componentTypeId,
        componentSubTypeId
      );
      setSearchComplete(true);
    }
  );

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchComponentNames = (assetSubTypeId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentNames?AssetSubTypeId=${assetSubTypeId}`)
      .then(({ data }) => {
        setComponentNameList(data);
      })
      .catch((error) => {});
  };

  const fetchComponentTypes = (componentNameId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentTypes?ComponentNameId=${componentNameId}`)
      .then(({ data }) => {
        setComponentTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchComponentSubTypes = (componentTypeId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentSubTypes?ComponentTypeId=${componentTypeId}`)
      .then(({ data }) => {
        setComponentSubTypeList(data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClass > 0) {
      fetchAssetTypes(selectedAssetClass);
    }
  }, [selectedAssetClass]);

  useEffect(() => {
    if (selectedAssetType > 0) {
      fetchAssetSubTypes(selectedAssetType);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    if (selectedAssetSubType > 0) {
      fetchComponentNames(selectedAssetSubType);
    }
  }, [selectedAssetSubType]);

  useEffect(() => {
    if (selectedComponentName > 0) {
      fetchComponentTypes(selectedComponentName);
    }
  }, [selectedComponentName]);

  useEffect(() => {
    if (selectedComponentType > 0) {
      fetchComponentSubTypes(selectedComponentType);
    }
  }, [selectedComponentType]);

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <div>
          <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
            <div className="mb-5 flex-1 min-w-0 justify-between flex">
              <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Component Assumptions</h1>
            </div>
            <div className="pb-2 flex items-end flex-wrap">
              <div className="flex-grow mr-3">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="m-1 relative min-w-max">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                    {/* Heroicon name: search */}
                    <svg
                      className="mr-3 h-4 w-4 text-gray-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    {...register('searchText')}
                    id="searchText"
                    defaultValue={componentAssumptionsSearchText}
                    className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                    placeholder="Search" 
                  />
                </div>
              </div>
              <Disclosure.Button as="div">
                <button
                  type="button"
                  className="m-1  inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-10 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                >
                  <ChevronDoubleDownIcon
                    className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                      !open && 'rotate-180'
                    } ease-in-out duration-150`}
                  />
                </button>
              </Disclosure.Button>
              <input type="submit" value="Search" className="my-auto mx-3 btn btn-primary" />
            </div>
            <div className="flex justify-end" />
            <Disclosure.Panel as="div" className="space-y-2">
              <div className="flex flex-grow justify-start mt-2 space-x-8">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700">Asset Class</label>
                  <select
                    defaultValue="All"
                    {...register('assetClassId')}
                    onChange={(e): void => setSelectedAssetClass(Number(e.target.value))}
                    className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    {assetClassList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-4">
                  <label className="block text-sm font-medium text-gray-700">Asset Type</label>
                  <select
                    defaultValue="All"
                    {...register('assetTypeId')}
                    onChange={(e): void => setSelectedAssetType(Number(e.target.value))}
                    className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {assetTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-4">
                  <label className="block text-sm font-medium text-gray-700">Asset Sub Type</label>
                  <select
                    defaultValue="All"
                    {...register('assetSubTypeId')}
                    onChange={(e): void => setSelectedAssetSubType(Number(e.target.value))}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {assetSubTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-4">
                  <label className="block text-sm font-medium text-gray-700">Component Name</label>
                  <select
                    defaultValue="All"
                    {...register('componentNameId')}
                    onChange={(e): void => setSelectedComponentName(Number(e.target.value))}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {componentNameList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-4">
                  <label className="block text-sm font-medium text-gray-700">Component Type</label>
                  <select
                    defaultValue="All"
                    {...register('componentTypeId')}
                    onChange={(e): void => setSelectedComponentType(Number(e.target.value))}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {componentTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-4">
                  <label className="block text-sm font-medium text-gray-700">Component Sub Type</label>
                  <select
                    defaultValue="All"
                    {...register('componentSubTypeId')}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {componentSubTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Disclosure.Panel>
          </form>
          <div
            className={`mx-3 ${
              !isSearchingComponentAssumptions && componentAssumptionsSearchText !== '' && componentAssumptions.length <= 0
            }`}
          >
            <BasicTable
              columns={columns}
              data={componentAssumptions}
              currentPage={componentAssumptionsCurrentPage}
              setCurrentPage={setComponentAssumptionsCurrentPage}
              isLoading={isSearchingComponentAssumptions}
              tablePageSize={tableComponentAssumptionsPageSize}
              setTablePageSize={setComponentAssumptionsTablePageSize}
            />

            {!isSearchingComponentAssumptions && searchComplete && componentAssumptions.length <= 0 && (
              <div className="text-center mt-14 pt-3 pb-6">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">No Component Assumptions Found</h3>
                <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
              </div>
            )}
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default ComponentAssumptionsSearch;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { resetWarningCache } from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { AxiosInstance, AxiosResponse } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { Asset } from '../../models/Asset';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { HierarchyType } from '../../models/HierarchyType';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ValuationClass } from '../../models/ValuationClass';

export const ValuationClassEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id = '' } = useParams<{ id: string }>();
  const history = useNavigate();
  const [valuationClass, setValuationClass] = useState<ValuationClass>({} as ValuationClass);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,

    formState: { errors },
  } = useForm<ValuationClass>({
    defaultValues: {
      id: parseInt(id),
    },
    reValidateMode: 'onBlur',
    // resolver: yupResolver(ClientSchema),
  });

  useEffect(() => {
    if (parseInt(id) > 0) {
      api
        .get<ValuationClass>(`/api/ValuationClass/GetValuationClass?id=${id}`)
        .then(({ data }) => {
          setValuationClass(data);
          reset({
            ...data,
          });
        })
        .catch((error) => {
          addToast(`Unable to get valuation class with ID ${id}`, ToastType.Error);
        });
    }
  }, [id]);

  const onSubmit = (formData: ValuationClass): void => {
    api
      .post('/api/ValuationClass/UpdateValuationClass', { valuationClass: formData })
      .then(({ data }) => {
        addToast(`${id === '0' ? 'Created' : 'Updated'} Valuation Class`);
        history('/valuationclasses');
      })
      .catch((error) => {
        addToast(
          `Unable to ${id === '0' ? 'create new' : 'update'} Valuation Class ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('id', { valueAsNumber: true })} />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Valuation Class</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Valuation Class Name
                </label>
                <input
                  type="text"
                  {...register('name')}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Valuation Technique
                </label>
                <input
                  type="text"
                  {...register('valuationTechnique')}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Valuation Level
                </label>
                <input
                  type="number"
                  {...register('valuationLevel')}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/valuationclasses`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

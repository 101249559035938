import React, { useEffect, useState } from 'react';
import { NavLink, Route, useParams, Link, Routes, Outlet } from 'react-router-dom';
import { Asset } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';
import { InsuranceEditor } from './InsuranceEditor';
import { GuaranteeEditor } from './GuaranteeEditor';
import { TenantEditor } from './TenantEditor';
import { LeaseEditor } from './LeaseEditor';
import { ReviewEditor } from './ReviewEditor';
import { useLeaseById } from '../../hooks/UseLease';
import { LeaseDto } from '../../models/Lease';

export const LeaseEditNav: React.FC<{}> = () => {
  const { id = '', assetId = '' } = useParams<{ id: string; assetId: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { data: asset = {} as Asset } = useAssetById(parseInt(assetId));

  return (
    <div className="relative mx-auto sm:px-4 md:px-8">
      <div className="bg-white  mt-4 overflow-hidden shadow rounded-md">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            {parseInt(id) > 0 ? (
              <div className="sm:flex sm:space-x-5">
                <div className="text-center sm:pt-1 sm:text-left">
                  <h2 className="text-lg font-bold leading-normal text-gray-900 sm:text-xl">{lease.name}</h2>
                  <p className="text-sm font-semibold leading-normal text-gray-500">{lease.addressLine1}</p>
                  <p className="text-sm font-semibold leading-normal text-gray-500">{lease.suburb}</p>
                  <Link to={`/assets/${lease.assetId}`} className="text-indigo-600 hover:text-indigo-900 text-sm font-bold">
                    {lease.assetReference}
                  </Link>
                </div>
              </div>
            ) : (
              <div className="sm:flex sm:space-x-5">
                <div className="text-center sm:pt-1 sm:text-left">
                  <p className="text-sm font-semibold leading-normal text-gray-500">{asset.streetAddress}</p>
                  <p className="text-sm font-semibold leading-normal text-gray-500">{asset.suburb}</p>
                  <Link to={`/assets/${assetId}`} className="text-indigo-600 hover:text-indigo-900 text-sm font-bold">
                    {asset.reference}
                  </Link>
                </div>
              </div>
            )}
            <div className="px-4 sm:px-6 md:px-0 flex justify-end">
              <Link to={`/leases/${id}`} className="btn btn-secondary">
                View
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 pb-4">
        <div className="hidden lg:block">
          <div className="mb-2">
            <nav className="flex space-x-4 mb-2">
              <NavLink
                to="."
                end
                className={({ isActive }) =>
                  `text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-700 ${
                    isActive && 'bg-gray-200 text-gray-800'
                  }`
                }
              >
                <span>Lease</span>
              </NavLink>
              {parseInt(id) > 0 && (
                <NavLink
                  to="Tenant"
                  className={({ isActive }) =>
                    `text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-700 ${
                      isActive && 'bg-gray-200 text-gray-800'
                    }`
                  }
                >
                  <span>Tenant</span>
                </NavLink>
              )}
              {parseInt(id) > 0 && (
                <NavLink
                  to="Guarantee"
                  aria-current="page"
                  className={({ isActive }) =>
                    `text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-700 ${
                      isActive && 'bg-gray-200 text-gray-800'
                    }`
                  }
                >
                  <span>Guarantee</span>
                </NavLink>
              )}
              {parseInt(id) > 0 && (
                <NavLink
                  to="Insurance"
                  aria-current="page"
                  className={({ isActive }) =>
                    `text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-700 ${
                      isActive && 'bg-gray-200 text-gray-800'
                    }`
                  }
                >
                  <span>Insurance</span>
                </NavLink>
              )}
              {parseInt(id) > 0 && (
                <NavLink
                  to="Review"
                  aria-current="page"
                  className={({ isActive }) =>
                    `text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-700 ${
                      isActive && 'bg-gray-200 text-gray-800'
                    }`
                  }
                >
                  <span>Review</span>
                </NavLink>
              )}
            </nav>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

import { DocumentTextIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import FileInput from '../components/FileInput';

interface ImportProps {
  isHierarchyChanges: boolean;
  setIsHierarchyChanges(isHierarchyChanges: boolean): void;
  isNoteChanges: boolean;
  setIsNoteChanges(isNoteChanges: boolean): void;
  isCustomData: boolean;
  setIsCustomData(isCustomData: boolean): void;
}

const ImportOptions: React.FC<ImportProps> = ({
  isHierarchyChanges,
  setIsHierarchyChanges,
  isNoteChanges,
  setIsNoteChanges,
  isCustomData,
  setIsCustomData,
}) => (
  <div className="">
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Options</h3>
    </div>
    <div className="">
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isHierarchyChanges}
                onChange={(): void => setIsHierarchyChanges(!isHierarchyChanges)}
                name="isHierarchyChanges"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Hierarchy Changes <strong>(Import speed may be affected by hierachy size)</strong>
              </label>
              <p className="text-gray-500">
                Check for changes made to the hierarchy (Only applies to existing Assets and Components)
              </p>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isNoteChanges}
                onChange={(): void => setIsNoteChanges(!isNoteChanges)}
                name="isNoteChanges"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Note Changes
              </label>
              <p className="text-gray-500">Check if changes made to any of the Asset Notes</p>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isCustomData}
                onChange={(): void => setIsCustomData(!isCustomData)}
                name="isCustomData"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Custom Data
              </label>
              <p className="text-gray-500">Check for specific data related to the asset</p>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
);

export const AssetImport: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isHierarchyChanges, setIsHierarchyChanges] = useState<boolean>(true);
  const [isNoteChanges, setIsNoteChanges] = useState<boolean>(true);
  const [isCustomData, setIsCustomData] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="align-middle inline-block min-w-full border-b border-gray-200">
      <form className="space-y-8 divide-y divide-gray-200 relative p-5 mx-3 my-5 bg-white shadow-md rounded-md">
        <div className="space-y-8">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Import</h3>
              <p className="mt-1 text-sm text-gray-500">Import a spreadsheet containing asset data</p>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                {Object.entries(file).length !== 0 ? (
                  <FileInput
                    file={file}
                    isValid={isValid}
                    closeAction={(): void => setFile({} as FileWithPath)}
                    logType="job"
                    url="/api/Asset/Import"
                    parameters={{
                      jobId: id,
                      isHierarchyChanges: isHierarchyChanges.toString(),
                      isNoteChanges: isNoteChanges.toString(),
                      isCustomData: isCustomData.toString(),
                    }}
                    options={
                      <ImportOptions
                        isHierarchyChanges={isHierarchyChanges}
                        setIsHierarchyChanges={setIsHierarchyChanges}
                        isNoteChanges={isNoteChanges}
                        setIsNoteChanges={setIsNoteChanges}
                        isCustomData={isCustomData}
                        setIsCustomData={setIsCustomData}
                      />
                    }
                  />
                ) : (
                  <div
                    {...getRootProps()}
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div className="space-y-1 text-center">
                      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <div className="flex text-sm text-gray-600">
                        <div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                          <span>Upload a file</span>
                          <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </div>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">XLSX up to 100MB</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

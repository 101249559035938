import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { useQuery } from '@tanstack/react-query';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Combobox } from '@headlessui/react';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { BasicTable } from '../../components/BasicTable';
import { Lease } from '../../models/Lease';
import { useApi } from '../../contexts/ApiContext';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { currencyFormat } from '../../Format';
import { Contact } from '../../models/Contact';
import { useLeaseSearch } from '../../contexts/LeaseSearchContext';
import ExportLeasesModal from '../../components/ExportLeasesModal';

export const LeaseList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const api = useApi();
  const { roles, client } = useAuth();
  const {
    leases,
    searchText,
    setSearchText,
    leaseType,
    setLeaseType,
    isSearchingLease,
    setIsSearchingLease,
    currentPage,
    setCurrentPage,
    deleteLeases,
    isDeletingLeases,
    exportLeases,
    isExportingLeases,
    tablePageSize,
    setTablePageSize,
  } = useLeaseSearch();
  const columns: Column<Lease>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        headerClassName: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
        className: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
        Cell: ({ row, value }): React.ReactElement => (
          <Link to={`/leases/${row.original.id}`} className="text-indigo-600 hover:text-indigo-900">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset',
        accessor: 'assetId',
        className: 'px-1 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): React.ReactElement => (
          <Link to={`/assets/${value}`} className="text-indigo-600 hover:text-indigo-900">
            {row.original.assetReference}
          </Link>
        ),
      },
      {
        Header: 'Address',
        accessor: 'addressLine1',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): React.ReactElement => (
          <>
            <div className="whitespace-nowrap text-xs leading-5">{value}</div>
            <div className="flex italic text-xs font-light">{row.original.suburb}</div>
          </>
        ),
      },
      {
        Header: 'Lease Type',
        accessor: 'leaseType',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Tenant',
        accessor: 'tenants',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => <div>{value.length > 0 ? value[0].name : ''}</div>,
      },
      {
        Header: 'Rent',
        accessor: 'annualRent',
        headerClassName: 'text-right whitespace-normal',
        className: 'px-2 py-3 text-sm leading-5 sm:truncate text-right',
        Cell: ({ value }): React.ReactElement => (
          <>
            <p className="">{`${currencyFormat.format(value)} p/a`}</p>
            <p className="">{`${currencyFormat.format(value / 12)} p/m`}</p>
          </>
        ),
      },
      {
        Header: 'Lease Term',
        accessor: 'leaseExpiryDate',
        headerClassName: 'text-right whitespace-normal',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ row, value }): React.ReactElement => (
          <div>{`${
            row.original.leaseStartDate !== null ? new Date(row.original.leaseStartDate).toLocaleDateString() : 'N/A'
          } - ${value !== null ? new Date(value).toLocaleDateString() : 'N/A'}`}</div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        headerClassName: 'text-right whitespace-normal',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): React.ReactElement => (
          <Link to={`/leases/${value}/edit`} className="text-indigo-600 hover:text-indigo-900 ">
            Edit
          </Link>
        ),
      },
    ],
    []
  );

  if (isSearchingLease) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Leases</p>
      </div>
    );
  }

  return (
    <div className="sm:block">
      <div className="align-middle inline-block min-w-full">
        <div className="relative p-5 m-3 bg-white shadow-md rounded-md">
          <div className="flex-1 min-w-0 mb-3 justify-between flex">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Leases</h1>
            <Link
              to="/leaseimport"
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Import Leases
            </Link>
          </div>
          <div className="flex items-end flex-wrap space-x-2">
            <div className="flex-grow rounded-md shadow-sm">
              <div className="relative flex items-stretch focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="mr-3 h-4 w-4 text-gray-600" aria-hidden="true" />
                </div>
                <input
                  defaultValue={searchText}
                  type="text"
                  name="searchText"
                  onChange={(e): void => setSearchText(e.target.value)}
                  className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-9"
                  placeholder="Search Asset ID or Lease Name"
                />
              </div>
            </div>
            <div className="">
              <label htmlFor="valuationPolicy" className="whitespace-nowrap block text-sm font-medium text-gray-700">
                Lease Type
              </label>
              <select
                defaultValue={leaseType}
                name="leaseType"
                onChange={(e): void => setLeaseType(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="All">All</option>
                <option value="Retail">Retail</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3 h-50">
        <BasicTable
          columns={columns}
          data={leases}
          isLoading={isSearchingLease}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          exportAction={exportLeases}
          isExporting={isExportingLeases}
          deleteAction={deleteLeases}
          isDeleting={isDeletingLeases}
          tablePageSize={tablePageSize}
          setTablePageSize={setTablePageSize}
          context="Lease"
        />
      </div>
    </div>
  );
};

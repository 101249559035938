import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { Link } from 'react-router-dom';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ValuationProfileRule } from '../../models/ValuationProfile';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { Job } from '../../models/Job';

interface GroupedRules {
  [key: string]: ValuationProfileRule[];
}

type Variables = { id: number };

const groupByKey = (list, key) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const RulesList: React.FC<{}> = () => {
  const api = useApi();
  const { mutate, isLoading: isDeleting } = useMutation((params: Variables) =>
    api.post('/api/ValuationProfileRule/Delete', params)
  );
  const [jobId, setJobId] = useState<number>(0);
  const { addToast } = useToast();
  const { roles } = useAuth();
  const { checkAssetClassEditable } = useClient();
  const [confirmationBox, setConfirmationBox] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [assetClassId, setAssetClassId] = useState<number>(0);
  const queryClient = useQueryClient();

  const openConfirmationBox = (id: number, assetClassId: number): void => {
    setId(id);
    setAssetClassId(assetClassId);
    setConfirmationBox(true);
  };

  const deleteAction = (): void => {
    addToast(`Deleting Rule`, ToastType.Warn);
    mutate(
      { id },
      {
        onSuccess: ({ data }) => {
          addToast(`Deleted Rule`, ToastType.Message);
        },
        onError: (error) => {
          addToast(`Unable to Delete Rule`, ToastType.Error);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['valuationProfileRule', id]);
          queryClient.invalidateQueries(['asset']);
          queryClient.invalidateQueries(['component']);
          queryClient.invalidateQueries(['valuationProfileRuleList']);
        },
      }
    );
  };

  const { isLoading, data: valuationProfileRules = [] as ValuationProfileRule[] } = useQuery<ValuationProfileRule[], Error>(
    ['valuationProfileRuleList', jobId],
    async () => api.get(`/api/ValuationProfileRule/List?jobId=${jobId}`).then((res) => res.data)
  );

  const { data: jobs = [{ id: 0, name: 'All' }] as Job[] } = useQuery<Job[], Error>(['jobslist'], () =>
    api.get('/api/Job/List').then((res) => res.data)
  );

  const assetClasses: GroupedRules = groupByKey(valuationProfileRules, 'assetClassName');

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between">
        <div className="flex flex-col flex-start space-y-3">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Valuation Profile Rules</h1>
          <select
            className="inline-flex s pl-3 py-2 text-base border-gray-300 rounded-md bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e): void => {
              setJobId(parseInt(e.target.value));
            }}
          >
            <option value={0}>All Jobs</option>
            {jobs.map((job) => {
              if (job.status !== 'Archive') {
                return <option value={job.id}>{job.name}</option>;
              }
            })}
          </select>
        </div>
        {valuationProfileRules.length > 0 && !roles.includes(Roles.Viewer) && (
          <div className="mt-4 flex sm:mt-0 sm:ml-4 space-x-4">
            <Link to="/rules/0/edit" type="button" className="order-2 btn btn-primary">
              Create Rule
            </Link>
          </div>
        )}
      </div>
      <div className="m-4 divide-y-2">
        {Object.entries(assetClasses).map(([assetClass, item]) => (
          <div key={assetClass} className="mb-10">
            <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">{assetClass}</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              {item
                .sort((a, b) => b.usefulLife - a.usefulLife)
                .map((rule) => (
                  <div
                    key={rule.id}
                    className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                  >
                    <dt className="space-y-2">
                      <p className="text-sm font-medium text-gray-500 truncate">
                        When Useful Life is greater than{' '}
                        <span className="text-indigo-700 text-base font-bold">{rule.usefulLife} yrs</span>
                      </p>
                      <p className="text-sm font-medium text-gray-500 truncate">
                        Then use the <span className="text-indigo-700 text-base font-bold">{rule.valuationProfileName}</span>
                      </p>
                    </dt>

                    <dd className="pb-2 flex items-baseline sm:pb-3">
                      {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && (
                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={(): void => openConfirmationBox(rule.id, rule.assetClassId)}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      )}{' '}
                      <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                          {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) ? (
                            <Link
                              to={`/rules/${rule.id}/edit`}
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              Edit<span className="sr-only"> {rule.assetClassName} rule</span>
                            </Link>
                          ) : (
                            <button disabled className="font-medium disabled:opacity-20">
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </dd>
                  </div>
                ))}
            </dl>
          </div>
        ))}
        {valuationProfileRules.length === 0 && !roles.includes(Roles.Viewer) && (
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            <Link
              to="/rules/0/edit"
              type="button"
              className="relative flex flex-col justify-center items-center bg-white pb-12 px-4 pt-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
              <span className="mt-2 block text-sm font-medium text-gray-900">Create new profile rule</span>
            </Link>
          </div>
        )}
      </div>
      <DeleteConfirmation
        itemType="rule"
        isOpen={confirmationBox}
        setOpen={setConfirmationBox}
        deleteAction={deleteAction}
      />
    </>
  );
};

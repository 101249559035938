import { createContext, useContext, useEffect, useState } from 'react';
import { downloadHandler } from '../Helper';
import { Lease } from '../models/Lease';
import { useApi } from './ApiContext';
import { useAuth } from './AuthContext';
import { ToastType, useToast } from './ToastContext';
import { useQuery } from '@tanstack/react-query';

interface LeaseSearchContext {
  leases: Lease[];
  setLeases: (leases: Lease[]) => void;
  getLeases: (text: string, leaseType?: string) => void;
  deleteLeases: (leaseIds: number[]) => void;
  exportLeases: (leaseIds: number[]) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  leaseType: string;
  setLeaseType: (type: string) => void;
  isSearchingLease: boolean;
  setIsSearchingLease: (open: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isDeletingLeases: boolean;
  isExportingLeases: boolean;
  leasesToExport: number[];
  setIsExportingLeases: (open: boolean) => void;
  tablePageSize: number;
  setTablePageSize: (size: number) => void;
}

const LeaseSearchContext = createContext<LeaseSearchContext>({} as LeaseSearchContext);

export const LeaseSearchProvider: React.FC<any> = (props) => {
  const api = useApi();
  const { client } = useAuth();
  const [tablePageSize, setTablePageSize] = useState<number>(10);
  const [leases, setLeases] = useState<Lease[]>([]);
  // const [dbLeases, setDbLeases] = useState<Lease[]>([]);
  const [leasesToExport, setLeasesToExport] = useState<number[]>([]);
  const [isSearchingLease, setIsSearchingLease] = useState<boolean>(false);
  const [isDeletingLeases, setIsDeletingLeases] = useState<boolean>(false);
  const [isExportingLeases, setIsExportingLeases] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [leaseType, setLeaseType] = useState<string>('All');
  const { addToast } = useToast();

  const { refetch: refetchLeases, data: dbLeases = [] as Lease[] } = useQuery<Lease[], Error>(['Lease List'], async () =>
    api.get('/api/Lease/List').then(({ data }) => data)
  );
  useEffect(() => {
    if (dbLeases && dbLeases.length > 0) {
      setLeases(dbLeases);
    }
  }, [dbLeases]);

  const getLeases = (text: string, type: string): void => {
    setSearchText(text);
    setLeaseType(type);
    setIsSearchingLease(true);
    const lowerSearchText = text.toLowerCase();
    setLeases(
      dbLeases.filter(
        (lease) =>
          (type === 'All' ? true : lease.leaseType === type) &&
          (lowerSearchText === '' || lowerSearchText === null
            ? true
            : lease.assetReference?.toLowerCase().includes(lowerSearchText) ||
              lease.name?.toLowerCase().includes(lowerSearchText) ||
              lease.addressLine1?.toLowerCase().includes(lowerSearchText) ||
              lease.suburb?.toLowerCase().includes(lowerSearchText))
      )
    );
    setIsSearchingLease(false);
  };

  const exportLeases = (leaseIds: number[]): void => {
    setIsExportingLeases(true);
    api.post('/api/Lease/Export', { leaseIds }, { responseType: 'blob' }).then(
      ({ data }) => {
        setIsExportingLeases(false);
        downloadHandler(data, `${client} Leases.xlsx`);
      },
      (error) => {
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const deleteLeases = (leaseIds: number[]): void => {
    setIsDeletingLeases(true);
    api.post('/api/Lease/BulkDelete', { leaseIds }).then(
      ({ data }) => {
        setIsDeletingLeases(false);
        setLeases(leases.filter((lease) => !leaseIds.includes(lease.id)));
        addToast(`${data.message}`);
      },
      (error) => {
        setIsDeletingLeases(false);
        addToast('BulkDelete Failed', ToastType.Error);
      }
    );
  };

  useEffect(() => {
    getLeases(searchText, leaseType);
  }, [leaseType, searchText]);

  return (
    <LeaseSearchContext.Provider
      value={{
        leases,
        getLeases,
        searchText,
        setSearchText,
        leaseType,
        setLeaseType,
        isSearchingLease,
        setIsSearchingLease,
        currentPage,
        setCurrentPage,
        deleteLeases,
        isDeletingLeases,
        exportLeases,
        isExportingLeases,
        setIsExportingLeases,
        leasesToExport,
        tablePageSize,
        setTablePageSize,
      }}
      {...props}
    />
  );
};

export const useLeaseSearch = (): LeaseSearchContext => useContext(LeaseSearchContext);
export default LeaseSearchProvider;

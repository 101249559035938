import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ReactComponent as Photo } from '../../icons/PhotoOutline.svg';
import { AssetAssumptions } from '../../models/AssetAssumptions';
import { HierarchyType } from '../../models/HierarchyType';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

const AssetAssumptionsEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();
  const [assetAssumptions, setAssetAssumptions] = useState<AssetAssumptions>({} as AssetAssumptions);
  const [financialAssetSubClasses, setFinancialAssetSubClasses] = useState<HierarchyType[]>([]);
  const [valuationClasses, setValuationClasses] = useState<HierarchyType[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const AssetAssumptionsSchema = yup.object().shape({
    localityFactorPct: yup
      .number()
      .min(0, 'Cannot be a negative number.')
      .max(100, 'Maximum length is 100.')
      .required('Name for the client is required'),
    unitRate: yup.number().min(0, 'Cannot be a negative number.').required('Unit Rate is required'),

    start: yup.date().when('groupId', {
      is: (field) => !!field,
      then: (schema) => schema.required('Start date is required'),
    }),
    expiry: yup.date().when('groupId', {
      is: (field) => !!field,
      then: (schema) => schema.required('Expiry date is required'),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,

    formState: { errors },
  } = useForm<AssetAssumptions>({
    defaultValues: {
      id: parseInt(id),
    },
    reValidateMode: 'onBlur',
    // resolver: yupResolver(ClientSchema),
  });

  const watchFinancialAssetClassId = watch('financialAssetClassId', 0); // you can supply default value as second argument
  const {
    isLoading,
    isSuccess,
    error,
    data: financialAssetClasses = [] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(['financialAssetClassList'], () =>
    api.post('/api/Lookup/FinancialAssetClass', {}).then(({ data }) => data));

  useEffect(() => {
    api
      .post('/api/ValuationClass/List', {})
      .then(({ data }) => {
        // console.log(`got valuation classes`, data)
        setValuationClasses(data);
        queryClient.invalidateQueries(['asset']);
        queryClient.invalidateQueries(['component']);
      })
      .catch((error) => {
        addToast(`Unable to Load Valuation Classes`, ToastType.Error);
      });
  }, []);

  useEffect(() => {
    if (parseInt(id) > 0 && financialAssetClasses.length > 0) {
      api
        .get(`/api/Assumptions/GetAssetAssumptions?id=${id}`)
        .then(({ data }) => {
          setAssetAssumptions(data);
          reset({
            ...data,
            localityFactorPct: data.localityFactorPct * 100,
            indices: data.indices.map((index) => index * 100),
          });
        })
        .catch((error) => {
          addToast(`Unable to get asset assumptions with ID ${id}`, ToastType.Error);
        });
    }
  }, [id, financialAssetClasses]);

  useEffect(() => {
    if (getValues('financialAssetClassId') > 0) {
      // console.log(`financialAssetClassId`, watchFinancialAssetClassId)
      api
        .get(`/api/Lookup/FinancialAssetSubClass?financialAssetClassId=${watchFinancialAssetClassId}`)
        .then(({ data }) => {
          setFinancialAssetSubClasses(data);
        })
        .catch((error) => {
          addToast(`Unable to Load Financial Asset Sub Classes`, ToastType.Error);
        });
    }
  }, [watchFinancialAssetClassId]);

  const refreshFinancialAssetSubClasses = (): void => {
    api
      .post('/api/Lookup/FinancialAssetSubClass', {
        financialAssetClassId: getValues('financialAssetClassId'),
      })
      .then(({ data }) => {
        setFinancialAssetSubClasses(data);
      })
      .catch((error) => {
        addToast(`Unable to Load Financial Asset Sub Classes`, ToastType.Error);
      });
  };

  const onSubmit = (formData: AssetAssumptions): void => {
    setIsSaving(true);
    const { assetClass, assetSubType, assetType, financialAssetClass, financialAssetSubClass, ...restOfAssumptions } =
      formData;

    const assumptions = {
      ...restOfAssumptions,
      localityFactorPct: formData.localityFactorPct / 100,
      indices: formData.indices.map((index) => index / 100),
    };

    api
      .post('/api/Assumptions/UpdateAssetAssumptions', { assetAssumptions: assumptions })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} Asset Assumptions`);
        setIsSaving(false);
        history(`/assetassumptions/${assumptions.id}`);
      })
      .catch((error) => {
        setIsSaving(false);
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} Asset Assumptions ${
            parseInt(id) > 0 && `with ID ${id}`
          }`,
          ToastType.Error
        );
      });
  };

  const { assetClass, assetType, assetSubType } = assetAssumptions;
  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('id', { valueAsNumber: true })} />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Asset Assumptions</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-600">
                  Asset Class
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetClass}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetType}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Sub Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetSubType}</dd>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            {/* <h3 className="text-lg font-medium leading-6 text-gray-900">Asset Assumptions</h3> */}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Financial Asset Class
                </label>
                <select
                  {...register('financialAssetClassId', { valueAsNumber: true })}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {financialAssetClasses.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Financial Asset Sub Class
                </label>
                <select
                  {...register('financialAssetSubClassId', { valueAsNumber: true })}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {financialAssetSubClasses.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-6">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Valuation Class
                </label>
                <select
                  {...register('valuationClassId', { valueAsNumber: true })}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {valuationClasses.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Unit Rate
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    {...register('unitRate', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Locality Factor
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('localityFactorPct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Indexation</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Year 1
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('indices.0', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Year 2
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('indices.1', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Year 3
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('indices.2', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Year 4
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('indices.3', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Year 5
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('indices.4', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/assetassumptions/${id}`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {isSaving && (
              <div className="flex space-x-4">
                <Loader className="animate-spin w-5 h-5" />
                <div>Saving</div>
              </div>
            )}

            {!isSaving && 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AssetAssumptionsEdit;

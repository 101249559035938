import React, { createContext, useState, useContext, useEffect } from 'react';
import { ToastType, useToast } from './ToastContext';
import { HierarchyType } from '../models/HierarchyType';
import { useApi } from './ApiContext';
import { AssetAssumptions } from '../models/AssetAssumptions';
import { ComponentAssumptionsWithHierarchy } from '../models/ComponentAssumptions';
import { InsuranceAssumptions } from '../models/InsuranceAssumptions';

interface AssumptionsContext {
  assetAssumptions: AssetAssumptions[];
  setAssetAssumptions: (assetAssumptions: AssetAssumptions[]) => void;
  componentAssumptions: ComponentAssumptionsWithHierarchy[];
  setComponentAssumptions: (componentAssumptions: ComponentAssumptionsWithHierarchy[]) => void;
  insuranceAssumptions: InsuranceAssumptions[];
  setInsuranceAssumptions: (insuranceAssumptions: InsuranceAssumptions[]) => void;
  assetAssumptionsSearchText: string;
  setAssetAssumptionsSearchText: string;
  componentAssumptionsSearchText: string;
  insuranceAssumptionsSearchText: string;
  getAssetAssumptions: (
    searchText: string,
    assetClassId: number,
    assetTypeId: number,
    assetSubTypeId: number,
    valuationClassId: number
  ) => void;
  getComponentAssumptions: (
    searchText: string,
    assetClassId: number,
    assetTypeId: number,
    assetSubTypeId: number,
    componentNameId: number,
    componentTypeId: number,
    componentSubTypeId: number
  ) => void;
  getInsuranceAssumptions: (searchText: string, assetClassId: number, assetTypeId: number, assetSubTypeId: number) => void;
  assetClassList: HierarchyType[];
  fetchAssetClasses: (jobId: number) => void;
  valuationClassList: HierarchyType[];
  jobList: HierarchyType[];
  isSearchingAssetAssumptions: boolean;
  setIsSearchingAssetAssumptions: (isSearchingAssetAssumptions: boolean) => void;
  isSearchingComponentAssumptions: boolean;
  setIsSearchingComponentAssumptions: (isSearchingComponentAssumptions: boolean) => void;
  isSearchingInsuranceAssumptions: boolean;
  setIsSearchingInsuranceAssumptions: (isSearchingInsuranceAssumptions: boolean) => void;
  tableAssetAssumptionsPageSize: number;
  setAssetAssumptionsTablePageSize: (size: number) => void;
  tableComponentAssumptionsPageSize: number;
  setComponentAssumptionsTablePageSize: (size: number) => void;
  tableInsuranceAssumptionsPageSize: number;
  setInsuranceAssumptionsTablePageSize: (size: number) => void;
  assetAssumptionsCurrentPage: number;
  setAssetAssumptionsCurrentPage: (page: number) => void;
  componentAssumptionsCurrentPage: number;
  setComponentAssumptionsCurrentPage: (page: number) => void;
  insuranceAssumptionsCurrentPage: number;
  setInsuranceAssumptionsCurrentPage: (page: number) => void;
}

const AssumptionsContext = createContext<AssumptionsContext>({} as AssumptionsContext);

export const AssumptionsSearchProvider: React.FC<any> = (props) => {
  const api = useApi();
  const [jobList, setJobList] = useState<HierarchyType[]>([]);
  const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([]);
  const [tableAssetAssumptionsPageSize, setAssetAssumptionsTablePageSize] = useState<number>(10);
  const [tableComponentAssumptionsPageSize, setComponentAssumptionsTablePageSize] = useState<number>(10);
  const [tableInsuranceAssumptionsPageSize, setInsuranceAssumptionsTablePageSize] = useState<number>(10);

  const [assetAssumptions, setAssetAssumptions] = useState<AssetAssumptions[]>([] as AssetAssumptions[]);
  const [componentAssumptions, setComponentAssumptions] = useState<ComponentAssumptionsWithHierarchy[]>(
    [] as ComponentAssumptionsWithHierarchy[]
  );
  const [insuranceAssumptions, setInsuranceAssumptions] = useState<InsuranceAssumptions[]>([] as InsuranceAssumptions[]);

  const [isSearchingAssetAssumptions, setIsSearchingAssetAssumptions] = useState<boolean>(false);
  const [isSearchingComponentAssumptions, setIsSearchingComponentAssumptions] = useState<boolean>(false);
  const [isSearchingInsuranceAssumptions, setIsSearchingInsuranceAssumptions] = useState<boolean>(false);

  const [assetAssumptionsSearchText, setAssetAssumptionsSearchText] = useState<string>('');
  const [componentAssumptionsSearchText, setComponentAssumptionsSearchText] = useState<string>('');
  const [insuranceAssumptionsSearchText, setInsuranceAssumptionsSearchText] = useState<string>('');

  const [assetAssumptionsCurrentPage, setAssetAssumptionsCurrentPage] = useState<number>(0);
  const [componentAssumptionsCurrentPage, setComponentAssumptionsCurrentPage] = useState<number>(0);
  const [insuranceAssumptionsCurrentPage, setInsuranceAssumptionsCurrentPage] = useState<number>(0);

  const { addToast } = useToast();

  const fetchAssetClasses = (jobId: number): void => {
    api
      .get(`/api/AssetClass/ListByJob?JobId=${jobId}`)
      .then(({ data }) => {
        setAssetClassList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    api
      .get(`/api/Job/ListOptions?JobStatus=active`)
      .then(({ data }) => {
        setJobList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetchAssetClasses(0);
  }, [jobList]);

  const getAssetAssumptions = (
    searchText: string,
    assetClassId: number,
    assetTypeId: number,
    assetSubTypeId: number,
    valuationClassId: number
  ): void => {
    setIsSearchingAssetAssumptions(true);
    setAssetAssumptions([]);
    setAssetAssumptionsSearchText(searchText);
    setAssetAssumptionsCurrentPage(0);
    api
      .post('/api/Assumptions/ListAssetAssumptions', {
        searchText,
        assetClassId,
        assetTypeId,
        assetSubTypeId,
        valuationClassId,
      })
      .then(({ data }) => {
        setIsSearchingAssetAssumptions(false);
        setAssetAssumptions(data);
      })
      .catch((error) => {
        setIsSearchingAssetAssumptions(false);
      });
  };

  const getComponentAssumptions = (
    searchText: string,
    assetClassId: number,
    assetTypeId: number,
    assetSubTypeId: number,
    componentNameId: number,
    componentTypeId: number,
    componentSubTypeId: number
  ): void => {
    setIsSearchingComponentAssumptions(true);
    setComponentAssumptions([]);
    setComponentAssumptionsSearchText(searchText);
    setComponentAssumptionsCurrentPage(0);
    api
      .post(`/api/Assumptions/ListComponentAssumptions?SearchText`, {
        searchText,
        assetClassId,
        assetTypeId,
        assetSubTypeId,
        componentNameId,
        componentTypeId,
        componentSubTypeId,
      })
      .then(({ data }) => {
        setIsSearchingComponentAssumptions(false);
        setComponentAssumptions(data);
      })
      .catch((error) => {
        setIsSearchingComponentAssumptions(false);
      });
  };

  const getInsuranceAssumptions = (
    searchText: string,
    assetClassId: number,
    assetTypeId: number,
    assetSubTypeId: number
  ): void => {
    setIsSearchingInsuranceAssumptions(true);
    setInsuranceAssumptions([]);
    setInsuranceAssumptionsSearchText(searchText);
    setInsuranceAssumptionsCurrentPage(0);
    api
      .post(`/api/Assumptions/ListInsuranceAssumptions`, { searchText, assetClassId, assetTypeId, assetSubTypeId })
      .then(({ data }) => {
        setIsSearchingInsuranceAssumptions(false);
        setInsuranceAssumptions(data);
      })
      .catch((error) => {
        setIsSearchingInsuranceAssumptions(false);
      });
  };

  return (
    <AssumptionsContext.Provider
      value={{
        assetAssumptions,
        setAssetAssumptions,
        getAssetAssumptions,
        componentAssumptions,
        setComponentAssumptions,
        getComponentAssumptions,
        insuranceAssumptions,
        setInsuranceAssumptions,
        getInsuranceAssumptions,
        fetchAssetClasses,
        jobList,
        assetClassList,
        assetAssumptionsSearchText,
        componentAssumptionsSearchText,
        insuranceAssumptionsSearchText,
        isSearchingAssetAssumptions,
        setIsSearchingAssetAssumptions,
        isSearchingComponentAssumptions,
        setIsSearchingComponentAssumptions,
        isSearchingInsuranceAssumptions,
        setIsSearchingInsuranceAssumptions,
        assetAssumptionsCurrentPage,
        setAssetAssumptionsCurrentPage,
        componentAssumptionsCurrentPage,
        setComponentAssumptionsCurrentPage,
        insuranceAssumptionsCurrentPage,
        setInsuranceAssumptionsCurrentPage,
        tableAssetAssumptionsPageSize,
        setAssetAssumptionsTablePageSize,
        tableComponentAssumptionsPageSize,
        setComponentAssumptionsTablePageSize,
        tableInsuranceAssumptionsPageSize,
        setInsuranceAssumptionsTablePageSize,
      }}
      {...props}
    />
  );
};

export const useAssumptionsSearch = (): AssumptionsContext => useContext(AssumptionsContext);
export default AssumptionsSearchProvider;

import { BlobServiceClient } from '@azure/storage-blob';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { parse } from 'date-fns';
import { useApi } from '../contexts/ApiContext';
import { useAuth } from '../contexts/AuthContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { Asset, IncomeApproach, MarketApproach } from '../models/Asset';
import { AssetAssumptions } from '../models/AssetAssumptions';
import { ReplacementCost } from '../models/ReplacementCostSummary';
import { ImageDisplay, ImagesImport } from '../models/Image';
import { Note } from '../models/Note';
import { Component } from '../models/Component';

export enum ReplacementCostType {
  Asset,
  Component,
}

const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

const emptyAsset: Asset = {
  id: 0,
  name: '',
  history: '',
  assetClassId: 0,
  assetClass: '',
  assetType: '',
  assetTypeId: 0,
  assetSubTypeId: 0,
  assetSubType: '',
  assumptions: {} as AssetAssumptions,
  assumptionsId: 0,
  financialAssetClassId: 0,
  financialAssetClass: '',
  financialAssetSubClassId: 0,
  financialAssetSubClass: '',
  facility: '',
  subFacility: '',
  valuer: '',
  reference: '',
  customerReference: '',
  isApportionment: false,
  gross: 0,
  isValid: false,
  isInsuranceRequired: false,
  status: '',
  inspectorName: '',
  isHeritageAsset: false,
  isHeldForSale: false,
  isInvestment: false,
  isControlledForFinancialPurpose: true,
  valuationPolicy: '',
  valuationType: '',
  marketApproach: {} as MarketApproach,
  incomeApproach: {} as IncomeApproach,
  insurance: {
    coverage: '',
    leadBuildMonths: 0,
    demoMonths: 0,
    escalationFactorPct: 0,
    professionalFeesPct: 0,
    debrisRemovalPct: 0,
    debrisRemovalMinimum: 0,
    debrisRemoval: 0,
    total: 0,
    escalationFees: 0,
    professionalFees: 0,
    totalPlusEscalation: 0,
    totalPlusEscalationPlusProfessional: 0,
  },
  isHighestAndBestUse: true,
  valuationClassId: 0,
  valuationClass: {
    id: 0,
    name: '',
    valuationLevel: 0,
    valuationTechnique: '',
    assetsCount: 0,
  },
  acquisitionDate: undefined,
  decommissionDate: undefined,
  indexedFromDate: undefined,
  latitude: 0,
  longitude: 0,
  assetCustodian: '',
  replacementCosts: [] as ReplacementCost[],
  obsolescenseAdjustmentPct: 0,
  valuationMethod: undefined,
  notes: [] as Note[],
  optimisationAdjustmentPct: 0,
  previousGross: 0,
  previousCurrentValue: 0,
  previousDepreciationExpense: 0,
  hasErrors: false,
  hasWarnings: false,
  errors: [],
  warnings: [],
  customFields: [],
  images: [] as ImageDisplay[],
  components: [],
};

const useAssetById = (id: number): UseQueryResult<Asset, Error> => {
  const blobServiceClient = new BlobServiceClient(BLOB_URL);
  const api = useApi();
  const { clientGuid } = useAuth();
  const queryClient = useQueryClient();
  const result: UseQueryResult<Asset, Error> = useQuery(
    ['asset', id],
    async () => {
      async function fetchImages(): Promise<Array<ImageDisplay>> {
        const blobResults = blobServiceClient.findBlobsByTags(
          `@container='${clientGuid}'AND assetId = '${asset.reference}' AND type = 'Image' AND isThumbnail = 'False' AND dateCreated >= '01/01/0001'`
        );

        // const container = blobServiceClient.getContainerClient('test');
        const tempImages: Array<ImageDisplay> = [];
        try {
          for await (const blob of blobResults) {
            const fileArray = blob.name.split('/');
            const fileName = fileArray[1].split('.')[0];
            const dateCreated = blob?.tags?.dateCreated.split(' ')[0];
            const timeCreated = blob?.tags?.dateCreated.split(' ')[1];
            tempImages.push({
              original: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`,
              thumbnail: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${
                fileArray[0]
              }/thumbnails/${fileName}.jpeg`,
              date: dateCreated,
              time: timeCreated,
            });
          }
        } catch (error) {
          console.error('Could not load images', error);
        }
        return tempImages.sort((a, b) => {
          const firstDate = parse(a.date || '', 'dd/MM/yyyy', new Date());
          const secondDate = parse(b.date || '', 'dd/MM/yyyy', new Date());
          const firstTime = parse(a.time || '', 'HH:mm:ss', new Date());
          const secondTime = parse(b.time || '', 'HH:mm:ss', new Date());
          if (firstDate === secondDate) {
            return firstTime > secondTime ? -1 : 1;
          }
          return firstDate > secondDate ? -1 : 1;
        });
      }

      async function fetchContentsImages(contentsId: string): Promise<Array<ImageDisplay>> {
        const blobResults = blobServiceClient.findBlobsByTags(
          `@container='${clientGuid}'AND contentsId = '${contentsId}' AND type = 'Image' AND isThumbnail = 'False' AND dateCreated >= '01/01/0001'`
        );
        const tempImages: Array<ImageDisplay> = [];
        try {
          for await (const blob of blobResults) {
            const fileArray = blob.name.split('/');
            const fileName = fileArray[1].split('.')[0];
            const dateCreated = blob?.tags?.dateCreated.split(' ')[0];
            const timeCreated = blob?.tags?.dateCreated.split(' ')[1];
            tempImages.push({
              original: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`,
              thumbnail: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${
                fileArray[0]
              }/thumbnails/${fileName}.jpeg`,
              date: dateCreated,
              time: timeCreated,
            });
          }
        } catch (error) {
          console.error('Could not load images');
          console.error('error', error);
        }
        const result = tempImages.sort((a, b) => {
          const firstDate = parse(a.date || '', 'dd/MM/yyyy', new Date());
          const secondDate = parse(b.date || '', 'dd/MM/yyyy', new Date());
          const firstTime = parse(a.time || '', 'HH:mm:ss', new Date());
          const secondTime = parse(b.time || '', 'HH:mm:ss', new Date());
          if (firstDate === secondDate) {
            return firstTime > secondTime ? -1 : 1;
          }
          return firstDate > secondDate ? -1 : 1;
        });
        console.log('result', result);
        return result;
      }
      // let asset: Asset = {} as Asset;
      if (id === 0) {
        return emptyAsset;
      }
      let { data: asset } = await api.get<Asset>(`/api/Asset/Get?id=${id}`);
      const images = await fetchImages();
      // asset = { ...asset };
      const { contents } = asset;
      for (const content of contents || []) {
        const contentsImages = await fetchContentsImages(`${content.id}`);
        content.images = contentsImages;
      }
      asset = { ...asset, images, contents };
      return asset;
    },
    {
      staleTime: 1800000,
      cacheTime: 0,
    }
  );
  if (result.isSuccess && id > 0) {
    result.data.components.forEach((component) => {
      queryClient.setQueryData(['component', component.id], result.data);
    });
  }
  return result;
};

const useAssetByComponentId = (id: number): UseQueryResult<Asset, Error> => {
  const api = useApi();
  const result: UseQueryResult<Asset, Error> = useQuery(['component', id], async () => {
    const { data } = await api.get(`/api/Asset/GetByComponentId?id=${id}`);
    return data;
  });
  return result;
};

const useAssetByContentsId = (id: number): UseQueryResult<Asset, Error> => {
  const api = useApi();
  const blobServiceClient = new BlobServiceClient(BLOB_URL);
  const { clientGuid } = useAuth();
  const result: UseQueryResult<Asset, Error> = useQuery(['contents', id], async () => {
    async function fetchContentsImages(contentsId: string): Promise<Array<ImageDisplay>> {
      const blobResults = blobServiceClient.findBlobsByTags(
        `@container='${clientGuid}'AND contentsId = '${contentsId}' AND type = 'Image' AND isThumbnail = 'False' AND dateCreated >= '01/01/0001'`
      );
      const tempImages: Array<ImageDisplay> = [];
      try {
        for await (const blob of blobResults) {
          const fileArray = blob.name.split('/');
          const fileName = fileArray[1].split('.')[0];
          tempImages.push({
            original: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`,
            thumbnail: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${fileArray[0]}/thumbnails/${fileName}.jpeg`,
            date: blob.tags !== undefined ? blob.tags.dateCreated : undefined,
          });
        }
      } catch (error) {
        console.error('Could not load images');
        console.error('error', error);
      }
      return tempImages;
    }
    const { data: asset } = await api.get(`/api/Asset/GetByContentsId?id=${id}`);
    const { contents } = asset;
    const contentsImages = await fetchContentsImages(contents.find((con) => con.id === id).contentsId);
    contents.find((con) => con.id === id).images = contentsImages;
    return asset;
  });
  return result;
};

const useUpdateAsset = (): UseMutationResult<number, Error, Asset> => {
  const api = useApi();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation(
    (asset: Asset) =>
      api.post(`/api/Asset/Update`, { asset }).then(({ data }) => {
        if (data === 0) {
          addToast(`Found duplicate Asset Id ${asset.reference}. Unable to save`, ToastType.Error);
        } else {
          asset.components.forEach((component) => {
            queryClient.invalidateQueries(['component', component.id]);
          });
        }
        return data;
      }),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['asset', id]);
        queryClient.invalidateQueries(['component']);
        addToast(`Updated Asset`);
      },
    }
  );
};

const useUpdateComponent = (): UseMutationResult<number, Error, { component: Component; assetId?: number }> => {
  const api = useApi();

  const queryClient = useQueryClient();

  return useMutation(
    ({ component, assetId }) => api.post<number>(`/api/Component/Update`, { component, assetId }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['asset']);
        queryClient.invalidateQueries(['component']);
        queryClient.invalidateQueries(['componentedit', id]);
      },
    }
  );
};

const useDeleteComponent = (): UseMutationResult<boolean, Error, { id: number; componentId: number }> => {
  const api = useApi();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation(
    ({ id, componentId }) =>
      api.post<boolean>('/api/Component/delete', { id: componentId, assetId: id }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        addToast('Component Deleted');
        queryClient.invalidateQueries(['component']);
        queryClient.invalidateQueries(['componentedit']);
        queryClient.invalidateQueries(['asset', id]);
      },
    }
  );
};

const useDeleteAssetReplacementCost = (): UseMutationResult<boolean, Error, { id: number; assetId: number }> => {
  const api = useApi();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation(
    ({ id, assetId }) => api.post('/api/AssetReplacementCost/delete', { id, assetId }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        addToast('Replacement Cost Deleted');
        queryClient.invalidateQueries(['component']);
        queryClient.invalidateQueries(['asset', id]);
      },
    }
  );
};

const useDeleteComponentReplacementCost = (): UseMutationResult<boolean, Error, { id: number; componentId: number }> => {
  const api = useApi();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation(
    ({ id, componentId }) => api.post('/api/ComponentReplacementCost/delete', { id, componentId }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        addToast('Replacement Cost Deleted');
        queryClient.invalidateQueries(['component']);
        queryClient.invalidateQueries(['asset', id]);
      },
    }
  );
};

export {
  useAssetById,
  useUpdateAsset,
  useAssetByComponentId,
  useAssetByContentsId,
  useDeleteComponent,
  useUpdateComponent,
  useDeleteAssetReplacementCost,
  useDeleteComponentReplacementCost,
};

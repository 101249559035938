import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { BlobServiceClient } from '@azure/storage-blob';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { DocumentType } from '../models/DocumentType';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useAuth } from '../contexts/AuthContext';
import { HelpFile } from '../models/HelpFile';

interface Document {
  name: string;
  type: DocumentType;
  uploadDate: string;
  url: string;
}

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

const HelpSlide: React.FC<Props> = ({ open, setOpen }) => {
  const { roles } = useAuth();
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.WorkPaper);
  const { addToast } = useToast();
  const [helpFiles, setHelpFiles] = useState<HelpFile[]>([] as HelpFile[]);
  const blobServiceClient: BlobServiceClient = new BlobServiceClient(BLOB_URL);

  const entityQuery = `isHelpFile = 'True'`;
  async function fetchDocs(): Promise<HelpFile[]> {
    const query = `@container='system-files' AND ${entityQuery}`;
    const blobResults = blobServiceClient.findBlobsByTags(query);
    const tempDocs: Array<HelpFile> = [];
    try {
      for await (const blob of blobResults) {
        const blobName = blob.name.split('.');
        tempDocs.push({
          url: `${import.meta.env.VITE_BLOB_STORAGE_URL}/system-files/${blob.name}`,
          name: `${blob.name}`,
          type: blobName[0],
        });
      }
    } catch (error) {
      // addToast('Could not load images', ToastType.Error);
      console.error('error', error);
    }
    setHelpFiles(tempDocs);
    setIsSuccess(false);
    return tempDocs;
  }

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    if (isSuccess && open) {
      fetching();
    }
  }, [isSuccess, open]);

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    fetching();
  }, []);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div onClick={(): void => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-sm pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-medium text-gray-900"> Help </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={(): void => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {helpFiles.map((file, index) => (
                        <div key={index} className="m-1 py-2">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            {file.name}
                          </a>
                        </div>
                      ))}
                    </div>
                    <img
                      src="../images/AVPLogo_Banner.png"
                      alt="AVP Banner"
                      className="w-3/4 h-auto flex-shrink-0 mx-auto rounded-full"
                    />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HelpSlide;

import React, { createContext, useState, useContext, useEffect } from 'react';

import { useToast } from './ToastContext';
import { HierarchyType } from '../models/HierarchyType';
import { Component } from '../models/Component';
import { useApi } from './ApiContext';

interface ComponentSearchContext {
  components: Component[];
  setComponents: (Components: Component[]) => void;
  getComponents: (text: string, jobId: number) => void;
  searchText: string;
  setJobFilter: (job: string) => void;
  setSearchText: () => void;
  jobList: HierarchyType[];
  assetClassList: HierarchyType[];
  isSearchingComponent: boolean;
  setIsSearchingComponent: () => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const ComponentSearchContext = createContext<ComponentSearchContext>({} as ComponentSearchContext);

// This component retrieves the strategies from the API so they can be read by all components
export const ComponentSearchProvider: React.FC<any> = (props) => {
  const api = useApi();
  const [components, setComponents] = useState<Component[]>([] as Component[]);
  const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([]);
  const [jobList, setJobList] = useState<HierarchyType[]>([]);
  const [isSearchingComponent, setIsSearchingComponent] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const { addToast } = useToast();

  const getComponents = (text: string, jobId: number): void => {
    setIsSearchingComponent(true);
    setSearchText(text);
    setComponents([]);
    api
      .post(`/api/Component/SearchComponents`, { searchText: text, jobId })
      .then(({ data }) => {
        setCurrentPage(0);
        console.log('data', data)
        setIsSearchingComponent(false);
        setComponents(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    api
      .get(`/api/AssetClass/ListByJob`)
      .then(({ data }) => {
        setAssetClassList(data);
      })
      .catch((error) => {
        console.log(`csearch error`, error)
      });
  }, []);
  useEffect(() => {
    api
      .get(`/api/Job/ListOptions`)
      .then(({ data }) => {
        setJobList(data);
      })
      .catch((error) => { });
  }, []);

  return (
    <ComponentSearchContext.Provider
      value={{
        components,
        setComponents,
        getComponents,
        jobList,
        assetClassList,
        searchText,
        setSearchText,
        isSearchingComponent,
        setIsSearchingComponent,
        currentPage,
        setCurrentPage,
      }}
      {...props}
    />
  );
};

export const useComponentSearch = (): ComponentSearchContext => useContext(ComponentSearchContext);
export default ComponentSearchProvider;

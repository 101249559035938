import React, { useState, createContext, useContext } from 'react';

interface JobContextProps {
  isValuationReportsOpen: boolean;
  setIsValuationReportsOpen: (isValuationReportsOpen: boolean) => void;
  isGenerateReportsOpen: boolean;
  setIsGenerateReportsOpen: (isGenerateReportsOpen: boolean) => void;
  isAssetManagementToolsOpen: boolean;
  setIsAssetManagementToolsOpen: (isAssetManagementToolsOpen: boolean) => void;
  isImportHistoryOpen: boolean;
  setIsImportHistoryOpen: (isImportHistoryOpen: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  tablePageSize: number;
  setTablePageSize: (size: number) => void;
}

const JobContext = createContext<JobContextProps>({} as JobContextProps);

export const JobContextProvider: React.FC<any> = (props) => {
  const [isValuationReportsOpen, setIsValuationReportsOpen] = useState<boolean>(true);
  const [isGenerateReportsOpen, setIsGenerateReportsOpen] = useState<boolean>(false);
  const [isAssetManagementToolsOpen, setIsAssetManagementToolsOpen] = useState<boolean>(false);
  const [isImportHistoryOpen, setIsImportHistoryOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [tablePageSize, setTablePageSize] = useState<number>(5);

  return (
    <JobContext.Provider
      value={{
        isValuationReportsOpen,
        setIsValuationReportsOpen,
        isGenerateReportsOpen,
        setIsGenerateReportsOpen,
        isAssetManagementToolsOpen,
        setIsAssetManagementToolsOpen,
        isImportHistoryOpen,
        setIsImportHistoryOpen,
        currentPage,
        setCurrentPage,
        tablePageSize,
        setTablePageSize,
      }}
      {...props}
    />
  );
};
export const useJob = (): JobContextProps => useContext(JobContext);
export default JobContextProvider;

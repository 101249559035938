export interface SubGroup {
  name: string;
}

export interface Group {
  name: string;
  subGroups: SubGroup[];
}

export interface Element {
  name: string;
  groups: Group[];
}

export const maintenancePlanHierarchy: Element[] = [
  {
    name: 'Buildings',
    groups: [
      {
        name: 'Substructure',
        subGroups: [{ name: 'Slab' }, { name: 'Posts' }, { name: 'Bearers and Joists' }],
      },
      {
        name: 'Structure',
        subGroups: [
          { name: 'Frame' },
          { name: 'External Cladding' },
          { name: 'External Windows' },
          { name: 'Window Frame' },
          { name: 'Door Frames' },
          { name: 'External doors' },
          { name: 'Bracing' },
          { name: 'Insulation' },
        ],
      },
      { name: 'Floor Coverings', subGroups: [{ name: 'Floor Base' }, { name: 'Floor Coverings' }] },
      {
        name: 'Fit-out',
        subGroups: [
          { name: 'Internal Windows' },
          { name: 'Ceiling' },
          { name: 'Internal Walls' },
          { name: 'Partitions' },
          { name: 'Fittings' },
          { name: 'Internal Doors' },
          { name: 'Fixtures' },
        ],
      },
      {
        name: 'Roof',
        subGroups: [
          { name: 'Cladding' },
          { name: 'Gutters and Downpipes' },
          { name: 'Ventilation' },
          { name: 'Soffit' },
          { name: 'Eaves' },
          { name: 'Structure' },
          { name: 'Bracing' },
          { name: 'Weatherproofing' },
          { name: 'Insulation' },
        ],
      },
      { name: 'Services - Mechanical', subGroups: [{ name: 'Air-condition' }, { name: 'Ventilation' }] },
      {
        name: 'Services - Electrical',
        subGroups: [
          { name: 'Switchboard' },
          { name: 'Solar panels' },
          { name: 'Solar inverter / control' },
          { name: 'Cabling' },
        ],
      },
      {
        name: 'Services - Security',
        subGroups: [
          { name: 'Cameras' },
          { name: 'Motion detectors' },
          { name: 'Alarm systems' },
          { name: 'Access controls' },
        ],
      },
      {
        name: 'Services - Fire',
        subGroups: [
          { name: 'Alarms' },
          { name: 'Sprinklers' },
          { name: 'Gas system' },
          { name: 'Hydrants' },
          { name: 'Hose and reel' },
          { name: 'Hand extinguishers' },
          { name: 'Escape system' },
        ],
      },
      { name: 'Services - Hydraulic', subGroups: [{ name: 'Pipework' }] },
      {
        name: 'Services - Transport',
        subGroups: [{ name: 'Lift' }, { name: 'Escalator' }, { name: 'Travelator' }, { name: 'Dumb waiter' }],
      },
      { name: 'External', subGroups: [{ name: 'Building access' }, { name: 'Building surround' }] },
    ],
  },
];

import React, { Fragment, useRef, useState } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  name: string;
  exportImages(jobId: number, quality: string): void;
  jobId: number;
}

export const ImageQualityModal: React.FC<Props> = ({ isOpen, setOpen, name, exportImages, jobId }) => {
  const cancelButtonRef = useRef(null);
  const [quality, setQuality] = useState<string>('Medium');
  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuality(e.target.value);
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={(): void => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="my-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900 capitalize">
                      Image Export Quality
                    </DialogTitle>
                    <fieldset className="mt-5">
                      <div className="space-y-2">
                        <div key="very high" className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              name="quality"
                              type="radio"
                              value="Very High"
                              defaultChecked={quality === 'Very High'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="very high" className="font-medium text-gray-900">
                              Very High
                            </label>{' '}
                          </div>
                        </div>

                        <div key="high" className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              name="quality"
                              type="radio"
                              value="High"
                              defaultChecked={quality === 'High'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="high" className="font-medium text-gray-900">
                              High
                            </label>{' '}
                          </div>
                        </div>

                        <div key="medium" className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              name="quality"
                              type="radio"
                              value="Medium"
                              defaultChecked={quality === 'Medium'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="medium" className="font-medium text-gray-900">
                              Medium
                            </label>{' '}
                          </div>
                        </div>

                        <div key="low" className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              name="quality"
                              type="radio"
                              value="Low"
                              defaultChecked={quality === 'Low'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="low" className="font-medium text-gray-900">
                              Low
                            </label>{' '}
                          </div>
                        </div>
                        <div key="very low" className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              name="quality"
                              type="radio"
                              value="Very Low"
                              defaultChecked={quality === 'Very Low'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="very low" className="font-medium text-gray-900">
                              Very Low
                            </label>{' '}
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                <button
                  type="button"
                  className="btn btn-primary ml-4"
                  onClick={(): void => {
                    exportImages(jobId, quality);
                    setOpen(false);
                  }}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(): void => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

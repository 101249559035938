/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon, CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { ImageDisplay } from '../models/Image';

interface Props {
  isOpen: boolean;
  closeModal(): void;
  image?: ImageDisplay;
}
const QuickEditImageModal: React.FC<Props> = ({ isOpen, closeModal, image }) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={closeModal}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
            {' '}
            <img
              src={image?.original}
              alt={`Asset: ${image?.assetId || ''}`}
              className="object-cover w-full pointer-events-none group-hover:opacity-75"
            />
            <div className="absolute top-0 left-0">
              <button className="focus:outline-none rounded-md p-2 ml-2 mb-2 " type="button" onClick={closeModal}>
                <XMarkIcon className="text-white mx-auto w-8 opacity-90" />
              </button>
            </div>
            <a
              href={image?.original}
              className="absolute bottom-0 right-0 rounded-md m-5 text-indigo-600 hover:text-indigo-900"
              rel="noreferrer"
              target="_blank"
            >
              <CloudArrowDownIcon className="h-9 w-9" />
            </a>
            <p className="block text-sm font-medium text-gray-500 pointer-events-none">Date taken: {image?.date}</p>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);

export default QuickEditImageModal;

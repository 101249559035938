import React from 'react';
import { AdjustmentsVerticalIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { ValuationProfile } from '../models/ValuationProfile';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';

type Variables = { valuationProfileId: number };

export const ValuationProfileList: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { data: valuationProfiles = [] as ValuationProfile[] } = useQuery<ValuationProfile[], Error>(
    ['valuationProfilesList'],
    () => api.post('/api/ValuationProfile/List', {}).then((res) => res.data)
  );
  const { mutate } = useMutation((params: Variables) => api.post('/api/ValuationProfile/Delete', params));

  const deleteValuationProfile = (id: number): void => {
    mutate(
      { valuationProfileId: id },
      {
        onSuccess: ({ data }) => {
          addToast(
            data
              ? 'Valuation Profile Rule has been deleted'
              : 'Unable to remove valuation profile data, please ensure all assumptions are removed beforehand',
            data ? ToastType.Message : ToastType.Error
          );
        },
        onError: (error) => {
          addToast('Unable to remove Valuation Profile Rule');
        },
        onSettled: () => {
          queryClient.invalidateQueries(['valuationProfilesList']);
        },
      }
    );
  };

  return (
    <div>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Valuation Profiles</h1>
        </div>
        {!roles.includes(Roles.Viewer) && (
          <Link to="/valuationprofiles/0/edit" type="button" className="btn btn-primary">
            New valuation profile
          </Link>
        )}
        <div className="mt-4 flex sm:mt-0 sm:ml-4 space-x-4">
          {valuationProfiles.length > 1 && (
            <Link to="/rules" type="button" className="btn btn-secondary">
              Rules
            </Link>
          )}
        </div>
      </div>
      <div className="max-w-6xl px-4">
        <div className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {/* Card */}
          {valuationProfiles.map(({ name, description, id }) => (
            <div key={id} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <AdjustmentsVerticalIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-lg font-medium text-gray-900">{name}</dt>
                      <dd>
                        <div className="text-sm font-medium text-gray-500 truncate">{description}</div>
                      </dd>
                    </dl>
                  </div>
                  {!roles.includes(Roles.Viewer) && (
                    <button
                      type="button"
                      className="px-1 py-1"
                      onClick={(): void => {
                        deleteValuationProfile(id);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-7 w-7 text-gray-400 hover:text-red-700" aria-hidden="true" />
                    </button>
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <Link to={`/valuationprofiles/${id}`} className="font-medium text-indigo-700 hover:text-indigo-900">
                    View
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Select from 'react-select';
import { User } from '../../models/User';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { useApi } from '../../contexts/ApiContext';
import { HierarchyType } from '../../models/HierarchyType';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Role } from '../../models/Role';

const UserEdit: React.FC<{}> = () => {
  const api = useApi();
  const { id = '' } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const history = useNavigate();
  const [rolesList, setRolesList] = useState<Role[]>([]);

  const UserSchema = yup.object().shape({
    userName: yup.string().required('First name for the User is required'),
    firstName: yup.string().max(100, 'Maximum length is 100').required('First name for the User is required'),
    lastName: yup.string().max(100, 'Maximum length is 100').required('Last name for the User is required'),
    email: yup.string().email().required('Email is required'),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<User>({
    defaultValues: { id: parseInt(id) },
    reValidateMode: 'onBlur',
    resolver: yupResolver(UserSchema),
  });

  useEffect(() => {
    if (parseInt(id) > 0)
      api
        .get(`/api/User/Get?userId=${id}`)
        .then(({ data }) => {
          reset({
            ...data,
            roles: data.roles.map((role) => ({ label: role.name, value: role.id })),
          });
        })
        .catch((error) => {
          addToast(`Unable to get subscription with ID ${id}`, ToastType.Error);
        });
    api
      .get(`/api/Role/List`)
      .then(({ data }) => {
        setRolesList(
          data.map(
            (role) =>
              ({
                label: role.name,
                value: role.id,
              } as Role)
          )
        );
      })
      .catch((error) => {});
  }, []);

  const onSubmit = (user: User): void => {
    const userDto = { ...user, roles: user.roles.map((role) => ({ id: role.value, name: role.label })) };
    api
      .post('/api/User/Update', { user: userDto })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} User ${data.name}`);
        history(`/Users/${data.id}`);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} User ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <input type="hidden" {...register('id')} />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Username
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('userName')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.userName && <ErrorMessage>{errors.userName.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                First Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('firstName')}
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Last Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('lastName')}
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('email')}
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Searchable
              </label>
              <div className="sm:mt-px sm:pt-1 sm:col-span-2">
                <input
                  {...register('isSearchable')}
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Roles
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  name="roles"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      isMulti
                      value={value}
                      onChange={onChange}
                      options={rolesList}
                      styles={{
                        multiValue: (base) => ({
                          ...base,
                          backgroundColor: '#DBEAFE',
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          color: '#1E40AF',
                          fontWeight: 500,
                        }),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserEdit;

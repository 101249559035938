import { formatDistance, subDays } from 'date-fns';
import React from 'react';
import { BasicTable, EnhancedColumn } from '../components/BasicTable';
import { useNotification } from '../contexts/NotificationContext';

export const NotificationList = () => {
  const { notifications, clearNotifications } = useNotification();

  const columns: EnhancedColumn<Notification>[] = React.useMemo(
    () => [
      {
        Header: 'Message',
        accessor: 'detail',
        className: 'px-2 py-2.5 text-sm leading-5',
      },
      {
        Header: 'Type',
        accessor: 'isFail',
        className: 'px-2 py-2.5 text-sm leading-5',
        Cell: ({ value }): JSX.Element => (
          <div>
            {value ? (
              <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                Error
              </span>
            ) : (
              <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                Success
              </span>
            )}
          </div>
        ),
      },
      {
        Header: 'Message Received',
        accessor: 'createdOn',
        className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): JSX.Element => <div>{formatDistance(value, new Date(), { addSuffix: true })}</div>,
      },
    ],
    []
  );
  console.log('notifications', notifications);
  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Notifications</h1>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <span className="shadow-sm">
              <button onClick={(): void => clearNotifications()} className="btn btn-secondary">
                Clear Notifications
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="mx-4 pt-4">
        <BasicTable data={notifications} columns={columns} />
      </div>
    </>
  );
};

import React from 'react';

export interface SummaryReportData {
  averageMaintenanceBase: number;
  averageRenewalsBase: number;
  averageTotalBase: number;
  averageMaintenanceProposed: number;
  averageRenewalsProposed: number;
  averageTotalProposed: number;
  averageMaintenanceImpactPct: number;
  averageRenewalsImpactPct: number;
  averageTotalImpactPct: number;
  averageMaintenanceImpactAmt: number;
  averageRenewalsImpactAmt: number;
  averageTotalImpactAmt: number;
  totalMaintenanceBase: number;
  totalRenewalsBase: number;
  totalBase: number;
  totalMaintenanceProposed: number;
  totalRenewalsProposed: number;
  totalProposed: number;
  totalMaintenanceImpactPct: number;
  totalRenewalsImpactPct: number;
  totalImpactPct: number;
  totalMaintenanceImpactAmt: number;
  totalRenewalsImpactAmt: number;
  totalImpactAmt: number;
  proportionMaintenanceBase: number;
  proportionRenewalsBase: number;
  proportionTotalBase: number;
  proportionMaintenanceProposed: number;
  proportionRenewalsProposed: number;
  proportionTotalProposed: number;
  proportionMaintenanceImpactPct: number;
  proportionRenewalsImpactPct: number;
  proportionTotalImpactPct: number;
  proportionMaintenanceImpactAmt: number;
  proportionRenewalsImpactAmt: number;
  proportionTotalImpactAmt: number;
  losScoreStartBase: number;
  losScoreEndBase: number;
  losScoreDiffBase: number;
  losScoreStartProposed: number;
  losScoreEndProposed: number;
  losScoreDiffProposed: number;
  losScoreStartImpactPct: number;
  losScoreEndImpactPct: number;
  losScoreStartImpactAmt: number;
  losScoreEndImpactAmt: number;
  losScoreDiffImpactAmt: number;
}

export const SummaryReport: React.FC<SummaryReportData> = (summaryReportData) => {
  return (
    <div>
      <div className="pagebreak" />
      {Object.keys(summaryReportData).length > 0 && (
        <div>
          <div className="my-4">
            <h1 className="text-xl font-semibold text-gray-900">Summary of Results</h1>
          </div>

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Total Projected Costs
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on current budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on proposed budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (%)
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (Amount)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Odd row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Maintenance
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalMaintenanceBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalMaintenanceProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.totalMaintenanceImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalMaintenanceImpactAmt.toLocaleString()}
                        </td>
                      </tr>

                      {/* Even row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Renewals
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalRenewalsBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalRenewalsProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.totalRenewalsImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalRenewalsImpactAmt.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="border-t-2 border-gray-700">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Cost
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.totalImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.totalImpactAmt.toLocaleString()}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col pt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Projected Average Annual Costs
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on current budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on proposed budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (%)
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (Amount)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Odd row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Maintenance
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageMaintenanceBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageMaintenanceProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.averageMaintenanceImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageMaintenanceImpactAmt.toLocaleString()}
                        </td>
                      </tr>

                      {/* Even row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Renewals
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageRenewalsBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageRenewalsProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.averageRenewalsImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageRenewalsImpactAmt.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="border-t-2 border-gray-700">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Cost
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageTotalBase.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageTotalProposed.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.averageTotalImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.averageTotalImpactAmt.toLocaleString()}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col pt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Proportion Maintenance v Renewal
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on current budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on proposed budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (%)
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (Amount)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Odd row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Maintenance
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionMaintenanceBase}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionMaintenanceProposed}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionMaintenanceImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionMaintenanceImpactAmt}%
                        </td>
                      </tr>

                      {/* Even row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Renewals
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionRenewalsBase}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionRenewalsProposed}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionRenewalsImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionRenewalsImpactAmt}%
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="border-t-2 border-gray-700">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Total Cost
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionTotalBase}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionTotalProposed}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          ${summaryReportData.proportionTotalImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.proportionTotalImpactAmt}%
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col pt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Average LOS Condition Score
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on current budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Based on proposed budget
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (%)
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Impact (Amount)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Odd row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Year 0
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreStartBase}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreStartProposed}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreStartImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreStartImpactAmt}
                        </td>
                      </tr>

                      {/* Even row */}
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Year 30
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreEndBase}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreEndProposed}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreEndImpactPct}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreEndImpactAmt}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="border-t-2 border-gray-700">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-left leading-5 font-medium text-gray-900">
                          Improvement (Worsening) of Average Condition
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreDiffBase}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreDiffProposed}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500" />
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500">
                          {summaryReportData.losScoreDiffImpactAmt}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export enum MaintenanceStatuses {
  Open = 'Open',
  Closed = 'Closed',
}

export enum Priorities {
  VeryLow = 'Very Low',
  Low = 'Low',
  Moderate = 'Moderate',
  High = 'High',
  VeryHigh = 'Very High',
}

export enum Drivers {
  Aesthetics = 'Aesthetics',
  Safety = 'Safety',
  Functionality = 'Functionality',
  WearAndTear = 'Wear and Tear',
  Damage = 'Damage',
}

export enum TreatmentTypes {
  Maintenance = 'Maintenance',
  Renewal = 'Renewal',
  Upgrade = 'Upgrade',
  Replacement = 'Replacement',
}

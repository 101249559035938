import React, { createContext, useState, useContext, useEffect } from 'react';
import { useApi } from './ApiContext';

import { ToastType, useToast } from './ToastContext';

export interface BaselineStrategy {
  id: number;
  assetClass: string;
  assetClassId: number;
}
export interface Strategy extends BaselineStrategy {
  name: string;
  notes?: string;
  numberOfComponents?: number;
  baselineStrategyName: string;
  baselineStrategyId: number;
  lastRefreshed: Date;
}

interface StrategyContext {
  strategies: Strategy[];
  baselineStrategies: BaselineStrategy[];
  copyStrategy: (newStrategy: Strategy, originalStrategy: Strategy, closeModal: Function) => void;
  isCopyingStrategy: boolean;
  deleteStrategy: (strategyId: number) => void;
  createBaselineStrategy: (assetClassId: number, loading: Function) => void;
  getStrategy: (id: number) => Strategy;
  activeStrategy: Strategy;
  setActive: (id: number) => Strategy;
}

const StrategyContext = createContext<StrategyContext>({} as StrategyContext);

// This component retrieves the strategies from the API so they can be read by all components
export const StrategyProvider: React.FC<any> = (props) => {
  const api = useApi();
  const [strategies, setStrategies] = useState<Strategy[]>([] as Strategy[]);
  const [baselineStrategies, setBaselineStrategies] = useState<BaselineStrategy[]>([] as BaselineStrategy[]);
  const [activeStrategy, setActiveStrategy] = useState<Strategy | BaselineStrategy | undefined>(undefined);
  const [isCopyingStrategy, setIsCopyingStrategy] = useState<boolean>(false);
  const { addToast } = useToast();

  // on creation, get the location of the user
  const getStrategy = (id: number): Strategy | BaselineStrategy | undefined => {
    const result = strategies.find((strategy) => strategy.id === id);
    if (result != undefined) {
      return result;
    }
    if (result === undefined || result === null) {
      const baselineResult = baselineStrategies.find((strategy) => strategy.id === id);
      if (baselineResult != undefined) {
        return baselineResult;
      }
      throw new TypeError('Cannot find strategy');
    }
    return result;
  };

  const setActive = (id: number): void => {
    const result = getStrategy(id);
    setActiveStrategy(result);
  };

  const copyStrategy = (newStrategy: Strategy, originalStrategy: Strategy, closeModal: Function): void => {
    setIsCopyingStrategy(true);
    api
      .post('/api/Strategy/CopyStrategy', { newStrategy, originalStrategyId: originalStrategy.id })
      .then(({ data }) => {
        addStrategy(data.strategy);
        setIsCopyingStrategy(false);
        closeModal();
      })
      .catch((error) => {
        setIsCopyingStrategy(false);
      });
  };

  const deleteStrategy = (strategyId: number): void => {
    api
      .post('/api/Strategy/DeleteStrategy', { strategyId })
      .then(({ data }) => {
        data ? addToast(`Deleted Strategy successfully`) : addToast(`Unable to delete Strategy`, ToastType.Error);
        setStrategies(strategies.filter((item) => item.id !== strategyId));
      })
      .catch((error) => {
        addToast(`Unable to delete Strategy`, ToastType.Error);
      });
  };

  const createBaselineStrategy = (assetClassId: number, loading: Function): void => {
    loading(true);
    api.post('/api/Strategy/CreateBaseline', { assetClassId }).then(
      ({ data }) => {
        addToast('Successfully Created Baseline Strategy');
        setBaselineStrategies(data.baselineStrategies);
        loading(false);
      },
      (error) => {
        loading(false);
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const addStrategy = (strategy: Strategy): void => {
    strategy.name && setStrategies(strategies.concat(strategy));
    addToast(`Created ${strategy.name} strategy.`);
  };

  const updateStrategy = (strategy: Strategy): void => {
    strategy.name &&
      setStrategies(
        strategies.map((item) => {
          if (strategy.name === item.name) {
            addToast(`Updated ${strategy.name} strategy.`);
            return item;
          }
          return item;
        })
      );
  };

  useEffect(() => {
    api
      .get('/api/Strategy/GetAlternateStrategies')
      .then(({ data }) => {
        setStrategies(data);
      })
      .catch((error) => {});

    api
      .get('/api/Strategy/GetBaselineStrategies')
      .then(({ data }) => {
        setBaselineStrategies(data);
      })
      .catch((error) => {});
  }, []);

  return (
    <StrategyContext.Provider
      value={{
        strategies,
        baselineStrategies,
        activeStrategy,
        copyStrategy,
        isCopyingStrategy,
        createBaselineStrategy,
        getStrategy,
        deleteStrategy,
        setActive,
      }}
      {...props}
    />
  );
};

export const useStrategy = (): StrategyContext => useContext(StrategyContext);
export default StrategyProvider;

import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import { EditHierarchyNodeModal } from '../components/EditHierarchyNodeModal';
import { DeleteConfirmation, DeleteConfirmation as TrimConfirmation } from '../components/DeleteConfirmation';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';
import { CopyHierarchyNodeModal } from '../components/CopyHierarchyNodeModal';
import { HierarchyNode, NodeView } from '../components/NodeView';
import Loading from '../components/Loading';
import { useCopyNode } from '../contexts/CopyNodeContext';

export const HierarchyNavigation: React.FC<{}> = () => {
  const api = useApi();
  const [id, setId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [currentNode, setCurrentNode] = useState<HierarchyNode>({} as HierarchyNode);
  const [itemType, setItemType] = useState<string>('');
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isTrimModalOpen, setIsTrimModalOpen] = useState<boolean>(false);
  const { copyNode, setCopyNode } = useCopyNode();
  const { mutate: deleteNode, isLoading: isDeleting } = useMutation((node: HierarchyNode) =>
    api.post('/api/AssetHierarchy/DeleteHierarchyNode', { id: node.id, type: node.type })
  );

  const { mutate: trimNode, isLoading: isTrimming } = useMutation((node: HierarchyNode) =>
    api.post('/api/AssetHierarchy/TrimHierarchyNode', { id: node.id, type: node.type })
  );

  const deleteAction = (node: HierarchyNode): void => {
    deleteNode(node, {
      onSuccess: () => {
        addToast(`Deleted ${itemType}`, ToastType.Message);
        queryClient.invalidateQueries(['hierarchy', node.parentId ?? 0]);
      },
      onError: () => {
        addToast(`Unable to Delete ${itemType}`, ToastType.Error);
      },
    });
  };

  const trimAction = (node: HierarchyNode): void => {
    trimNode(node, {
      onSuccess: () => {
        console.log('node', node);
        addToast(`Trimmed ${itemType}`, ToastType.Message);
        queryClient.invalidateQueries(['hierarchy', node.id ?? 0]);
      },
      onError: () => {
        addToast(`Unable to Trim ${itemType}`, ToastType.Error);
      },
    });
  };

  const { data: hierarchyNodes = [] as HierarchyNode[] } = useQuery<HierarchyNode[], Error>(['hierarchy', 0], () =>
    api.get<HierarchyNode[]>(`/api/AssetHierarchy/GetAssetClassNodes?parentId=0`).then(({ data }) => data)
  );

  const openDeleteModal = useCallback((node: HierarchyNode): void => {
    setItemType(node.type);
    setId(node.id);
    setIsDeleteModalOpen(true);
    setCurrentNode(node);
  }, []);

  const openTrimModal = useCallback((node: HierarchyNode): void => {
    setItemType(node.type);
    setId(node.id);
    setIsTrimModalOpen(true);
    setCurrentNode(node);
  }, []);

  const openCopyModal = useCallback((parentId: number): void => {
    setName(copyNode.name);
    setItemType(copyNode.type);
    setId(parentId);
    setIsCopyModalOpen(true);
  }, []);

  const openEditModal = useCallback((node: HierarchyNode): void => {
    setItemType(node.type);
    setId(node.id);
    setName(node.name);
    setIsEditModalOpen(true);
    setCurrentNode(node);
  }, []);

  if (hierarchyNodes.length === 0 || isDeleting || isTrimming) {
    return <Loading message="Hierarchy" />;
  }

  return (
    <div className="space-y-4 mx-4 py-2">
      <div className="flex justify-end space-x-4">
        <NavLink to="/valuationprofiles" className="btn btn-secondary">
          {' '}
          Valuation Profiles
        </NavLink>
        <NavLink to="/rules" className="btn btn-secondary">
          Rules
        </NavLink>
        <NavLink to="/financialclasses" className="btn btn-secondary">
          Financial Classes
        </NavLink>
        <NavLink to="/valuationclasses" className="btn btn-secondary">
          Valuation Classes
        </NavLink>
      </div>
      {hierarchyNodes.length > 0 &&
        hierarchyNodes.map((node) => (
          <NodeView
            key={node.id}
            node={node}
            deleteAction={openDeleteModal}
            copyAction={setCopyNode}
            editAction={openEditModal}
            pasteAction={openCopyModal}
            trimAction={openTrimModal}
          />
        ))}

      <EditHierarchyNodeModal
        closeModal={(): void => setIsEditModalOpen(false)}
        isOpen={isEditModalOpen}
        itemType={itemType}
        url="/api/AssetHierarchy/UpdateHierarchyNode"
        node={{ id, name, type: itemType, parentId: currentNode.parentId }}
      />
      <CopyHierarchyNodeModal
        closeModal={(): void => setIsCopyModalOpen(false)}
        isOpen={isCopyModalOpen}
        itemType={itemType}
        url="/api/AssetHierarchy/CopyHierarchyNode"
        node={{ id, name, type: itemType, parentId: currentNode.parentId }}
      />
      <TrimConfirmation
        itemType={itemType}
        isOpen={isTrimModalOpen}
        setOpen={setIsTrimModalOpen}
        deleteAction={(): void => trimAction(currentNode)}
        actionText="Trim"
      />
      <DeleteConfirmation
        itemType={itemType}
        isOpen={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        deleteAction={(): void => deleteAction(currentNode)}
      />
    </div>
  );
};

import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Listbox, Transition, TransitionChild } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TagIcon } from '@heroicons/react/24/solid';

type Props = {
  isOpen: boolean;
  setOpen(open: boolean): void;
  noteTag: string;
  noteDetail: string;
  updateNote(): void;
  setNoteTag(note: string): void;
  notesAvailable: string[];
  setNoteDetail(detail: string): void;
};

export const NoteModal: React.FC<Props> = ({
  setNoteTag,
  notesAvailable,
  updateNote,
  noteTag,
  noteDetail,
  isOpen,
  setOpen,
  setNoteDetail,
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={(): void => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="px-2 py-2 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="relative border border-gray-300 rounded-lg shadow-sm overflow-hidden">
                <button
                  type="button"
                  className="absolute right-0 top-0 mt-2 mr-2 rounded-md bg-white text-gray-400 hover:text-red-700"
                  onClick={(): void => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <textarea
                  rows={10}
                  onChange={(e): void => setNoteDetail(e.target.value)}
                  className="block w-full py-3 border-0 resize-none sm:text-sm"
                  placeholder="Add your note..."
                  defaultValue={noteDetail}
                />
                <div className="flex w-full justify-between absolute bottom-0 mb-2 px-2">
                  <div className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-100 py-2 px-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <TagIcon className="text-gray-500 h-5 w-5 flex-shrink-0 sm:-ml-1" aria-hidden="true" />
                    <span className="block truncate ml-2">{noteTag}</span>
                  </div>

                  <button
                    onClick={(): void => {
                      updateNote();
                      setOpen(false);
                    }}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Update
                  </button>
                </div>
              </div>
              {/* <div className="py-2 flex justify-end">
                <div className="flex-shrink-0">

                </div>
              </div> */}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { BasicTable } from '../../components/BasicTable';
import { currencyFormat, numberFormat, numberOneDecimalFormat } from '../../Format';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';

const ValuationDetail: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: {
      components = [],
      valuationMethod: {
        gross = NaN,
        currentValue = NaN,
        depreciationExpense = NaN,
        weightedAverageRUL = NaN,
        weightedAverageUL = NaN,
      } = {},
    } = {} as Asset,
  } = useAssetById(parseInt(id));

  const columns: Column<Asset>[] = React.useMemo(
    () => [
      {
        Header: 'Name ',
        accessor: 'name',
        className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): React.ReactElement => (
          <div className="div">
            <Link to={`/components/${row.original.id}`} className="text-indigo-600 hover:text-indigo-900">
              {value}
            </Link>
          </div>
        ),
      },
      {
        Header: 'Gross',
        accessor: 'gross',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'Current Value',
        accessor: 'currentValue',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'DE',
        accessor: 'depreciationExpense',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'WA UL',
        accessor: 'weightedAverageUL',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${numberOneDecimalFormat.format(value)} yrs`,
      },
      {
        Header: 'WA RUL',
        accessor: 'weightedAverageRUL',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${numberOneDecimalFormat.format(value)} yrs`,
      },
    ],
    []
  );

  return (
    <div className="bg-white p-4">
      <div className="flex flex-col">
        <div className="">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <dl className="my-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-5 md:divide-y-0 md:divide-x">
              <div>
                <div className="px-4 py-5 sm:p-6 flex flex-col h-full justify-end">
                  <dt className="text-base font-sm lg:font-normal text-gray-900">Gross</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className="text-xl lg:text-2xl font-semibold text-indigo-600">
                      {gross && currencyFormat.format(gross)}
                    </div>
                  </dd>
                </div>
              </div>

              <div>
                <div className="px-4 py-5 sm:p-6 flex flex-col h-full justify-end">
                  <dt className="text-base font-sm lg:font-normal text-gray-900">Current Value</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className="text-xl lg:text-2xl font-semibold text-indigo-600">
                      {currentValue && currencyFormat.format(currentValue)}
                    </div>
                  </dd>
                </div>
              </div>

              <div>
                <div className="px-4 py-5 sm:p-6 flex flex-col h-full justify-end">
                  <dt className="text-base font-sm lg:font-normal text-gray-900">Depn Expense</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className="text-xl lg:text-2xl font-semibold text-indigo-600">
                      {depreciationExpense && currencyFormat.format(depreciationExpense)}
                    </div>
                  </dd>
                </div>
              </div>

              <div>
                <div className="px-4 py-5 sm:p-6 flex flex-col h-full justify-end">
                  <dt className="text-base font-sm lg:font-normal text-gray-900">WA UL</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className="text-xl lg:text-2xl font-semibold text-indigo-600">
                      {weightedAverageUL && numberFormat.format(weightedAverageUL)} yrs
                    </div>
                  </dd>
                </div>
              </div>

              <div>
                <div className="px-4 py-5 sm:p-6 flex flex-col h-full justify-end">
                  <dt className="text-base font-sm lg:font-normal text-gray-900">WA RUL</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className="text-xl lg:text-2xl font-semibold text-indigo-600">
                      {weightedAverageRUL && numberFormat.format(weightedAverageRUL)} yrs
                    </div>
                  </dd>
                </div>
              </div>
            </dl>

            <BasicTable columns={columns} data={components} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationDetail;

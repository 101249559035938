import React from 'react';
import { defaults, Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ProjectedCosts } from './interfaces';

export interface Props {
  strategy?: string;
  comparisonStrategy?: string;
  data: ProjectedCosts[];
}

defaults.font.family = 'proxima-nova';

const TotalCostingChart: React.FC<Props> = ({ data, comparisonStrategy, strategy }) => {
  Chart.register(...registerables);
  const labels = data.map((projectedCost) => projectedCost.year);

  const baseRenewal = data.map((projectedCost) => projectedCost.baseRenewal);

  const baseMaintenance = data.map((projectedCost) => projectedCost.baseMaintenance);
  const projectedMaintenance = data.map((projectedCost) => projectedCost.proposedMaintenance);

  const projectedRenewals = data.map((projectedCost) => projectedCost.proposedRenewal);

  const chartData = {
    labels,
    datasets: [
      {
        label: `${strategy} Maintenance`,
        type: 'bar' as any,
        stack: 'Stack 0',
        lineTension: 0,
        backgroundColor: 'rgba(43,108,176, 0.8)',
        borderColor: 'rgba(44, 82,130, 0.8)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(43,108,176, 1)',
        hoverBorderColor: 'rgba(44, 82,130, 1)',
        data: projectedMaintenance,
      },
      {
        label: `${strategy} Renewals`,
        type: 'bar' as any,
        stack: 'Stack 0',
        backgroundColor: 'rgba(214, 158, 46, 0.8)',
        borderColor: 'rgba(183, 121, 31, 0.8)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(214, 158, 46, 1)',
        hoverBorderColor: 'rgba(183, 121, 31, 1)',
        data: projectedRenewals,
      },
      {
        label: `${comparisonStrategy} Maintenance`,
        type: 'bar' as any,
        stack: 'Stack 1',
        lineTension: 0,
        backgroundColor: 'rgba(43,108,176, 0.2)',
        borderColor: 'rgba(44, 82,130, 0.2)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(43,108,176, 0.4)',
        hoverBorderColor: 'rgba(44, 82,130, 0.4)',
        data: baseMaintenance,
      },
      {
        label: `${comparisonStrategy} Renewals`,
        type: 'bar' as any,
        stack: 'Stack 1',
        backgroundColor: 'rgba(214, 158, 46, 0.2)',
        borderColor: 'rgba(183, 121, 31, 0.2)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(214, 158, 46, 0.4)',
        hoverBorderColor: 'rgba(183, 121, 31, 0.4)',
        data: baseRenewal,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <div className="my-4">
      <h2 className="text-xl font-semibold text-gray-900">Maintenance and Renewals Chart</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TotalCostingChart;

import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { Review, LeaseDto } from '../../models/Lease';
import { useLeaseById, useUpdateLease } from '../../hooks/UseLease';
import { removeTimezoneOffset } from '../../Helper';

export const ReviewEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    reset,
    control,

    formState: { isDirty, isSubmitted, errors },
  } = useForm<LeaseDto>({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reviews',
  });
  const history = useNavigate();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const updateLease = useUpdateLease();

  useEffect(() => {
    if (Object.keys(lease).length > 0) {
      const reviews = lease.reviews.map((review) => ({
        ...review,
        date: review.date ? new Date(review.date.toString().substring(0, 10)) : undefined,
      }));

      reset({
        ...lease,
        reviews: [...reviews],
      });
    }
  }, [lease]);

  // Add to database eventually
  const onSubmit = async (submittedData: LeaseDto): Promise<void> => {
    const dates = submittedData.reviews.map((review) => ({
      ...review,
      date: review.date ? removeTimezoneOffset(review.date) : undefined,
    }));
    const result = { ...lease, ...submittedData, reviews: [...dates] };
    updateLease.mutateAsync(result).then((leaseId) => {});
  };

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <div className="flex flex-col m-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="table-fixed min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Frequency
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Method
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Note
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button
                        type="button"
                        className="text-indigo-600 hover:text-indigo-900 whitespace-nowrap  text-sm  font-medium"
                        onClick={(): void =>
                          append({
                            id: 0,
                          } as Review)
                        }
                      >
                        Add
                      </button>

                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => (
                    <tr key={field.id} className="py-4 border-b border-gray-200 bg-white">
                      <td className="px-3 py-2 w-5 whitespace-nowrap text-sm text-gray-500">
                        <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                          <select
                            {...register(`reviews.${index}.frequency`)}
                            defaultValue={field.frequency}
                            className=" block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option>Once</option>
                            <option>Annually</option>
                          </select>
                        </div>
                      </td>

                      <td className="px-3 py-2 w-5 whitespace-nowrap text-sm text-gray-500">
                        <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                          <select
                            {...register(`reviews.${index}.type`)}
                            defaultValue={field.type}
                            className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option>Fixed</option>
                            <option>Market</option>
                            <option>CPI</option>
                          </select>
                        </div>
                      </td>

                      <td className="px-3 py-2 w-4 whitespace-nowrap text-sm text-gray-500">
                        <Controller
                          name={`reviews.${index}.date`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <ReactDatePicker
                              dateFormat="dd/MM/yyyy"
                              className={`${
                                errors !== undefined && errors.reviews !== undefined && errors.reviews[index]?.date
                                  ? 'border-rose-600'
                                  : 'border-gray-300'
                              } shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:text-sm`}
                              selected={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <div className="mb-3 border-box">
                          <textarea
                            key={field.id}
                            rows={1}
                            {...register(`reviews.${index}.note`)}
                            defaultValue={field.note}
                            className="form-control block w-full px-3 mx-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out my-3 focus:text-gray-700 focus:bg-white focus:border-indigo-600 focus:outline-none"
                          />
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
                        <div className="justify-center flex space-x-3">
                          <button
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={(): void => {
                              remove(index);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="my-4 pb-2">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={(): void => {
                history(`/leases/${id}`);
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

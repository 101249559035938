import { RadioGroup } from '@headlessui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { QueryCache, useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { ClientSummary } from './Client';

type Variables = { clientId: number };

export const ChangeClient: React.FC<{}> = () => {
  const api = useApi();
  const { clientId, ...auth } = useAuth();
  const { addToast } = useToast();
  const [selected, setSelected] = useState<number>(clientId);
  const history = useNavigate();

  const { handleSubmit } = useForm<Variables>({
    reValidateMode: 'onBlur',
    // resolver: yupResolver(ClientSchema),
  });

  const { isLoading, isSuccess, error, data: clients = [] as ClientSummary[] } = useQuery<ClientSummary[], Error>(
    ['clients'],
    () => api.get('/api/Client/ListByUserId').then((res) => res.data)
  );

  const { mutate } = useMutation((params: Variables) => {
    return api.post('/api/User/ChangeClient', params);
  });

  const submitChange = (): void => {
    mutate(
      { clientId: selected },
      {
        onSuccess: ({ data }) => {
          addToast(`Changed Client. Please log back in...`);
          window.location.assign("/bff/logout");
        },
        onError: (error) => {
          addToast(`Unable to Change Client`, ToastType.Error);
        },
        // onSettled: () => {
        //   queryClient.invalidateQueries(['valuationProfileRule', id]);
        //   queryClient.invalidateQueries('valuationProfileRuleList');
        // },
      }
    );
  };

  return (
    <section className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" aria-labelledby="plan-heading">
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h2 id="plan-heading" className="text-lg leading-6 font-medium text-gray-900">
              Clients
            </h2>
          </div>{' '}
          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Clients</RadioGroup.Label>
            <div className="relative bg-white rounded-md -space-y-px">
              {clients.map((client, index) => (
                <RadioGroup.Option
                  key={client.id}
                  value={client.id}
                  className={({ checked }) => `${index === 0 ? 'rounded-tl-md rounded-tr-md' : ''} ${
                    index === clients.length - 1 ? 'rounded-bl-md rounded-br-md' : ''
                  }
                    ${checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'}
                    relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none`}
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex items-center text-sm">
                        <span
                          className={`${checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300'} ${
                            active ? 'ring-2 ring-offset-2 ring-indigo-500' : ''
                          } h-4 w-4 rounded-full border flex items-center justify-center flex-shrink-0`}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <RadioGroup.Label
                          as="span"
                          className={`${
                            checked ? 'text-indigo-900' : 'text-gray-900'
                          } ml-3 font-medium truncate whitespace-nowrap`}
                        >
                          {client.displayName}
                        </RadioGroup.Label>
                      </div>
                      <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                        <span className={`${checked ? 'text-indigo-900' : 'text-gray-900'} font-medium`}>
                          {client.contactName}
                        </span>
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        className={`${
                          checked ? 'text-indigo-700' : 'text-gray-500'
                        } ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right`}
                      >
                        {client.contactEmail}
                      </RadioGroup.Description>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="px-4 py-3 sm:px-6">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={(): void => {
              submitChange();
            }}
            className="btn btn-primary"
          >
            Change
          </button>
        </div>
      </div>
      {/* </form> */}
    </section>
  );
};

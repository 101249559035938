import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import formatRelative from 'date-fns/formatRelative';
import { useApi } from '../contexts/ApiContext';
import { ReactComponent as Loader } from '../icons/Loader.svg';

interface Props {}

interface CostAnalysis {
  id: string;
  component: string;
  lastRefreshed: string;
}

const ReportSummary: React.FC<CostAnalysis> = ({ id, component, lastRefreshed }) => (
  <tr>
    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
      <div className="text-sm leading-5 font-medium text-gray-900">{component}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
      <div className="text-sm leading-5 text-gray-900 capitalize">{formatRelative(new Date(lastRefreshed), new Date())}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-indigo-100 text-indigo-800">Ready</span>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
      <NavLink to={`/reports/lifecycleprojection/${id}`} className="text-indigo-600 hover:text-indigo-900">
        View
      </NavLink>
    </td>
  </tr>
);

const ReportTable: React.FC<Props> = () => {
  const api = useApi();
  const [reports, setReports] = useState<CostAnalysis[]>([]);
  const [isLoadingReports, setIsLoadingReports] = useState<boolean>(true);

  useEffect(() => {
    api
      .get('/api/LifecycleProjections')
      .then(({ data }) => {
        setReports(data);
      })
      .catch((error) => {});
    setIsLoadingReports(false);
  }, []);

  return (
    (<div className="flex flex-col">
      {!isLoadingReports ? (
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:border-b border-gray-200">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Asset Class
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Generated
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50" />
                </tr>
              </thead>
              <tbody className="bg-white">
                {reports.map((report) => (
                  // TODO: Create / Import a lightweight spinner
                  (<ReportSummary {...report} />)
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
          <Loader className="animate-spin w-6 h-6 mb-3" />
          <p className="text-sm font-medium text-gray-500">Loading</p>
        </div>
      )}
    </div>)
  );
};

const Reporting: React.FC<Props> = () => (
  <div className="bg-white overflow-hidden shadow">
    <div className="px-4 py-5 sm:px-6">
      <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>
    </div>
    <div className="bg-gray-50 px-4 py-5 sm:p-6">
      <ReportTable />
    </div>
  </div>
);

export default Reporting;

import React, { Fragment, useEffect, useRef, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Controller, useForm } from 'react-hook-form';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import { useApi } from '../../contexts/ApiContext';
import { InsuranceAssumptions } from '../../models/InsuranceAssumptions';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, percentFormat } from '../../Format';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClassId: number;
  assetTypeId: number;
  assetSubTypeId: number;
};

const InsuranceAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const [open, setOpen] = useState<boolean>(true);
  const { addToast } = useToast();
  const {
    insuranceAssumptions,
    insuranceAssumptionsSearchText,
    getInsuranceAssumptions,
    insuranceAssumptionsCurrentPage,
    setInsuranceAssumptionsCurrentPage,
    isSearchingInsuranceAssumptions,
    assetClassList,
    tableInsuranceAssumptionsPageSize,
    setInsuranceAssumptionsTablePageSize,
  } = useAssumptionsSearch();

  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [valuationClassList, setValuationClassList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [selectedAssetClass, setSelectedAssetClass] = useState<number>(0);
  const [selectedAssetType, setSelectedAssetType] = useState<number>(0);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchValuationClasses = (): void => {
    api
      .post('/api/ValuationClass/List', {})
      .then(({ data }) => {
        setValuationClassList(data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClass > 0) {
      fetchAssetTypes(selectedAssetClass);
    }
  }, [selectedAssetClass]);

  useEffect(() => {
    if (selectedAssetType > 0) {
      fetchAssetSubTypes(selectedAssetType);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    fetchValuationClasses();
  }, []);

  const [assetList, setAssetList] = useState<InsuranceAssumptions[]>([]);
  const [isSearchingHierarchy, setIsSearchingHierarchy] = useState<boolean>(false);

  const {
    register,
    setValue,
    handleSubmit,
    control,

    formState: {
      errors,
    },
  } = useForm<FormData>();

  const columns: Column<InsuranceAssumptions>[] = React.useMemo(
    () => [
      {
        Header: 'Asset Class',
        headerClassName: 'px-3.5 py-3',
        accessor: 'assetClass',
        className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Type',
        headerClassName: 'px-3.5 py-3',
        accessor: 'assetType',
        className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Sub Type',
        headerClassName: 'px-3.5 py-3',
        accessor: 'assetSubType',
        className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'No. of Assets',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'assetsCount',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
      },

      {
        Header: 'Lead Build Months',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'leadBuildMonths',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Demolition Months',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'demoMonths',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Escalation Factor',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'escalationFactorPct',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Professional Fees',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'professionalFeesPct',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Debris Removal Rate',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'debrisRemovalPct',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Debris Removal Minimum',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'debrisRemovalMinimum',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: '',
        accessor: 'edit ',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ row }) => (
          <>
            {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass) && (
              <NavLink className="btn-link" to={`/insuranceassumptions/${row.original.id}/edit`}>
                edit
              </NavLink>
            )}
          </>
        ),
      },
    ],
    [readOnlyAssetClasses]
  );

  const onSubmit = handleSubmit(({ searchText, assetClassId, assetTypeId, assetSubTypeId }) => {
    getInsuranceAssumptions(searchText, assetClassId, assetTypeId, assetSubTypeId);
    setSearchComplete(true);
  });

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <div className="hidden sm:block">
          <div className="align-middle inline-block min-w-full">
            <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
              <div className="flex-1 min-w-0 justify-between flex">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Insurance Assumptions</h1>
              </div>
              <div className="pb-2 flex items-end flex-wrap">
                <div className="flex-grow pt-1 mr-3">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="mt-1 relative min-w-max">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                      {/* Heroicon name: search */}
                      <svg
                        className="mr-3 h-4 w-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      {...register('searchText')}
                      id="searchText"
                      defaultValue={insuranceAssumptionsSearchText}
                      className="py-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                      placeholder="Search" />
                  </div>
                </div>
                <div className="pt-1 mx-2">
                  <label className="block text-sm font-medium text-gray-700">Asset Class</label>
                  <select
                    defaultValue="All"
                    {...register('assetClassId')}
                    onChange={(e): void => setSelectedAssetClass(Number(e.target.value))}
                    className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    {assetClassList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pt-1 mx-2">
                  <label className="block text-sm font-medium text-gray-700">Asset Type</label>
                  <select
                    defaultValue="All"
                    {...register('assetTypeId')}
                    onChange={(e): void => setSelectedAssetType(Number(e.target.value))}
                    className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {assetTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pt-1 mx-2">
                  <label className="block text-sm font-medium text-gray-700">Asset Sub Type</label>
                  <select
                    defaultValue="All"
                    {...register('assetSubTypeId')}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value={0}>All</option>
                    {assetSubTypeList.map((ac) => (
                      <option key={ac.value} value={ac.value}>
                        {ac.label}
                      </option>
                    ))}
                  </select>
                </div>
                <input type="submit" value="Search" className="mt-1 ml-1 mr-3 btn btn-primary" />
              </div>
            </form>
            <div
              className={`mx-3 ${
                !isSearchingInsuranceAssumptions && insuranceAssumptionsSearchText !== '' && insuranceAssumptions.length <= 0
              }`}
            >
              <BasicTable
                columns={columns}
                data={insuranceAssumptions}
                currentPage={insuranceAssumptionsCurrentPage}
                setCurrentPage={setInsuranceAssumptionsCurrentPage}
                isLoading={isSearchingInsuranceAssumptions}
                tablePageSize={tableInsuranceAssumptionsPageSize}
                setTablePageSize={setInsuranceAssumptionsTablePageSize}
              />

              {!isSearchingInsuranceAssumptions && searchComplete && insuranceAssumptions.length <= 0 && (
                <div className="text-center mt-14 pt-3 pb-6">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">No Insurance Assumptions Found</h3>
                  <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default InsuranceAssumptionsSearch;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ComponentHierarchyItem } from '../models/Asset';

interface Props {
  isOpen: boolean;
  componentList: ComponentHierarchyItem[];
  setOpen(open: boolean): void;
  addComponent(component: ComponentHierarchyItem): void;
}

export const NewComponentModal: React.FC<Props> = ({ isOpen, setOpen, componentList, addComponent }) => {
  const cancelButtonRef = useRef(null);
  const [selectedComponent, setSelectedComponent] = useState<ComponentHierarchyItem>({} as ComponentHierarchyItem);

  useEffect(() => {
    if (isOpen) {
      setSelectedComponent(componentList[0]);
    }
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={(): void => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      New Component
                    </DialogTitle>
                    <select
                      onChange={(e): void =>
                        setSelectedComponent(
                          componentList.find((comp) => comp.id === parseInt(e.currentTarget.value)) ||
                            ({} as ComponentHierarchyItem)
                        )
                      }
                      className="block w-full whitespace-normal rounded-md border-gray-300 bg-none my-3 text-left shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:w-16 sm:px-0.5 sm:text-sm md:w-36 lg:w-52 lg:px-1"
                    >
                      {componentList
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((component) => (
                          <option key={component.id} value={component.id}>
                            {component.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={(): void => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    addComponent(selectedComponent);
                    setOpen(false);
                  }}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Save
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import { DocumentTextIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import FileInput from '../components/FileInput';
import FAQModal from '../components/FAQModal';
import { FAQ } from '../models/FAQ';

export const DocumentImport: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  // const [isHierarchyChanges, setIsHierarchyChanges] = useState<boolean>(true);
  // const [isNoteChanges, setIsNoteChanges] = useState<boolean>(true);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState<boolean>(false);

  const faqs: FAQ[] = [
    {
      question: 'How to import a document to each asset ?',
      answer: (
        <>
          <p className="mt-1 text-sm text-gray-700">
            Documents must contain the <strong>Asset ID</strong> of the asset to be attached to in the format below:
          </p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID123.xlsx</p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID124.docx</p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID125.pdf</p>
        </>
      ),
    },
    {
      question: 'How to attach multiple documents to each asset ?',
      answer: (
        <>
          <p className="mt-1 text-sm text-gray-700">
            To import documents to the same asset, please ensure the filename is unique and starts with the <strong>Asset ID</strong>. To ensure each file's name
            is unique, use either a space or enclose the document count in brackets. Shown below are example filenames to upload documents to the asset <strong>TESTID123</strong>.
          </p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID123 1.xlsx</p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID123 2.pdf</p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID123(1).xlsx</p>
          <p className="mt-1 text-sm italic text-gray-500">TESTID123(2).pdf</p>
        </>
      ),
    },
    {
      question: 'What document file types can I attach ?',
      answer: <p className="mt-1 text-sm text-gray-700">Most document file types are accepted (e.g xlsx, pdf, docx, txt)</p>,
    },
    // More questions...
  ];

  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/zip': ['.zip'] },
  });

  return (
    <div className="align-middle inline-block min-w-full border-b border-gray-200">
      <form className="space-y-8 divide-y divide-gray-200 relative p-5 mx-3 my-5 bg-white shadow-md rounded-md">
        <div className="space-y-8">
          <div>
            <div >
              <div className="flex flex-row flex-start items-center space-x-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Import</h3>
                <button
                type="button"
                className="bg-white rounded-md text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                onClick={(): void => setIsFAQModalOpen(true)}
              >
                <span className="sr-only">Close</span>
                <QuestionMarkCircleIcon className="h-6 w-6" />
              </button>
              </div>
              <p className="mt-1 text-sm text-gray-700">Import a zip archive containing asset documents</p>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                {Object.entries(file).length !== 0 ? (
                  <FileInput
                    file={file}
                    isValid={isValid}
                    closeAction={(): void => setFile({} as FileWithPath)}
                    url="/api/Job/ImportDocuments"
                    logType="job"
                    parameters={{
                      jobId: id || '',
                      // isHierarchyChanges: isHierarchyChanges.toString(),
                      // isNoteChanges: isNoteChanges.toString(),
                    }}
                  />
                ) : (
                  <div
                    {...getRootProps()}
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div className="space-y-1 text-center">
                      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <div className="flex text-sm text-gray-600">
                        <div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                          <span>Upload a file</span>
                          <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </div>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">ZIP up to 500MB</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <FAQModal isOpen={isFAQModalOpen} closeModal={(): void => setIsFAQModalOpen(false)} faqs={faqs} />
    </div>
  );
};

import React, { Fragment, useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useToast, Toast, ToastType } from '../contexts/ToastContext';

const ToastElement: React.FC<Toast> = ({ children, id, type = ToastType.Message, duration = 10000 }) => {
  const { removeToast } = useToast();
  const [show, setShow] = useState(true);
  const [timedout, cancelTimeout, resetTimeout] = useTimeoutFn(hide, type === ToastType.Error ? 99999999999999 : duration);

  function hide(): void {
    if (timedout() === false) {
      cancelTimeout();
    }

    setShow(false);
  }

  function onMouseEnter(): void {
    cancelTimeout();
  }

  function onMouseLeave(): void {
    resetTimeout();
  }

  useEffect(() => {
    return (): void => {
      if (show === false) {
        removeToast(id);
      }
    };
  });

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {(ref): JSX.Element => (
        <div
          ref={ref}
          className="max-w-sm w-full bg-white rounded-md shadow-lg mb-4 pointer-events-auto"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className="shadow-xs overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 self-center">
                  {type === ToastType.Error && <XCircleIcon className="h-6 w-6 text-red-400" />}
                  {type === ToastType.Message && <CheckCircleIcon className="h-6 w-6 text-green-400" />}
                  {type === ToastType.Warn && <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm leading-5 text-gray-800">{children}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex self-center">
                  <button
                    className={`inline-flex ${type} focus:outline-none text-gray-400 focus:text-gray-500 transition ease-in-out duration-150`}
                    onClick={hide}
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default ToastElement;

export interface OldClient {
  oid: string;
  name: string;
}

export const clients: OldClient[] = [
  {
    oid: '7EEB1310-9F32-4C4C-BCCD-02FF04685768',
    name: 'APV_Barcoo_Shire_Council',
  },
  {
    oid: '059D41CC-E8F9-4473-A8C6-0374B50DF069',
    name: 'APV_Cowra_Shire_Council',
  },
  {
    oid: '5A44B177-9077-4AC3-AFAA-03FBA853D021',
    name: 'APV_Shoalhaven_City_Council',
  },
  {
    oid: '2CAA3605-FA15-4151-B429-05230FFFB963',
    name: 'APV_Bundaberg_Regional_Council',
  },
  {
    oid: 'E5C3F48B-036B-4DBE-AFCC-052C1E342670',
    name: 'APV_Queanbeyan-Palerang_Regional_Council',
  },
  {
    oid: '4819D78A-E76D-4E67-9FE6-05942DD81A9C',
    name: 'APV_Blue_Mountains_City_Council',
  },
  {
    oid: '28C27934-AAEF-4BCF-ADE9-05D84DF5B05B',
    name: 'APV_Anglican_Church_Southern_Queensland',
  },
  {
    oid: '79630C06-5ACC-422B-9D2B-0615311599EA',
    name: 'APV_Waverley_Council',
  },
  {
    oid: '5CFC2A06-14F5-45B6-82DA-06254DB5895C',
    name: 'APV_Training',
  },
  {
    oid: '243CD404-86E8-460E-A53B-06AE2FBA3BBC',
    name: 'APV_Error',
  },
  {
    oid: '8511C8DE-A21F-4E99-93A1-075CF0BE57FE',
    name: 'APV_Default_Nov16',
  },
  {
    oid: '83D483B3-9232-44CC-9145-079DA1853962',
    name: 'APV_Longreach_Regional_Council',
  },
  {
    oid: '6CE7F2DE-B74D-4725-981E-08A659FB67F7',
    name: 'APV_City_of_Nedlands',
  },
  {
    oid: 'F3CD128D-78FB-40D4-9897-08A81DC28866',
    name: 'APV_Flinders_Shire_Council',
  },
  {
    oid: 'B83A3CB9-93BB-4CD4-AEA0-08CDE5E47515',
    name: 'APV_Central_Darling_Shire_Council',
  },
  {
    oid: '0BCA827B-2EF1-4410-B47D-08F323611C03',
    name: 'APV_Shire_of_Williams_WA',
  },
  {
    oid: '8046A6CA-A06E-4581-804A-0913819D89A0',
    name: 'APV_Fllinders_Shire_Council',
  },
  {
    oid: 'C17BDA86-37B1-4627-8DFC-0A3F8AF5D4EB',
    name: 'APV_South_Western_Sydney_Local_Health_District',
  },
  {
    oid: 'D0C63834-2F36-4B4B-AE24-0AC753BE7556',
    name: 'APV_Dev',
  },
  {
    oid: '633DB2B2-8EA3-427A-B414-0B309853427D',
    name: 'APV_Wollongong_City_Council',
  },
  {
    oid: '66F8C1DF-8ED2-4A96-BAA6-0B3ABF74FC49',
    name: 'FVP5 Workshop',
  },
  {
    oid: 'C7D43046-BFF0-4EC8-A1C2-0CAF2B2E79EA',
    name: 'APV_Circular_Head_Council',
  },
  {
    oid: '454949DF-B2C8-44DE-A27A-0EB9B1D1AB62',
    name: 'APV_District_Council_of_Yankalilla',
  },
  {
    oid: 'F05D9D77-F250-4E69-A08A-0F17AB132D1D',
    name: 'APV_SO_Ashburton',
  },
  {
    oid: 'DC817660-30E9-4997-AD2C-0F99B93EF353',
    name: 'APV_City_of_Wagga_Wagga ',
  },
  {
    oid: 'DD6BF3FC-F93B-409B-8668-10D934C3F56F',
    name: 'APV_Client_Three',
  },
  {
    oid: '62E83E32-60DA-4194-8593-10FAE9A1EBC0',
    name: 'APV_Rural_City_of_Wangaratta',
  },
  {
    oid: '6B707896-D7D2-4564-8F5A-129BC8B2D9A2',
    name: 'APV_City_of_Joondalup',
  },
  {
    oid: '26AD60D0-16C2-4689-8EBB-12CDB98E1574',
    name: 'FVP2',
  },
  {
    oid: 'F4FC435F-509C-4581-92D0-15583855257F',
    name: 'APV_Test',
  },
  {
    oid: 'C9CBF411-F395-4818-B64F-15A47224888D',
    name: 'APV_Armidale_Regional_Council ',
  },
  {
    oid: '68EE09B1-6EA9-487B-987E-16249ADE23C0',
    name: 'APV_Central_Highlands_Regional_Council',
  },
  {
    oid: '3FEE00A0-AD4F-4D6B-A6BA-17C17467FDDB',
    name: 'APV_Kyogle_Old',
  },
  {
    oid: '2B9C5DFC-970A-41C6-A964-180145F21306',
    name: 'APV_Kiama_Municipal_Council',
  },
  {
    oid: 'C9FCD31B-2F20-49BF-9602-1844BD6043AA',
    name: 'APV_Ballina_Shire_Council',
  },
  {
    oid: '8DF8E4A5-6037-413F-8FED-1895565BD53D',
    name: 'APV_Bass_Coast_Shire',
  },
  {
    oid: '5F291D9D-B8AA-4924-9924-19BD20062CAD',
    name: 'APV_Central_West_Hospital',
  },
  {
    oid: '034E6C00-0F1E-4D00-9816-19C008D438DA',
    name: 'APV_Strathbogie_Shire_Council',
  },
  {
    oid: '9136B100-4925-4AF6-B250-1A07A7745EC4',
    name: 'Default',
  },
  {
    oid: '71E48D51-9981-4619-9829-1A747EDC3B3F',
    name: 'APV_Demo_1',
  },
  {
    oid: '4E5B5901-66C5-4BF3-8118-1AE60CC22785',
    name: 'APV_Murweh_Shire_Council',
  },
  {
    oid: '6FCE1FC5-A664-4055-A6E8-1BB5740C96CD',
    name: 'APV_Mid_West_Ports',
  },
  {
    oid: 'D3610A41-2F64-4A4E-B182-1E17D902FE28',
    name: 'APV_Gympie_Regional_Council',
  },
  {
    oid: '697D817B-CB7E-4F39-9934-1F34010730AE',
    name: 'FVP1 Workshop Coffs',
  },
  {
    oid: '6A2F400E-417F-4A9F-B5A5-200E70E15621',
    name: 'APV_Charters_Towers_Regional_Council',
  },
  {
    oid: 'E76B066F-C538-44F3-A684-2350733607C4',
    name: 'APV_Demo2_Council',
  },
  {
    oid: '7D985AE4-B5EE-4D02-A8FC-24C61A1657E0',
    name: 'Department_of_Child_Safety_Youth_and_Women',
  },
  {
    oid: 'D845143D-E9D1-43C0-882C-265D8E3814FB',
    name: 'APV_Southern_Downs',
  },
  {
    oid: 'AC0452C7-1602-432C-B988-26D8E92213FF',
    name: 'APV_Wellington_Shire_Council',
  },
  {
    oid: 'DC96E0FE-63E2-4CE4-B865-270D2F83C570',
    name: 'APV_Client_One',
  },
  {
    oid: 'E2501B5F-3D87-4135-A8F3-28A821889670',
    name: 'APV_Georges_River_Council',
  },
  {
    oid: '522BAE15-4DD9-43FD-819A-2A97F924FC65',
    name: 'Samoa_Roads',
  },
  {
    oid: '9C9D25A1-13D1-4061-9152-2B118CC28F24',
    name: 'APV_Qld_South_West_Hospital',
  },
  {
    oid: 'DE95F7FB-4B8A-4CD3-89FE-2B24B0468111',
    name: 'APV_Aurukun_Shire_Council',
  },
  {
    oid: '5F7AD8EB-36BC-4974-BB12-2B6ADD395F46',
    name: 'APV_Narrabri_Shire_Council',
  },
  {
    oid: '74C4D921-43A4-4581-A29F-2BAE10744787',
    name: 'APV Client Demo',
  },
  {
    oid: 'D8B1F5EB-B52F-42FC-9434-2DDF1C2D07FE',
    name: 'APV_Workshops',
  },
  {
    oid: 'CF010C9E-B1D4-4A52-B22B-2E88A19E1D2B',
    name: 'APV_Palm_Island_Aboriginal_Shire_Council',
  },
  {
    oid: '45AFA2AA-A656-45B9-ABDF-307B1445423B',
    name: 'APV_Solomon_Water',
  },
  {
    oid: '495D0B13-1032-4FD4-BAEB-30A2EF8F0BB1',
    name: 'APV_George_Town_Council',
  },
  {
    oid: 'B9F004F1-EAFE-4513-B307-30E9218C6A3C',
    name: 'APV_Adelaide_Hills_Council',
  },
  {
    oid: 'BBA7FFD4-9FEE-4B8D-8108-32F0654C55EA',
    name: 'APV_Scenic_Rim_Regional_Council',
  },
  {
    oid: 'CA3F7A38-F107-46D8-97CA-33CD5B8D9B5A',
    name: 'APV_Lismore_City_Council',
  },
  {
    oid: 'AB4507FE-FF59-4120-847A-3427F275B671',
    name: 'Sandpit_Goldenfields',
  },
  {
    oid: '58DBEF18-322B-4300-B091-36C9DDF0920D',
    name: 'APV_Workshop_19 Jan 2017_Admin',
  },
  {
    oid: 'C85CB064-363F-4328-885F-37C10892770E',
    name: 'APV_St_Johns_Anglican_College',
  },
  {
    oid: '0B4C4EDD-9636-4CCE-AC3C-396D6481CEB4',
    name: 'APV_Laurie_Demo',
  },
  {
    oid: '160C123C-4DCD-4CED-A571-39A48942572D',
    name: 'APV_Hunters_Hill_Council',
  },
  {
    oid: '97154261-606D-4401-8445-3A368CD74124',
    name: 'APV_City_of_South_Perth',
  },
  {
    oid: 'D4717341-4FAD-43F1-A94B-3B562E2AC603',
    name: 'APV_Department_of_Communities_Disability_Services_and_Seniors',
  },
  {
    oid: '77572BAE-8196-41D3-98E2-3C69D41DCC80',
    name: 'APV_Balranald_Shire_Council',
  },
  {
    oid: 'A83B8466-51BD-4A17-92F8-3D517387361A',
    name: 'APV_City_of_Bayswater',
  },
  {
    oid: 'B3313AA8-B19A-43EA-AFAB-3D9E519C74DE',
    name: 'FVP6 Workshop',
  },
  {
    oid: '7E262081-85D6-4BE7-9C1D-3DD3269980AA',
    name: 'Paroo_Shire_Council',
  },
  {
    oid: '15513A23-AFD0-4EE8-ADE5-3FACFE15531F',
    name: 'APV_Tweed_Shire_Council',
  },
  {
    oid: 'AEDC6812-7D68-481C-95A7-41991A1149BF',
    name: 'APV_Narromine_Shire_Council',
  },
  {
    oid: 'BAE3D1AA-7049-4ED4-AC2E-41DEE7CFFE21',
    name: 'APV_Wattle_Range_Council',
  },
  {
    oid: '56E1FC4F-F9A6-48F8-9D8E-432A573DC567',
    name: 'APV_Bellingen_Shire_Council',
  },
  {
    oid: '2E601C90-C257-4B15-8895-432A8A3F36C2',
    name: 'APV_Example_Client',
  },
  {
    oid: '73C2F3B2-190D-418F-8CF8-445A9CBC3E8E',
    name: 'APV_Swan_Hill_Rural_Council',
  },
  {
    oid: '919772AD-E652-4657-B475-44883716C5FC',
    name: 'APV_Singleton_Council',
  },
  {
    oid: 'D60FEA56-9E39-4FBE-A9E6-4691AF754F68',
    name: 'APV_Molongle_Creek_Boat_Club_Inc',
  },
  {
    oid: '603F6742-B233-4FDD-9E7F-47C1FF55BEA6',
    name: 'APV_Heights_College',
  },
  {
    oid: '3F262400-0C50-4C8D-AA06-48927E2BE3C0',
    name: 'APV_City_of_Port_Adelaide_Enfield',
  },
  {
    oid: '28497C55-8DDA-481F-B3BE-4A195A35683F',
    name: 'APV_TO_Port_Hedland',
  },
  {
    oid: '9E140311-2C66-46F2-87D7-4A2E6C63CFC2',
    name: 'APV_Rous_County',
  },
  {
    oid: '2482FE52-9756-44DD-848B-4B242BFF3FB4',
    name: 'APV_Liverpool_City_Council',
  },
  {
    oid: 'D4CA2AC4-7058-45B1-80FA-4B5E6731E268',
    name: 'APV_Shire_of_Bridgetown-Greenbushes',
  },
  {
    oid: '8A8100C6-F91F-439C-8DEC-4BF960FFD530',
    name: 'APV_Wagga_Wagga_City_Council',
  },
  {
    oid: '38176BF8-2299-452B-9FE9-4C365A23856A',
    name: 'APV_Doomadgee_Aboriginal_Shire_Council',
  },
  {
    oid: 'E30D27E8-4D69-42DD-BDB2-4CE861D27CAB',
    name: 'APV_City_of_Marion',
  },
  {
    oid: 'F7918FBF-CE97-449B-B428-4D505E40C022',
    name: 'APV_City_of_Rockingham',
  },
  {
    oid: '1E4C08E6-3BA2-4F0A-9961-4E0990FE3A44',
    name: 'APV_Derwent_Valley_Council',
  },
  {
    oid: '6FED730A-946D-4BAD-ACC0-4E406627BAE0',
    name: 'APV_City_of_Mandurah',
  },
  {
    oid: '7AB7DAE7-9E61-4C78-97B9-4E535CE03EAA',
    name: 'APV_Snowy_Valleys_Council',
  },
  {
    oid: '3DC4434A-49CD-4165-A106-4F4FC662784F',
    name: 'Default',
  },
  {
    oid: '591D6D34-7755-4FD2-925B-4F63EF0D8FFC',
    name: 'APV_District_Council_of_Ceduna',
  },
  {
    oid: '3E00971E-3E01-4263-969D-501B25F47C71',
    name: 'APV_SO_Harvey',
  },
  {
    oid: '98AF6D07-EF27-4181-9BFF-5190E674A7AB',
    name: 'Old_Barcaldine_Regional_Council',
  },
  {
    oid: '3D015AD9-AF17-4238-A34B-53ADBC19F81E',
    name: 'APV_Logan_City_Council',
  },
  {
    oid: '78020467-1E38-4B40-9C29-53E9FF6F5357',
    name: 'APV_University_of_New_England',
  },
  {
    oid: 'CFAE4D18-7A49-4850-9E7A-545E2C37A6FB',
    name: 'APV_Eastern_Metropolitan_Regional_Council',
  },
  {
    oid: '8A868143-8F6D-4651-B060-55FFC9781000',
    name: 'AVP_Demo_Council',
  },
  {
    oid: '15E0810B-F7FD-4510-AE0B-57697B83FD31',
    name: 'APV_Mid_Western_Regional_Council',
  },
  {
    oid: 'F8360CDF-354F-431D-A9AD-576CB52A3C39',
    name: 'APV_CO_Armadale',
  },
  {
    oid: '7F9227CB-24C7-4594-8BD7-58D5A58F5D23',
    name: 'Goldenfields_Water',
  },
  {
    oid: '3D95C0BD-A8EF-4A81-8C68-59EB0A9F577A',
    name: 'APV_MidCoast_Council',
  },
  {
    oid: 'C4908A5C-90F6-4475-BC14-5AB4B7096BB6',
    name: 'APV_Southern_Grampians_Shire_Council_to_be_delete',
  },
  {
    oid: '9E33DBE8-BCC3-47CB-8155-5BA3904805A3',
    name: 'APV_City_of_Sydney',
  },
  {
    oid: 'B9286E3F-34EE-4E8B-88DC-5C1F7300B970',
    name: 'APV_Port_Macquarie_Hastings_Council',
  },
  {
    oid: '188266AB-63FB-4F22-A0A2-5D11B0EE310A',
    name: 'APV_TO_Bassendean',
  },
  {
    oid: '7B58A02D-9011-4D35-BB53-5D5DCCA28458',
    name: 'APV_City_of_Canada_Bay',
  },
  {
    oid: 'F138766C-59D9-4730-9586-5D960FFDA6F7',
    name: 'APV_Shire_of_Williams',
  },
  {
    oid: '53ADCA82-CB8B-411B-B809-5DBDFB10587B',
    name: 'Small_CLient',
  },
  {
    oid: '8BAC9A1C-ABDE-49EC-BFA9-5DF336FFCCCF',
    name: 'GOV_Group_Admin',
  },
  {
    oid: '4F4F9A43-D486-45B0-B804-5EBD9EF2BCA5',
    name: 'APV_Warrumbungle_Shire_Council',
  },
  {
    oid: '740428AB-5AEF-4EB6-B9ED-5EC095178A2F',
    name: 'APV_Dev_Client',
  },
  {
    oid: '57F690F9-762F-43B7-AB71-5F59DE10A92E',
    name: 'APV_Broken_Hill_City_Council',
  },
  {
    oid: '590AFC47-B0DF-4CFA-9C95-5F5BB84F635A',
    name: 'APV_SO_Toodyay',
  },
  {
    oid: 'D4F60BF5-D273-41B1-BAE5-5F999D66EB75',
    name: 'APV_Glen_Innes_Severn_Council ',
  },
  {
    oid: '99BF0009-EC7D-4EBC-81CD-60A6EAC15201',
    name: 'APV_Mornington_Shire_Council_CTM',
  },
  {
    oid: '7897F216-C765-4FF0-8EBE-6164D1355606',
    name: 'APV_City_of_Onkaparinga',
  },
  {
    oid: '8883735A-08DD-4EB2-978C-62299FB7650D',
    name: 'Port_Stephens_Council',
  },
  {
    oid: 'DC550989-EE54-4329-B289-62602B61CC86',
    name: 'APV_PMSA_Clayfield_College',
  },
  {
    oid: '9CBC3C33-BE1A-4C90-A30B-63CF9AF6C76F',
    name: 'APV_Default',
  },
  {
    oid: '8C6E4A23-4F5A-4341-BBD0-640900A7017C',
    name: 'APV_City_of_Tea_Tree_Gully',
  },
  {
    oid: '948B6ACB-1FE5-46D3-9699-64F75C375911',
    name: 'APV_Richmond_Valley_Council',
  },
  {
    oid: '44139482-810D-4F35-9AAF-65A217FCD81E',
    name: 'APV_Sept2016',
  },
  {
    oid: '1633316A-6BEB-431E-9BBE-671F55724BD1',
    name: 'APV_Ballina_Shire_Council_Old',
  },
  {
    oid: '4E2F814C-B772-4742-8873-67CE003E577C',
    name: 'Nambucca_Valley_Council',
  },
  {
    oid: 'BE16BAB7-C633-4FED-AFE9-686D97004C16',
    name: 'APV_Narrandera_Shire_Council',
  },
  {
    oid: 'B6320E3F-EAD5-4A32-A607-698609B8AF90',
    name: 'APV_Yeronga_Football_Club',
  },
  {
    oid: '0B452B5D-C62F-4BB4-AE9E-69C4C935C5F9',
    name: 'APV_TasWater_Corporation_Pty_Ltd',
  },
  {
    oid: '55658390-3B7C-436B-8999-6A389A433705',
    name: 'APV_Gunnedah_Shire_Council',
  },
  {
    oid: '6F1BFB48-9D47-461D-A824-6BD5A1E0D6C7',
    name: 'APV_Hawkesbury_City_Council',
  },
  {
    oid: 'F8F8322F-E193-42F1-8A5E-6C6C32F9A915',
    name: 'APV_Goulburn_Mulwaree_Council',
  },
  {
    oid: 'EF27B59B-A47D-4220-8030-6CF28DB2F710',
    name: 'APV_Hunter_Water_Corporation',
  },
  {
    oid: 'BDDC33C3-4D23-4CB0-8FD1-6D5B12FA6FC4',
    name: 'APV_Demo_GOV',
  },
  {
    oid: '8972D736-6808-49B1-853D-6DB8D683DBB8',
    name: 'Barcaldine_Regional_Council',
  },
  {
    oid: 'DDB3D756-26AA-4731-9825-6EBD1EEF6641',
    name: 'APV_My_Pathway_Doomadgee',
  },
  {
    oid: 'BD2892A4-E5B8-436B-93AD-711A6F674F6A',
    name: 'APV_City_of_Charles_Sturt',
  },
  {
    oid: 'EA81C126-AC10-4590-81EF-721966B87656',
    name: 'APV_Inverell_Shire_Council',
  },
  {
    oid: '786AD437-286E-4F2C-A0C1-723769EBCDB6',
    name: 'APV_Sunshine_Coast_Grammar_School',
  },
  {
    oid: '9666A62C-2340-49CC-B622-73102EDD7E72',
    name: 'APV_Client_One',
  },
  {
    oid: '9365423F-B897-4F90-BE9F-737ED04DDB98',
    name: 'APV_Shire_of_Kulin',
  },
  {
    oid: 'BD77A7BE-BE94-4000-99C0-73CFD0E4C4A2',
    name: 'APV_Client_Three',
  },
  {
    oid: '498DCF11-8E3A-40C0-8527-749462ADFAFE',
    name: 'APV_Lithgow_CC',
  },
  {
    oid: 'AE3829CD-CA22-4F08-9FA6-7515BDB03BA3',
    name: 'APV_Mackay_Regional_Council',
  },
  {
    oid: '738CAF19-E6DC-4CDA-9916-76595A3EC4E9',
    name: 'APV_Carrathool_Shire_Council',
  },
  {
    oid: '2F49E648-35B3-46CD-BA4F-76659CFA7AD2',
    name: 'APV_Cherbourg',
  },
  {
    oid: '1530AFB2-34F9-4E5C-9415-76CCE995FE39',
    name: 'GOV_Education',
  },
  {
    oid: '2B00B6CC-FDBA-42A2-A28E-7702C6FE4C25',
    name: 'APV_SO_Boddington',
  },
  {
    oid: 'E12D2354-8D16-4936-91EA-7BDF96120AC6',
    name: 'FVP3',
  },
  {
    oid: '7FB79E9A-3064-4679-AB1A-7C236850308D',
    name: 'APV_Brewarrina_Shire_Council',
  },
  {
    oid: 'EF694CFA-19A3-4B71-B8E8-7CC307630523',
    name: 'APV_Willoughby_City_Council',
  },
  {
    oid: 'EBC2828F-641C-4336-881F-7E64F68DB0CE',
    name: 'APV_City_of_Belmont',
  },
  {
    oid: '6AFC865E-F26E-4398-A1A5-7EB15E5AE0E7',
    name: 'APV_Department_of_Communities_Housing_and_Digital_Economy',
  },
  {
    oid: '782D46AA-E7FB-4D28-93A0-7EBDF0ED1A87',
    name: 'Test_V_Client',
  },
  {
    oid: 'EF7D2722-F9CC-446E-B0D0-7F3DD18B7F5F',
    name: 'APV_James_Cook_University',
  },
  {
    oid: 'F4B57E24-E1ED-43DF-9E5F-80874AB7ED62',
    name: 'APV_SO_Chapman_Valley',
  },
  {
    oid: '3597195F-F27B-4CE3-A140-822CEBD6422C',
    name: 'APV_PMSA_Sunshine_Coast_Grammar_School',
  },
  {
    oid: 'F05CF8DA-445F-427E-9623-8233EC202C25',
    name: 'APV_PMSA_Brisbane_Boys_College',
  },
  {
    oid: '6EC0F6DD-17EB-4D14-AE63-8262AFC51B50',
    name: 'Clients_AUSTRALIA',
  },
  {
    oid: '57FBDBE6-4588-4918-B586-827EF280AE83',
    name: 'CTMG_Central_Goldfields_Council',
  },
  {
    oid: '9DC4FA9A-8B68-4C7B-981A-82F30938D00E',
    name: 'Deleted_APV_Townsville_City_Council',
  },
  {
    oid: '4D009160-81B7-41E2-9AF9-84CA305F954B',
    name: 'APV_Mareeba_Shire_Council',
  },
  {
    oid: '7FF614D2-3263-451D-9E74-852FAE605A95',
    name: 'APV_City_of_Burnside',
  },
  {
    oid: 'AF54AD5E-7B33-41B3-AF58-8708E72BCFB4',
    name: 'APV_Town_of_Claremont',
  },
  {
    oid: '402FDEC5-F703-4F15-B6E6-8759DE42F1C5',
    name: 'APV_Lake_Macquarie_City_Council',
  },
  {
    oid: 'F57240A6-B1FC-4807-8CB0-87E3C6CB0CE9',
    name: 'APV_Test_Client',
  },
  {
    oid: '088339AE-8532-4476-959E-88DB23DE734F',
    name: 'APV_City_of_Port_Lincoln',
  },
  {
    oid: 'A41E0E8D-7333-4B3D-ACDB-8AB924978B3F',
    name: 'APV Dev Demo',
  },
  {
    oid: '7BFDFE12-4E9A-45FB-B3EA-8D2B3EF99BE0',
    name: 'APV_City_of_Swan',
  },
  {
    oid: 'B6FC3321-E45B-4710-9DA5-8EAF8CA8C2FA',
    name: 'APV_Shire_of_Lake_Grace',
  },
  {
    oid: '871A00F9-2346-47E2-BD2F-8F1A335B75E9',
    name: 'APV_Berrigan_Shire_Council',
  },
  {
    oid: '826F47ED-42AD-456D-8653-8FBACFADBC22',
    name: 'APV_Anglican_Community_Care',
  },
  {
    oid: '57C17831-0D73-431D-95DA-90F8E0BF8414',
    name: 'APV_SO_Peppermint_Grove',
  },
  {
    oid: '75981742-BDA7-4593-9C0C-91CE67969409',
    name: 'APV_Test_2',
  },
  {
    oid: '4AC2BC75-32BA-4436-A07E-9250BD5E392E',
    name: 'APV_Walgett_Shire_Council',
  },
  {
    oid: '09955C05-FFAD-4391-85B5-92A9B506A3D1',
    name: 'APV_Anglican_Diocese_of_Adelaide',
  },
  {
    oid: 'DD6AA345-DD47-4EBB-AA2D-9301D5C0B968',
    name: 'APV_Boulia_Shire_Council',
  },
  {
    oid: 'B41DB999-FC9C-4140-8E8D-945DDA06C468',
    name: 'FVP4 Workshop',
  },
  {
    oid: 'E94CF788-D3B7-49B0-8A1E-94A5E26FF512',
    name: 'APV_Client_Two',
  },
  {
    oid: '7597619A-FABA-41EB-BABB-955533027A81',
    name: 'APV_Cessnock_City_Council',
  },
  {
    oid: 'CC37644B-1057-4734-AFD6-960C1A39D4A5',
    name: 'APV_Grace_College ',
  },
  {
    oid: 'FA45ECA6-92FE-453D-8FE6-96ECE30AF495',
    name: 'APV_Griffith_City_Council_ori',
  },
  {
    oid: '3B8E2AB8-C992-476A-9048-977A20AD9FB1',
    name: 'APV_Barcaldine_Regional_Council_Old',
  },
  {
    oid: '75A4659C-56E6-4C5A-AECC-98DDAF1C5166',
    name: 'APV_Maranoa_Regional_Council',
  },
  {
    oid: '42036B29-C7BF-42BD-A41E-993ADC2F1E12',
    name: 'APV_Clayfield_College',
  },
  {
    oid: '5FFBEBDE-2163-43FA-98A4-998425A29B70',
    name: 'AVP_Demo_Group',
  },
  {
    oid: '2120236C-D580-4DAC-8F93-99A403CE32C0',
    name: 'APV_Noosa_Council',
  },
  {
    oid: '7AA30148-9C8B-4FB9-98FC-99C744AC1005',
    name: 'Sandpit_PSCC',
  },
  {
    oid: '4EA7E747-01C1-4B92-A15D-9A3B2790D090',
    name: 'APV_Department_of_Child_Safety_Youth_and_Women',
  },
  {
    oid: '3DF08C4F-D981-4BA1-93FB-9B1920EA0671',
    name: 'APV_Gilgandra_Shire_Council',
  },
  {
    oid: '382AE09E-C1CD-4E88-AA7B-9BE3FA45078A',
    name: 'CTM Group',
  },
  {
    oid: '749D9444-83A1-44D7-A8FC-9D155EADF22D',
    name: 'APV_Cairns_Regional_Council',
  },
  {
    oid: 'E2772710-BD43-47FF-9936-9D999C572E08',
    name: 'City_of_Greater_Geelong',
  },
  {
    oid: 'FC37AD6B-46ED-4EC4-AB51-9DB79FAC3392',
    name: 'APV_Burdekin_Shire_Council',
  },
  {
    oid: '2AF5CA68-2908-4BD2-8320-9DF43CC731AB',
    name: 'APV_Bayside_Council',
  },
  {
    oid: '6E617F9E-7CAC-4B81-ACD0-9EA1B9524C02',
    name: 'APV_ACT_Government_Property_Group',
  },
  {
    oid: 'EDF68570-9AFD-4244-9240-9F2C1B1B6D1A',
    name: 'APV_Shire_of_Quairading',
  },
  {
    oid: '6A049820-C637-4DFE-8510-A003763D6138',
    name: 'APV_Waratah_Wynyard_Council',
  },
  {
    oid: 'BC3867A9-BF38-4D20-A9A2-A10377CEA81B',
    name: 'APV_Shire_of_Gnowangerup',
  },
  {
    oid: '5EC1A145-07BA-491E-ADF1-A3462DF6948D',
    name: 'Sandpit_GCC',
  },
  {
    oid: '55C2F27B-2900-4C75-AC06-A6E88E4BBC4F',
    name: 'APV_Shire_of_Corrigin',
  },
  {
    oid: 'CBAFADB5-04B2-47A8-B954-A8552A4DF7E6',
    name: 'FairValuePro_Logo_Client',
  },
  {
    oid: 'E63BEAE5-7EF6-4DB7-AE53-A8B1C25854F5',
    name: 'APV_Gladstone_Regional_Council',
  },
  {
    oid: '5C938FA6-4BC4-4175-A85E-A8E94EBB93EE',
    name: 'APV_Department_of_Land_WA',
  },
  {
    oid: '67229BAF-BED8-4B46-8C45-AB053FB3A6F1',
    name: 'APV_Shire_of_Trayning',
  },
  {
    oid: '43135623-B636-4E23-ABBC-ACCFA4F56312',
    name: 'TestTest',
  },
  {
    oid: '84A4FC79-8EE1-4B5D-8B7E-ADDC4BE50AB9',
    name: 'GOV_OGCIO',
  },
  {
    oid: '76A9B3B8-2A71-427E-BA68-AEA9FCF016EB',
    name: 'APV_Shire_of_Derby_West_Kimberley',
  },
  {
    oid: 'D878C434-0BF4-4B51-9315-AEC5AAA6467E',
    name: 'APV_Shire_of_Murray',
  },
  {
    oid: '16709214-6B84-4EBA-99AD-B0D7AD281B26',
    name: 'AVP_Demo_Dev',
  },
  {
    oid: 'A3D8B5DD-BEA9-48B4-A48F-B278D3E60C39',
    name: 'APV_Edward_River_Council_Delete',
  },
  {
    oid: 'D56D4632-BBD9-48E7-B668-B2B4AFBC95F1',
    name: 'APV_Toowoomba_Regional_Council',
  },
  {
    oid: '2012DCD4-7236-4B34-92DE-B301D2FF7A66',
    name: 'APV_Western_Downs_Regional_Council',
  },
  {
    oid: '43DC6A97-D42B-46C6-9854-B3EF85149DA7',
    name: 'APV_Torres_Shire_Council',
  },
  {
    oid: '1E7B6795-5C92-4BE5-98DF-B40A45633D76',
    name: 'GOV_Land',
  },
  {
    oid: 'C994BC8C-386B-447F-BB92-B4368EDE83A5',
    name: 'GOV_Training_File',
  },
  {
    oid: 'EA677111-41E3-4A80-8539-B6C26C49BE72',
    name: 'AVP_DPT_of_Seniors_Disability_Services_N_Aboriginal_N_Torres_Strait_Islander_Partnerships',
  },
  {
    oid: '12237936-0D6C-48AE-A84B-B81AF32BA98A',
    name: 'APV_Fraser_Coast_Regional_Council',
  },
  {
    oid: '365FCD86-EB3F-4EE0-AA36-B87DFF048318',
    name: 'APV_Lockhart_River_Aboriginal_Shire_Council',
  },
  {
    oid: 'AE169327-AE19-4B21-96BC-B90923138800',
    name: 'APV_Augusta_Margaret_River',
  },
  {
    oid: '4697DFCF-6EA2-46A1-B56C-B9CAAA52A33F',
    name: 'APV_Upper_Hunter_Shire_Council',
  },
  {
    oid: '64B70E1A-8704-473A-96BF-B9DEFBA71B5A',
    name: 'GOV_Health',
  },
  {
    oid: '42A5382F-A869-4857-ACFF-BAC4C17436B8',
    name: 'APV_Edward_River_Council_NSW',
  },
  {
    oid: '78B44CDA-6C1B-4A7F-BD44-BAD12B58BA3C',
    name: 'APV_Southern_Grampians_Shire_Council',
  },
  {
    oid: '9053F285-B91D-4F84-9625-BBBF09C6F208',
    name: 'APV_Tenterfield_Shire_Council',
  },
  {
    oid: '9E3E2328-BAAF-4B03-9670-BC7AB0D011DF',
    name: 'APV_Gwydir_Shire_Council',
  },
  {
    oid: 'B0509515-8BBF-4DAC-9BBE-BCBC9F6C3013',
    name: 'Sandpits_Anglican_SEQ',
  },
  {
    oid: '66080C5F-E809-404F-BD81-BCE18F24E5E1',
    name: 'APV_Coonamble_Shire_Council',
  },
  {
    oid: '8B114653-D08D-4387-B3F2-BCF84B9CFF1B',
    name: 'APV_Coffs_Harbour',
  },
  {
    oid: '44C0AABA-9DAF-4621-8DC8-BD264147CA5B',
    name: 'APV_Shire_of_Dowerin',
  },
  {
    oid: 'AF03165B-84DE-4988-B9D5-BD26BD53CB12',
    name: 'GOV_OPS',
  },
  {
    oid: '6D0DF3DB-5F60-425D-9716-BD415D310940',
    name: 'Sandpit_Nambucca',
  },
  {
    oid: 'B8E53F15-22B6-49C3-83A7-BD70B773356A',
    name: 'APV_Port_Stephens_Council',
  },
  {
    oid: 'B024F314-B8B0-4C4F-A08C-BD998542C2EC',
    name: 'APV_Moreland_City_Council',
  },
  {
    oid: 'FBE4D6D5-5A58-47C7-A340-BE614E9F78A0',
    name: 'APV_Redland_City_Council',
  },
  {
    oid: 'B05D39D0-A65D-4F02-B921-BF2BA97CCDF9',
    name: 'APV_Murray_River_Council',
  },
  {
    oid: 'AC1CC005-553E-4015-B9C7-BFC6CB2967AB',
    name: 'APV_Shire_of_Pingelly',
  },
  {
    oid: '8AC7FAAA-B416-4F9A-BDBC-C0FBC954CF47',
    name: 'APV_Town_of_Mosman_Park',
  },
  {
    oid: 'DC72341D-94AA-4111-BC7E-C1373B1158B6',
    name: 'APV_Hinchinbrook_Shire_Council',
  },
  {
    oid: '7C64A33C-62D0-497D-BFEB-C2608867EA50',
    name: 'Sandpits_Group',
  },
  {
    oid: '418FEADE-D66D-41F0-99C7-C32F7F8F17D4',
    name: 'APV_Mosman_Council',
  },
  {
    oid: '46F0D83A-F74D-4A0A-8BE3-C49EE30585B8',
    name: 'APV_Clarence_Valley',
  },
  {
    oid: '222BDDEC-CB86-4FC0-B2CF-C4CBEEF51905',
    name: 'APV_YFS_Ltd',
  },
  {
    oid: '14A65B6F-79F7-4078-A6CA-C508D3CC4309',
    name: 'APV_Lockhart_Shire_Council',
  },
  {
    oid: 'FD9681B4-EA1D-4A06-91B0-C5A24307A532',
    name: 'APV_Sydney_Local_Health_District',
  },
  {
    oid: '23F742CA-395A-408C-8446-C5BB84C134FB',
    name: 'APV_Shire_of_Nannup',
  },
  {
    oid: '0D6084F0-A6F2-414A-9DA3-C75D7D2ED92B',
    name: 'APV_Shire_of_Wandering',
  },
  {
    oid: '8A46CF34-1CBF-4489-98E4-C7C11079C600',
    name: 'AVP_Demo',
  },
  {
    oid: '5FF540E1-13B7-43A5-9F02-C89EEDCFDBE3',
    name: 'APV_ACT_Government_Economic_Development',
  },
  {
    oid: '409D8748-5AD6-46A7-BFC1-C9E3E3D31671',
    name: 'APV_SO_Murchison',
  },
  {
    oid: '1764301B-2A4B-4B76-A068-CD2939BC3E8C',
    name: 'Griffith_City_Council',
  },
  {
    oid: 'A30F6EEC-A166-480C-ACB3-CDC603E6DC6A',
    name: 'APV_Department_of_Children_Youth_Justice_and_Multicultural_Affairs',
  },
  {
    oid: '63F0A6CF-ADDE-4E4E-8268-CE9F3CCEA5C9',
    name: 'APV_Shire_of_Irwin',
  },
  {
    oid: 'A00BCB7E-2FAD-4E03-B748-CF040A590DE0',
    name: 'APV_Blackall_Tambo_Regional_Council',
  },
  {
    oid: 'D2C5E08F-DF27-4C39-8A85-CF4740DDDB2D',
    name: 'GOV_SCA_Assets',
  },
  {
    oid: 'C950A7D8-70BC-400D-9CA4-D3D9874B73FF',
    name: 'Default',
  },
  {
    oid: 'CA54AE15-098A-43D5-9D35-D40A30CD349F',
    name: 'APV_Mindarie_Regional_Council',
  },
  {
    oid: '44C97308-E31B-466D-8C28-D5317E60EA77',
    name: 'APV_Eurobodalla_Shire_Council',
  },
  {
    oid: '71583E67-7129-4AD3-93AE-D533C63C6ADB',
    name: 'Default',
  },
  {
    oid: 'A3E80D69-3B9B-4267-AE9B-D683694F8E16',
    name: 'APV_Central_Queensland_University',
  },
  {
    oid: 'BCE6F13A-640A-4AE2-ADAE-D7E1275A65E9',
    name: 'APV_Mildura_Airport_Pty_Ltd',
  },
  {
    oid: '2567A24B-45F5-4743-A81E-D85450A7410B',
    name: 'APV_City_of_Charles_Sturt',
  },
  {
    oid: '09694FB9-BA6C-497D-B876-D88391C639C3',
    name: 'APV_The_University_of_the_South_Pacific',
  },
  {
    oid: '046B5C6E-F568-4599-96D7-D902D3DA8A39',
    name: 'APV_Department_of_Seniors_Disability_Services_and_Aboriginal_and_Torres_Strait_Island_Partnerships',
  },
  {
    oid: '2034640F-388D-4FB6-9AA2-D9FA1203274B',
    name: 'APV_Office_of_the_Coordinator-General',
  },
  {
    oid: '3762ADA0-B4EF-4CC1-AF11-DB65ED099DAB',
    name: 'GOV_DPW',
  },
  {
    oid: '148D69D7-453C-48D9-B99D-DC66D56DD1C0',
    name: 'APV_TEST_VALUATION',
  },
  {
    oid: '4EC34E68-25B2-436B-9503-DCA4468A7CF2',
    name: 'APV_Shire_of_Beverley',
  },
  {
    oid: '84C12A7E-D872-4838-8B2C-DD9BD297959B',
    name: 'APV_Sync_Client',
  },
  {
    oid: 'F6864E03-BD99-48A7-A5C7-DDBFFD4288D4',
    name: 'APV_Port_of_Brisbane',
  },
  {
    oid: '2994B3F8-6D43-4F57-A97A-DE0462205232',
    name: 'APV_The_University_of_the_Sunshine_Coast',
  },
  {
    oid: 'BC8F835C-C8AF-43BA-A144-DE082ABE93BE',
    name: 'APV_Villanova_College',
  },
  {
    oid: 'DE14B11C-FED2-496D-86E2-DE8FA17462D4',
    name: 'APV_Lockyer_Valley_Regional_Council',
  },
  {
    oid: '36F57E09-C4B6-4774-AA04-DF41D5BF9A22',
    name: 'APV_DSDSATSIP',
  },
  {
    oid: '3242A232-CFCC-4450-BA50-DF5122A64CA2',
    name: 'APV_City_of_Parramatta',
  },
  {
    oid: '77A790CE-1636-4CE5-B9B0-E12DF2918958',
    name: 'Default',
  },
  {
    oid: 'B3431F3B-3503-4B95-9C83-E22F6D1964CE',
    name: 'GOV_Education_Facilities_Survey',
  },
  {
    oid: 'F28A493F-4D71-4873-ACE6-E2D9E35762C6',
    name: 'APV_Forbes_Shire_Council',
  },
  {
    oid: 'AE1A4D03-2888-4354-94B8-E41A0245E2C2',
    name: 'APV_National_Trust_of_Australia',
  },
  {
    oid: 'DDAE6007-87D0-4F02-B851-E4340DACDE08',
    name: 'APV_Mapoon_Aboriginal_Shire_Council',
  },
  {
    oid: '7FFC3EDB-F0CE-4C6F-96AB-E5917C77482F',
    name: 'APV_North_Burnett_Regional_Council',
  },
  {
    oid: 'E95A9992-373F-4C79-BA7B-E5A5910BCC75',
    name: 'APV_Riverina_Water_County_Council',
  },
  {
    oid: 'DDE16D0D-498F-4182-B813-E661DFC50AFE',
    name: 'APV Group Administrator',
  },
  {
    oid: 'D48EC4E8-FE6D-4F70-AFB5-E7136B2792D6',
    name: 'APV_Bogan_Shire_Council',
  },
  {
    oid: '61898632-2E44-4442-BB56-E7A6CF02BEA6',
    name: 'APV_Hay_Shire_Council',
  },
  {
    oid: '35982D01-9B40-42F9-9BD0-E86ED9D39A1C',
    name: 'APV_University_of_Southern_Queensland',
  },
  {
    oid: '9FB40A8F-DF76-41B1-B216-E8E020B6CF36',
    name: 'APV_City_of_Greater_Geraldton',
  },
  {
    oid: '9B0FC63D-7C81-4131-95F6-EA0136F00768',
    name: 'APV_South_Burnett',
  },
  {
    oid: '15E5AFD0-B1B1-4189-AB02-EAB81743310D',
    name: 'APV_Shire_of_Brookton',
  },
  {
    oid: 'B387E38E-8A6A-4176-9C8B-EB06413CBE3C',
    name: 'APV_Campbelltown_City_Council',
  },
  {
    oid: 'CEB49E54-EC54-4904-BB89-EB2DF4622471',
    name: 'APV_TO_Cottesloe',
  },
  {
    oid: '68DD94B5-6D9A-47BC-993C-EE9C95315A33',
    name: 'APV_Warren_Shire_Council',
  },
  {
    oid: '37D62059-13D4-42AC-80A9-EEDB095AAF10',
    name: 'APV_SO_Kalamunda',
  },
  {
    oid: '42E4FDD3-71F8-4CA7-A76C-EEE6B9E874D4',
    name: 'APV_Murrindindi_Shire_Council',
  },
  {
    oid: 'AD8E374D-D2B7-4B8B-B90C-EF06398AF918',
    name: 'CTM_NZ',
  },
  {
    oid: 'E7AE033A-50C9-446F-B569-EF34C724EDFD',
    name: 'APV_Kyogle',
  },
  {
    oid: 'C2112AC2-AB17-464C-B3CD-F0043D0C81BC',
    name: 'APV_Richmond_Shire_Council',
  },
  {
    oid: 'A8DE5676-4F34-4A53-9C93-F09BF8E48F7F',
    name: 'CTMG_Central_Goldfields',
  },
  {
    oid: 'EA284D2D-51EF-4C7B-8721-F0A03B8E896C',
    name: 'APV_Penrith_City_Council ',
  },
  {
    oid: '6ECD6370-8543-4712-91AD-F10C878B365C',
    name: 'APV_Test_180816',
  },
  {
    oid: '95221DD1-631B-40F2-BB10-F13237239BC3',
    name: 'APV_Bega_Valley',
  },
  {
    oid: 'BE8EEF7E-8B35-4C87-8C59-F1C9228FB793',
    name: 'APV_PMSA_Somerville_House',
  },
  {
    oid: '291315EF-77B3-4375-8589-F3B3432013D9',
    name: 'APV_Client_Two',
  },
  {
    oid: '4A3F0392-13ED-4CC0-88F8-F59794F1F98D',
    name: 'Sandpits_Barcaldine',
  },
  {
    oid: '71B65FDA-E21A-45AE-8E7B-F5EA2B8888E6',
    name: 'APV_Somerset_Regional_Council',
  },
  {
    oid: 'F41667D5-1D35-4189-9B2C-F6692A834206',
    name: 'APV_Townsville_City_Council',
  },
  {
    oid: '385DA1E8-C819-4F30-82BC-F72C22A5EC86',
    name: 'APV_Yass_Valley_Council',
  },
  {
    oid: '0D3CAD1B-6809-4190-BF4B-F802137C0F73',
    name: 'APV_Blacktown_City_Council',
  },
  {
    oid: '8A763CAD-1AD1-446E-9095-F896091B1F4F',
    name: 'APV_Mornington_Shire_Council',
  },
  {
    oid: '020307A6-3063-4C72-96B9-F89F54127746',
    name: 'APV_Sutherland_Shire_Council',
  },
  {
    oid: 'C0172EA4-5B60-429F-A6C7-F8CB2E6F2537',
    name: 'APV_St_Aidans_Anglican_Girls_School',
  },
  {
    oid: '63D2BDB0-76BD-406D-A1D4-FB811804C25B',
    name: 'Default',
  },
  {
    oid: '1364071D-6ACD-4399-8631-FBE4C05639CD',
    name: 'GOV_User_Name',
  },
  {
    oid: '336B953A-D24E-4347-8575-FDBDFDD3B36C',
    name: 'Test_Client_Admin',
  },
  {
    oid: '76521199-ACD2-49F7-94EE-FDC26B79E6E7',
    name: 'APV_Barunga_West_Council',
  },
  {
    oid: '64D2EE66-E6FA-4284-AA1E-FE240E687EF7',
    name: 'APV_Clarence_Valley_Demo',
  },
  {
    oid: '2DFAC155-13D9-42C1-8AC1-FE706A7A8506',
    name: 'APV_Muswellbrook_Shire_Council',
  },
  {
    oid: '9FC093DA-AC8B-4871-B4B2-FFFAA9827565',
    name: 'APV_Wingecarribee_Shire_Council',
  },
  {
    oid: '2b0a7a35-8698-4e0d-8770-0b9a9f9038fc',
    name: 'APV_Wynnum_RSL',
  },
];
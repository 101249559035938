import React, { Fragment, useEffect, useRef, useState } from 'react';
import { UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import 'react-datepicker/dist/react-datepicker.css';

import { Note } from '../models/Note';
import { Asset } from '../models/Asset';
import { ToastType, useToast } from '../contexts/ToastContext';

type NoteEditProps = {
  // index: number;
  // control: Control<Asset, object>;
  // watch: UseFormWatch<Asset>;
  // register: UseFormRegister<Asset>;
  // // defaultValues: Asset;
  // remove: (index?: number | number[] | undefined) => void;
  // field: FieldArrayWithId<Asset, 'notes', 'id'>;
  // noteList: Note[];
  noteType: string;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
  reset: UseFormReset<Asset>;
  isOpen: boolean;
  closeModal(): void;
};

const NoteModal: React.FC<NoteEditProps> = ({ isOpen, closeModal, noteType, reset, getValues }) => {
  // const CopyJobSchema = yup.object().shape({
  //   name: yup
  //     .string()
  //     .max(100, 'Maximum length is 100.')
  //     .required('Name is required')
  //     .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
  //   valuationDate: yup.date().required("Valuation Date is required").nullable(),
  //   type: yup.string().required('Please select a type'),
  //   status: yup.string().required('Please select a status'),
  // });
  const { addToast } = useToast();

  const [note, setNote] = useState<Note>({ tag: noteType } as Note);
  const ref = useRef<HTMLInputElement | null>(null);

  const onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setNote({ tag: noteType, detail: e.target.value } as Note);
  };

  const postNote = (): void => {
    try {
      const notes = getValues('notes') || ([] as Note[]);
      const otherNotes: Note[] = notes.filter((item) => item.tag !== noteType);
      reset({
        ...getValues(),
        notes: [...otherNotes, note],
      });
      addToast('New note added to asset. Please save the asset to ensure the note(s) is saved.');
    } catch (error) {
      console.error('error', error);
      addToast('Sytem Error', ToastType.Error);
    }
    closeModal();
  };

  // useOnClickOutside(ref, () => {
  //   closeModal();
  // });

  useEffect(() => {
    if (isOpen) {
      const notes = getValues('notes') || ([] as Note[]);
      setNote(notes.find((item) => item.tag === noteType) || ({ tag: noteType } as Note));
    }
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" key={note?.id + (note?.tag || 'new')} initialFocus={ref} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="px-4 py-4 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="relative rounded-lg shadow-sm overflow-hidden">
                <div className="flex flex-row justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{noteType} Note</h3>
                  {/* <button
                className="inline-flex focus:outline-none text-gray-400 focus:text-gray-500 transition ease-in-out duration-150"
                onClick={(): void => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button> */}
                  <button
                    type="button"
                    className="rounded-md bg-white  text-gray-400 hover:text-red-700"
                    onClick={(): void => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 sm:mt-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-2">
                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                      <div className="max-w-lg flex flex-col">
                        <div className="mt-1 relative shadow-sm">
                          <div className="flex items-start space-x-4">
                            <div className="min-w-0 flex-1">
                              <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                <textarea
                                  rows={3}
                                  onChange={(e): void => onChangeNote(e)}
                                  className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                  placeholder="Add your note..."
                                  defaultValue={note.detail}
                                />

                                {/* Spacer element to match the height of the toolbar */}
                                <div className="py-2" aria-hidden="true">
                                  {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                  <div className="py-px">
                                    <div className="h-9" />
                                  </div>
                                </div>
                              </div>

                              <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
                                <div className="flex-shrink-0">
                                  <button
                                    onClick={(): void => postNote()}
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    Post
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NoteModal;

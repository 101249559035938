import React, { useEffect } from 'react';

interface Props {}

export const NoClient: React.FC<Props> = () => {
  return (
    <div className="flex min-h-screen justify-center items-center text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
      <h1>Could not find your company details, please contact your administrator</h1>
    </div>
  );
};

import React from 'react';

interface Props {
  assetId?: string;
  assetName?: string;
  assetClass?: string;
  assetType?: string;
  assetSubType?: string;
  component?: string;
  componentType?: string;
  componentSubType?: string;
}

export const FilterTable: React.FC<Props> = ({
  assetId,
  assetName,
  assetClass,
  assetType,
  assetSubType,
  component,
  componentType,
  componentSubType,
}) => (
  <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 contents">
      <div className="py-2 align-middle inline-block sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody>
              <tr className="bg-white">
                <td className="pl-6 pr-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Asset Class</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{assetClass}</td>
                <td className="px-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Component</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{component}</td>
                <td className="px-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Asset ID</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{assetId}</td>
              </tr>

              {/* Even row */}
              <tr className="bg-gray-50">
                <td className="pl-6 pr-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Asset Type</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{assetType}</td>
                <td className="px-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Component Type</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{componentType}</td>
                <td className="px-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">Asset Name</td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{assetName}</td>
              </tr>

              <tr className="bg-white">
                <td className="pl-6 pr-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">
                  Asset Sub-Type
                </td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{assetSubType}</td>
                <td className="px-2 py-1 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">
                  Component Sub-Type
                </td>
                <td className="pl-1 pr-6 py-1 whitespace-nowrap text-xs leading-5 text-gray-500">{componentSubType}</td>
              </tr>

              {/* Odd row */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

import React, { useState, useRef } from 'react';
import { Switch, Transition } from '@headlessui/react';
import { useOidc } from '@axa-fr/react-oidc';
import { NavLink } from 'react-router-dom';
import useOnClickOutside from './useOnClickOutside';
import { useAuth } from '../contexts/AuthContext';
import { useClient } from '../contexts/ClientContext';
import { useNotification } from '../contexts/NotificationContext';
import { useAssetSearch } from '../contexts/AssetSearchContext';
import { Roles } from '../models/Role';

interface Props {
  isAdmin: boolean;
}

export const UserSummary: React.FC<Props> = ({ isAdmin }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { userName, client, isLoggedIn, fullName, roles } = useAuth();
  const { removeReadOnlyAssetClasses } = useClient();
  const { notifications } = useNotification();
  const { isValuerMode, setIsValuerMode } = useAssetSearch();

  const [isOptions, setIsOptions] = useState<boolean>(false);
  const { logout } = useOidc();

  useOnClickOutside(ref, () => {
    setIsOptions(false);
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="px-2 mt-6 relative inline-block text-left">
      <button
        onClick={(): void => setIsOptions(true)}
        type="button"
        className="group w-full px-3.5 py-2 text-sm leading-5 rounded-md font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center justify-between space-x-3">
            {notifications.length > 0 && (
              <span className="flex absolute h-3 w-3 top-0 right-2 -translate-y-1/4 translate-x-1/4 block">
                <span className="relative inline-flex rounded-full h-3 w-3 bg-indigo-600" />
              </span>
            )}

            <div className="flex-1">
              <h2 className="text-gray-900 text-sm leading-5 font-medium">{fullName.length > 2 ? fullName : userName}</h2>
              <p className="text-xs font-medium hidden lg:block text-gray-500 group-hover:text-gray-700">{client}</p>
            </div>
          </div>
          <div className="flex flex-col content-center">
            <svg className="h-6 w-6 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </button>
      <Transition
        show={isOptions}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div ref={ref} className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 shadow-lg">
          <div className="bg-white shadow rounded-md" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className="py-1">
              <NavLink
                to="/notifications"
                className="block px-4 py-2 text-sm leading-5 text-gray-700 flex justify-between hover:bg-gray-100 hover:text-indigo-600 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="flex-1">Notifications</span>
                {notifications.length > 0 && (
                  <span className="bg-gray-100 group-hover:bg-gray-200 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
                    {notifications.length}
                  </span>
                )}
              </NavLink>
              {!roles.includes(Roles.Viewer) && (
                <Switch.Group
                  as="div"
                  className="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-indigo-600 focus:outline-none focus:bg-gray-100 focus:text-indigo-600 flex justify-between items-center"
                >
                  <Switch.Label as="span" className="mr-3 text-sm">
                    <span className="text-gray-700">Valuer Mode</span>
                  </Switch.Label>
                  <Switch
                    checked={isValuerMode}
                    onChange={setIsValuerMode}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-offset-2"
                  >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isValuerMode ? 'bg-indigo-700' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isValuerMode ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              )}

              {isLoggedIn && (
                <button
                  type="button"
                  onClick={(): void => {
                    logout('/logout-callback');
                    removeReadOnlyAssetClasses();
                  }}
                  className="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-indigo-600 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

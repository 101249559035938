/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { ImageDisplay } from '../../models/Image';
import { useAuth } from '../../contexts/AuthContext';

interface Props {
  isOpen: boolean;
  closeModal(): void;
  images: ImageDisplay[];
  imageUrls?: string[];
  index: number;
}
const ImageModal: React.FC<Props> = ({ isOpen, closeModal, images, imageUrls, index }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const { clientGuid } = useAuth();
  useEffect(() => {
    setCurrentImage(index);
  }, [index]);
  const imageList = imageUrls != undefined ? imageUrls : images;

  const nextImage = (): void => {
    if (currentImage === imageList.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };
  const prevImage = (): void => {
    if (currentImage === 0) {
      setCurrentImage(imageList.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
              {imageList.length > 1 && (
                <button
                  type="button"
                  className="absolute pr-3 text-slate-700 top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                  onClick={(): void => {
                    nextImage();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <ChevronRightIcon
                    className="h-8 w-8 bg-black text-white opacity-75 rounded-full m-3 hover:opacity-100"
                    aria-hidden="true"
                  />
                </button>
              )}
              {imageList.length > 1 && (
                <button
                  type="button"
                  className="absolute pl-3 text-slate-700 top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                  onClick={(): void => {
                    prevImage();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <ChevronLeftIcon
                    className="h-8 w-8 bg-black text-white opacity-75 rounded-full m-3 hover:opacity-100"
                    aria-hidden="true"
                  />
                </button>
              )}

              <img
                src={
                  imageUrls != undefined
                    ? `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${imageUrls[currentImage]}`
                    : images[currentImage].original
                }
                alt=""
                className="object-cover w-full pointer-events-none group-hover:opacity-75"
              />
              {/* {imageUrls == undefined && (
                <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                  Date taken: {images[currentImage].date}
                </p>
              )} */}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageModal;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useClient } from '../../contexts/ClientContext';
import { ReplacementCostType, useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { ReplacementCostSummary } from '../../models/ReplacementCostSummary';
import ReplacementCostList from './ReplacementCostList';

const AssetReplacementCostList: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const { checkAssetClassEditable } = useClient();
  const { data: { replacementCosts = [] as ReplacementCostSummary[], assetClass } = {} as Asset, refetch } = useAssetById(
    parseInt(id)
  );
  return (
    <div className="bg-white p-4">
      <div className="flex flex-col">
        <ReplacementCostList
          parentId={parseInt(id)}
          refetch={refetch}
          replacementCostList={replacementCosts}
          type={ReplacementCostType.Asset}
          editable={checkAssetClassEditable(assetClass)}
        />
      </div>
    </div>
  );
};

export default AssetReplacementCostList;

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { format, parseJSON } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { Subscription } from '../../models/Subscription';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useApi } from '../../contexts/ApiContext';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { removeTimezoneOffset } from '../../Helper';

const SubscriptionEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id = '' } = useParams<{ id: string }>();
  const history = useNavigate();

  const SubscriptionSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .required('Name for the subscription is required')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
    type: yup.string().required('Please select a type'),
    fee: yup.number().required('Please enter a fee'),
    start: yup.date().required('Start Date is required'),
    expiry: yup.date().required('Expiry Date is required'),
    due: yup.date(),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,

    formState: { errors },
  } = useForm<Subscription>({
    defaultValues: { id: parseInt(id) },
    reValidateMode: 'onBlur',
    resolver: yupResolver(SubscriptionSchema),
  });

  useEffect(() => {
    if (parseInt(id) > 0) {
      api
        .get(`/api/Subscription/Get?id=${id}`)
        .then(({ data }) => {
          reset({
            ...data,
            start: new Date(data.start),
            expiry: new Date(data.expiry),
          });
        })
        .catch((error) => {
          addToast(`Unable to get subscription with ID ${id}`, ToastType.Error);
        });
    }
  }, []);

  const onSubmit = (data: Subscription): void => {
    const subscription = {
      ...data,
      start: removeTimezoneOffset(data.start),
      expiry: removeTimezoneOffset(data.expiry),
      frequency: Number(data.frequency),
    };
    api
      .post('/api/Subscription/Update', { subscription })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} subscription ${data.name}`);
        history(`/subscriptions/${data.id}`);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} subscription ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md sm:overflow-hidden space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <input type="hidden" {...register('id')} />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('name')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </div>
            </div>
            <div>
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Type
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="max-w-lg">
                      <div className="space-y-4">
                        <div className="flex items-center">
                          <input
                            value="Client"
                            {...register('type')}
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                            Client
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            value="Group"
                            {...register('type')}
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                            Group
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            value="External"
                            {...register('type')}
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                            External Client
                          </label>
                        </div>
                        {errors?.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Start Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  name="start"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <ReactDatePicker
                      dateFormat="d MMM yyyy"
                      className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                {errors?.start && <ErrorMessage>{errors.start.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Expiry Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  name="expiry"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <ReactDatePicker
                      dateFormat="d MMM yyyy"
                      className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                {errors?.expiry && <ErrorMessage>{errors.expiry.message}</ErrorMessage>}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Billing Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Price
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  {...register('fee')}
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md block w-full pl-7 pr-12 sm:text-sm border-gray-300"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <label htmlFor="currency" className="sr-only">
                    Currency
                  </label>
                  <select
                    {...register('currency')}
                    className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm"
                  >
                    <option>AUD</option>
                    <option>USD</option>
                    <option>GBP</option>
                    <option>EUR</option>
                    <option>CAD</option>
                    <option>NZD</option>
                    <option>FJD</option>
                    <option>VUV</option>
                    <option>UGX</option>
                    <option>WST</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Billing Frequency
                    </div>
                  </div>
                  <select
                    {...register('frequency')}
                    className="mt-1 block w-full bg-white rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value={0}>Annually</option>
                    <option value={1}>Bi-Anually</option>
                    <option value={2}>Quarterly</option>
                    <option value={3}>Monthly</option>
                    <option value={4}>Fortnightly</option>
                    <option value={5}>Weekly</option>
                    <option value={6}>Once Off</option>
                    <option value={7}>Never</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Full Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.name')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Position
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.position')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email address
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.email')}
                  type="text"
                  autoComplete="email"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Phone Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.phone')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 1
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine1')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 2
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine2')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Suburb
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.suburb')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                City
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.city')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                State / Province
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.state')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 lg:grid-cols-6 sm:gap-4 sm:items-start">
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 col-span-1 lg:col-span-2"
              >
                Postcode / ZIP
              </label>
              <div className="mt-1 sm:mt-0 col-span-1">
                <input
                  {...register('contact.address.postCode')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default SubscriptionEdit;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useParams, unstable_usePrompt, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { DocumentTextIcon, TagIcon } from '@heroicons/react/24/solid';
import { CameraIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useApi } from '../../contexts/ApiContext';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { bulkUploadImages, getEnumKeys } from '../../Helper';
import { DocumentType } from '../../models/DocumentType';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { MaintenancePlan } from '../../models/MaintenancePlan';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Drivers, Priorities, TreatmentTypes } from '../../models/Enums';
import { maintenancePlanHierarchy } from '../../data/MaintenancePlanHierarchy';
import { useAuth } from '../../contexts/AuthContext';
import ImageModal from '../Asset/ImageModal';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { useMaintenancePlanById, useUpdateMaintenancePlan } from '../../hooks/UseMaintenancePlan';
import { ImagesImport } from '../../models/Image';

export const MaintenancePlanEdit: React.FC = () => {
  const api = useApi();
  const [{ element, group, subGroup }, setData] = useState({
    element: '',
    group: '',
    subGroup: '',
  });
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([] as string[]);
  const [isImageDeleteModalOpen, setIsImageDeleteModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const { id = '' } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const { clientGuid } = useAuth();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<FileWithPath[]>([] as FileWithPath[]);
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.Report);


  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const {
    data: maintenancePlan = { images: [] as string[] } as MaintenancePlan,
    refetch,
    isLoading,
  } = useMaintenancePlanById(parseInt(id));
  const cameraRef = useRef<HTMLInputElement>(null);
  const { mutate, isLoading: isSaving } = useUpdateMaintenancePlan();

  const maintenancePlanSchema = yup.object().shape({
    space: yup.string().required('Space is required'),
    item: yup.string().required('Item is required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
    formState: { isDirty, isSubmitted, errors },
  } = useForm<MaintenancePlan>({
    defaultValues: {
      id: 0,
      space: '',
      item: '',
      treatmentType: TreatmentTypes.Maintenance,
    },
    resolver: yupResolver(maintenancePlanSchema),
  });

  const elements: string[] = maintenancePlanHierarchy.map((element) => element.name);

  const groups: string[] = maintenancePlanHierarchy
    .find((item) => item.name === element)
    ?.groups.map((group) => group.name) || [''];

  const subGroups: string[] = maintenancePlanHierarchy
    .find((elementItem) => elementItem.name === element)
    ?.groups.find((groupItem) => groupItem.name === group)
    ?.subGroups.map((subGroup) => subGroup.name) || [''];

  const history = useNavigate();

  const handleElementChange: (value: string) => void = (value: string) => {
    setValue('element', value);
    setData(() => ({ element: value, group: '', subGroup: '' }));
  };

  const handleGroupChange: (e: string) => void = (e: string) => {
    setValue('group', e);
    setData((data) => ({ element: data.element, group: e, subGroup: '' }));
  };

  const handleSubGroupChange: (e: string) => void = (e: string) => {
    setData((data) => ({ element: data.element, group: data.group, subGroup: e }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/vnd.ms-excel': ['.xlsx'], 'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'] },
  });

  useEffect(() => {
    reset({
      ...maintenancePlan,
      driver: Drivers[maintenancePlan.driver],
      impact: Priorities[maintenancePlan.impact],
      urgency: Priorities[maintenancePlan.urgency],
    });
    setImages(maintenancePlan.images);
    setData({
      element: maintenancePlan.element,
      group: maintenancePlan.group,
      subGroup: maintenancePlan.subGroup,
    });
  }, [maintenancePlan]);

  const onSubmit = handleSubmit((form) => {
    mutate(form, {
      onSuccess: (id) => {
        history(`/maintenanceplans/${id}`);
      },
    });
  });

  const deleteImage = (): void => {
    api
      .post(`/api/MaintenancePlan/DeleteImage`, {
        url: images[imageIndex],
        id: getValues('id'),
      })
      .then(({ data }) => {
        addToast('Deleted Image');
        setImages(images.filter((url) => url !== images[imageIndex]));
      })
      .catch(({ error }) =>
        // setIsRefetchingImages(true);
        // refetch()
        //   .then((data) => {
        //     setIsRefetchingImages(false);
        //     setImages(data.data?.images || []);
        //   })
        //   .catch((error) => console.error('Unable to get images', error));
        addToast('An error has occurred')
      );
  };

  const handleFileUpload = (e) => {
    const selectedFiles: File[] = Array.from(e.target.files as FileList);
    bulkUploadImages(selectedFiles).then((result) => {
      const imageImport: ImagesImport = {
        data: result,
        assetId: id,
      };
      api
        .post(`/api/Asset/ImportImages`, {
          images: imageImport.data,
          assetId: maintenancePlan.assetId,
          maintenancePlanId: parseInt(id),
        })
        .then(
          ({ data }) => {
            queryClient.invalidateQueries(['maintenancePlan', parseInt(id)]);
          },
          (error) => {
            addToast('Image Upload Failed', ToastType.Error);
          }
        );
    });
  };

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-6 m-4">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Space
                    </label>
                    <input
                      type="text"
                      {...register('space')}
                      id="space"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors?.space && <ErrorMessage>{errors.space.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      Item
                    </label>
                    <input
                      type="text"
                      {...register('item')}
                      id="customerReference"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3 xl:col-span-3">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={4}
                        {...register('description')}
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      Treatment
                    </label>
                    <div className="relative mt-1">
                      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                        <textarea
                          rows={2}
                          {...register('treatment')}
                          name="description"
                          id="description"
                          className="block w-full resize-none border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Write a description..."
                        />

                        {/* Spacer element to match the height of the toolbar */}
                        <div aria-hidden="true">
                          <div className="h-px" />
                          <div className="py-2">
                            <div className="py-px">
                              <div className="h-9" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="absolute inset-x-px bottom-0 w-full">
                        {/* Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. */}
                        <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
                          <Controller
                            name="treatmentType"
                            control={control}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Listbox as="div" value={value} onChange={onChange} className="flex-shrink-0">
                                {({ open }) => (
                                  <>
                                    <Label className="sr-only">Add a label</Label>
                                    <div className="relative">
                                      <ListboxButton className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                                        <TagIcon
                                          className={`h-5 w-5 flex-shrink-0 sm:-ml-1 ${
                                            value === null ? '' : 'text-gray-900'
                                          }`}
                                          aria-hidden="true"
                                        />
                                        <span
                                          className={`'hidden truncate sm:ml-2 sm:block' ${
                                            value === null ? 'text-gray-300' : 'text-gray-500'
                                          }`}
                                        >
                                          {value === null ? TreatmentTypes.Maintenance : getValues('treatmentType')}
                                        </span>
                                      </ListboxButton>

                                      <Transition
                                        show={open}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <ListboxOptions className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {getEnumKeys(TreatmentTypes).map((key, index) => (
                                            <ListboxOption
                                              key={index}
                                              className={({ focus }) =>
                                                `relative cursor-default select-none px-3 py-2 ${
                                                  focus ? 'bg-gray-100' : 'bg-white'
                                                }`
                                              }
                                              value={TreatmentTypes[key]}
                                            >
                                              <span className="block truncate font-medium">{TreatmentTypes[key]}</span>
                                            </ListboxOption>
                                          ))}
                                        </ListboxOptions>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <fieldset className="col-span-6 sm:col-span-6">
                    <div className="mt-4 space-y-4">
                      <div className="sm:col-span-3">
                        <div className="bg-white space-y-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                              <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                                Impact
                              </label>
                              <select
                                defaultValue="Select an Impact"
                                {...register('impact', {
                                  // onChange: (e) => {
                                  //   const { options, selectedIndex } = e.target;
                                  //   setValue('impact', options[selectedIndex].innerHTML);
                                  // },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {getEnumKeys(Priorities).map((key, index) => (
                                  <option className="flex items-center" key={index} value={Priorities[key]}>
                                    {Priorities[key]}
                                  </option>
                                ))}
                              </select>
                              {errors?.impact && <ErrorMessage>{errors.impact.message}</ErrorMessage>}
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                Urgency
                              </label>
                              <select
                                defaultValue="Select an Urgency"
                                {...register('urgency', {
                                  // onChange: (e) => {
                                  //   const { options, selectedIndex } = e.target;
                                  //   setValue('urgency', options[selectedIndex].innerHTML);
                                  // },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {getEnumKeys(Priorities).map((key, index) => (
                                  <option className="flex items-center" key={index} value={Priorities[key]}>
                                    {Priorities[key]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="col-span-6 sm:col-span-6">
                    <div className="mt-4 space-y-4">
                      <div className="sm:col-span-3">
                        <div className="bg-white space-y-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                              <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                                Driver
                              </label>
                              <select
                                defaultValue="Select an Impact"
                                {...register('driver', {
                                  // onChange: (e) => {
                                  //   const { options, selectedIndex } = e.target;
                                  //   setValue('driver', options[selectedIndex].innerHTML);
                                  // },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {getEnumKeys(Drivers).map((key, index) => (
                                  <option className="flex items-center" key={index} value={Drivers[key]}>
                                    {Drivers[key]}
                                  </option>
                                ))}
                              </select>
                              {errors?.impact && <ErrorMessage>{errors.impact.message}</ErrorMessage>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                Estimated Cost
                              </label>
                              <input
                                type="text"
                                {...register('estimatedCost')}
                                id="estimatedCost"
                                defaultValue=""
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                              {errors?.estimatedCost && <ErrorMessage>{errors.estimatedCost.message}</ErrorMessage>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="col-span-6 sm:col-span-6">
                    <div className="mt-4 space-y-4">
                      <div className="sm:col-span-3">
                        <div className="bg-white space-y-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                              <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                                Element
                              </label>
                              <select
                                defaultValue="Select an Asset Class"
                                {...register('element', {
                                  onChange: (e) => {
                                    handleElementChange(e.target.value);
                                  },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option value="Select an Element">Select an Element</option>
                                {elements.map((element) => (
                                  <option key={element} value={element}>
                                    {element}
                                  </option>
                                ))}
                              </select>
                              {errors?.element && <ErrorMessage>{errors.element.message}</ErrorMessage>}
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                Group
                              </label>
                              <select
                                defaultValue="Select an Asset Type"
                                {...register('group', {
                                  onChange: (e) => {
                                    handleGroupChange(e.target.value);
                                  },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option value="Select a Group">Select a Group</option>
                                {groups
                                  .sort((a, b) => (a > b ? 1 : -1))
                                  .map((group) => (
                                    <option key={group} value={group}>
                                      {group}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label htmlFor="subType" className="block text-sm font-medium text-gray-700">
                                Sub-Group
                              </label>
                              <select
                                defaultValue="Select a Sub Type"
                                {...register('subGroup', {
                                  onChange: (e) => {
                                    handleSubGroupChange(e.target.value);
                                  },
                                })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option value="Select a Sub-Group">Select a Sub-Group</option>
                                {subGroups
                                  .sort((a, b) => (a > b ? 1 : -1))
                                  .map((subGroup) => (
                                    <option key={subGroup} value={subGroup}>
                                      {subGroup}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="md:col-span-1">
              <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3 max-h-screen">
                <div className="">
                  <div className="mt-6 flow-root overflow-y-auto max-h-80vh">
                    <ul className="grid grid-cols-3 lg:grid-cols-1 gap-x-4 gap-y-8 sm:gap-x-6 xl:gap-x-8 ">
                      {images.map((file, index) => {
                        const [start, end] = file.split('/');
                        const url = `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${start}/thumbnails/${
                          end.split('.')[0]
                        }.jpeg`;
                        return (
                          <li key={index} className="relative">
                            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                              <img src={url} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                              <button
                                type="button"
                                className="absolute inset-0 focus:outline-none"
                                onClick={(): void => {
                                  setImageIndex(index);
                                  setIsModalOpen(true);
                                }}
                              >
                                {/* <span className="sr-only">View details for {file.title}</span> */}
                              </button>
                              <button
                                type="button"
                                className="absolute right-0 top-0 p-0.5 w-6 h-6 m-1 bg-red-100 rounded-full"
                                onClick={(): void => {
                                  setIsImageDeleteModalOpen(true);
                                  setImageIndex(index);
                                }}
                              >
                                <XMarkIcon className="text-red-700 " />
                              </button>
                            </div>
                          </li>
                        );
                      })}
                      {/* <li
                        key={0}
                        className="block w-full aspect-w-10 aspect-h-7 border-2 border-dashed border-gray-300 rounded-lg overflow-hidden"
                      >
                        <div className="flex items-center justify-center">
                          <input
                            accept="image/*"
                            className="hidden"
                            name="files[]"
                            id="files"
                            type="file"
                            multiple
                            ref={cameraRef}
                            onChange={(e): void => handleFileUpload(e)}
                          />
                          <button
                            className="flex text-white p-0.5 rounded m-1 focus:outline-none "
                            onClick={(): void => cameraRef.current?.click()}
                            type="button"
                          >
                            <div className="flex flex-col items-center">
                              <CameraIcon className="w-10 h-10 text-gray-400" />
                              <div className="flex flex-col items-center">
                                <span className="block text-sm font-medium text-gray-400">Add multiple images</span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="px-4 sm:px-6">
                          {Object.entries(files).length !== 0 ? (
                            <DocumentInput
                              files={files}
                              isValid
                              closeAction={(): void => setFiles([] as FileWithPath[])}
                              url="/api/Document/Import"
                              parameters={
                                {
                                  // entity: entity.toString(),
                                  // entityId: entityId.toString(),
                                  // documentType: documentType.toString(),
                                }
                              }
                              options={
                                <DocumentImportOptions documentType={documentType} setDocumentType={setDocumentType} />
                              }
                              // reloadAction={async (): Promise<Document[]> => fetchDocs()}
                            />
                          ) : (
                            <div
                              {...getRootProps()}
                              className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                            >
                              <div className="space-y-1 text-center">
                                <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                                <div className="flex text-sm text-gray-600">
                                  <div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>Upload multiple files</span>
                                    <input
                                      {...getInputProps()}
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </div>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">XLSX/DOCX/ZIP up to 100MB</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="pb-2">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={(): void => {
                if (!isSubmitted && isDirty) {
                  if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                    return parseInt(id) === 0 ? history('/') : history(-1);
                  }
                } else {
                  return parseInt(id) === 0 ? history('/') : history(-1);
                }
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button disabled={isSaving} type="submit" className="btn btn-primary">
              {isSaving ? (
                <>
                  <span>Saving</span>
                  <Loader className="animate-spin w-5 h-5 mx-2" />
                </>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
      </div>
      {images.length > 0 && (
        <>
          <ImageModal
            isOpen={isModalOpen}
            closeModal={(): void => setIsModalOpen(false)}
            imageUrls={images}
            index={imageIndex}
            images={[]}
          />
          <DeleteConfirmation
            isOpen={isImageDeleteModalOpen}
            setOpen={setIsImageDeleteModalOpen}
            deleteAction={deleteImage}
            itemType="Image"
          />
        </>
      )}
    </form>
  );
};

import React, { Fragment, useRef } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { UseFormSetValue } from 'react-hook-form';
import { Asset } from '../models/Asset';
import { Component } from '../models/Component';

interface Props {
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  newComponents: Component[];
  oldComponents: Component[];
  setValue: UseFormSetValue<Asset>;
}

export const NewApportionmentModal: React.FC<Props> = ({ isOpen, setOpen, newComponents, oldComponents, setValue }) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={(): void => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Replace Reapportioned Components:
                    </DialogTitle>
                    <div className="p-2">
                      {isOpen &&
                        newComponents?.map((component, index) => (
                          <p>
                            <b>{component.name}</b> Apportionment Change: {oldComponents[index].apportionmentPct} <b>{'> '}</b>
                            {component.apportionmentPct}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 space-x-4 sm:px-6 sm:flex justify-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(): void => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(): void => {
                    setOpen(false);
                    setValue('components', newComponents);
                  }}
                  ref={cancelButtonRef}
                >
                  Ok
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import { type } from 'os';
import React from 'react';
import { useParams } from 'react-router-dom';
import { currencyFormat, percentFormat } from '../../Format';
import { useLeaseById } from '../../hooks/UseLease';
import { LeaseDto } from '../../models/Lease';

export const LeaseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { occupancy, occupancyType, leaseOptionExpiries, takeUpOptionDate, takeUpOptionMonths } = lease;
  const leaseOptionExpiryView =
    leaseOptionExpiries?.length > 0
      ? leaseOptionExpiries.map((leaseOption) => new Date(leaseOption.date).toLocaleDateString('en-AU')).join(', ')
      : '';

  return (
    <ul className=" grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 mt-4 ">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg  ">
        <div className="px-4 sm:px-6 py-4 ">
          <h3 className="uppercase leading-6 font-medium text-gray-900 text-center ">General</h3>
        </div>
        <div className="space-y-4 py-2">
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Occupancy</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{occupancy}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{occupancyType}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Rent PA</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {currencyFormat.format(lease.annualRent)}
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Rent P/M</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {currencyFormat.format(lease.annualRent / 12)}
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Area</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {lease.area} {lease.isGross ? 'Gross' : 'Net'}
            </dd>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
        <div className="px-4 sm:px-6 py-4">
          <h3 className="uppercase leading-6 font-medium text-gray-900 text-center">Details</h3>
        </div>
        <div className="space-y-4 py-2">
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.leaseType}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Category</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.leaseCategory}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Original held by</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.originalExecutedLeaseHeldBy}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Owned by</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.ownedBy}</dd>
          </div>

          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Make Good Clause</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.makeGood}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Registered With Titles Office</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {lease.isRegisteredWithTitlesOffice ? 'Yes' : 'No'}
            </dd>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
        <div className="px-4 sm:px-6 py-4">
          <h3 className="uppercase leading-6 font-medium text-gray-900 text-center">Terms</h3>
          {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="space-y-4 py-2">
          <div className=" sm:grid sm:grid-cols-5 sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Lease Start</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {lease.leaseStartDate && new Date(lease.leaseStartDate).toDateString()}
            </dd>
          </div>
          <div className=" sm:grid sm:grid-cols-5 sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Rent Start</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {lease.rentStartDate && new Date(lease.rentStartDate).toDateString()}
            </dd>
          </div>
          <div className=" sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Expiry</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
              {lease.leaseExpiryDate && new Date(lease.leaseExpiryDate).toDateString()}
            </dd>
          </div>
          <div className=" sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Outgoing Exp Item</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.outgoingExpenseItem}</dd>
          </div>
          <div className=" sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Outgoing Exp</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{lease.outgoingExpense}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Option Expiries</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8 space-x-1">{leaseOptionExpiryView}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Take Up Option Date</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8 space-x-1">
              {lease.takeUpOptionDate && new Date(lease.takeUpOptionDate).toDateString()}
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-5  sm:px-4">
            <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Take Up Option Months</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8 space-x-1">{lease.takeUpOptionMonths}</dd>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default LeaseDetail;

import { CalendarIcon, DocumentTextIcon, UserIcon } from '@heroicons/react/24/solid';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { BlobServiceClient } from '@azure/storage-blob';
import { ImportLogDetail } from '../../models/ImportLogItem';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { ImportResult, Reason } from '../../models/ImportResults';
import { useAuth } from '../../contexts/AuthContext';
import { removeTimezoneOffset } from '../../Helper';

const ImportJobLogView: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const api = useApi();
  const history = useNavigate();
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const { isLoading, data: importResults = {} as ImportLogDetail } = useQuery<ImportLogDetail, Error>(
    ['importLogDetail', id],
    () => api.get(`/api/ImportLog/GetJobLog?Id=${id}`).then(({ data }) => data)
  );
  const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

  const blobServiceClient: BlobServiceClient = new BlobServiceClient(BLOB_URL);
  const { clientGuid } = useAuth();

  async function fetchDocs(): Promise<void> {
    const query = `@container='${clientGuid}' AND logId = '${id}'`;
    const blobResults = blobServiceClient.findBlobsByTags(query);
    try {
      for await (const blob of blobResults) {
        setDownloadUrl(`${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`);
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    fetching();
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Import Log</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow m-4">
      <div className="px-4 py-5 sm:px-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Import Log
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {downloadUrl.length > 0 ? (
                  <a
                    href={downloadUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 hover:text-blue-900 flex flex-row"
                  >
                    <CloudArrowDownIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-600" />
                    {importResults.fileName}
                  </a>
                ) : (
                  <>
                    <DocumentTextIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {importResults.fileName}
                  </>
                )}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                {importResults.createdBy}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                {format(removeTimezoneOffset(new Date(importResults.created)), "PP hh:mm aaaaa'm'")}
              </div>
            </div>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0">
            <span className="hidden sm:block">
              <button
                type="button"
                onClick={(): void => history(-1)}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Back
              </button>
            </span>
          </div>
        </div>
      </div>
      <ImportResultList {...importResults.result} />
    </div>
  );
};

export const ImportResultList: React.FC<ImportResult> = ({ errors, isSuccess, successes }) => (
  <div className="px-4 py-5 sm:p-6">
    <ul className="space-y-2">
      {isSuccess
        ? successes.map((success, i) => (
            <li key={i} className="relative flex gap-x-4">
              <div
                className={`${
                  i === successes.length - 1 ? 'h-6' : '-bottom-6'
                } absolute left-0 top-0 flex w-6 justify-center`}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <SuccessReasonItem {...success} />
            </li>
          ))
        : errors.map((error, i) => (
            <li key={i} className="relative flex gap-x-4">
              <div
                className={`${i === errors.length - 1 ? 'h-1' : '-bottom-6'} absolute left-0 top-0 flex w-6 justify-center`}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <ErrorReasonItem {...error} />
            </li>
          ))}
    </ul>
  </div>
);

const ErrorReasonItem: React.FC<Reason> = ({ message, metadata }) => {
  const otherInfo = Object.entries(metadata)
    .filter(([key, value]) => !key.includes('Timestamp'))
    .map((item) => {
      console.log('item', item);
      return (
        <li>
          {item[0]} - {item[1]}
        </li>
      );
    });
  return (
    <div className="flex ml-2 w-1/2">
      <div className="h-2 w-2 rounded-full bg-red-100 z-10 ring-1 ring-red-300 mt-1" />
      <div className="flex-auto rounded-md p-3 ring-1 w-1/2 ring-inset ring-gray-200 bg-gray-50 ml-2">
        <div className="flex justify-between gap-x-4">
          <div className="py-0.5 text-sm leading-5 text-gray-500 space-x-4">
            <span className="font-medium text-gray-900">{message}</span>
            <span className="inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              Error
            </span>
          </div>
          {Object.keys(metadata).length > 0 ? (
            <time dateTime={metadata?.Timestamp} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
              {format(new Date(metadata?.Timestamp), "hh:mm:ss aaaaa'm'")}
            </time>
          ) : (
            <span className="inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              N/A
            </span>
          )}
        </div>
        <ul className="list-disc px-4 text-xs leading-6 text-gray-500">{otherInfo}</ul>
      </div>
    </div>
  );
};

const SuccessReasonItem: React.FC<Reason> = ({ message, metadata }) => {
  const otherInfo = Object.entries(metadata)
    .filter(([key, value]) => !key.includes('Timestamp'))
    .map((item) => {
      console.log('item', item);
      return (
        <li>
          {item[0]} - {item[1]}
        </li>
      );
    });

  return (
    <div className="flex ml-2 w-1/2">
      <div
        className={`h-2 w-2 rounded-full bg-blue-100 z-10 ring-1 ring-blue-300 mt-1 ${
          otherInfo && otherInfo.length > 0 ? 'bg-yellow-100 ring-yellow-300' : ''
        }`}
      />
      <div className="flex-auto rounded-md p-3 ring-1 w-1/2 ring-inset ring-gray-200 bg-gray-50 ml-2">
        <div className="flex justify-between gap-x-4">
          <div className="py-0.5 text-sm leading-5 text-gray-500 space-x-4">
            <span className="font-medium text-gray-900">{message}</span>
            <span
              className={`inline-flex items-center rounded-md bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/10 ${
                otherInfo && otherInfo.length > 0 ? 'bg-yellow-50 text-yellow-700 ring-yellow-600/10' : ''
              }`}
            >
              {otherInfo.length > 0 ? 'Warning' : 'Success'}
            </span>
          </div>
          <time dateTime={metadata?.Timestamp} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
            {format(new Date(metadata?.Timestamp), "hh:mm:ss aaaaa'm'")}
          </time>
        </div>
      </div>
    </div>
  );
};

export default ImportJobLogView;

import * as yup from "yup";
import { ArraySchema, ValidationError } from "yup";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayType = any[] | null | undefined;

function distinctOnProperty<TIn extends ArrayType, TContext, TDefault = undefined, TFlags extends yup.Flags = "">(
  this: ArraySchema<TIn, TContext, TDefault, TFlags>,
  property: string,
  message: string,
) {
  return this.test("distinct-on-property", message, (array, context) => {
    if (!array) {
      return true;
    }
    console.log('array', array)
    const errors: ValidationError[] = [];
    const duplicateProperties = array.filter((e, i) => array.findIndex((e2) => e2[property] === e[property]) !== i).map((e) => e[property]);
    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element[property] !== "" && duplicateProperties.includes(element[property])) {
        errors.push(new ValidationError(message, element, `${context.path}[${i}].${property}`));
      }
    }

    if (errors.length > 0) {
      return context.createError({ message: () => errors });
    }

    return true;
  });
}

yup.addMethod(yup.array, "distinctOnProperty", distinctOnProperty);

declare module "yup" {
  interface ArraySchema<TIn extends ArrayType, TContext, TDefault = undefined, TFlags extends yup.Flags = ""> {
    distinctOnProperty(property: string, message: string): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }
}

export default yup;
import React, { useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { ErrorMessage } from './ErrorMessage';
import { ReactComponent as DownChevron } from '../icons/DownChevron.svg';
import { ReactComponent as Loader } from '../icons/Loader.svg';
import { Strategy, useStrategy } from '../contexts/StrategyContext';

interface Props {
  isOpen: boolean;
  closeModal(): void;
}

const CopyStrategyModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const CopyStrategySchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed')
      .required('Name for the strategy is required')
      .test('is-same', `Name cannot be the same as original`, (value) => value !== activeStrategy.name),
    notes: yup.string(),
  });

  const { activeStrategy, copyStrategy, isCopyingStrategy } = useStrategy();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Strategy>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(CopyStrategySchema),
  });

  const onSubmit = (data: Strategy): void => {
    copyStrategy(data, activeStrategy, closeModal);
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div
                  className="bg-white  px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Copy Strategy</h3>
                    <div className="mt-6 sm:mt-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                          Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex flex-col  shadow-sm">
                            <input
                              defaultValue={activeStrategy?.name}
                              id="name"
                              {...register('name')}
                              className="flex-1 form-input block w-full min-w-0 border border-gray-300 shadow-sm transition focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                            {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="notes"
                          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex shadow-sm">
                            <textarea
                              id="notes"
                              {...register('notes')}
                              rows={3}
                              className="form-textarea block w-full border border-gray-300 shadow-sm transition focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 flex flex-row space-x-2 justify-end">
                      <button onClick={closeModal} type="button" className="btn btn-secondary">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        {!isCopyingStrategy ? (
                          <span>Copy</span>
                        ) : (
                          <>
                            <span>Copying</span>
                            <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CopyStrategyModal;

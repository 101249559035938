import React from 'react';
import { defaults, Chart, registerables, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ProjectedCosts } from './interfaces';

export interface Props {
  data: ProjectedCosts[];
}

defaults.font.family = 'proxima-nova';

const LOSConditionChart: React.FC<Props> = ({ data }) => {
  Chart.register(...registerables);

  const labels = data.map((projectedCost) => projectedCost.year);

  const projectedLOSScore = data.map((projectedCost) => projectedCost.proposedLOSScore);

  const baseLOSScore = data.map((projectedCost) => projectedCost.baseLOSScore);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Base LOS Score',
        type: 'line' as any,
        fill: false,
        lineTension: 0.0,
        borderWidth: 2,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,

        backgroundColor: 'rgba(0,0,205,0.6)',
        borderColor: 'rgba(0,0,77,1)',
        hoverBackgroundColor: 'rgba(0,0,205,0.8)',
        hoverBorderColor: 'rgba(0,0,77,1)',
        data: baseLOSScore,
      },
      {
        label: 'Proposed LOS Score',
        type: 'line' as any,
        fill: false,
        lineTension: 0.0,
        borderWidth: 2,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        pointHoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
        pointHoverBorderColor: 'rgba(255, 159, 64, 1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: projectedLOSScore,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        display: true,
        gridLines: {
          display: true,
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          maxTicksLimit: 10,
          suggestedMin: 1,
        },
        gridLines: {
          display: true,
        },
      },
    },
  };
  return (
    <div className="my-4">
      <h2 className="text-xl font-semibold text-gray-900">LOS Condition</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default LOSConditionChart;

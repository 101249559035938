import React, { useState } from 'react';
import { NavLink, useParams, Outlet } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { Asset } from '../../models/Asset';
import AssetSummary from './AssetSummary';
import { useAssetById } from '../../hooks/UseAsset';
import DocumentSlide from '../../components/DocumentSlide';
import { EntityType } from '../../models/EntityType';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { useClient } from '../../contexts/ClientContext';
import { ExceptionPage } from '../../components/Error';
import LeaseSummary from './LeaseSummary';
import { useAssetSearch } from '../../contexts/AssetSearchContext';
import Loading from '../../components/Loading';

const AssetView: React.FC<{}> = () => {
  const { roles } = useAuth();
  const { isLeasesEnabled, checkAssetClassEditable } = useClient();
  const { isValuerMode } = useAssetSearch();
  const { id = '' } = useParams<{ id: string }>();
  const { data: asset = {} as Asset, isLoading, isError } = useAssetById(parseInt(id));
  const [open, setOpen] = useState<boolean>(false);

  if (isLoading) {
    return <Loading message="Asset" />;
  }

  const {
    name,
    assetClass,
    reference,
    customerReference,
    components,
    replacementCosts,
    insurance,
    valuationPolicy,
    errors,
    warnings,
    notes,
    valuationType,
    images,
    contents,
  } = asset;
  const isValued = valuationPolicy !== 'Not to be Valued';
  const tabs = [
    { name: 'General', href: '.', hidden: false },
    { name: 'Valuation', href: 'valuation', hidden: !isValued },
    { name: 'Location', href: 'location', hidden: false },
    { name: 'Components', href: 'components', items: components?.length },
    { name: 'Images', href: 'images', hidden: false, items: images?.length },
    { name: 'Notes', href: 'notes', hidden: false, items: notes?.length },
    { name: 'Insurance', href: 'insurance', hidden: !isValued || insurance === null || insurance?.coverage === 'None' },
    {
      name: 'Replacement Costs',
      href: 'replacementcosts',
      items: replacementCosts?.length,
      hidden: !isValued || (valuationType !== 'Apportionment Cost' && insurance?.coverage === 'None'),
    },
  ];

  if (isError) {
    return <ExceptionPage message={`There seems to be an error with asset with the ID: ${id}`} />;
  }
  return (
    <>
      <div className="p-4 space-y-4">
        <div className="bg-white overflow-hidden shadow rounded-md">
          <h2 className="sr-only" id="profile-overview-title">
            Asset Overview
          </h2>
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="flex-shrink-0">
                  {asset.images !== undefined && asset.images.length > 0 ? (
                    <img className="mx-auto h-20 w-20 rounded-full" src={asset.images[0].original} alt="" />
                  ) : (
                    <div className="bg-gray-200 p-4 rounded-full">
                      <PhotoIcon className="h-10 w-10 object-cover text-gray-500" />
                    </div>
                  )}
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-sm font-medium text-gray-600">{name}</p>
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">{reference}</p>
                  <p className="text-sm font-medium text-gray-600">{customerReference}</p>
                </div>
              </div>

              <div className="mt-5 flex justify-center sm:mt-0 space-x-4">
                {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && !isValuerMode && (
                  <NavLink to={`/assets/${id}/edit`} className="btn btn-primary">
                    Edit
                  </NavLink>
                )}
                {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && isValuerMode && (
                  <NavLink to={`/assets/${id}/quickedit`} className="btn btn-primary">
                    Edit
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-3xl mx-auto grid grid-cols-1 gap-4 lg:max-w-7xl xl:max-w-full xl:grid-flow-col-dense xl:grid-cols-10">
          <div className="space-y-6 xl:col-start-1 xl:col-span-7">
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl mx-auto px-2 sm:px-6">
                    <nav className="-mb-px flex justify-around" aria-label="Tabs">
                      {tabs
                        .filter((tab) => !tab.hidden)
                        .map((tab, index) => (
                          <NavLink
                            key={index}
                            end={tab.name === 'General'}
                            to={`${tab.href}`}
                            className={({ isActive }) =>
                              `text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                                isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                              }`
                            }
                            // className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            aria-current="page"
                            // activeClassName="border-indigo-500 hover:border-indigo-500"
                          >
                            {tab.name}
                            {tab.items !== undefined && (
                              <span className="bg-gray-100 hidden text-gray-900 ml-1 py-0.5 px-2.5 rounded-full text-xs font-medium xl:inline-block">
                                {tab.items}
                              </span>
                            )}
                          </NavLink>
                        ))}
                    </nav>
                  </div>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
          {/* This example requires Tailwind CSS v2.0+ */}
          <section aria-labelledby="errors" className="xl:col-start-8 xl:col-span-3 space-y-3">
            {Object.keys(errors).length > 0 && (
              <div className="rounded-md bg-red-50 mb-2 p-2 shadow">
                <div className="flex-shrink-0 flex space-x-4">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <h3 className="text-sm font-medium text-red-800">
                    There is {Object.keys(errors).length} errors with this asset
                  </h3>
                </div>

                <div className="mt-2 ml-2 text-sm text-red-700">
                  {Object.keys(errors).map((error, key) => (
                    <li key={key}>{errors[error]}</li>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(warnings).length > 0 && (
              <div className="rounded-md bg-yellow-50 mb-2 p-2 shadow">
                <div className="flex-shrink-0 flex space-x-4">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  <h3 className="text-sm font-medium text-yellow-800">
                    There is {Object.keys(warnings).length} warnings with this asset
                  </h3>
                </div>

                <div className="mt-2 ml-2 text-sm text-yellow-700">
                  {Object.keys(warnings).map((warning, key) => (
                    <li key={key}>
                      {warning} - {warnings[warning]}
                    </li>
                  ))}
                </div>
              </div>
            )}

            <AssetSummary {...asset} />
            {isLeasesEnabled && <LeaseSummary assetId={asset.id} />}
          </section>
        </div>
      </div>
      <button type="button" onClick={(): void => setOpen(true)} className="document-tab">
        <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <DocumentSlide entity={EntityType.Asset} entityId={parseInt(id)} open={open} setOpen={setOpen} />
    </>
  );
};

export default AssetView;

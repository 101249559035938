import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ToastType, useToast } from '../../contexts/ToastContext';

import { Group } from '../../models/Group';
import { useApi } from '../../contexts/ApiContext';

const GroupEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id = '', subscriptionId = '' } = useParams<{ id: string; subscriptionId: string }>();
  const history = useNavigate();

  // const [startDate, setStartDate] = useState<Date>(new Date());
  // const [expiryDate, setExpiryDate] = useState<Date>(new Date());

  const GroupSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .required('Name for the group is required')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
    // start: yup.date().required('Start date is required'),
    // expiry: yup.date().required('Expiry date is required'),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm<Group>({
    defaultValues: { id: parseInt(id) },
    reValidateMode: 'onBlur',
    resolver: yupResolver(GroupSchema),
  });

  useEffect(() => {
    if (parseInt(id) > 0)
      api
        .get(`/api/Group/Get?id=${id}`)
        .then(({ data }) => {
          reset({
            ...data,
            // start: new Date(data.start),
            // expiry: new Date(data.expiry),
          });
        })
        .catch((error) => {
          addToast(`Unable to get Group with ID ${id}`, ToastType.Error);
        });
  }, []);

  const onSubmit = (data: Group): void => {
    const group = { ...data, subscriptionId: subscriptionId ?? data.subscriptionId };
    api
      .post('/api/Group/Update', { group })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} Group ${data.name}`);
        history(`/groups/${data.id}`);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} group ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow sm:overflow-hidden rounded-md space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Group Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <input type="hidden" {...register('id')} />
            <input type="hidden" {...register('subscriptionId')} />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('name')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.name')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Position
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.position')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email address
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.email')}
                  type="email"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Phone Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.phone')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Country / Region
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="country"
                  ref={register}
                  name="contact.name"
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm rounded-md sm:max-w-xs sm:text-sm border-gray-300"
                >
                  <option>Australia</option>
                  <option>Vanuatu</option>
                  <option>England</option>
                </select>
              </div>
            </div> */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 1
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine1')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 2
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine2')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Suburb
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.suburb')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                City
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.city')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                State / Province
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.state')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 lg:grid-cols-6 sm:gap-4 sm:items-start">
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 col-span-1 lg:col-span-2"
              >
                Postcode / ZIP
              </label>
              <div className="mt-1 sm:mt-0 col-span-1">
                <input
                  {...register('contact.address.postCode')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default GroupEdit;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useLeaseById } from '../../hooks/UseLease';
import { LeaseDto } from '../../models/Lease';

export const Insurance: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));

  return (
    <ul className=" flex flex-col ">
      <div className=" py-2 pt-4 align-middle inline-block min-w-full sm:px-6 lg:px-8 bg-white shadow overflow-hidden  ">
        <div className="min-w-full">
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Insurer</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {lease.currentInsurer && lease.currentInsurer}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {lease.insuranceType && lease.insuranceType}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Start Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {lease.insuranceStartDate && new Date(lease.insuranceStartDate).toDateString()}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Expiry Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {lease.insuranceEndDate && new Date(lease.insuranceEndDate).toDateString()}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </ul>
  );
};

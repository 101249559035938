import Axios, { AxiosInstance } from 'axios';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

const ApiContext = createContext<AxiosInstance>({} as AxiosInstance);
export const ApiProvider: React.FC<ReactNode> = ({ children }) => {
  const { accessToken } = useOidcAccessToken();
  const api = useMemo(() => {
    const axios = Axios.create({
      baseURL: import.meta.env.VITE_REPORTING_API,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    axios.interceptors.request.use((config) => {
      if (accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    return axios;
  }, []);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export function useApi(): AxiosInstance {
  return useContext(ApiContext);
}

import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { downloadHandler } from '../Helper';
import { ReactComponent as Loader } from '../icons/Loader.svg';

interface Props {
  isOpen: boolean;
  closeModal(open: boolean): void;
  assetIds: number[];
  jobId: number;
  context: string;
}

interface ExportOptions {
  assetRegister: boolean;
  components: boolean;
  replacementcost: boolean;
  insurance: boolean;
  marketApproach: boolean;
  incomeApproach: boolean;
  contents: boolean;
}

const AssetsExportModal: React.FC<Props> = ({ isOpen, closeModal, assetIds, jobId, context }) => {
  const { addToast } = useToast();
  const api = useApi();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<ExportOptions>();

  const onSubmit = (exportOptions: ExportOptions): void => {
    if (assetIds.length > 25000 && jobId === 0) {
      addToast('Exceeded maximum assets selected. Please refine your search query', ToastType.Error);
    } else {
      setIsExporting(true);
      api.post('/api/Asset/Export', { ...exportOptions, assetIds, jobId }, { responseType: 'blob' }).then(
        ({ data }) => {
          setIsExporting(false);
          downloadHandler(data, `${context} Assets.xlsx`);
        },
        (error) => {
          setIsExporting(false);
          addToast('API Fetch Failed', ToastType.Error);
        }
      );
    }
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setValue('components', true);
      setValue('insurance', true);
      setValue('incomeApproach', true);
      setValue('marketApproach', true);
      setValue('replacementcost', true);
      setValue('contents', true);
    } else {
      setIsCheckAll(false);
      setValue('components', false);
      setValue('insurance', false);
      setValue('incomeApproach', false);
      setValue('marketApproach', false);
      setValue('replacementcost', false);
      setValue('contents', false);
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0" open={isOpen} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-2 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-200 sm:max-w-3xl sm:w-full sm:p-6">
              <div className="flex flex-col">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                    onClick={(): void => closeModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="p-2">
                  <div className="text-center">
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Asset Export Options</h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col items-center">
                    <fieldset className="col-span-2">
                      <div className="grid grid-cols-2 gap-4 gap-x-16 mb-4">
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              type="checkbox"
                              onChange={(e): void => checkAll(e)}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="assetHierarchy" className="font-medium text-gray-700">
                              {isCheckAll ? 'Unselect all' : 'Select all'}
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              checked
                              {...register('assetRegister')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-gray-500 opacity-50 "
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="assetRegister" className="font-medium text-gray-700">
                              Assets
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              defaultChecked
                              {...register('components')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="components" className="font-medium text-gray-700">
                              Components
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              {...register('incomeApproach')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="incomeApproach" className="font-medium text-gray-700">
                              Income Approach
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              defaultChecked
                              {...register('replacementcost')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="rcDirectCost" className="font-medium text-gray-700">
                              Replacement Cost
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              {...register('marketApproach')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="marketApproach" className="font-medium text-gray-700">
                              Market Approach
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              {...register('insurance')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="insurance" className="font-medium text-gray-700">
                              Insurance
                            </label>
                          </div>
                        </div>
                        <div className="relative flex justify-start">
                          <div className="flex h-5 items-center">
                            <input
                              {...register('contents')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-700 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="contents" className="font-medium text-gray-700">
                              Contents
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="w-full mt-4 flex justify-end">
                      <button
                        type="submit"
                        disabled={isExporting}
                        className="inline-flex items-center justify-center rounded-md px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                      >
                        {!isExporting ? (
                          <span>Export</span>
                        ) : (
                          <>
                            <Loader className="animate-spin -ml-1 mr-2 h-5 w-5 text-gray-400" />
                            <span>Exporting</span>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AssetsExportModal;

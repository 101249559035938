import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '../contexts/ApiContext';
import { useToast } from '../contexts/ToastContext';
import { Lease, LeaseDto } from '../models/Lease';

const useLeaseById = (id: number): UseQueryResult<LeaseDto, Error> => {
  const api = useApi();
  const result: UseQueryResult<LeaseDto, Error> = useQuery(
    ['lease', id],
    async () => {
      if (id === 0) {
        return {};
      }
      const { data: lease } = await api.get<LeaseDto>(`/api/Lease/Get?id=${id}`);

      return lease;
    },
    {
      staleTime: 0,
      cacheTime: 0,
      retry: false,
    }
  );

  return result;
};

const useUpdateLease = (): UseMutationResult<number, Error, LeaseDto> => {
  const api = useApi();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation((lease: LeaseDto) => api.post(`/api/Lease/Update`, { lease }).then(({ data }) => data), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['lease', id]);
      queryClient.invalidateQueries(['Lease List']);
      addToast(`Updated Lease`);
      return id.toString();
    },
  });
};

export { useLeaseById, useUpdateLease };

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const percentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const twoDecimalsPercentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  currency: 'USD',
  maximumFractionDigits: 2,
});

export const sixDecimalsPercentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  currency: 'USD',
  maximumFractionDigits: 6,
});

export const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

export const numberOneDecimalFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 1,
});

export const numberTwoDecimalsFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

// export const yearFormat = Intl.NumberFormat('pt-PT',  {
//   style: 'unit',
//   unit: 'kilometer-per-hour'
// });

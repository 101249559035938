import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { currencyFormat } from '../Format';
import { Asset } from '../models/Asset';
import { Content } from '../models/Content';

type ContentListEditProps = {
  index: number;
  control: Control<Asset, object>;
  nameError?: string | undefined;
  quantityError?: string | undefined;
  rateError?: string | undefined;
  setContentImageUrls: React.Dispatch<React.SetStateAction<string[]>>;
  setIsContentImagesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContentName: React.Dispatch<React.SetStateAction<string>>;
  watch: UseFormWatch<Asset>;
  register: UseFormRegister<Asset>;
  // defaultValues: Asset;
  remove: (index?: number | number[] | undefined) => void;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
};

export const ContentListEdit: React.FC<ContentListEditProps> = ({
  index,
  nameError,
  quantityError,
  rateError,
  register,
  setContentImageUrls,
  setIsContentImagesModalOpen,
  setContentName,
  watch,
  remove,
  getValues,
  setValue,
  control,
}) => (
  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-24">
      <div className="flex space-x-2 ">
        <input
          // key={field.id}
          {...register(`contents.${index}.name` as const)}
          type="text"
          className={`block w-full rounded-md ${
            nameError ? 'border-rose-600' : ''
          } border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
        />
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-16">
      <input
        {...register(`contents.${index}.quantity` as const, { valueAsNumber: true })}
        type="number"
        step="any"
        onFocus={(e): void => e.target.select()}
        className={`block w-full rounded-md ${
          quantityError ? 'border-rose-600' : ''
        } border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
      />
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-16">
      <input
        {...register(`contents.${index}.rate` as const, { valueAsNumber: true })}
        type="number"
        step="any"
        onFocus={(e): void => e.target.select()}
        className={`block w-full rounded-md ${
          rateError ? 'border-rose-600' : ''
        } border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
      />
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-center text-gray-500 sm:w-16">
      {currencyFormat.format(getValues(`contents.${index}.quantity`) * getValues(`contents.${index}.rate`))}
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-24">
      <div className="flex space-x-2 ">
        <input
          // key={field.id}
          {...register(`contents.${index}.type` as const)}
          type="text"
          className="block w-full rounded-md  border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </td>

    <td className="whitespace-nowrap px-3 py-2 text-right text-sm font-medium sm:w-9">
      <button
        type="button"
        onClick={(): void => {
          setIsContentImagesModalOpen(true);
          setContentImageUrls(getValues(`contents.${index}.imageUrls`) || []);
          setContentName(getValues(`contents.${index}.name`));
        }}
      >
        <PhotoIcon className="h-6 w-8 text-indigo-600  font-medium rounded-md focus:outline-none" />
      </button>
    </td>

    <td className="whitespace-nowrap px-3 py-2 text-right text-sm font-medium sm:w-9">
      <button
        type="button"
        onClick={(): void => {
          remove(index);
        }}
      >
        <TrashIcon className="h-6 w-6 text-gray-400 hover:text-red-700" />
      </button>
    </td>
  </tr>
);

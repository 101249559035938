import React, { createContext, useState, useContext } from 'react';
import { HierarchyNode } from '../models/HierarchyNode';

interface CopyNodeContextProps {
  copyNode: HierarchyNode;
  setCopyNode: (node: HierarchyNode) => void;
}

const CopyNodeContext = createContext<CopyNodeContextProps>({} as CopyNodeContextProps);

export const CopyNodeProvider: React.FC<any> = (props) => {
  const [copyNode, setCopyNode] = useState<HierarchyNode>({} as HierarchyNode);

  return <CopyNodeContext.Provider value={{ copyNode, setCopyNode }} {...props} />;
};

export const useCopyNode = (): CopyNodeContextProps => useContext(CopyNodeContext);
export default CopyNodeProvider;

export interface Role {
  value: number;
  label: string;
}

export enum Roles {
  Administrator = 'Administrator',
  GAdministrator = 'Group Administrator',
  CAdministrator = 'Client Administrator',
  Valuer = 'Valuer',
  Viewer = 'Viewer',
  Editor = 'Editor',
}

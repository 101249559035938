import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { AssetReplacementCost, ComponentReplacementCost } from '../models/ReplacementCostSummary';

const useAssetReplacementCostById = (id: number): UseQueryResult<AssetReplacementCost, Error> => {
  const api = useApi();
  const result: UseQueryResult<AssetReplacementCost, Error> = useQuery(['assetReplacementCost', id], async () => {
    const { data } = await api.get(`/api/AssetReplacementCost/Get?id=${id}`);
    return data;
  });
  return result;
};

const useComponentReplacementCostById = (id: number): UseQueryResult<ComponentReplacementCost, Error> => {
  const api = useApi();
  const result: UseQueryResult<ComponentReplacementCost, Error> = useQuery(['componentReplacementCost', id], async () => {
    const { data } = await api.get(`/api/ComponentReplacementCost/Get?id=${id}`);
    return data;
  });
  return result;
};

const useUpdateComponentReplacementCost = (): UseMutationResult<number, Error, ComponentReplacementCost> => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation(
    (componentReplacementCost: ComponentReplacementCost) =>
      api.post(`/api/ComponentReplacementCost/Update`, { componentReplacementCost }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['asset']);
        queryClient.invalidateQueries(['component', id]);
        queryClient.invalidateQueries(['componentReplacementCost']);
        addToast(`Updated Replacement Cost`);
      },
      onError: (error) => {
        addToast(`Unable to update Replacement Cost`);
      },
    }
  );
};

const useUpdateAssetReplacementCost = (): UseMutationResult<number, Error, AssetReplacementCost> => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation(
    (assetReplacementCost: AssetReplacementCost) =>
      api.post(`/api/AssetReplacementCost/Update`, { assetReplacementCost }).then(({ data }) => data),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['asset', id]);
        queryClient.invalidateQueries(['assetReplacementCost']);
        addToast(`Updated Replacement Cost`);
      },
      onError: (error) => {
        addToast(`Unable to update Replacement Cost`, ToastType.Error);
      },
    }
  );
};

export {
  useAssetReplacementCostById,
  useUpdateAssetReplacementCost,
  useComponentReplacementCostById,
  useUpdateComponentReplacementCost,
};

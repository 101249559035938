import React from 'react';
import { DocumentType } from '../models/DocumentType';

interface DocumentImportProps {
  documentType: DocumentType;
  isJobDocumentContainer?: boolean;
  setDocumentType(documentType: DocumentType): void;
}

const DocumentImportOptions: React.FC<DocumentImportProps> = ({ setDocumentType, isJobDocumentContainer = false }) => (
  <div className="">
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Options</h3>
    </div>
    <div className="">
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <select
                onChange={(e): void => setDocumentType(e.target.value as DocumentType)}
                name="setDocumentType"
                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {!isJobDocumentContainer && (
                  <option key={DocumentType.Report} value={DocumentType.Report}>
                    Report
                  </option>
                )}
                <option key={DocumentType.Source} value={DocumentType.Source}>
                  Source
                </option>
                <option key={DocumentType.Support} value={DocumentType.Support}>
                  Support
                </option>
                <option key={DocumentType.WorkPaper} value={DocumentType.WorkPaper}>
                  Work Paper
                </option>
                <option key={DocumentType.RawData} value={DocumentType.RawData}>
                  Raw Data
                </option>
              </select>
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Document Type
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
);

export default DocumentImportOptions;

import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '../contexts/ApiContext';
import { MaintenancePlan } from '../models/MaintenancePlan';
import { ToastType, useToast } from '../contexts/ToastContext';

const useMaintenancePlanById = (id: number): UseQueryResult<MaintenancePlan, Error> => {
  const api = useApi();
  const result: UseQueryResult<MaintenancePlan, Error> = useQuery(['maintenancePlan', id], async () => {
    if (id === 0) {
      return {};
    }
    const { data: maintenancePlan } = await api.get<MaintenancePlan>(`/api/MaintenancePlan/Get?id=${id}`);
    return maintenancePlan;
  });
  return result;
};

const useUpdateMaintenancePlan = (): UseMutationResult<number, Error, MaintenancePlan> => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  return useMutation(
    (maintenancePlan: MaintenancePlan) =>
      api.post<number>(`/api/MaintenancePlan/Update`, { maintenancePlan }).then(({ data }) => {
        if (data === 0) {
          addToast(`Found duplicate Maintenance Plan. Unable to save`, ToastType.Error);
        }
        return data;
      }),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['maintenancePlan', id]);
        queryClient.invalidateQueries(['maintenancePlan list']);
        addToast(`Updated Maintenance Plan`);
      },
    }
  );
};

export { useMaintenancePlanById, useUpdateMaintenancePlan };

import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Column } from 'react-table';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';
import { ContactType, ExternalPropertyManager, Landlord, LeaseContact } from '../models/Lease';
import { BasicTable } from './BasicTable';

type Contact = Landlord | ExternalPropertyManager | LeaseContact;

type Variables = { id: number; name: string; type: string };

interface Props {
  contact: string;
  isOpen: boolean;
  closeModal(): void;
  assetId: number;
}

export const ExisitingLeaseContactModal: React.FC<Props> = ({ contact, isOpen, closeModal, assetId }) => {
  const api = useApi();
  const cancelButtonRef = useRef(null);
  const { addToast } = useToast();
  const [contactType, setContactType] = useState<string>(contact);
  const [contactList, setContactList] = useState<Contact[]>([]);
  const isLandLord = (props: Contact): props is Landlord => {
    // check if the specified property is in the given object
    return props.contactType === ContactType.Landlord;
  };

  useEffect(() => {
    api
      .get(`/api/Lease/ListContacts`)
      .then(({ data }) => {
        setContactList(data.filter((allContacts) => allContacts.contactType === contact));
      })
      .catch((error) => {});
  }, [isOpen]);

  const isExternalPropertyManager = (props: Contact): props is ExternalPropertyManager => {
    // check if the specified property is in the given object
    return props.contactType === ContactType.ExternalPropertyManager;
  };

  // const { mutate } = useMutation((params: Variables) => {
  //   return api.post(url, params);
  // });

  const nodeSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
  });

  const { register, handleSubmit, control, reset } = useForm<Landlord | ExternalPropertyManager | LeaseContact>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(nodeSchema),
  });

  const updateContact = (item: Contact | Landlord | ExternalPropertyManager): void => {
    if (contact === 'Landlord') {
      api
        .post('api/Lease/AddLandlord', { landlordId: item.id, assetId })
        .then(() => {
          closeModal();
        })
        .catch(() => addToast('Unable to Add Landlord', ToastType.Error));
    } else if (contact === 'Internal Property Manager') {
      api
        .post('api/Lease/AddInternalPropertyManager', { internalPropertyManagerId: item.id, assetId })
        .then(() => {
          closeModal();
        })
        .catch(() => addToast('Unable to Add Internal Property Manager', ToastType.Error));
    } else if (contact === 'External Property Manager') {
      api
        .post('api/Lease/AddExternalPropertyManager', { ExternalPropertyManagerId: item.id, assetId })
        .then(() => {
          closeModal();
        })
        .catch(() => addToast('Unable to Add External Property Manager', ToastType.Error));
    }
  };

  // const columns: Column<Contact>[] = React.useMemo(
  //   () => [
  //     {
  //       Header: 'Name',
  //       accessor: 'name',
  //       className: 'px-2 py-3 whitespace-normal text-sm leading-5',
  //     },
  //     {
  //       Header: 'Email',
  //       accessor: 'email',
  //       className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
  //     },
  //     {
  //       Header: 'Phone',
  //       accessor: 'phone',
  //       className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
  //     },
  //     {
  //       Header: '',
  //       accessor: 'id',
  //       className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
  //       Cell: ({ row, value }): React.ReactElement => {
  //         return (
  //           <button
  //             onClick={(): void => {
  //               updateContact(contact, row.original, assetId);
  //             }}
  //             className="text-indigo-600 hover:text-indigo-900 mr-3 text-right text-sm font-medium"
  //           >
  //             Add Contact
  //           </button>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom max-h-144 bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-144 sm:max-w-4xl sm:w-full sm:p-6">
              {contactList !== null && contactList.length > 0 && (
                <h3 className="mt-2 mb-4 text-lg font-medium leading-6 text-gray-900">{contact}</h3>
              )}
              <div className="max-h-92 overflow-y-auto">
                {contactList.map((contact) => (
                  <li key={contact.id} className="py-4 flex justify-between">
                    <div className="flex items-center">
                      <div className="ml-3 w-56">
                        <p className="text-sm font-medium text-gray-900">{contact.name}</p>
                        <p className="text-xs text-gray-500">{contact.email}</p>
                      </div>
                      <div className="flex space-x-4 m-2">
                        <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 h-6">
                          {contact.contactType}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={(): void => updateContact(contact)}
                      className="text-indigo-600 hover:text-indigo-900 mr-3 text-right text-sm font-medium"
                    >
                      Add contact
                    </button>
                  </li>
                ))}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import React, { createContext, useState, useContext, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { AxiosResponse } from 'axios';
import { useApi } from './ApiContext';
import { ToastType, useToast } from './ToastContext';
import { Asset } from '../models/Asset';
import { useAuth } from './AuthContext';

interface AssetContext {
  asset: Asset;
  isLoadingAsset: boolean;
  setAsset: (asset: Asset) => void;
  getAsset: (id: number) => Asset;
  getAssetByComponentId: (id: number) => Asset;
}

interface ImageDisplay {
  original: string;
  thumbnail: string;
  date?: string;
}

const AssetContext = createContext<AssetContext>({} as AssetContext);

export const AssetProvider: React.FC<any> = (props) => {
  const api = useApi();
  const [asset, setAsset] = useState<Asset>({} as Asset);
  const [isLoadingAsset, setIsLoadingAsset] = useState<boolean>(false);
  const { addToast } = useToast();
  const { userName, clientId, clientGuid } = useAuth();


  const getAsset = async (id: number): Promise<Asset> => {
    setIsLoadingAsset(true);
    setAsset({} as Asset);
    setIsLoadingAsset(false);
    try {
      const result: AxiosResponse<Asset> = await api.get(`/api/Asset/Get?id=${id}`);
      setAsset(result.data);
    } catch (error) {
      addToast('Could not Load Asset', ToastType.Error);
      setIsLoadingAsset(false);
    }
    return asset;
  };

  const getAssetByComponentId = (id: number): Asset => {
    setIsLoadingAsset(true);
    setAsset({} as Asset);
    api
      .get(`/api/Asset/GetByComponentId?id=${id}`)
      .then(({ data }) => {
        setIsLoadingAsset(false);
        setAsset(data);
      })
      .catch((error) => {
        setIsLoadingAsset(false);
      });
    return asset;
  };

  return (
    <AssetContext.Provider
      value={{
        asset,
        isLoadingAsset,
        setAsset,
        getAsset,
        getAssetByComponentId,
      }}
      {...props}
    />
  );
};

export const useAsset = (): AssetContext => useContext(AssetContext);
export default AssetProvider;

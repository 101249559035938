import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { InsuranceAssumptions } from '../../models/InsuranceAssumptions';
import { useApi } from '../../contexts/ApiContext';

const InsuranceAssumptionsEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();
  const [insuranceAssumptions, setInsuranceAssumptions] = useState<InsuranceAssumptions>({} as InsuranceAssumptions);

  const InsuranceAssumptionsSchema = yup.object().shape({
    demoMonths: yup.number().min(0, 'Cannot be a negative number.').required('Demolition Months is required'),
    leadBuildMonths: yup.number().min(0, 'Cannot be a negative number.').required('Lead Build Months is required'),
    start: yup.date().when('groupId', {
      is: (field) => !!field,
      then: (schema) => schema.required('Start date is required'),
    }),
    expiry: yup.date().when('groupId', {
      is: (field) => !!field,
      then: (schema) => schema.required('Expiry date is required'),
    }),
  });

  const { register, handleSubmit, reset } = useForm<InsuranceAssumptions>({
    defaultValues: {
      id: parseInt(id),
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(InsuranceAssumptionsSchema),
  });

  useEffect(() => {
    if (id) {
      api
        .get(`/api/Assumptions/GetInsuranceAssumptions?id=${id}`)
        .then(({ data }) => {
          setInsuranceAssumptions(data);
          reset({
            ...data,
            escalationFactorPct: Number((data.escalationFactorPct * 100).toFixed(2)),
            professionalFeesPct: Number((data.professionalFeesPct * 100).toFixed(2)),
            debrisRemovalPct: Number((data.debrisRemovalPct * 100).toFixed(2)),
          });
        })
        .catch((error) => {
          addToast(`Unable to get asset assumptions with ID ${id}`, ToastType.Error);
        });
    }
  }, [id]);

  const onSubmit = (data: InsuranceAssumptions): void => {
    const assumptions = {
      ...data,
      escalationFactorPct: Number((data.escalationFactorPct / 100).toFixed(5)),
      professionalFeesPct: Number((data.professionalFeesPct / 100).toFixed(5)),
      debrisRemovalPct: Number((data.debrisRemovalPct / 100).toFixed(5)),
    };
    api
      .post('/api/Assumptions/UpdateInsuranceAssumptions', { insuranceAssumptions: assumptions })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} Insurance Assumptions`);
        history(`/insuranceassumptions/${assumptions.id}`);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} Insurance Assumptions ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };

  const { assetClass, assetType, assetSubType } = insuranceAssumptions;

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('id', { valueAsNumber: true })} />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Asset Assumptions</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-600">
                  Asset Class
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetClass}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetType}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Sub Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetSubType}</dd>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Duration</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Lead Build Months
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('leadBuildMonths', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency" />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      months
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Demolition Months
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('demoMonths', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency" />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      months
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Fees</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Escalation Factor
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('escalationFactorPct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency" />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Locality Factor
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('professionalFeesPct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency" />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Debris Removal Costs</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Fee
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('debrisRemovalPct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency" />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Minimum Cost
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    {...register('debrisRemovalMinimum', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="price-currency" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/insuranceassumptions/${id}`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default InsuranceAssumptionsEdit;

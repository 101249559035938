import { format } from 'date-fns/esm';
import React from 'react';
import { ReactComponent as Logo } from '../images/AVP_HorizontalLogo.svg';

import avp from '../images/AVP.jpg';
import sam from '../images/samcover.png';

interface Props {
  baseStrategy: string;
  proposedStrategy: string;
}

export const ReportCover: React.FC<Props> = ({ baseStrategy, proposedStrategy }) => {
  const currentDate = format(new Date(), 'PPp');
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      {/* <img className="mt-8 h-48 w-auto" src={sam} alt="Easy Sam" /> */}
      <div className="flex-grow flex flex-col justify-center items-center mb-32">
        <h2 className="mt-16 text-3xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
          Proposed Strategy: {proposedStrategy}
        </h2>
        <h2 className="mt-8 text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
          Base Strategy: {baseStrategy}
        </h2>

        <h2 className="mt-8 text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
          {currentDate}
        </h2>
      </div>
      <Logo className="h-12 w-auto" />
    </div>
  );
};

import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';

export interface HierarchyItem {
  id: number;
  name: string;
  type: string;
  parentId?: number;
}

export interface HierarchyNodeModalProps {
  node: HierarchyItem;
  isOpen: boolean;
  closeModal(): void;
  itemType: string;
  url: string;
}

type Variables = { id: number; name: string; type: string };

export const EditHierarchyNodeModal: React.FC<HierarchyNodeModalProps> = ({ node, itemType, isOpen, closeModal, url }) => {
  const api = useApi();
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { mutate } = useMutation((params: Variables) => api.post(url, params));

  const nodeSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
  });

  const { register, handleSubmit, reset } = useForm<HierarchyItem>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(nodeSchema),
  });

  useEffect(() => {
    if (node !== undefined) {
      reset({
        ...node,
      });
    }
  }, [node]);

  const onSubmit = (formData: HierarchyItem): void => {
    mutate(
      { ...formData },
      {
        onSuccess: ({ data }) => {
          addToast(`${formData.id === 0 ? 'Created' : 'Updated'} ${formData.type}`);
          queryClient.invalidateQueries(['hierarchy', node.parentId ?? 0]);
          closeModal();
        },
        onError: (error) => {
          addToast(
            `Unable to ${formData.id === 0 ? 'create new' : 'update'} ${formData.type} ${
              formData.id > 0 && `with ID ${formData.id}`
            }`,
            ToastType.Error
          );
          closeModal();
        },
      }
    );
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                <div className="space-y-8 sm:space-y-5">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Edit {itemType}</h3>
                    </div>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <input type="hidden" {...register('id', { valueAsNumber: true })} />
                      <input type="hidden" {...register('type')} />
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            {...register('name')}
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo*-500 focus:border-indigo*-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end space-x-2">
                  <button onClick={closeModal} type="button" className="btn btn-secondary">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Column } from 'react-table';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { BasicTable } from '../../components/BasicTable';
import AddUserModal from '../../components/AddUserModal';
import { Client } from '../../models/Client';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import DocumentSlide from '../../components/DocumentSlide';
import { User } from '../../models/User';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { downloadHandler } from '../../Helper';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { EntityType } from '../../models/EntityType';
import { clients, OldClient } from '../../data/clients';

interface ImportFile {
  clientId: number;
  file: FileWithPath;
  isValid: boolean | null;
  closeAction(): void;
}

const ClientView: React.FC<{}> = () => {
  const api = useApi();
  const { id = '' } = useParams<{ id: string }>();
  const { roles } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isClientAdmin, setIsClientAdmin] = useState<boolean>(false);
  const { addToast } = useToast();
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [setAdmin, setIsAdmin] = useState<boolean>(false);
  // const [client, setClient] = useState<Client>({} as Client);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<OldClient>({} as OldClient);
  const [query, setQuery] = useState<string>('');
  const [isFrameworkImporting, setIsFrameworkImporting] = useState<boolean>(false);
  const [isJobImporting, setIsJobImporting] = useState<boolean>(false);
  const [isAssetsImporting, setIsAssetImporting] = useState<boolean>(false);
  const [isImagesImporting, setIsImagesImporting] = useState<boolean>(false);

  const { refetch, data: client = {} as Client } = useQuery<Client, Error>(['Client', id], async () =>
    api.get(`/api/Client/GetDetail?id=${id}`).then(({ data }) => data)
  );

  const {
    id: clientId,
    name,
    displayName,
    start,
    expiry,
    subscriptionName,
    subscriptionId,
    groupName,
    groupId,
    contact,
    administrator,
    users,
    currency,
    isLeasesEnabled,
    isMaintenancePlansEnabled,
  } = client;

  const filteredClient =
    query === ''
      ? clients.slice(0, 5)
      : clients.filter((oldClient: OldClient) => oldClient.name.toLowerCase().includes(query.toLowerCase())).slice(0, 5);

  const removeUser = (userId: number): void => {
    api
      .post(`/api/Client/RemoveUser`, { userId, clientId: id })
      .then(({ data }) => {
        // setClient(data);
        refetch();
        addToast(`Successfully removed user`);
      })
      .catch((error) => {
        addToast(`Unable to remove user`, ToastType.Error);
      });
  };

  const removeAdministrator = (userId: number): void => {
    api
      .post(`/api/Client/RemoveAdministrator`, { userId, clientId: id })
      .then(({ data }) => {
        // setClient(data);
        refetch();
        addToast(`Successfully removed administrator`);
      })
      .catch((error) => {
        addToast(`Unable to remove administrator`, ToastType.Error);
      });
  };

  const removeDocument = (document: string): void => {
    api
      .post(`/api/Client/RemoveDocument`, { document, clientId: id })
      .then(({ data }) => {
        // setClient({ ...client, documents: client.documents?.filter((doc) => document !== doc) });
        refetch();
        addToast(`Successfully removed document ${document}`);
      })
      .catch((error) => {
        addToast(`Unable to remove document ${document}`, ToastType.Error);
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/vnd.ms-excel': ['.xlsx'] },
  });

  const columns: Column<User>[] = useMemo(
    () => [
      {
        Header: 'Name ',
        accessor: 'firstName',
        className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ value, row }): JSX.Element => (
          <div>
            {value} {row.original.lastName}
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate space-x-4 m-2',
        Cell: ({ value }): JSX.Element =>
          value.map((role) => (
            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 h-6">
              {role.name}
            </div>
          )),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'px-4 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ value }): JSX.Element => (
          <button className="font-medium text-right text-indigo-600 hover:text-indigo-500" onClick={() => removeUser(value)}>
            Remove
          </button>
        ),
      },
    ],
    []
  );

  const importHierarchy = (v2Client: OldClient): void => {
    setIsFrameworkImporting(true);
    api
      .get(`/api/AssetHierarchy/V2Import?ClientName=${client.name}&V2Client=${v2Client.name}`)
      .then(({ data }) => {
        setIsFrameworkImporting(false);
        addToast(data.message);
      })
      .catch((error) => {
        setIsFrameworkImporting(false);
      });
    setIsUpdated(false);
  };

  const importJobs = (v2Client: OldClient): void => {
    setIsJobImporting(true);
    api
      .get(`/api/Job/V2Import?ClientName=${client.name}&V2Client=${v2Client.name}`)
      .then(({ data }) => {
        setIsJobImporting(false);
        addToast(data.message);
      })
      .catch((error) => {
        setIsJobImporting(false);
      });
    setIsUpdated(false);
  };

  const importAssets = (v2Client: OldClient): void => {
    setIsAssetImporting(true);
    api
      .get(`/api/Asset/V2Import?ClientName=${client.name}&V2Client=${v2Client.name}`)
      .then(({ data }) => {
        setIsAssetImporting(false);
        addToast(data.message);
      })
      .catch((error) => {
        console.error(error);
        setIsAssetImporting(false);
      });
    setIsUpdated(false);
  };

  const importImages = (v2Client: OldClient): void => {
    setIsImagesImporting(true);
    api
      .get(`/api/Asset/V2ImportImages?ClientName=${client.name}&V2Client=${v2Client.name}`)
      .then(({ data }) => {
        setIsImagesImporting(false);
        addToast(data.message);
      })
      .catch((error) => {
        console.error(error);
        setIsImagesImporting(false);
      });
    setIsUpdated(false);
  };

  const importArchiveData = (v2Client: OldClient): void => {
    api
      .get(`/api/Asset/V2ImportArchiveData?ClientName=${client.name}&V2Client=${v2Client.name}`)
      .then(({ data }) => {
        addToast(data.message);
      })
      .catch((error) => {
        console.error(error);
      });
    setIsUpdated(false);
  };

  return (
    <>
      {Object.keys(client).length === 0 ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <Loader className="animate-spin w-6 h-6 mb-3" />
          <p className="text-sm font-medium text-gray-500">Loading Client</p>
        </div>
      ) : (
        <>
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="bg-gray-50 shadow rounded-md overflow-hidden">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Client Information</h3>
                    <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                      {(roles.includes(Roles.Administrator) || roles.includes(Roles.GAdministrator)) && (
                        <Link to={`/clients/${id}/edit`} className="btn btn-link">
                          Edit
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Name</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Display Name</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{displayName}</dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Currency</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {currency !== null ? currency : 'N/A'}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Leases</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {isLeasesEnabled ? 'Enabled' : 'Disabled'}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Maintenance Plans</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {isMaintenancePlansEnabled ? 'Enabled' : 'Disabled'}
                      </dd>
                    </div>
                    {subscriptionId !== null ? (
                      <>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Start Date</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {start && format(new Date(start), 'PP')}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">End Date</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {expiry && format(new Date(expiry), 'PP')}
                          </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Subscription</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!roles.includes(Roles.CAdministrator) ? (
                              <Link to={`/subscriptions/${subscriptionId}`} className="btn btn-link">
                                {subscriptionName}
                              </Link>
                            ) : (
                              <p className="inline-flex items-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                                {subscriptionName}
                              </p>
                            )}
                          </dd>
                        </div>
                      </>
                    ) : (
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Group</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <Link to={`/groups/${groupId}`} className="btn btn-link">
                            {groupName}
                          </Link>
                        </dd>
                      </div>
                    )}
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Administrator</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {administrator ? (
                          roles.includes(Roles.Administrator) || roles.includes(Roles.GAdministrator) ? (
                            <>
                              <Link
                                to={`/users/${administrator.id}`}
                                className="inline-flex items-center mr-3 text-sm font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                              >
                                {administrator.firstName} {administrator.lastName}
                              </Link>
                              <button
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                onClick={() => removeAdministrator(administrator.id)}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <p className="inline-flex items-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                              {administrator.firstName} {administrator.lastName}
                            </p>
                          )
                        ) : (
                          <button
                            onClick={() => {
                              setIsClientAdmin(true);
                              setIsModalOpen(true);
                              setIsAdmin(true);
                            }}
                            className="btn btn-secondary"
                          >
                            Add User
                          </button>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>

            {(!roles.includes(Roles.GAdministrator) || groupId === 2 || groupId === 126) && (
              <div className="px-4 pt-4 sm:px-6 lg:px-8">
                <div className="bg-gray-50 shadow rounded-md overflow-hidden">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Users</h3>
                      <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        <span className="shadow-sm">
                          <button
                            onClick={(): void => {
                              setIsAdmin(false);
                              setIsClientAdmin(false);
                              setIsModalOpen(true);
                            }}
                            className="btn btn-primary"
                          >
                            Add User
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex flex-col bg-white">
                      <div className="overflow-x-auto ">
                        <div className="p-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          {users && users.length > 0 && <BasicTable columns={columns} data={users} />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="px-4 pt-4 sm:px-6 lg:px-8">
              <div className="bg-gray-50 shadow rounded-md overflow-hidden">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Name</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.name}</dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Postion</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.position}</dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Email</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.email}</dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.phone}</dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Address Line 1</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.addressLine1}</dd>
                    </div>
                    {contact?.address?.addressLine2 && (
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Address Line 2</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {contact?.address?.addressLine2}
                        </dd>
                      </div>
                    )}

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Suburb</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.suburb}</dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">City</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.city}</dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">State / Province</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.state}</dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Postcode / Zip</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.postCode}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 pt-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Migrate Data from Legacy Application.</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Please select a client from the legacy application</p>
                </div>
                <form className="mt-5 sm:flex sm:items-center space-x-2">
                  <div className="w-full sm:max-w-xs">
                    <label htmlFor="client" className="sr-only">
                      Client
                    </label>
                    <Combobox value={selectedClient} onChange={setSelectedClient}>
                      <div className="relative mt-1">
                        <ComboboxInput
                          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                          onChange={(event): void => setQuery(event.target.value)}
                          displayValue={(oldClient: OldClient): string => oldClient.name}
                        />
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredClient.map((fClient) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={fClient.oid}
                              value={fClient}
                            >
                              {fClient.name}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                  </div>
                  <button
                    type="button"
                    className="mt-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={(): void => importHierarchy(selectedClient)}
                    disabled={isFrameworkImporting}
                  >
                    {isFrameworkImporting ? (
                      <>
                        <span>Importing</span>
                        <Loader className="animate-spin w-5 h-5 mx-2" />
                      </>
                    ) : (
                      <span>Hierarchy</span>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={(): void => importJobs(selectedClient)}
                    disabled={isJobImporting}
                  >
                    {isJobImporting ? (
                      <>
                        <span>Importing</span>
                        <Loader className="animate-spin w-5 h-5 mx-2" />
                      </>
                    ) : (
                      <span>Jobs</span>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={(): void => importAssets(selectedClient)}
                    disabled={isAssetsImporting}
                  >
                    {isAssetsImporting ? (
                      <>
                        <span>Importing</span>
                        <Loader className="animate-spin w-5 h-5 mx-2" />
                      </>
                    ) : (
                      <span>Assets</span>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={(): void => importImages(selectedClient)}
                    disabled={isImagesImporting}
                  >
                    {isImagesImporting ? (
                      <>
                        <span>Importing</span>
                        <Loader className="animate-spin w-5 h-5 mx-2" />
                      </>
                    ) : (
                      <span>Images</span>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={(): void => importArchiveData(selectedClient)}
                  >
                    <span>Archive</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <AddUserModal
        isOpen={isModalOpen}
        closeModal={(): void => setIsModalOpen(false)}
        hasUpdated={(): void => setIsUpdated(true)}
        clientAdmin={isClientAdmin}
        clientId={clientId}
        groupId={groupId}
        setAdmin={setAdmin}
      />
      <button type="button" onClick={(): void => setOpen(true)} className="document-tab">
        <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      {Object.keys(client).length > 0 && (
        <DocumentSlide
          entity={EntityType.Client}
          entityId={parseInt(id || '')}
          open={open}
          setOpen={setOpen}
          entityGuid={client.legacyId}
        />
      )}
    </>
  );
};

export default ClientView;

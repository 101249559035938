import React, { createContext, useState, useContext } from 'react';

interface SidebarContext {
  isSidebarOpen: boolean;
  closeSidebar: () => void;
  openSideBar: () => void;
  setIsSidebarOpen: (open: boolean) => void;
}

const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider: React.FC<any> = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const closeSidebar = (): void => {
    setIsSidebarOpen(false);
  };

  const openSidebar = (): void => {
    setIsSidebarOpen(true);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        closeSidebar,
        openSidebar,
        setIsSidebarOpen,
      }}
      {...props}
    />
  );
};

export const useSidebar = (): SidebarContext => useContext(SidebarContext);
export default SidebarProvider;

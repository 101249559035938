import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { BasicTable } from '../../components/BasicTable';
import { MaintenancePlan } from '../../models/MaintenancePlan';
import { useMaintenancePlanSearch } from '../../contexts/MaintenancePlanSearchContext';
import { Drivers, MaintenanceStatuses, Priorities, TreatmentTypes } from '../../models/Enums';

export const MaintenancePlanSearch: React.FC<{}> = () => {
  const {
    maintenancePlans,
    isSearchingMaintenancePlans,
    currentPage,
    setCurrentPage,
    deleteMaintenancePlans,
    isDeletingMaintenancePlans,
    exportMaintenancePlans,
    isExportingMaintenancePlans,
    tablePageSize,
    setTablePageSize,
  } = useMaintenancePlanSearch();
  const columns: Column<MaintenancePlan>[] = React.useMemo(
    () => [
      {
        Header: 'Space',
        accessor: 'space',
        headerClassName: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
        className: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
        Cell: ({ row, value }): React.ReactElement => (
          <>
            <Link to={`/maintenanceplans/${row.original.id}`} className="text-indigo-600 hover:text-indigo-900">
              {value}
            </Link>
            <div className="flex italic text-xs font-light">{row.original.item}</div>
          </>
        ),
      },
      {
        Header: 'Asset',
        accessor: 'assetName',
        className: 'px-1 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): React.ReactElement => (
          <>
            <Link to={`/assets/${row.original.assetId}`} className="text-indigo-600 hover:text-indigo-900">
              {value}
            </Link>
            <div className="flex italic text-xs font-light">{row.original.assetReference}</div>
          </>
        ),
      },
      {
        Header: 'Driver',
        accessor: 'driver',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => <div className="truncate">{Drivers[value]}</div>,
      },
      {
        Header: 'Treatment',
        accessor: 'treatmentType',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => <div className="truncate">{TreatmentTypes[value]}</div>,
      },
      {
        Header: 'Urgency',
        accessor: 'urgency',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => <div className="truncate">{Priorities[value]}</div>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => (
          <div className="truncate">
            {value === MaintenanceStatuses.Open ? (
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Open
              </span>
            ) : (
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Closed
              </span>
            )}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        headerClassName: 'text-right whitespace-normal',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): React.ReactElement => (
          <Link to={`/maintenanceplans/${value}/edit`} className="text-indigo-600 hover:text-indigo-900 ">
            Edit
          </Link>
        ),
      },
    ],
    []
  );

  if (isSearchingMaintenancePlans) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Maintenance Plans</p>
      </div>
    );
  }
  console.log('maintenancePlans', maintenancePlans)

  return (
    <div className="sm:block">
      <div className="align-middle inline-block min-w-full">
        <div className="relative p-5 m-3 bg-white shadow-md rounded-md">
          <div className="flex-1 min-w-0 mb-3 justify-between flex">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Maintenance Plans</h1>
          </div>
          {/* <div className="flex items-end flex-wrap space-x-2">
            <div className="flex-grow rounded-md shadow-sm">
              <div className="relative flex items-stretch focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="mr-3 h-4 w-4 text-gray-600" aria-hidden="true" />
                </div>
                <input
                  defaultValue={searchText}
                  type="text"
                  name="searchText"
                  onChange={(e): void => setSearchText(e.target.value)}
                  className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-9"
                  placeholder="Search Asset ID"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="mx-3 h-50">
        <BasicTable
          columns={columns}
          data={maintenancePlans}
          isLoading={isSearchingMaintenancePlans}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          exportAction={exportMaintenancePlans}
          isExporting={isExportingMaintenancePlans}
          deleteAction={deleteMaintenancePlans}
          isDeleting={isDeletingMaintenancePlans}
          tablePageSize={tablePageSize}
          setTablePageSize={setTablePageSize}
          context="Maintenance Plan"
        />
      </div>
    </div>
  );
};

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { currencyFormat, numberFormat } from '../../Format';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { Roles } from '../../models/Role';

const ValuationComparison: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: {
      notes,
      previousCurrentValue,
      previousGross,
      previousDepreciationExpense,
      valuationMethod: {
        gross = NaN,
        accumulatedDepreciation = NaN,
        currentValue = NaN,
        depreciationExpense = NaN,
        weightedAverageRUL = NaN,
        weightedAverageUL = NaN,
      } = {},
    } = {} as Asset,
  } = useAssetById(parseInt(id));

  const { roles } = useAuth();

  const previousAccumulatedDepreciation = previousCurrentValue > previousGross ? 0 : previousGross - previousCurrentValue;

  // const valuationNotes: string | undefined = notes?.find((note) => note.tag === 'Valuation Policy')?.detail;

  return (
    <div className="bg-white p-4">
      {/* {valuationNotes !== undefined && !roles.includes(Roles.Viewer) && (
          <div className="rounded-md bg-indigo-50 p-4 mb-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-indigo-700">{valuationNotes}</p>
              </div>
            </div>
          </div>
        )} */}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow rounded-lg overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    />
                    <th
                      scope="col"
                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
                    >
                      Current
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
                    >
                      Previous
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Odd row */}
                  <tr className="bg-white">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Gross</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {gross && currencyFormat.format(gross)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousGross && currencyFormat.format(previousGross)}
                    </td>
                  </tr>

                  {/* Even row */}
                  <tr className="bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                      Accumulated Depreciation
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {accumulatedDepreciation && currencyFormat.format(accumulatedDepreciation)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousAccumulatedDepreciation && currencyFormat.format(previousAccumulatedDepreciation)}
                    </td>
                  </tr>
                  {/* Odd row */}
                  <tr className="bg-white">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Current Value</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {currentValue && currencyFormat.format(currentValue)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousCurrentValue && currencyFormat.format(previousCurrentValue)}
                    </td>
                  </tr>

                  {/* Even row */}
                  <tr className="bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Depreciation Expense</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {depreciationExpense && currencyFormat.format(depreciationExpense)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousDepreciationExpense && currencyFormat.format(previousDepreciationExpense)}
                    </td>
                  </tr>
                  {/* Odd row */}
                  <tr className="bg-white">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Weighted Average RUL</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {weightedAverageRUL && numberFormat.format(weightedAverageRUL)} yrs
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousCurrentValue &&
                        previousDepreciationExpense &&
                        numberFormat.format(previousCurrentValue / previousDepreciationExpense)}{' '}
                      yrs
                    </td>
                  </tr>

                  {/* Even row */}
                  <tr className="bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Weighted Average UL</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {weightedAverageUL && numberFormat.format(weightedAverageUL)} yrs
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">
                      {previousGross &&
                        previousDepreciationExpense &&
                        numberFormat.format(previousGross / previousDepreciationExpense)}{' '}
                      yrs
                    </td>
                  </tr>

                  {/* More items... */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationComparison;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { UseFormGetValues, UseFormReset, UseFormSetValue, useForm } from 'react-hook-form';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import 'react-datepicker/dist/react-datepicker.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { Content } from '../../models/Content';
import yup from '../../components/yupextensions';
import { useApi } from '../../contexts/ApiContext';

type ContentModalProps = {
  content: Content;
  assetId: string;
  isOpen: boolean;
  closeModal(): void;
};

const ContentModal: React.FC<ContentModalProps> = ({ isOpen, closeModal, content, assetId }) => {
  const { addToast } = useToast();
  const api = useApi();
  const ref = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const [originalName, setOriginalName] = useState<string>('');
  const ContentSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    quantity: yup.number().moreThan(0, 'Quantity is required'),
    appliedRate: yup.number().moreThan(0, 'Quantity is required'),
    type: yup.string().required('Type is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    reset,
  } = useForm<Content>({
    resolver: yupResolver(ContentSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset({
      ...content,
    });
    setOriginalName(content.name);
  }, [isOpen]);

  const onSubmit = handleSubmit((formContent): void => {
    api
      .post('/api/Content/UpdateContents', { assetId, content: formContent, originalName })
      .then(({ data }) => {
        addToast(`${data}`);
        closeModal();
      })
      .finally(() => {
        queryClient.invalidateQueries(['asset', parseInt(assetId)]);
      });
  });

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" initialFocus={ref} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="px-4 py-4 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:h-2/3 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="relative overflow-hidden px-2 py-2">
                <div className="flex flex-row justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Content</h3>
                  {/* <button
                className="inline-flex focus:outline-none text-gray-400 focus:text-gray-500 transition ease-in-out duration-150"
                onClick={(): void => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button> */}
                  <button
                    type="button"
                    className="rounded-md bg-white  text-gray-400 hover:text-red-700"
                    onClick={(): void => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form className="mt-6 sm:mt-5" onSubmit={onSubmit}>
                  <div className="w-full sm:col-span-2 sm:gap-4 sm:items-start sm:pt-2">
                    <div className="mt-1 relative shadow-sm">
                      <div className="flex items-stretch space-x-4">
                        <div className="min-w-0 flex-1">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="sm:col-span-3">
                              <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                Name
                              </label>
                              <input
                                type="text"
                                id="type"
                                {...register('name')}
                                className={`mt-1 block w-full rounded-md ${
                                  errors?.name ? 'border-rose-600' : ''
                                } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                              />
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                Type
                              </label>
                              <input
                                type="text"
                                id="type"
                                {...register('type')}
                                className={`mt-1 block w-full rounded-md ${
                                  errors?.type ? 'border-rose-600' : ''
                                } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                              />
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                Quantity
                              </label>
                              <input
                                type="number"
                                step="any"
                                {...register('quantity')}
                                className={`mt-1 block w-full rounded-md ${
                                  errors?.quantity ? 'border-rose-600' : ''
                                } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                              />
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                Rate
                              </label>
                              <input
                                type="number"
                                step="any"
                                {...register('rate')}
                                className={`mt-1 block w-full rounded-md ${
                                  errors?.rate ? 'border-rose-600' : ''
                                } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                              />
                            </div>
                          </div>

                          <div className="pl-3 pt-4 flex justify-end">
                            <div className="flex-shrink-0">
                              <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContentModal;

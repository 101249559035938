import React from 'react';
import { useOidcUser } from '@axa-fr/react-oidc';

import { UnauthenticatedContent } from '../components/UnauthenticatedContent';
import { AuthenticatedContent } from '../components/AuthenticatedContent';
import { NoClient } from '../components/NoClient';
import { useAuth } from '../contexts/AuthContext';

export const Home: React.FC<{}> = () => {
  const { client, roles, isLoading, isLoggedIn } = useAuth();
  if (isLoggedIn && isLoading)
    return (
      <div className="flex min-h-screen justify-center items-center text-base bg-gray-200 text-gray-700 sm:text-xl">
        <h1>Loading User Profile...</h1>
      </div>
    );
  if (isLoggedIn) {
    return client || roles.includes('Administrator') || roles.includes('Group Administrator') ? (
      <AuthenticatedContent />
    ) : (
      <NoClient />
    );
  }

  return <UnauthenticatedContent />;
};

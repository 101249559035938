import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TreeMenu from 'react-simple-tree-menu';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useApi } from '../../contexts/ApiContext';
import { BasicTable, EnhancedColumn } from '../../components/BasicTable';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { FinancialClass } from '../../models/FinancialClass';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { EditHierarchyNodeModal } from '../../components/EditHierarchyNodeModal';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { NewHierarchyNodeModal } from '../../components/NewHierarchyNodeModal';

type Variables = { id: number; type: string };

export interface HierarchyNode {
  key: string;
  label: string;
  nodes?: HierarchyNode[];
  assetsCount?: number;
}

const FinancialClassList: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const [id, setId] = useState<number>(0);
  const { addToast } = useToast();
  const [searchText, setSearchText] = useState<string>('');
  const queryClient = useQueryClient();
  const [name, setName] = useState<string>('');
  const [itemType, setItemType] = useState<string>('');
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [financialAssetClassId, setFinancialAssetClassId] = useState<number>(0);
  const { mutate } = useMutation((params: Variables) => api.post('/api/FinancialClass/Delete', params));

  const deleteAction = (): void => {
    mutate(
      { id, type: itemType },
      {
        onSuccess: ({ data }) => {
          addToast(`Deleted ${itemType}`, ToastType.Message);
        },
        onError: (error) => {
          addToast(`Unable to Delete ${itemType}`, ToastType.Error);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['hierarchy']);
        },
      }
    );
  };

  const {
    isLoading,
    isFetching,
    data: financialAssetClassList = [] as HierarchyNode[],
  } = useQuery<HierarchyNode[], Error>(['hierarchy'], () =>
    api.get<HierarchyNode[]>('/api/FinancialClass/FinancialAssetClassHierarchy').then(({ data }) => data)
  );

  const openDeleteModal = (nodeType: string, key: string): void => {
    const index = key.substring(key.lastIndexOf('/') + 1, key.length);
    setItemType(nodeType);
    setId(parseInt(index));
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (nodeType: string, node: HierarchyNode): void => {
    const index = node.key.substring(node.key.lastIndexOf('/') + 1, node.key.length);
    setItemType(nodeType);
    setId(parseInt(index));
    setName(node.label);
    setIsEditModalOpen(true);
  };

  const openNewModal = (nodeType: string, node: HierarchyNode): void => {
    const index = node.key.substring(node.key.lastIndexOf('/') + 1, node.key.length);
    setFinancialAssetClassId(parseInt(index));
    setIsNewModalOpen(true);
    setItemType('Financial Asset Sub Class');
  };

  const openNewFACModal = (): void => {
    setIsNewModalOpen(true);
    setItemType('Financial Asset Class');
  };

  const renderHierarchyLevel = (level: number): JSX.Element => {
    switch (level) {
      case 0:
        return (
          <div className="flex-shrink-0 bg-indigo-500 flex items-center justify-center w-32 text-white text-indigo-50 font-medium rounded-l-md">
            Financial Class
          </div>
        );
      default:
        return (
          <div className="flex-shrink-0 bg-indigo-900 flex items-center justify-center w-24 text-white text-indigo-50 font-medium rounded-l-md">
            Sub Class
          </div>
        );
    }
  };

  const renderName = (level: number): string => {
    switch (level) {
      case 0:
        return 'Financial Asset Class';
      default:
        return 'Financial Asset Sub Class';
    }
  };

  return (
    <>
      <TreeMenu data={financialAssetClassList} cacheSearch={false}>
        {({ search, items, resetOpenNodes }): JSX.Element => {
          const searchHierarchy = (event: React.KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === 'Enter' && search) {
              search(searchText);
            }
          };

          // const reset = (): void => {
          //   setSearchText('');
          //   resetOpenNodes && resetOpenNodes();
          //   search && search('');
          // };

          return (
            <>
              <div className="m-4 bg-white shadow overflow-hidden sm:rounded-md flex ">
                <div className="flex-grow m-4">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="m-1 relative min-w-max">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                      {/* Heroicon name: search */}
                      <svg
                        className="mr-3 h-4 w-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onKeyDown={searchHierarchy}
                      onChange={(e): void => setSearchText(e.target.value)}
                      type="text"
                      name="searchText"
                      value={searchText}
                      className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                      placeholder="Search Financial Asset Classes Hierarchy"
                    />
                  </div>
                </div>
                {!roles.includes(Roles.Viewer) && (
                  <button
                    className="mb-5 mr-4 btn h-10 self-end font-medium rounded-md btn-primary sm:ml-0"
                    value="Search"
                    onClick={(): void => openNewFACModal()}
                  >
                    New FAC
                  </button>
                )}
              </div>
              {isFetching && !isLoading && (
                <div className="m-4 flex space-x-4">
                  <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
                  <div className="text-sm text-gray-900 font-medium">Updating</div>
                </div>
              )}
              {isLoading && (
                <div className="m-4 flex space-x-4">
                  <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
                  <div className="text-sm text-gray-900 font-medium">Loading</div>
                </div>
              )}

              <div className="m-4 sm:rounded-md">
                {items.length > 300 ? (
                  <div className="text-center p-4">Too Many Results, please narrow your search</div>
                ) : (
                  <ul className="divide-y divide-gray-200 space-y-2">
                    {items.map(
                      ({ key, level, assetsCount = 0, componentsCount = 0, index, label, hasNodes, toggleNode, isOpen }) => {
                        const hasAssetsOrComponents = assetsCount + componentsCount > 0;
                        const acKey = key.split('/');
                        return (
                          <Transition
                            key={key}
                            className={`ml-${level * 32} flex shadow-sm rounded-md bg-white w-max`}
                            as="li"
                            appear
                            show
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            {renderHierarchyLevel(level)}
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                              <div className="text-gray-900 font-medium hover:text-gray-600">{label}</div>
                            </div>
                            {assetsCount > 0 && (
                              <span className="inline-flex mr-2 h-auto self-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                {assetsCount}
                              </span>
                            )}
                            {componentsCount > 0 && (
                              <span className="inline-flex mr-2 h-auto self-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                {componentsCount}
                              </span>
                            )}
                            {hasNodes && (
                              <ChevronUpIcon
                                onClick={(e): void => {
                                  hasNodes && toggleNode && toggleNode();
                                  e.stopPropagation();
                                }}
                                className={`${
                                  isOpen ? 'transform rotate-180' : ''
                                } m-2 w-5 self-center h-5 ease-in-out duration-150 cursor-pointer`}
                              />
                            )}
                            {!roles.includes(Roles.Viewer) && (
                              <Menu as="div" className="relative inline-block text-left self-center mx-2">
                                <div>
                                  <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="z-10 mx-3 origin-top absolute left-5 -top-4 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={(): void =>
                                              openEditModal(renderName(level), {
                                                key,
                                                label,
                                              })
                                            }
                                            className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </Menu.Item>
                                      {level === 0 && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={(): void => {
                                                openNewModal(renderName(level), {
                                                  key,
                                                  label,
                                                });
                                              }}
                                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                            >
                                              Add
                                            </button>
                                          )}
                                        </Menu.Item>
                                      )}

                                      {/* <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                      >
                                        Copy
                                      </a>
                                    )}
                                  </Menu.Item> */}
                                      {!hasAssetsOrComponents && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={(): void => openDeleteModal(renderName(level), key)}
                                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                            >
                                              Delete
                                            </button>
                                          )}
                                        </Menu.Item>
                                      )}

                                      {/* {level < 5 && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                        >
                                          Create {renderChildName(level)}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  )} */}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </Transition>
                        );
                      }
                    )}
                  </ul>
                )}
              </div>
            </>
          );
        }}
      </TreeMenu>
      <NewHierarchyNodeModal
        closeModal={(): void => setIsNewModalOpen(false)}
        isOpen={isNewModalOpen}
        itemType={itemType}
        url="/api/FinancialClass/UpdateFinancialClass"
        node={{ id: 0, name: '', type: itemType, financialAssetClassId }}
      />
      <EditHierarchyNodeModal
        closeModal={(): void => setIsEditModalOpen(false)}
        isOpen={isEditModalOpen}
        itemType={itemType}
        url="/api/FinancialClass/UpdateFinancialClass"
        node={{ id, name, type: itemType }}
      />
      <DeleteConfirmation
        itemType={itemType}
        isOpen={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        deleteAction={deleteAction}
      />
    </>
  );
};

export default FinancialClassList;

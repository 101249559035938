import React, { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GoogleMapReact from 'google-map-react';
import {
  ChevronUpIcon,
  PlusIcon,
  CalendarIcon,
  XMarkIcon,
  EyeIcon,
  ExclamationTriangleIcon,
  AdjustmentsHorizontalIcon,
  GlobeAsiaAustraliaIcon,
} from '@heroicons/react/24/solid';
import {
  ChevronDownIcon,
  InformationCircleIcon,
  UserIcon,
  CameraIcon as OutlineCamera,
  ArrowTrendingUpIcon,
  ChatBubbleBottomCenterTextIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, NavLink, unstable_usePrompt, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as LocationMarker } from '../../icons/LocationMarker.svg';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import {
  Asset,
  ComponentHierarchyItem,
  DropDownItem,
  IncomeApproach,
  IncomeApproachItem,
  MarketApproach,
} from '../../models/Asset';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { useAssetById } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { AssetAssumptions } from '../../models/AssetAssumptions';
import { Component } from '../../models/Component';
import { ComponentReplacementCost, ReplacementCost } from '../../models/ReplacementCostSummary';
import { bulkUploadImages, convertToBase64, removeTimezoneOffset } from '../../Helper';
import { AutoCompleteWithControl } from '../AutoComplete';
import { ReplacementCostEdit } from '../ReplacementCostEdit';
import { currencyFormat, numberFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { ImageBlob, ImageDisplay, ImagesImport } from '../../models/Image';
import { ComponentEdit } from '../ComponentListEdit';
import { HierarchyType } from '../../models/HierarchyType';
import QuickEditImageModal from '../QuickEditImageModal';
import { DeleteConfirmation } from '../DeleteConfirmation';
import NoteModal from '../NoteModal';
import { Note } from '../../models/Note';
import { IncomeEdit } from '../IncomeListEdit';
import ExportAssetsModal from '../ExportAssetsModal';
import { Job } from '../../models/Job';
import { useAssetSearch } from '../../contexts/AssetSearchContext';
import { SaveWithErrorsModal } from '../SaveWithErrorsModal';
import { NewApportionmentModal } from '../NewApportionmentModal';
import { NewComponentModal } from '../NewComponentModal';
import yup from '../yupextensions';
import { Content } from '../../models/Content';
import { ContentListEdit } from '../ContentListEdit';
import ContentImagesModal from '../ContentImagesModal';
import Loading from '../Loading';

interface Center {
  lat: number;
  lng: number;
}

export const AssetQuickEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id = '' } = useParams<{ id: string }>();
  const history = useNavigate();
  const queryClient = useQueryClient();
  const { assets, setAssets } = useAssetSearch();
  const { data: asset = {} as Asset, refetch, isLoading: isLoadingAsset } = useAssetById(parseInt(id));
  const [isSavingAsset, setIsSavingAsset] = useState<boolean>(false);
  const cameraRef = useRef<HTMLInputElement>(null);
  const [draggable, setDraggable] = useState<boolean>(true);
  const [defaultCenter, setDefaultCenter] = useState<Center>({
    lat: 0,
    lng: 0,
  });
  const [center, setCenter] = useState<Center>({
    lat: 0,
    lng: 0,
  });
  const [isContentImagesModalOpen, setIsContentImagesModalOpen] = useState<boolean>(false);
  const [contentImageUrls, setContentImageUrls] = useState<string[]>([]);
  const [showDates, setShowDates] = useState<boolean>(false);
  const [noteCount, setNoteCount] = useState<number>(0);
  const [noteType, setNoteType] = useState<string>('General');
  const [isGeneralNoteFilled, setIsGeneralNoteFilled] = useState<boolean>(false);
  const [isLocationNoteFilled, setIsLocationNoteFilled] = useState<boolean>(false);
  const [isValuerNoteFilled, setIsValuerNoteFilled] = useState<boolean>(false);
  const [isVPolicyNoteFilled, setIsVPolicyNoteFilled] = useState<boolean>(false);
  const [isInformationNoteFilled, setInformationNoteFilled] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [suburbOptions, setSuburbOptions] = useState<string[]>([]);
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [facilityOptions, setFacilityOptions] = useState<string[]>([]);
  const [subFacilityOptions, setSubFacilityOptions] = useState<string[]>([]);
  const [isImageViewerModalOpen, setIsImageViewerModalOpen] = useState<boolean>(false);
  const [isNewComponentModalOpen, setIsNewComponentModalOpen] = useState<boolean>(false);
  const [images, setImages] = useState<ImageDisplay[]>([]);
  const [image, setImage] = useState<ImageDisplay>();
  const [imageToDelete, setImageToDelete] = useState<string>('');
  const [isImageDeletModalOpen, setIsImageDeleteModalOpen] = useState<boolean>(false);
  const [contentName, setContentName] = useState<string>('');
  const [isRefetchingImages, setIsRefetchingImages] = useState<boolean>(false);
  const [isExportAssetModaOpen, setIsExportAssetModalOpen] = useState<boolean>(false);
  const [isSaveWithErrorModalOpen, setIsSaveWithErrorModalOpen] = useState<boolean>(false);
  const [existingComponents, setExistingComponents] = useState<Component[]>([]);
  const [reapportionedComponents, setReapportionedComponents] = useState<Component[]>([]);
  const [isNewApportModalOpen, setIsNewApportModalOpen] = useState<boolean>(false);
  const [job, setJob] = useState<Job>();
  const [componentList, setComponentList] = useState<ComponentHierarchyItem[]>([] as ComponentHierarchyItem[]);

  const AssetSchema = yup.object().shape({
    reference: yup.string().matches(/^\S*$/, 'Whitespace is not allowed').required('Asset ID is required'),
    name: yup.string().required('Name is required'),
    assetClassId: yup.number().moreThan(0, 'Asset Class is required'),
    acquisitionDate: yup.date().nullable(),
    decommissionDate: yup
      .date()
      .nullable()
      .when('acquisitionDate', ([acquisitionDate], schema) => {
        if (acquisitionDate) {
          return schema.min(acquisitionDate, 'Decomission date must be after Acquisition date');
        }
        return schema;
      }),
    components: yup
      .array()
      .of(
        yup.object().shape({
          typeId: yup.number().moreThan(0, 'Component Type is required'),
          subTypeId: yup.number().moreThan(0, 'Component Sub Type is required'),
          acquisitionDate: yup.date().nullable(),
          decommissionDate: yup
            .date()
            .nullable()
            .when('acquisitionDate', ([acquisitionDate], schema) => {
              if (acquisitionDate) {
                return schema.min(acquisitionDate, 'Decomission date must be after Acquisition date');
              }
              return schema;
            }),
        })
      )
      .distinctOnProperty('name', 'Name must be unique'),
    replacementCosts: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Name is required'),
          length: yup.number().moreThan(0, 'Length is required'),
          width: yup.number().moreThan(0, 'Width is required'),
          quantity: yup.number().moreThan(0, 'Quantity is required'),
        })
      )
      .distinctOnProperty('name', 'Name must be unique'),
    contents: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Name is required'),
          quantity: yup.number().moreThan(0, 'Quantity is required'),
          appliedRate: yup.number().moreThan(0, 'Quantity is required'),
          type: yup.string().required('Type is required'),
        })
      )
      .distinctOnProperty('name', 'Name must be unique'),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, isSubmitted },
    reset,
    getValues,
    setValue,
    resetField,
  } = useForm<Asset>({
    defaultValues: {
      id: 0,
      name: '',
      reference: '',
      customerReference: '',
      streetAddress: '',
      suburb: '',
      city: '',
      facility: '',
      subFacility: '',
      isControlledForFinancialPurpose: true,
      isHighestAndBestUse: true,
      notes: [],
    },
    resolver: yupResolver(AssetSchema),
    mode: 'onSubmit',
  });

  const zoom = 17;

  const watchLatitude = watch('latitude') || undefined;
  const watchLongitude = watch('longitude') || undefined;
  const watchAssetClassId = watch('assetClassId') || 0;
  const watchAssetTypeId = watch('assetTypeId') || 0;
  const watchAssetSubTypeId = watch('assetSubTypeId') || 0;
  const replacementCosts = watch(`replacementCosts`) || [];
  const contents = watch(`contents`) || [];
  const watchReference = watch(`reference`) || '';
  const watchName = watch(`name`) || '';
  const financialAssetClassIdWatch = watch('financialAssetClassId');
  const watchStatus = watch('status') || '';
  const watchValuationPolicy = watch('valuationPolicy');
  const watchValuationType = watch('valuationType');
  const watchMarketApproach = watch('marketApproach.type') || 'Item Only';
  const watchMarketApproachTotal: number = watch('marketApproach.landTotal');
  const watchMarketApproachArea: number = watch('marketApproach.landArea');
  const isMarket = watchValuationType === 'Market';
  const isIncome = watchValuationType === 'Income';
  const isDirect = watchValuationType === 'Direct Cost';

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
  });

  const Marker: React.FC<{
    lat: number;
    lng: number;
  }> = () => (
    <div className="absolute top-1/2 left-1/2 pb-6 select-none transform -translate-x-1/2 -translate-y-1/2 ">
      <LocationMarker className="h-8 w-8 text-red-600" />
    </div>
  );

  const {
    fields: contentFields,
    append: appendContentField,
    remove: removeContentField,
  } = useFieldArray({
    control,
    name: 'contents',
  });

  const {
    fields: rcFields,
    append: appendRC,
    remove: removeRC,
  } = useFieldArray({
    control,
    name: 'replacementCosts',
  });

  const {
    fields: incomeFields,
    append: appendIncomeField,
    remove: removeIncomeField,
  } = useFieldArray({
    control,
    name: 'incomeApproach.incomeApproachItems',
  });

  const valuationApproaches = [
    { value: 'Apportionment Cost', label: 'Apportionment Cost' },
    { value: 'Direct Cost', label: 'Direct Cost' },
    { value: 'Market', label: 'Market' },
    { value: 'Income', label: 'Income' },
  ];

  const valuationPolicies = [
    { value: 'Above Revaluation Threshold', label: 'Above Revaluation Threshold' },
    { value: 'Not to be Valued', label: 'Not To Be Valued' },
    { value: 'None', label: 'None' },
  ];

  const assetHistories = [
    { value: 'Found', label: 'Found' },
    { value: 'Old', label: 'Old' },
    { value: 'New', label: 'New' },
  ];

  const marketApproaches = [
    { value: 'Item Only', label: 'Item Only' },
    { value: 'Land Only', label: 'Land Only' },
    { value: 'Improvements On Land', label: 'Improvements On Land' },
  ];

  const { data: assetClassList = [{ value: asset.assetClassId, label: asset.assetClass }] as DropDownItem[] } = useQuery<
    DropDownItem[],
    Error
  >(['Asset Class List'], () => api.get('/api/Lookup/AssetClass').then(({ data }) => data));

  const fetchAssetType = async (assetClassId: number): Promise<DropDownItem[]> =>
    api.get<DropDownItem[]>(`/api/Lookup/AssetType?assetClassId=${assetClassId}`).then(({ data }) => data);

  const {
    refetch: refetchAssetTypes,
    data: assetTypeList = [{ value: asset.assetTypeId, label: asset.assetType }] as DropDownItem[],
  } = useQuery<DropDownItem[], Error>(
    ['Asset Type List', watchAssetClassId],
    async () => fetchAssetType(watchAssetClassId),
    { enabled: false }
  );

  const fetchAssetSubType = async (assetTypeId: number): Promise<DropDownItem[]> =>
    api.get<DropDownItem[]>(`/api/Lookup/AssetSubType?assetTypeId=${assetTypeId}`).then(({ data }) => data);

  const {
    refetch: refetchAssetSubTypes,
    data: assetSubTypeList = [{ value: asset.assetSubTypeId, label: asset.assetSubType }] as DropDownItem[],
  } = useQuery<DropDownItem[], Error>(
    ['Asset Sub Type List', watchAssetTypeId],
    async () => fetchAssetSubType(watchAssetTypeId),
    {
      enabled: false,
    }
  );

  const { refetch: refetchAssetAssumption, data: assetAssumption = {} as AssetAssumptions } = useQuery<
    AssetAssumptions,
    Error
  >([watchAssetSubTypeId], () =>
    api
      .get<AssetAssumptions>(`/api/Assumptions/GetAssetAssumptionsBySubType?assetSubTypeId=${watchAssetSubTypeId}`)
      .then(({ data }) => data)
  );

  const {
    data: financialAssetClasses = [
      { value: asset.financialAssetClassId, label: asset.financialAssetClass },
    ] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(['FinancialAssetClassList'], () =>
    api.post<HierarchyType[]>('/api/Lookup/FinancialAssetClass', {}).then(({ data }) => data)
  );

  const { data: valuationClasses = [{ value: asset.valuationClassId, label: '' }] as HierarchyType[] } = useQuery<
    HierarchyType[],
    Error
  >(['ValuationClassList'], () => api.post('/api/ValuationClass/List', {}).then(({ data }) => data));

  const fetchFinancialAssetSubClass = async (financialAssetClassId: number): Promise<HierarchyType[]> =>
    api
      .get<HierarchyType[]>(`/api/Lookup/FinancialAssetSubClass?financialAssetClassId=${financialAssetClassId}`)
      .then(({ data }) => data);

  const {
    refetch: refetchFinancialAssetSubClasss,
    data: financialAssetSubClasses = [
      { value: asset.financialAssetSubClassId, label: asset.financialAssetSubClass },
    ] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(
    ['Financial Sub Class List', financialAssetClassIdWatch],
    async () => fetchFinancialAssetSubClass(financialAssetClassIdWatch),
    { enabled: false }
  );

  const recalculateApportionmnet = (): void => {
    const oldComponents: Component[] = getValues('components');
    const newComponents: Component[] = [] as Component[];
    const apportionmentTotal = Math.round(oldComponents.reduce((prev, curr) => prev + curr.apportionmentPct, 0) * 100) / 100;
    let newApportionmentTotal = 0;
    if (apportionmentTotal !== 100) {
      const apportionmentRatio = +(100 / apportionmentTotal).toFixed(1);
      oldComponents.forEach((comp) => {
        const compName = componentList.find((comlis) => comlis.id === comp.nameId)?.name || '';
        const reapportionedComp = {
          ...comp,
          name: comp.name === undefined ? compName : comp.name,
          apportionmentPct: +(comp.apportionmentPct * apportionmentRatio).toFixed(1),
        } as Component;
        newComponents.push(reapportionedComp);
      });
      newApportionmentTotal = newComponents.reduce((prev, curr) => prev + curr.apportionmentPct, 0);
      const structureComponent = newComponents.find((comp) => comp.name.includes('02 Structure'));
      const subStructureComponent = newComponents.find((comp) => comp.name.includes('01 Sub-Structure'));
      const compHighestApport = newComponents.reduce((prev, current) =>
        prev.apportionmentPct > current.apportionmentPct ? prev : current
      );
      if (newApportionmentTotal !== 100) {
        const leftoverApportionment = +(100 - newApportionmentTotal).toFixed(1);
        if (structureComponent !== undefined) {
          const structureCompIndex = newComponents.findIndex((comp) => comp.nameId === structureComponent.nameId);
          const newReallocatedApportionment = newComponents[structureCompIndex].apportionmentPct + leftoverApportionment;
          newComponents[structureCompIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        } else if (subStructureComponent !== undefined) {
          const subStructureCompIndex = newComponents.findIndex((comp) => comp.nameId === subStructureComponent.nameId);
          const newReallocatedApportionment = newComponents[subStructureCompIndex].apportionmentPct + leftoverApportionment;
          newComponents[subStructureCompIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        } else {
          const compHighestApportIndex = newComponents.findIndex((comp) => comp.nameId === compHighestApport.nameId);
          const newReallocatedApportionment = newComponents[compHighestApportIndex].apportionmentPct + leftoverApportionment;
          newComponents[compHighestApportIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        }
      }
      setExistingComponents(oldComponents);
      setReapportionedComponents(newComponents);
      setIsNewApportModalOpen(true);
    } else if (apportionmentTotal === 100) {
      addToast('Total apportionment is equal to 100%');
    }
  };

  const handleFileUpload = (e) => {
    const selectedFiles: File[] = Array.from(e.target.files as FileList);
    bulkUploadImages(selectedFiles).then((result) => {
      const imageImport: ImagesImport = {
        data: result,
        assetId: id,
      };
      api.post(`/api/Asset/ImportImages`, { images: imageImport.data, assetId: asset.id }).then(
        ({ data }) => {
          addToast('Importing Images');
          setIsRefetchingImages(true);
          setTimeout(() => {
            refetch()
              .then((data) => {
                addToast('Images imported');
                setIsRefetchingImages(false);
                setImages(data.data?.images || []);
              })
              .catch((error) => console.error('not got docs', error));
          }, 2000);
        },
        (error) => {
          addToast('Image Upload Failed', ToastType.Error);
        }
      );
    });
  };
  const deleteImage = (): void => {
    setImages(images.filter((img) => img.original !== imageToDelete));
    api
      .post(`/api/Asset/DeleteImage`, {
        imagePath: imageToDelete,
        assetId: asset.id,
        contentName,
      })
      .then(({ data }) => {
        addToast('Deleted Image');
        if (contentName.length > 0) {
          setContentImageUrls(contentImageUrls.filter((url) => url !== imageToDelete));
        }
      })
      .catch(({ error }) => {
        setIsRefetchingImages(true);
        refetch()
          .then((data) => {
            setIsRefetchingImages(false);
            setImages(data.data?.images || []);
          })
          .catch((error) => console.error('Unable to get images', error));
        return addToast('An error has occurred');
      });
  };

  const sortAlphaNumeric = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  const addComponent = (selectedComponent: ComponentHierarchyItem): void => {
    const comptToAppend = componentList.find((comp) => comp.id === selectedComponent.id) || ({} as ComponentHierarchyItem);
    append({
      id: comptToAppend.id,
      nameId: comptToAppend.id,
      name: comptToAppend.name,
      apportionmentPct: comptToAppend.apportionment,
      acquisitionDate: getValues('acquisitionDate') != null ? getValues('acquisitionDate') : undefined,
      decommissionDate: getValues('decommissionDate') != null ? getValues('decommissionDate') : undefined,
    } as Component);
    setComponentList(componentList.filter((comp) => comp.id !== selectedComponent.id));
  };

  function rcGrossCalculator(replacementCost: ComponentReplacementCost) {
    const gross =
      (Number.isNaN(replacementCost.adjustedRate) ? replacementCost.unitRate : replacementCost.adjustedRate) *
      (replacementCost.total || 1) *
      (1 + replacementCost.localityFactorPct / 100);
    return gross;
  }

  useEffect(() => {
    if (watchAssetClassId > 0) {
      refetchAssetTypes();
      refetchAssetSubTypes();
    }
  }, [watchAssetClassId, assetClassList]);

  useEffect(() => {
    if (watchAssetTypeId > 0) {
      refetchAssetSubTypes();
    }
  }, [watchAssetTypeId, assetTypeList]);

  useEffect(() => {
    if (facilityOptions.length === 0) {
      api
        .get<string[]>(`/api/Asset/GetAssetFacilities`)
        .then(({ data }) => {
          const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
          setFacilityOptions(sortedData);
        })
        .catch((error) => {});
    }

    if (subFacilityOptions.length === 0) {
      api
        .get<string[]>(`/api/Asset/GetAssetSubFacilities`)
        .then(({ data }) => {
          const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
          setSubFacilityOptions(sortedData);
        })
        .catch((error) => {});
    }

    if (cityOptions.length === 0 && suburbOptions.length === 0) {
      api
        .get<string[]>(`/api/Asset/GetAssetSuburbsAndTowns`)
        .then(({ data }) => {
          const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
          setCityOptions(sortedData);
          setSuburbOptions(sortedData);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (financialAssetClassIdWatch > 0) {
      refetchFinancialAssetSubClasss();
    }
  }, [financialAssetClassIdWatch]);

  useEffect(() => {
    if (Object.keys(asset).length > 0) {
      reset({
        ...asset,
        acquisitionDate: asset?.acquisitionDate ? new Date(asset.acquisitionDate) : undefined,
        decommissionDate: asset?.decommissionDate ? new Date(asset.decommissionDate) : undefined,
        indexedFromDate: asset?.indexedFromDate ? new Date(asset.indexedFromDate) : undefined,
        apportionmentTotal:
          // eslint-disable-next-line no-unsafe-optional-chaining
          Math.round(asset?.components.reduce((prev, curr) => prev + curr.apportionmentPct, 0) * 100) / 100,
        isHighestAndBestUse: asset?.isHighestAndBestUse,
        isControlledForFinancialPurpose: asset?.isControlledForFinancialPurpose,
        replacementCosts: asset?.replacementCosts?.map((replacementCost) => ({
          ...replacementCost,
          area: +(replacementCost.length * replacementCost.width).toFixed(2),
          total: +(replacementCost.length * replacementCost.width * replacementCost.quantity).toFixed(2),
          adjustedRate: parseFloat(((1 + replacementCost.adjustmentPct) * replacementCost.unitRate).toFixed(2)),
          adjustmentPct: replacementCost.adjustmentPct * 100,
          localityFactorPct: replacementCost.localityFactorPct * 100,
          gross:
            replacementCost.length *
            replacementCost.width *
            replacementCost.quantity *
            (replacementCost.specifiedRate
              ? replacementCost.specifiedRate
              : (1 + replacementCost.adjustmentPct) * replacementCost.unitRate * (1 + replacementCost.localityFactorPct)) *
            (1 + replacementCost.indexationPct),
        })),
        valuationType: asset.valuationType,
        history: asset.history,
        incomeApproach:
          asset.incomeApproach !== undefined
            ? {
                ...(asset.incomeApproach || {}),
                improvementsPct: asset.incomeApproach?.improvementsPct ? asset.incomeApproach?.improvementsPct * 100 : 0,
                incomeApproachItems: asset.incomeApproach?.incomeApproachItems?.map((item) => ({
                  ...item,
                  capitalisationRatePct: Number((item.capitalisationRatePct * 100).toFixed(2)),
                  vacancyFactorPct: item.vacancyFactorPct * 100,
                })),
              }
            : ({} as IncomeApproach),
        marketApproach:
          asset.marketApproach !== undefined
            ? {
                ...(asset.marketApproach || {}),
                improvementsPct: asset.marketApproach?.improvementsPct
                  ? (asset.marketApproach?.improvementsPct || 1) * 100
                  : 0,
                indexationPct: asset.marketApproach?.indexationPct ? asset.marketApproach?.indexationPct * 100 : 0,
              }
            : ({} as MarketApproach),
        valuationPolicy: asset.valuationPolicy,
        components: asset?.components
          .sort((a: Component, b: Component) => ((a.name || '') > (b.name || '') ? 1 : -1))
          .map((component) => ({
            ...component,
            apportionmentPct: +(component.apportionmentPct * 100).toFixed(2),
            name: component.name,
            nameId: component.nameId,
            typeId: component.typeId,
            subTypeId: component.subTypeId,
            acquisitionDate: component?.acquisitionDate ? new Date(component.acquisitionDate) : undefined,
            decommissionDate: component?.decommissionDate ? new Date(component.decommissionDate) : undefined,
            scoreChangeDate: component?.scoreChangeDate ? new Date(component.scoreChangeDate) : undefined,
            replacementCosts: component?.replacementCosts?.map((replacementcost) => ({
              ...replacementcost,
              area: replacementcost.length * replacementcost.width,
              total: replacementcost.length * replacementcost.width * replacementcost.quantity,
              adjustedRate: parseFloat(((1 + replacementcost.adjustmentPct) * replacementcost.unitRate).toFixed(0)),
              adjustmentPct: replacementcost.adjustmentPct * 100,
              localityFactorPct: replacementcost.localityFactorPct * 100,
              gross:
                replacementcost.length *
                replacementcost.width *
                replacementcost.quantity *
                (replacementcost.specifiedRate
                  ? replacementcost.specifiedRate
                  : (1 + replacementcost.adjustmentPct) *
                    replacementcost.unitRate *
                    (1 + replacementcost.localityFactorPct)) *
                (1 + replacementcost.indexationPct),
            })),
          })),
      });
      setDefaultCenter({
        lat: asset?.latitude || 0,
        lng: asset?.longitude || 0,
      });
      setCenter({
        lat: asset?.latitude || 0,
        lng: asset?.longitude || 0,
      });
      setNoteCount(getValues('notes')?.length || 0);
      const notes = getValues('notes') || [];
      setIsGeneralNoteFilled(notes.find((item) => item.tag === 'General') !== undefined);
      setIsLocationNoteFilled(notes.find((item) => item.tag === 'Location') !== undefined);
      setIsValuerNoteFilled(notes.find((item) => item.tag === 'Valuer') !== undefined);
      setIsVPolicyNoteFilled(notes.find((item) => item.tag === 'Valuation Policy') !== undefined);
      setInformationNoteFilled(notes.find((item) => item.tag === 'Information Request') !== undefined);
      if (asset.valuationType === 'Direct Cost') {
        const assetAfterReset = getValues();
        reset({
          ...assetAfterReset,
          components: assetAfterReset.components.map((component) => ({
            ...component,
            gross: component.replacementCosts?.reduce((acc, value) => acc + rcGrossCalculator(value), 0),
          })),
        });
      } else if (asset.valuationType === 'Apportionment Cost') {
        const assetAfterReset = getValues();
        const totalReplacementCostGross =
          assetAfterReset.replacementCosts?.reduce((acc: number, value: ReplacementCost) => acc + (value.gross ?? 0), 0) ||
          0;
        reset({
          ...assetAfterReset,
          components: assetAfterReset.components.map((component) => ({
            ...component,
            gross: totalReplacementCostGross * (component.apportionmentPct / 100),
          })),
        });
      }
      setImages(asset?.images || []);
    } else {
      setValue('isControlledForFinancialPurpose', true);
      setValue('isHighestAndBestUse', true);
    }
  }, [asset]);

  useEffect(() => {
    if (watchAssetClassId !== 0) {
      api.get(`/api/AssetClass/JobByAssetClassId?assetClassId=${watchAssetClassId}`).then(({ data }) => {
        setJob(data);
      });
    }
  }, [watchAssetClassId]);

  const onChangeAssetSubType = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    refetchAssetAssumption();
    const { options, selectedIndex } = e.target;
    setValue('assetSubType', options[selectedIndex].innerHTML);
    api
      .get<ComponentHierarchyItem[]>(`/api/ComponentHierarchy/GetComponentHierarchyList?assetSubTypeId=${+e.target.value}`)
      .then(({ data: components }) => {
        setComponentList([]);
        setValue('components', []);
        reset({
          ...getValues(),
          assetSubTypeId: +e.target.value,
          components: components
            .filter((component) => component?.apportionment && component.apportionment > 0)
            .map((component) => ({
              id: 0,
              name: component.name,
              nameId: component.id,
              typeId: 0,
              subTypeId: 0,
              apportionmentPct: component.apportionment && +(component.apportionment * 100).toFixed(2),
              replacementCosts: [],
            })),
        });
      });
    if (getValues('replacementCosts') !== undefined) {
      api
        .get<AssetAssumptions>(`/api/Assumptions/GetAssetAssumptionsBySubType?assetSubTypeId=${watchAssetSubTypeId}`)
        .then(({ data: assumptions }) => {
          reset({
            ...getValues(),
            assetSubTypeId: +e.target.value,
            replacementCosts: getValues('replacementCosts')?.map((replacementCost) => ({
              ...replacementCost,
              unitRate: assumptions?.unitRate ?? 0,
              adjustmentPct:
                assumptions?.unitRate !== undefined
                  ? (replacementCost.adjustedRate / (assumptions?.unitRate ?? 1) - 1) * 100
                  : 0,
            })),
          });
        });
    }
  };

  useEffect(() => {
    if (watchAssetSubTypeId > 0) {
      api
        .get<ComponentHierarchyItem[]>(
          `/api/ComponentHierarchy/GetComponentHierarchyList?assetSubTypeId=${watchAssetSubTypeId}`
        )
        .then(({ data }) => {
          const newCompList = data.filter((comp) => !fields.find((field) => field?.nameId === comp.id));
          setComponentList(newCompList);
        });
      api
        .get<AssetAssumptions>(`/api/Assumptions/GetAssetAssumptionsBySubType?assetSubTypeId=${watchAssetSubTypeId}`)
        .then(({ data: newAssetAssumptions }) => {
          reset({
            ...getValues(),
            assumptionsId: newAssetAssumptions?.id,
            assumptions: newAssetAssumptions,
            replacementCosts: getValues('replacementCosts')?.map((rc: ReplacementCost) => ({
              ...rc,
              unitRate: newAssetAssumptions.unitRate,
            })),
            // apportionmentTotal: getValues('components').reduce((prev, curr) => prev + curr.apportionmentPct, 0) / 100,
          });
        });
    }
  }, [watchAssetSubTypeId]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsSaveWithErrorModalOpen(true);
    }
  }, [errors]);

  const onSubmit = handleSubmit((formAsset): void => {
    try {
      setIsSavingAsset(true);
      const assetToSubmit = {
        ...formAsset,
        acquisitionDate:
          formAsset.acquisitionDate !== undefined && formAsset.acquisitionDate !== null
            ? removeTimezoneOffset(formAsset.acquisitionDate)
            : undefined,
        decommissionDate:
          formAsset.decommissionDate !== undefined && formAsset.decommissionDate !== null
            ? removeTimezoneOffset(formAsset.decommissionDate)
            : undefined,
        indexedFromDate:
          formAsset.indexedFromDate !== undefined && formAsset.indexedFromDate !== null
            ? removeTimezoneOffset(formAsset.indexedFromDate)
            : undefined,
        replacementCosts: formAsset.replacementCosts?.map((replacementCost) => ({
          ...replacementCost,
          localityFactorPct: replacementCost.localityFactorPct / 100,
          adjustmentPct: replacementCost.adjustmentPct / 100,
        })),
        components: formAsset.components
          ? formAsset.components
              .filter((component) => (component.nameId || 0) > 0) // 'TODO: this code is a hack to remove delinquent components.
              .map((component) => ({
                ...component,
                acquisitionDate: component.acquisitionDate ? removeTimezoneOffset(component.acquisitionDate) : undefined,
                decommissionDate: component.decommissionDate ? removeTimezoneOffset(component.decommissionDate) : undefined,
                scoreChangeDate: component.scoreChangeDate ? removeTimezoneOffset(component.scoreChangeDate) : undefined,
                apportionmentPct: +(component.apportionmentPct / 100).toFixed(4),
                replacementCosts: component?.replacementCosts?.map((replacementcost) => ({
                  ...replacementcost,
                  localityFactorPct: replacementcost.localityFactorPct / 100,
                  adjustmentPct: replacementcost.adjustmentPct / 100,
                })),
              }))
          : ([] as Component[]),
        marketApproach:
          formAsset.marketApproach !== undefined
            ? {
                ...(formAsset.marketApproach || ({} as MarketApproach)),
                improvementsPct: formAsset.marketApproach?.improvementsPct
                  ? formAsset.marketApproach?.improvementsPct / 100
                  : 0,
                indexationPct: formAsset.marketApproach?.indexationPct ? formAsset.marketApproach?.indexationPct / 100 : 0,
              }
            : ({} as MarketApproach),
        incomeApproach:
          formAsset.incomeApproach !== undefined
            ? {
                ...(formAsset.incomeApproach || ({} as IncomeApproach)),
                improvementsPct: formAsset.incomeApproach?.improvementsPct
                  ? formAsset.incomeApproach?.improvementsPct / 100
                  : 0,
                incomeApproachItems: formAsset.incomeApproach?.incomeApproachItems?.map((item) => ({
                  ...item,
                  capitalisationRatePct: item.capitalisationRatePct / 100,
                  vacancyFactorPct: item.vacancyFactorPct / 100,
                })),
              }
            : ({} as IncomeApproach),
        notes: formAsset.notes || ([] as Note[]),
        errors: {},
        warnings: {},
        customFields: {},
      };
      api
        .post<number>(`/api/Asset/QuickUpdate`, { asset: { ...assetToSubmit } })
        .then(({ data }) => {
          setIsSavingAsset(false);
          if (data === 0) {
            addToast(`Found duplicate Asset Id ${assetToSubmit.reference}. Unable to save`, ToastType.Error);
          } else {
            addToast(`Saved Asset: ${assetToSubmit.reference}`);
            const newAssets = assets.map((assetToChange) => {
              if (assetToChange.id === assetToSubmit.id) {
                return {
                  ...assetToChange,
                  name: assetToSubmit.name,
                  reference: assetToSubmit.reference,
                  class: assetToSubmit.assetClass,
                  type: assetToSubmit.assetType,
                  subType: assetToSubmit.assetSubType,
                  inspectionStatus: assetToSubmit.status,
                  suburb: assetToSubmit.suburb,
                  facility: assetToSubmit.facility,
                  hasErrors: false,
                  hasWarnings: false,
                };
              }
              return assetToChange;
            });
            setAssets(newAssets);
            refetch();
          }
        })
        .catch((error) => {
          addToast(`Unable to update Asset`, ToastType.Error);
        });
    } catch (err) {
      addToast(
        err instanceof TypeError
          ? err?.message
          : 'Error in saving, please ensure that all data entered into fields are valid this includes adding letters into number only fields',
        ToastType.Error
      );
    }
  });

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  if(isLoadingAsset){
    return (<Loading message='Asset' />)
  }

  return (
    <>
      <form className="relative mx-auto pt-2 min-h-screen h-screen flex flex-col sm:px-4 md:px-8" onSubmit={onSubmit}>
        <div className="pt-8 pb-4">
          <div className="px-4 sm:px-6 md:px-0 flex flex-row justify-between items-center">
            <div className="flex flex-col justify-start">
              <h1 className="text-3xl font-extrabold text-gray-900">{watchReference}</h1>
              <h1 className="text-3xl font-extrabold text-gray-900">{watchName}</h1>
              {/* <button
                type="button"
                onClick={(): void => setIsExportAssetModalOpen(true)}
                className="ml-5 text-indigo-600 hover:text-indigo-900"
                title={`Export Asset ${watchReference}`}
              >
                <ArrowDownOnSquareStackIcon className="text-indigo-500 h-7 w-7" />
              </button>
              <Link
                to={`/jobs/${job?.id || 0}/import`}
                className="ml-5 text-indigo-600 hover:text-indigo-900"
                title={`Import Asset ${watchReference}`}
              >
                <ArrowUpOnSquareStackIcon className="text-indigo-500 h-7 w-7" />
              </Link> */}
            </div>
            {parseInt(id) > 0 && (
              <Link className="btn btn-secondary self-center" to={`/assets/${id}`}>
                View
              </Link>
            )}
          </div>
          <div className="flex flex-row mt-4 space-x-4">
            {Object.keys(asset).length > 0 && Object.keys(asset.errors).length > 0 && (
              <div className="flex-1 rounded-md bg-red-50 mb-2 p-2 shadow">
                <div className="flex-shrink-0 flex space-x-4">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <h3 className="text-sm font-medium text-red-800">
                    There is {Object.keys(asset.errors).length} errors with this asset
                  </h3>
                </div>

                <div className="mt-2 ml-2 text-sm text-red-700">
                  {Object.keys(asset.errors).map((error, key) => (
                    <li key={key}>{asset.errors[error]}</li>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(asset).length > 0 && Object.keys(asset.warnings).length > 0 && (
              <div className="flex-1 rounded-md bg-yellow-50 mb-2 p-2 shadow">
                <div className="flex-shrink-0 flex space-x-4">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  <h3 className="text-sm font-medium text-yellow-800">
                    There is {Object.keys(asset.warnings).length} warnings with this asset
                  </h3>
                </div>

                <div className="mt-2 ml-2 text-sm text-yellow-700">
                  {Object.keys(asset.warnings).map((warning, key) => (
                    <li key={key}>
                      {warning} - {asset.warnings[warning]}
                    </li>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2 pb-10">
          <input type="hidden" {...register('id', { valueAsNumber: true })} />
          <div className="space-y-6">
            <Disclosure defaultOpen>
              {({ open }): JSX.Element => (
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                    <div className="flex w-full justify-between ">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">General</h3>
                      <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                      </Disclosure.Button>
                    </div>

                    <Disclosure.Panel className="xl:col-span-2 xl:mt-0">
                      <div className="space-y-6 bg-white">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                              type="text"
                              {...register('name')}
                              autoComplete="off"
                              className={`mt-1 block w-full rounded-md ${
                                errors?.name ? 'border-rose-600' : ''
                              } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Asset ID</label>
                            <input
                              type="text"
                              {...register('reference')}
                              className={`mt-1 block w-full rounded-md ${id !== '0' ? `disabled:opacity-50` : ''} ${
                                errors?.reference ? 'border-rose-600' : ''
                              } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Entity ID</label>
                            <input
                              type="text"
                              {...register('customerReference')}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Acquisition Date</label>
                            <Controller
                              control={control}
                              name="acquisitionDate"
                              render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
                                <ReactDatePicker
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  name="acquisitionDate"
                                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  selected={value}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  dateFormat="dd/MM/yyyy"
                                />
                              )}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Decommission Date</label>
                            <Controller
                              control={control}
                              name="decommissionDate"
                              render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
                                <ReactDatePicker
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  name="decommissionDate"
                                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  selected={value}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  dateFormat="dd/MM/yyyy"
                                />
                              )}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Indexed From Date</label>
                            <Controller
                              control={control}
                              name="indexedFromDate"
                              render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
                                <ReactDatePicker
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  name="indexedFromDate"
                                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  selected={value}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  dateFormat="dd/MM/yyyy"
                                />
                              )}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Valuer</label>
                            <input
                              defaultValue=""
                              type="text"
                              {...register('valuer')}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Inspector</label>
                            <input
                              defaultValue=""
                              type="text"
                              {...register('inspectorName')}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </div>
                </div>
              )}
            </Disclosure>

            <Disclosure defaultOpen>
              {({ open }): JSX.Element => (
                <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                    <div className="flex w-full justify-between ">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
                      <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="mt-5 xl:col-span-2 xl:mt-0">
                      <div className="space-y-6 bg-white">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">Address Line</label>
                            <input
                              defaultValue=""
                              type="text"
                              id="streetAddress"
                              {...register('streetAddress')}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="mb-1 block text-sm font-medium text-gray-700">Suburb</label>
                            <AutoCompleteWithControl
                              name="suburb"
                              control={control}
                              rules={{ required: true }}
                              labels={suburbOptions}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Town / City</label>
                            <AutoCompleteWithControl
                              name="city"
                              control={control}
                              rules={{ required: true }}
                              labels={cityOptions}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Facility</label>
                            <AutoCompleteWithControl
                              name="facility"
                              control={control}
                              rules={{ required: true }}
                              labels={facilityOptions}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Sub-Facility</label>
                            <AutoCompleteWithControl
                              name="subFacility"
                              control={control}
                              rules={{ required: false }}
                              labels={subFacilityOptions}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Latitude</label>
                            <input
                              type="text"
                              {...register('latitude', { valueAsNumber: true })}
                              className="mt-1 block w-full rounded-md border border-gray-300 disabled:opacity-50 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">Longtitude</label>
                            <input
                              type="text"
                              {...register('longitude', { valueAsNumber: true })}
                              className="mt-1 block w-full rounded-md border border-gray-300 disabled:opacity-50 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {watchLatitude && watchLongitude ? (
                          <div className="w-full h-[60vh] relative">
                            <GoogleMapReact
                              yesIWantToUseGoogleMapApiInternals
                              options={(map) => ({
                                streetViewControl: true,
                                controlSize: 22,
                                mapTypeControl: true,
                                mapTypeId: map.MapTypeId.SATELLITE,
                                mapTypeControlOptions: {
                                  style: map.MapTypeControlStyle.DROPDOWN,
                                  position: map.ControlPosition.TOP_CENTER,
                                  mapTypeIds: [map.MapTypeId.ROADMAP, map.MapTypeId.SATELLITE],
                                },
                              })}
                              // onGoogleApiLoaded={({ map, maps }) => handleMapLoaded(map, maps)}
                              onChildMouseDown={(a, b, c) => {
                                setDraggable(false);
                              }}
                              onChildMouseUp={(a, b, c) => {
                                setDraggable(true);
                                setCenter({
                                  lat: c.lat,
                                  lng: c.lng,
                                });
                                setValue('latitude', c.lat);
                                setValue('longitude', c.lng);
                                addToast(`Asset coordinates changed to latitude: ${c.lat} and longitude: ${c.lng}`);
                              }}
                              onChildMouseMove={(a, b, c) => {
                                setValue('latitude', c.lat);
                                setValue('longitude', c.lng);
                              }}
                              bootstrapURLKeys={{ key: 'AIzaSyAIa1oCSlhCZOpu9lAdfqENa_r0oOKuyKU' }}
                              defaultCenter={{
                                lat: defaultCenter.lat,
                                lng: defaultCenter.lng,
                              }}
                              center={{
                                lat: center.lat,
                                lng: center.lng,
                              }}
                              draggable={draggable}
                              defaultZoom={zoom}
                            >
                              <Marker lat={watchLatitude} lng={watchLongitude} />
                            </GoogleMapReact>
                            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-42 bg-white mb-4 py-2 px-4 rounded-md shadow-sm border border-gray-300 flex items-center">
                              <div className="flex-shrink-0 self-center mr-1">
                                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
                              </div>
                              <h3 className="text-sm font-medium text-gray-800">Drag marker to change coordinates</h3>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full h-96 relative bg-gray-50 flex justify-center items-center">
                            <div className="flex items-start m-auto">
                              <div className="flex-shrink-0 self-center">
                                <GlobeAsiaAustraliaIcon className="h-12 w-12 text-gray-700" aria-hidden="true" />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-gray-800">Invalid or missing coordinates</h3>
                                <div className="mt-1 text-sm text-gray-700">
                                  <p>Unable to show map view due to invalid or empty coordinates</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Disclosure.Panel>
                  </div>
                </div>
              )}
            </Disclosure>

            <Disclosure defaultOpen>
              {({ open }): JSX.Element => (
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                    <div className="flex w-full justify-between ">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Valuation Options</h3>
                      <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="xl:col-span-2 xl:mt-0">
                      <div className="space-y-6 bg-white">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-2 sm:col-span-6 md:col-span-3">
                            <div>
                              <legend className="text-base text-sm font-medium text-gray-700">Valuation Policy</legend>
                            </div>
                            <div className="mt-2 space-y-4">
                              <select
                                // onChange={refreshComponentSubType}
                                {...register('valuationPolicy')}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                // onChange={(e): void => {
                                //   if (e.target.value === String(valuationPolicies)) {
                                //     // setNoteType('Valuation Policy');
                                //     // setIsModalOpen(true);
                                //   }
                                // }}
                              >
                                <option key={0} value={0}>
                                  Select Valuation Policy
                                </option>
                                {valuationPolicies.map((valuationPolicies) => (
                                  <option key={valuationPolicies.value} value={valuationPolicies.value}>
                                    {valuationPolicies.label}
                                  </option>
                                ))}
                              </select>
                            </div>{' '}
                          </div>
                          {watchValuationPolicy === 'Above Revaluation Threshold' && (
                            <fieldset className="col-span-2 sm:col-span-6 md:col-span-3">
                              <div>
                                <legend className="text-base text-sm font-medium text-gray-700">Valuation Method</legend>
                              </div>
                              <div className="mt-2 space-y-4">
                                <select
                                  // onChange={refreshComponentSubType}
                                  {...register('valuationType')}
                                  className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                  <option key={0} value={0}>
                                    Select Valuation Approach
                                  </option>
                                  {valuationApproaches.map((valuationApproach) => (
                                    <option key={valuationApproach.value} value={valuationApproach.value}>
                                      {valuationApproach.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </fieldset>
                          )}
                          <fieldset className="col-span-2 sm:col-span-6 md:col-span-3">
                            <div>
                              <legend className="text-base text-sm font-medium text-gray-700">Asset History</legend>
                            </div>
                            <div className="mt-2 space-y-4">
                              <select
                                // onChange={refreshComponentSubType}
                                {...register('history')}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option key={0} value={0}>
                                  Select Asset History
                                </option>
                                {assetHistories.map((AssetHistories) => (
                                  <option key={AssetHistories.value} value={AssetHistories.value}>
                                    {AssetHistories.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </fieldset>

                          <fieldset className="col-span-2 sm:col-span-6 md:col-span-3">
                            <div>
                              <legend className="text-base text-sm font-medium text-gray-700">Financial Class</legend>
                            </div>
                            <div className="mt-2 space-y-4">
                              <select
                                {...register('financialAssetClassId', { valueAsNumber: true })}
                                className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option key={0} value={0}>
                                  Select Financial Asset Class
                                </option>
                                {financialAssetClasses.map((financialAssetClass, index) => (
                                  <option key={index + 1} value={financialAssetClass.value}>
                                    {financialAssetClass.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </div>
                </div>
              )}
            </Disclosure>

            {isIncome && watchValuationPolicy === 'Above Revaluation Threshold' && (
              <Disclosure defaultOpen>
                {({ open }): JSX.Element => (
                  <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                      <div className="flex w-full justify-between ">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Income</h3>
                        <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                          <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                        </Disclosure.Button>
                      </div>
                      <Disclosure.Panel className="mt-5 xl:col-span-2 xl:mt-0">
                        <div className="space-y-6 bg-white">
                          <div className="grid grid-cols-3 gap-6">
                            <div className="sm:col-span-1 lg:col-span-1">
                              <label className="block text-sm font-medium text-gray-700">Land Rate</label>
                              <div>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="text"
                                    {...register('incomeApproach.landRate', { valueAsNumber: true })}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pl-7 pr-7 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                              <label className="block text-sm font-medium text-gray-700">Land Area</label>
                              <div>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    {...register('incomeApproach.landArea', { valueAsNumber: true })}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pl-7 pr-7 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                              <label className="block text-sm font-medium text-gray-700">Improvements Percentage</label>
                              <div>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    {...register('incomeApproach.improvementsPct', { valueAsNumber: true })}
                                    type="text"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pr-9 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                  />
                                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </div>
                  </div>
                )}
              </Disclosure>
            )}

            {isIncome && watchValuationPolicy === 'Above Revaluation Threshold' && (
              <div className="overflow-x-auto bg-white  pt-2 sm:space-y-2 sm:rounded-lg">
                <h3 className="text-lg font-medium leading-6 text-gray-900 sm:ml-2">Income Approach Items</h3>

                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Item Name
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Inflow
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Outflow
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Vacancy Factor
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Leased Up Months
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Cap Rate
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Capital Adjustment
                          </th>

                          <th scope="col" className="whitespace-nowrap px-1 py-2 text-right text-sm font-medium">
                            <button
                              type="button"
                              className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900 pr-4"
                              onClick={(): void =>
                                appendIncomeField({
                                  id: 0,
                                  itemName: '',
                                  inflow: 0,
                                  outflow: 0,
                                  vacancyFactorPct: 0,
                                  capitalisationRatePct: 0,
                                  capitalAdjustment: 0,
                                  leasedUpMonths: 0,
                                } as IncomeApproachItem)
                              }
                            >
                              <PlusIcon className="h-4 w-4" />
                            </button>

                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {incomeFields?.map((field, index) => (
                          <IncomeEdit
                            remove={removeIncomeField}
                            {...{
                              index,
                              field,
                              control,
                              watch,
                              register,
                              getValues,
                              setValue,
                              // defaultValues,
                            }}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {isMarket && (
              <Disclosure defaultOpen>
                {({ open }): JSX.Element => (
                  <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                      <div className="flex w-full justify-between ">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Market Valuation</h3>
                        <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                          <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                        </Disclosure.Button>
                      </div>
                      <Disclosure.Panel className="mt-5 xl:col-span-2 xl:mt-0">
                        <div className="space-y-6 bg-white">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="sm:col-span-6 lg:col-span-6">
                              <label className="block text-sm font-medium text-gray-700">Market Approach</label>
                              <select
                                // onChange={refreshComponentSubType}
                                {...register('marketApproach.type')}
                                className="mt-1 block w-full lg:w-1/2  border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {marketApproaches.map((marketApproach) => (
                                  <option key={marketApproach.value} value={marketApproach.value}>
                                    {marketApproach.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {watchMarketApproach !== 'Land Only' && (
                              <div className="sm:col-span-3 lg:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">Property Value</label>
                                <div>
                                  <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                      <span className="text-gray-500 sm:text-sm">$</span>
                                    </div>
                                    <input
                                      type="text"
                                      {...register('marketApproach.propertyValue', { valueAsNumber: true })}
                                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                      placeholder="0"
                                      step="any"
                                      aria-describedby="price-currency"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {watchMarketApproach !== 'Item Only' && (
                              <>
                                <div className="sm:col-span-3 lg:col-span-3">
                                  <label className="block text-sm font-medium text-gray-700">Land Total</label>
                                  <div>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">$</span>
                                      </div>
                                      <input
                                        type="text"
                                        {...register('marketApproach.landTotal', { valueAsNumber: true })}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="0"
                                        aria-describedby="price-currency"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="sm:col-span-3 lg:col-span-3">
                                  <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                                    Land Area
                                  </label>

                                  <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                      {...register('marketApproach.landArea', { valueAsNumber: true })}
                                      type="number"
                                      className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md block w-full pl-3 pr-12 sm:text-sm border-gray-300"
                                      defaultValue={0}
                                      step="any"
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center">
                                      <label htmlFor="currency" className="sr-only">
                                        Unit
                                      </label>
                                      <select
                                        {...register('marketApproach.landAreaUnit')}
                                        defaultValue="sqm"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm"
                                      >
                                        <option>sqm</option>
                                        <option>ha</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="sm:col-span-3 lg:col-span-3">
                                  <label className="block text-sm font-medium text-gray-700">Land Rate</label>
                                  <div>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">$</span>
                                      </div>
                                      <input
                                        disabled
                                        value={
                                          !Number.isNaN(watchMarketApproachTotal / watchMarketApproachArea) &&
                                          Number.isFinite(watchMarketApproachTotal / watchMarketApproachArea)
                                            ? numberFormat.format(watchMarketApproachTotal / watchMarketApproachArea)
                                            : 0
                                        }
                                        type="text"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="0"
                                        aria-describedby="price-currency"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {watchMarketApproach === 'Improvements On Land' && (
                              <div className="sm:col-span-3 lg:col-span-3">
                                <label className="block text-sm font-medium text-gray-700">Improvements Percentage</label>
                                <div>
                                  <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                      {...register('marketApproach.improvementsPct', { valueAsNumber: true })}
                                      type="text"
                                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                                      placeholder="0"
                                      step="any"
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                      <span className="text-gray-500 sm:text-sm">%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="sm:col-span-3 lg:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">Indexation Percentage</label>
                              <div>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    {...register('marketApproach.indexationPct', { valueAsNumber: true })}
                                    type="number"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                                    defaultValue={0}
                                    step="any"
                                  />
                                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </div>
                  </div>
                )}
              </Disclosure>
            )}

            <Disclosure defaultOpen>
              {({ open }): JSX.Element => (
                <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                    <div className="flex w-full justify-between ">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">General</h3>
                      <Disclosure.Button className="rounded-lg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 xl:col-span-1">
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="mt-5 xl:col-span-2 xl:mt-0">
                      <div className="space-y-6 bg-white">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Asset Class</label>
                            <select
                              {...register('assetClassId', {
                                valueAsNumber: true,
                                onChange: (e) => {
                                  const { options, selectedIndex } = e.target;
                                  setValue('assetClass', options[selectedIndex].innerHTML);
                                },
                              })}
                              className={`mt-1 block w-full rounded-md ${
                                errors?.assetClassId ? 'border-rose-600' : ''
                              } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                            >
                              <option key={0} value={0}>
                                Select Asset Class
                              </option>
                              {assetClassList.map((assetClass, index) => (
                                <option key={index + 1} value={assetClass.value}>
                                  {assetClass.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Asset Type</label>
                            <select
                              {...register('assetTypeId', {
                                valueAsNumber: true,
                                onChange: (e) => {
                                  const { options, selectedIndex } = e.target;
                                  setValue('assetType', options[selectedIndex].innerHTML);
                                },
                              })}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              <option key={0} value={0}>
                                Select Asset Type
                              </option>
                              {assetTypeList.map((assetType, index) => (
                                <option key={index + 1} value={assetType.value}>
                                  {assetType.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <div className="flex flex-row justify-between items-center">
                              {assetAssumption && assetAssumption?.id > 0 && watchAssetSubTypeId !== 0 ? (
                                <NavLink
                                  className="block text-sm font-medium text-indigo-600 hover:text-indigo-900"
                                  to={`/assetassumptions/${assetAssumption?.id}`}
                                >
                                  Asset Sub-Type
                                </NavLink>
                              ) : (
                                <label className="block text-sm font-medium text-gray-700">Asset Sub-Type</label>
                              )}
                            </div>
                            <select
                              {...register('assetSubTypeId', { valueAsNumber: true })}
                              onChange={onChangeAssetSubType}
                              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              <option key={0} value={0}>
                                Select Asset Sub Type
                              </option>
                              {assetSubTypeList.map((assetSubType, index) => (
                                <option key={index + 1} value={assetSubType.value}>
                                  {assetSubType.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </div>
                </div>
              )}
            </Disclosure>
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">Images</h3>
              <div className="grid w-full grid-cols-6 gap-2 sm:col-span-6">
                <div className="col-span-6 sm:col-span-6">
                  <div className="w-full mx-auto bg-white rounded-2xl">
                    <ul className="grid sm:gap-x-4 sm:gap-y-4 sm:grid-cols-4 lg:grid-cols-4 lg:gap-x-4 xl:gap-x-6">
                      <li
                        key={0}
                        className="block w-full aspect-w-10 aspect-h-7 border-2 border-dashed border-gray-300 rounded-lg overflow-hidden"
                      >
                        <div className="flex items-center justify-center">
                          <input
                            accept="image/*"
                            className="hidden"
                            name="files[]"
                            id="files"
                            type="file"
                            multiple
                            ref={cameraRef}
                            onChange={(e): void => handleFileUpload(e)}
                          />
                          <button
                            className="flex text-white p-0.5 rounded m-1 focus:outline-none "
                            onClick={(): void => cameraRef.current?.click()}
                            type="button"
                          >
                            <div className="flex flex-col items-center">
                              <OutlineCamera className="w-10 h-10 text-gray-400" />
                              <div className="flex flex-col items-center">
                                <span className="block text-sm font-medium text-gray-400">Add multiple images</span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </li>

                      {!isRefetchingImages ? (
                        images.map((image, index) => (
                          <li
                            key={index + 1}
                            className="group aspect-w-10 aspect-h-7 relative block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                          >
                            <button
                              onClick={() => {
                                setImage(image);
                                setIsImageViewerModalOpen(true);
                              }}
                              type="button"
                            >
                              <img
                                src={image.original}
                                alt=""
                                className="pointer-events-none object-cover group-hover:opacity-75"
                              />
                            </button>
                            <button
                              type="button"
                              className="absolute p-0.5 w-6 h-6 m-1 bg-red-100 rounded-full"
                              onClick={(): void => {
                                setImageToDelete(image.original);
                                setIsImageDeleteModalOpen(true);
                                setContentName('');
                              }}
                            >
                              <XMarkIcon className="text-red-700 " />
                            </button>
                          </li>
                        ))
                      ) : (
                        <li className="group aspect-w-10 aspect-h-7 relative w-full border-transparent overflow-hidden rounded-lg bg-gray-100">
                          <div className="flex flex-row justify-center items-center">
                            <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {job?.isContents && (
              <div className="overflow-x-auto shadow bg-white pt-2 sm:space-y-2 sm:rounded-lg">
                <h3 className="text-lg font-medium leading-6 text-gray-900 sm:ml-2">Contents</h3>
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Applied Rate
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Total
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Images
                          </th>
                          <th scope="col" className="relative px-3 py-3 text-right">
                            <button
                              type="button"
                              className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                              onClick={(): void => {
                                appendContentField({
                                  id: 0,
                                  assetId: parseInt(id) || 0,
                                  name: 'Item',
                                  quantity: 1,
                                  rate: 100,
                                  type: '',
                                  images: [] as ImageDisplay[],
                                  imageUrls: [] as string[],
                                } as Content);
                              }}
                            >
                              <PlusIcon className="h-4 w-4" />
                            </button>

                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contentFields?.map((field, index) => (
                          <ContentListEdit
                            setContentImageUrls={setContentImageUrls}
                            setIsContentImagesModalOpen={setIsContentImagesModalOpen}
                            setContentName={setContentName}
                            remove={removeContentField}
                            {...{
                              index,
                              nameError:
                                errors !== undefined && errors.contents !== undefined
                                  ? errors.contents[index]?.name?.message
                                  : undefined,
                              quantityError:
                                errors !== undefined && errors.contents !== undefined
                                  ? errors.contents[index]?.quantity?.message
                                  : undefined,
                              rateError:
                                errors !== undefined && errors.contents !== undefined
                                  ? errors.contents[index]?.rate?.message
                                  : undefined,
                              field,

                              control,
                              watch,
                              register,
                              getValues,
                              setValue,
                              // defaultValues,
                            }}
                          />
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-50">
                        <tr>
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-center text-sm uppercase tracking-wide text-gray-500">
                            {currencyFormat.format(
                              contents.reduce(
                                (previousValue, currentValue) => previousValue + currentValue.quantity * currentValue.rate,
                                0
                              )
                            )}
                          </td>
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500" />
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {watchAssetSubTypeId > 0 && !isDirect && (
              <div className="overflow-x-auto shadow bg-white pt-2 sm:space-y-2 sm:rounded-lg">
                <h3 className="text-lg font-medium leading-6 text-gray-900 sm:ml-2">Replacement Costs</h3>

                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            L
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            W
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Area
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Total
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Specified Rate
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-3 text-left text-right text-xs font-medium uppercase text-gray-500 ${
                              assetAssumption.unitRate === 0 ? 'text-red-700' : ''
                            }`}
                            title={assetAssumption.unitRate === 0 ? 'Base unit rate is 0' : ''}
                          >
                            Adj Rate ({assetAssumption.unitRate})
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Locality
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Gross
                          </th>

                          <th scope="col" className="relative px-3 py-3 text-right">
                            <button
                              type="button"
                              className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                              onClick={(): void => {
                                appendRC({
                                  id: 0,
                                  name: 'Main',
                                  length: 1,
                                  width: 1,
                                  quantity: 1,
                                  localityFactorPct: (assetAssumption?.localityFactorPct ?? 0) * 100,
                                  indexationPct: asset.assumptions?.accumulatedIndexTotal ?? 0,
                                  unitRate: assetAssumption?.unitRate ?? 0,
                                  adjustedRate: assetAssumption?.unitRate ?? 0,
                                  adjustmentPct: 0,
                                  gross: 0,
                                } as ReplacementCost);
                              }}
                            >
                              <PlusIcon className="h-4 w-4" />
                            </button>

                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rcFields?.map((field, index) => (
                          <ReplacementCostEdit
                            remove={removeRC}
                            {...{
                              index,
                              nameError:
                                errors !== undefined && errors.replacementCosts !== undefined
                                  ? errors.replacementCosts[index]?.name?.message
                                  : undefined,
                              quantityError:
                                errors !== undefined && errors.replacementCosts !== undefined
                                  ? errors.replacementCosts[index]?.quantity?.message
                                  : undefined,
                              lengthError:
                                errors !== undefined && errors.replacementCosts !== undefined
                                  ? errors.replacementCosts[index]?.length?.message
                                  : undefined,
                              widthError:
                                errors !== undefined && errors.replacementCosts !== undefined
                                  ? errors.replacementCosts[index]?.width?.message
                                  : undefined,
                              field,
                              control,
                              watch,
                              register,
                              getValues,
                              setValue,
                              // defaultValues,
                            }}
                          />
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-50">
                        <tr>
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-right text-gray-500">
                            <div>
                              {currencyFormat.format(
                                replacementCosts.reduce(
                                  (previousValue, currentValue) => previousValue + currentValue.gross,
                                  0
                                )
                              )}
                            </div>
                            {getValues('previousGross') > 0 && (
                              <div className="text-xs italic text-gray-400 flex space-x-1 justify-end">
                                <span className="hidden xl:block">prev</span>
                                <span>{currencyFormat.format(getValues('previousGross') || 0)}</span>{' '}
                              </div>
                            )}
                          </td>
                          {getValues('previousGross') !== null &&
                          (getValues('previousGross') || 0) > 0 &&
                          replacementCosts.reduce((previousValue, currentValue) => previousValue + currentValue.gross, 0) >
                            0 ? (
                            <td className="px-3 py-2 text-right text-sm font-medium uppercase text-gray-500">
                              {percentFormat.format(
                                (replacementCosts.reduce(
                                  (previousValue, currentValue) => previousValue + currentValue.gross,
                                  0
                                ) -
                                  (getValues('previousGross') || 0)) /
                                  (getValues('previousGross') || 1)
                              )}
                            </td>
                          ) : (
                            <td className="px-1 py-2 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500" />
                          )}
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {watchAssetSubTypeId > 0 && (
              <div className="overflow-x-auto shadow bg-white pt-2 sm:space-y-2 sm:rounded-lg">
                <h3 className="text-lg font-medium leading-6 text-gray-900 sm:ml-2">Components</h3>
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Component
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Sub-Type
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Score
                          </th>
                          <th
                            scope="col"
                            className="px-1 py-2 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Apport
                          </th>
                          <th
                            scope="col"
                            className="px-1 py-2 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Gross
                          </th>
                          {showDates && (
                            <>
                              <th
                                scope="col"
                                className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                              >
                                Acquisition
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                              >
                                Decomission
                              </th>
                            </>
                          )}

                          <th scope="col" className="relative px-3 py-3 text-right flex flex-row justify-center space-x-4">
                            <button
                              type="button"
                              className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                              onClick={(): void => setShowDates(!showDates)}
                              title="Change Acquisition/Decomission Dates"
                            >
                              <CalendarIcon className="h-4 w-4" />
                            </button>
                            {componentList.length > 0 && (
                              <button
                                type="button"
                                className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                                onClick={(): void => {
                                  setIsNewComponentModalOpen(true);
                                }}
                              >
                                <PlusIcon className="h-4 w-4" />
                              </button>
                            )}

                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields?.map((field, index) => (
                          <ComponentEdit
                            key={index}
                            assetAssumption={asset.assumptions}
                            remove={remove}
                            {...{
                              index,
                              field,
                              typeError:
                                errors !== undefined && errors.components !== undefined
                                  ? errors.components[index]?.typeId?.message
                                  : undefined,
                              subTypeError:
                                errors !== undefined && errors.components !== undefined
                                  ? errors.components[index]?.subTypeId?.message
                                  : undefined,
                              dateError:
                                errors !== undefined && errors.components !== undefined
                                  ? errors.components[index]?.decommissionDate?.message
                                  : undefined,
                              control,
                              watch,
                              register,
                              componentList,
                              setComponentList,
                              showDates,
                              getValues,
                              setValue,
                              reset,
                              resetField,
                              isAssetCost: isDirect,
                              // defaultValues,
                            }}
                          />
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-50">
                        <tr>
                          <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td className="px-1 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                          <td
                            className={`px-2 pt-3 pb-2 text-right text-sm font-medium tracking-wide text-gray-500 ${
                              errors?.apportionmentTotal && 'text-red-600'
                            }`}
                          >
                            {twoDecimalsPercentFormat.format(
                              getValues('components').reduce((prev, curr) => prev + curr.apportionmentPct, 0) / 100 || 0
                            )}{' '}
                          </td>
                          <td className="px-1 py-2 text-right text-xs font-medium uppercase text-gray-500">
                            {' '}
                            <button
                              type="button"
                              onClick={(): void => {
                                recalculateApportionmnet();
                              }}
                              disabled={getValues('components')?.length === 0}
                              className="group flex rounded-md disabled:opacity-50 items-center w-full text-xs btn btn-secondary"
                            >
                              <AdjustmentsHorizontalIcon className="w-6 h-6 mr-2" /> Reapportion
                            </button>
                          </td>

                          {showDates && (
                            <>
                              <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                              <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                            </>
                          )}

                          <td className="px-2 py-2 text-right lg:space-x-2" />
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="overflow-x-auto shadow bg-white pt-2 sm:space-y-2 sm:rounded-lg">
            <h3 className="text-lg font-medium leading-6 text-gray-900 sm:ml-2">Valuation</h3>
            <div className="p-4 inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Gross
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Accumulated Depn
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Current Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                        {isSavingAsset ? (
                          <span className="animate-pulse tracking-widest">...</span>
                        ) : (
                          <span>{currencyFormat.format(asset.valuationMethod?.gross || 0)}</span>
                        )}
                      </td>
                      <td className="px-2 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                        {isSavingAsset ? (
                          <span className="animate-pulse tracking-widest">...</span>
                        ) : (
                          <span>{currencyFormat.format(asset.valuationMethod?.accumulatedDepreciation || 0)}</span>
                        )}
                      </td>
                      <td className="px-2 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                        {isSavingAsset ? (
                          <span className="animate-pulse tracking-widest">...</span>
                        ) : (
                          <span>{currencyFormat.format(asset.valuationMethod?.currentValue || 0)}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-2 flex flex-row justify-between bg-white rounded-md shadow-sm">
            <div className="flex-initial">
              <Menu as="div" className="relative inline-block text-left">
                {({ open }): JSX.Element => (
                  <>
                    <div>
                      <Menu.Button className="inline-flex justify-center bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm rounded-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Notes
                        <ChevronDownIcon
                          className={`w-5 h-5 ml-2 -mr-1  ${open && 'rotate-180'} ease-in-out duration-150`}
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    {open && (
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute left-0 w-56 bottom-12 mt-2 origin-bottom-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item>
                            {({ active }): JSX.Element => (
                              <button
                                onClick={(): void => {
                                  setNoteType('General');
                                  setIsModalOpen(true);
                                }}
                                className={`${
                                  active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                General
                                {isGeneralNoteFilled && (
                                  <span className="ml-2 w-2 h-2 text-xs font-semibold bg-indigo-600 rounded-full" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }): JSX.Element => (
                              <button
                                onClick={(): void => {
                                  setNoteType('Location');
                                  setIsModalOpen(true);
                                }}
                                className={`${
                                  active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                <MapPinIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                Location
                                {isLocationNoteFilled && (
                                  <span className="ml-2 w-2 h-2 text-xs font-semibold bg-indigo-600 rounded-full" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }): JSX.Element => (
                              <button
                                onClick={(): void => {
                                  setNoteType('Valuer');
                                  setIsModalOpen(true);
                                }}
                                className={`${
                                  active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                <UserIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                Valuer
                                {isValuerNoteFilled && (
                                  <span className="ml-2 w-2 h-2 text-xs font-semibold bg-indigo-600 rounded-full" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }): JSX.Element => (
                              <button
                                onClick={(): void => {
                                  setNoteType('Valuation Policy');
                                  setIsModalOpen(true);
                                }}
                                className={`${
                                  active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                <ArrowTrendingUpIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                Valuation Policy
                                {isVPolicyNoteFilled && (
                                  <span className="ml-2 w-2 h-2 text-xs font-semibold bg-indigo-600 rounded-full" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }): JSX.Element => (
                              <button
                                onClick={(): void => {
                                  setNoteType('Information Request');
                                  setIsModalOpen(true);
                                }}
                                className={`${
                                  active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                Information Request
                                {isInformationNoteFilled && (
                                  <span className="ml-2 w-2 h-2 text-xs font-semibold bg-indigo-600 rounded-full" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    )}
                  </>
                )}
              </Menu>
              <span
                className="bg-gray-200 text-gray-600
                 inline-block py-0.5 m-2 px-3 text-sm rounded-full"
              >
                {noteCount}
              </span>
            </div>

            <div className="flex-1 flex justify-end space-x-3">
              {watchStatus === 'To Be Inspected' && (
                <div className="flex items-center space-x-2">
                  {/* Heroicon name: solid/lock-open */}
                  <EyeIcon className="h-6 w-6 text-red-500 mx-1" />
                </div>
              )}
              {watchStatus === 'Inspected' && (
                <div className="flex items-center space-x-2">
                  {/* Heroicon name: solid/lock-open */}
                  <EyeIcon className="h-6 w-6 text-yellow-500 mx-1" />
                </div>
              )}
              {watchStatus === 'Complete' && (
                <div className="flex items-center space-x-2">
                  {/* Heroicon name: solid/lock-open */}
                  <EyeIcon className="h-6 w-6 text-indigo-500 mx-1" />
                </div>
              )}
              {watchStatus === 'Not Required' && (
                <div className="flex items-center space-x-2">
                  {/* Heroicon name: solid/lock-open */}
                  <EyeIcon className="h-6 w-6 text-gray-500 mx-1" />
                </div>
              )}
              <button
                type="button"
                onClick={(): void => {
                  setValue('status', 'Inspected');
                  addToast('Asset inspected', ToastType.Message);
                }}
                className="btn btn-secondary"
              >
                Set As Inspected
              </button>
              <button
                type="button"
                onClick={(): void => {
                  setValue('status', 'Complete');
                  addToast('Asset set as complete. Please save to complete edit', ToastType.Message);
                }}
                className="btn btn-secondary"
              >
                Complete
              </button>
              <button
                disabled={isSavingAsset}
                onClick={(): void => {
                  onSubmit();
                }}
                className="btn btn-secondary"
              >
                {isSavingAsset ? (
                  <>
                    <span>Saving</span>
                    <Loader className="animate-spin w-5 h-5 mx-2" />
                  </>
                ) : (
                  <span>Revalue</span>
                )}
              </button>
              <button
                type="button"
                onClick={(): void => {
                  if (!isSubmitted && isDirty) {
                    if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                      return parseInt(id) === 0 ? history('/') : history(-1);
                    }
                  } else {
                    return parseInt(id) === 0 ? history('/') : history(-1);
                  }
                }}
                className="btn btn-primary"
              >
                Exit
              </button>
            </div>
          </div>
        </div>

        {images !== undefined && Object.keys(images).length > 0 && (
          <QuickEditImageModal
            isOpen={isImageViewerModalOpen}
            closeModal={(): void => setIsImageViewerModalOpen(false)}
            image={image}
          />
        )}
        <DeleteConfirmation
          isOpen={isImageDeletModalOpen}
          setOpen={setIsImageDeleteModalOpen}
          deleteAction={deleteImage}
          itemType="Image"
        />
        <NoteModal
          isOpen={isModalOpen}
          closeModal={(): void => setIsModalOpen(false)}
          getValues={getValues}
          setValue={setValue}
          noteType={noteType}
          reset={reset}
        />
      </form>
      <ExportAssetsModal
        isOpen={isExportAssetModaOpen}
        closeModal={setIsExportAssetModalOpen}
        assetIds={new Array(1).fill(asset.id || 0)}
        jobId={0}
        context={asset && (asset?.name || '')}
      />
      <SaveWithErrorsModal
        isOpen={isSaveWithErrorModalOpen}
        setOpen={(): void => setIsSaveWithErrorModalOpen(false)}
        error={errors}
        assetName={getValues('reference') || ''}
      />
      <NewApportionmentModal
        isOpen={isNewApportModalOpen}
        setOpen={setIsNewApportModalOpen}
        newComponents={reapportionedComponents}
        oldComponents={existingComponents}
        setValue={setValue}
      />
      <NewComponentModal
        isOpen={isNewComponentModalOpen}
        componentList={componentList}
        setOpen={setIsNewComponentModalOpen}
        addComponent={addComponent}
      />
      <ContentImagesModal
        isOpen={isContentImagesModalOpen}
        closeModal={(): void => setIsContentImagesModalOpen(false)}
        imageUrls={contentImageUrls}
        setImageToDelete={setImageToDelete}
        setIsImageDeleteModalOpen={setIsImageDeleteModalOpen}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { format, formatRelative } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { GroupSummary } from '../../models/Asset';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'dd/MM/yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token): void => formatRelativeLocale[token],
};

const GroupList: React.FC<{}> = () => {
  const api = useApi();
  const [groups, setGroups] = useState<GroupSummary[]>([] as GroupSummary[]);
  const { groupId } = useAuth();

  useEffect(() => {
    api
      .get(`/api/Group/List?groupId=${groupId}`)
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Groups</h1>
        </div>
      </div>
      <div className="pt-4 mx-4">
        <GroupTable groups={groups} />
        {/* <JobModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} /> */}
      </div>
    </>
  );
};
interface Props {
  groups: GroupSummary[];
}

const GroupTable: React.FC<Props> = ({ groups }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3" />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow rounded-md overflow-hidden border-b border-gray-200 ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map((group) => {
                    return <GroupRow {...group} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const GroupRow: React.FC<GroupSummary> = ({ id, name }) => {
  return (
    <tr className="bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <Link className="btn-link" to={`/groups/${id}`}>
          {name}
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link to={`/groups/${id}/edit`} className="btn-link">
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default GroupList;

import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { ValuationProfile, ValuationProfileScore } from '../models/ValuationProfile';

type Variables = { valuationProfile: ValuationProfile };

export const ValuationProfileEdit: React.FC<{}> = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ValuationProfile>({
    defaultValues: {
      id: 0,
    },
    mode: 'onBlur',
  });

  const { mutate } = useMutation((params: Variables) => api.post('/api/ValuationProfile/Update', params));

  const { data: valuationProfile } = useQuery<ValuationProfile, Error>(['valuationProfile', id], () =>
    api
      .post('/api/ValuationProfile/Get', { id })
      .then(({ data }) =>
        id !== '0' ? data : ({ valuationProfileScores: [] as ValuationProfileScore[] } as ValuationProfile)
      )
  );

  const { fields, append, remove } = useFieldArray({
    name: 'valuationProfileScores',
    control,
    keyName: 'scoreId',
  });

  useEffect(() => {
    if (valuationProfile != undefined) {
      reset({
        ...valuationProfile,
        valuationProfileScores: valuationProfile.valuationProfileScores.map((score) => ({
          ...score,
          rspPctStart: score.rspPctStart * 100,
          rspPctEnd: score.rspPctEnd * 100,
          rulPctStart: score.rulPctStart * 100,
          rulPctEnd: score.rulPctEnd * 100,
        })),
      });
    }
  }, [valuationProfile]);

  const onSubmit = (valuationProfileData: ValuationProfile): void => {
    const vpData: ValuationProfileScore[] = valuationProfileData.valuationProfileScores.map((score) => ({
      ...score,
      rspPctStart: score.rspPctStart / 100,
      rspPctEnd: score.rspPctEnd / 100,
      rulPctStart: score.rulPctStart / 100,
      rulPctEnd: score.rulPctEnd / 100,
    }));

    const formData = { ...valuationProfileData, valuationProfileScores: vpData };
    mutate(
      { valuationProfile: formData },
      {
        onSuccess: ({ data }) => {
          addToast(`${id === '0' ? 'Created' : 'Updated'} Valuation Profile`);
          history(`/valuationprofiles/${data}`);
        },
        onError: (error) => {
          addToast(
            `Unable to ${id === '0' ? 'create new' : 'update'} Valuation Profile ${id !== '0' && `with ID ${id}`}`,
            ToastType.Error
          );
        },
        onSettled: () => {
          queryClient.invalidateQueries(['valuationProfile', id]);
          queryClient.invalidateQueries(['valuationProfilesList']);
        },
      }
    );
  };
  return (
    <div className="flex flex-col m-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Valuation Profile</h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <input type="hidden" {...register('id', { valueAsNumber: true })} />

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    {...register('name')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    aria-describedby="name"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    {...register('description')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    aria-describedby="price-currency"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Consumption Score
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Remaining Service Potential
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Remaining Useful Life
                    </th>
                    <th scope="col" className="relative px-3 py-3 text-right">
                      <button
                        type="button"
                        className="btn-link"
                        onClick={(): void =>
                          append({
                            id: 0,
                            consumptionScoreStart: 0,
                            consumptionScoreEnd: 0,
                            rspPctStart: 0,
                            rspPctEnd: 0,
                            rulPctStart: 0,
                            rulPctEnd: 0,
                          })
                        }
                      >
                        Add
                      </button>

                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="hidden"
                          {...register(`valuationProfileScores.${index}.id`, { valueAsNumber: true })}
                          defaultValue={field.id}
                        />

                        <div className="flex space-x-2">
                          <input
                            key={field.scoreId}
                            {...register(`valuationProfileScores.${index}.consumptionScoreStart`, { valueAsNumber: true })}
                            defaultValue={field.consumptionScoreStart}
                            type="text"
                            className="shadow-sm focus:ring-indigo-500 sm:w-12 md:w-20 focus:border-indigo-500 block w-full text-right sm:text-sm border-gray-300 rounded-md"
                          />
                          <span className="self-center font-medium text-2xl"> - </span>
                          <input
                            {...register(`valuationProfileScores.${index}.consumptionScoreEnd`, { valueAsNumber: true })}
                            defaultValue={field.consumptionScoreEnd}
                            type="text"
                            className="shadow-sm focus:ring-indigo-500 sm:w-12 md:w-20 focus:border-indigo-500 block w-full text-right sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex space-x-2">
                          <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                            <input
                              key={field.scoreId}
                              {...register(`valuationProfileScores.${index}.rspPctStart`, { valueAsNumber: true })}
                              defaultValue={field.rspPctStart}
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-right pr-6 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">%</div>
                          </div>
                          <span className="self-center font-medium text-2xl"> - </span>
                          <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                            <input
                              key={field.scoreId}
                              {...register(`valuationProfileScores.${index}.rspPctEnd`, { valueAsNumber: true })}
                              defaultValue={field.rspPctEnd}
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-right pr-6 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">%</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex space-x-2">
                          <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                            <input
                              key={field.scoreId}
                              {...register(`valuationProfileScores.${index}.rulPctStart`, { valueAsNumber: true })}
                              defaultValue={field.rulPctStart}
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-right pr-6 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">%</div>
                          </div>
                          <span className="self-center font-medium text-2xl"> - </span>
                          <div className="relative rounded-md shadow-sm sm:w-20 md:w-28">
                            <input
                              key={field.scoreId}
                              {...register(`valuationProfileScores.${index}.rulPctEnd`, { valueAsNumber: true })}
                              // `reviews.${index}.note`
                              defaultValue={field.rulPctEnd}
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-right pr-6 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">%</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          className="btn-link"
                          onClick={(): void => {
                            remove(index);
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={(): void => {
                history(`/valuationprofiles/${id}`);
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

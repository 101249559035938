import React, { useState, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Asset, IncomeApproach, IncomeApproachItem, MarketApproach } from '../../models/Asset';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { numberFormat } from '../../Format';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

const valuationApproaches = [
  { value: 'Apportionment Cost', label: 'Apportionment Cost' },
  { value: 'Direct Cost', label: 'Direct Cost' },
  { value: 'Market', label: 'Market' },
  { value: 'Income', label: 'Income' },
  { value: 'Historical Cost', label: 'Historical Cost' },
];

const marketApproaches = [
  { value: 'Item Only', label: 'Item Only' },
  { value: 'Land Only', label: 'Land Only' },
  { value: 'Improvements On Land', label: 'Improvements On Land' },
];

export const ValuationEditor: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: asset = {} as Asset } = useAssetById(parseInt(id));

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,

    formState: { isDirty, isSubmitted },
  } = useForm<Asset>({
    defaultValues: { ...asset, incomeApproach: {} as IncomeApproach, marketApproach: {} as MarketApproach },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'incomeApproach.incomeApproachItems',
    control,
  });

  const updateAsset = useUpdateAsset();

  const watchValuationPolicy = watch('valuationPolicy');
  const watchValuationType = watch('valuationType');
  const watchMarketApproach = watch('marketApproach.type');
  const watchMarketApproachTotal: number = watch('marketApproach.landTotal');
  const watchMarketApproachArea: number = watch('marketApproach.landArea');

  useEffect(() => {
    if (Object.keys(asset).length > 0) {
      reset({
        ...asset,
        marketApproach:
          asset.marketApproach !== undefined
            ? {
                ...(asset.marketApproach || {}),
                improvementsPct: asset.marketApproach?.improvementsPct ? asset.marketApproach?.improvementsPct * 100 : 0,
                indexationPct: asset.marketApproach?.indexationPct ? asset.marketApproach?.indexationPct * 100 : 0,
              }
            : ({} as MarketApproach),
        incomeApproach:
          asset.incomeApproach !== undefined
            ? {
                ...(asset.incomeApproach || {}),
                improvementsPct: asset.incomeApproach?.improvementsPct ? asset.incomeApproach?.improvementsPct * 100 : 0,
                incomeApproachItems: asset.incomeApproach?.incomeApproachItems?.map((item) => ({
                  ...item,
                  capitalisationRatePct: Number((item.capitalisationRatePct * 100).toFixed(2)),
                  vacancyFactorPct: item.vacancyFactorPct * 100,
                })),
              }
            : ({} as IncomeApproach),

        // notes: asset.notes?.filter((note) => note.tag === 'Valuation Policy'),
        optimisationAdjustmentPct: asset.optimisationAdjustmentPct ? asset.optimisationAdjustmentPct * 100 : undefined,
      });
    }
  }, [asset]);

  const onSubmit = handleSubmit((formAsset = {} as Asset) => {
    setIsSaving(true);
    updateAsset.mutate(
      {
        ...asset,
        ...formAsset,
        incomeApproach:
          asset.incomeApproach !== undefined
            ? {
                ...(formAsset.incomeApproach || ({} as IncomeApproach)),
                improvementsPct: formAsset.incomeApproach?.improvementsPct
                  ? formAsset.incomeApproach?.improvementsPct / 100
                  : 0,
                incomeApproachItems: formAsset.incomeApproach?.incomeApproachItems?.map((item) => ({
                  ...item,
                  capitalisationRatePct: item.capitalisationRatePct / 100,
                  vacancyFactorPct: item.vacancyFactorPct / 100,
                })),
              }
            : ({} as IncomeApproach),
        marketApproach:
          asset.marketApproach !== undefined
            ? {
                ...(formAsset.marketApproach || ({} as MarketApproach)),
                improvementsPct: formAsset.marketApproach?.improvementsPct
                  ? formAsset.marketApproach?.improvementsPct / 100
                  : 0,
                indexationPct: formAsset.marketApproach?.indexationPct ? formAsset.marketApproach?.indexationPct / 100 : 0,
              }
            : ({} as MarketApproach),
        optimisationAdjustmentPct: formAsset.optimisationAdjustmentPct
          ? formAsset.optimisationAdjustmentPct / 100
          : undefined,
      },
      { onSettled: () => setIsSaving(false) }
    );
  });

  const isMarket = watchValuationType === 'Market';
  const isIncome = watchValuationType === 'Income';

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Valuation Information</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="bg-white space-y-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Valuer
                </label>
                <input
                  type="text"
                  {...register('valuer')}
                  defaultValue=""
                  className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <fieldset className="col-span-6 sm:col-span-6">
                <div>
                  <legend className="text-base text-sm font-medium text-gray-700">Valuation Policy</legend>
                </div>
                <div className="mt-4 space-y-4">
                  <div className="sm:col-span-3">
                    <div className="mt-4 flex">
                      <div className="flex mr-4">
                        <input
                          {...register('valuationPolicy')}
                          type="radio"
                          value="Above Revaluation Threshold"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                          Above Revaluation Threshold
                        </label>
                      </div>
                      <div className="flex mr-4">
                        <input
                          {...register('valuationPolicy')}
                          type="radio"
                          value="Not to be Valued"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                          Not To Be Valued
                        </label>
                      </div>
                      <div className="flex mr-4">
                        <input
                          {...register('valuationPolicy')}
                          type="radio"
                          value="None"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                          None
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {watchValuationPolicy === 'Above Revaluation Threshold' && (
                <fieldset className="col-span-6 sm:col-span-3">
                  <div>
                    <legend className="text-sm font-medium text-gray-900">Valuation Method</legend>
                  </div>
                  <div className="mt-2 space-y-4">
                    <select
                      {...register('valuationType')}
                      className="mt-1 block w-full lg:w-1/3 border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      {valuationApproaches.map((valuationApproach) => (
                        <option key={valuationApproach.value} value={valuationApproach.value}>
                          {valuationApproach.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </fieldset>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMarket && (
        <div className="bg-white shadow mt-4 px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Market Valuation</h3>
              {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                    Market Approach
                  </label>
                  <select
                    {...register('marketApproach.type')}
                    className="mt-1 block w-full lg:w-1/3 border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {marketApproaches.map((marketApproach) => (
                      <option key={marketApproach.value} value={marketApproach.value}>
                        {marketApproach.label}
                      </option>
                    ))}
                  </select>
                </div>
                {watchMarketApproach !== 'Land Only' && (
                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Property Value
                    </label>
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm lg:w-1/4">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          {...register('marketApproach.propertyValue', { valueAsNumber: true })}
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={0}
                          step="any"
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {watchMarketApproach !== 'Item Only' && (
                  <>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                        Land Total
                      </label>
                      <div>
                        <div className="mt-1 relative rounded-md shadow-sm lg:w-1/4">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            {...register('marketApproach.landTotal', { valueAsNumber: true })}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            defaultValue={0}
                            aria-describedby="price-currency"
                            step="any"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                        Land Area
                      </label>

                      <div className="mt-1 relative rounded-md shadow-sm lg:w-1/4">
                        <input
                          {...register('marketApproach.landArea', { valueAsNumber: true })}
                          type="number"
                          className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md block w-full pl-3 pr-12 sm:text-sm border-gray-300"
                          defaultValue={0}
                          step="any"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Unit
                          </label>
                          <select
                            {...register('marketApproach.landAreaUnit')}
                            className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm"
                          >
                            <option>sqm</option>
                            <option>ha</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                        Land Rate
                      </label>
                      <div>
                        <div className="mt-1 relative rounded-md shadow-sm lg:w-1/5">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            disabled
                            type="text"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            aria-describedby="price-currency"
                            value={numberFormat.format(watchMarketApproachTotal / watchMarketApproachArea)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {watchMarketApproach === 'Improvements On Land' && (
                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Improvements Pct
                    </label>
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm lg:w-24">
                        <input
                          type="number"
                          {...register('marketApproach.improvementsPct', { valueAsNumber: true })}
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={0}
                          step="any"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          {/* Heroicon name: solid/question-mark-circle */}
                          <span className="text-gray-500 sm:text-sm">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                    Indexation Pct
                  </label>
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-24">
                      <input
                        type="number"
                        step="any"
                        {...register('marketApproach.indexationPct', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                        defaultValue={0}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isIncome && (
        <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Income Approach</h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6 bg-white">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-1 sm:col-span-1">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Land Rate
                    </label>
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          {...register('incomeApproach.landRate', { valueAsNumber: true })}
                          className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pl-7 pr-7 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={0}
                          step="any"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Land Area
                    </label>
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          {...register('incomeApproach.landArea', { valueAsNumber: true })}
                          className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pl-7 pr-7 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={0}
                          step="any"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Improvements Pct
                    </label>
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          {...register('incomeApproach.improvementsPct', { valueAsNumber: true })}
                          className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pr-7 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={0}
                          step="any"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="py-2 pt-5 align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Inflow
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Outflow
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Vacancy Factor
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Leased Up Months
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Cap Rate
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Capitalisation Adjustment
                      </th>
 
                      <th scope="col" className="whitespace-nowrap px-1 py-2 text-right text-sm font-medium">
                        <button
                          type="button"
                          className="text-indigo-600 hover:text-indigo-900 whitespace-nowrap pr-4 text-sm  font-medium"
                          onClick={(): void =>
                            append({
                              id: 0,
                              itemName: '',
                              inflow: 0,
                              outflow: 0,
                              vacancyFactorPct: 0,
                              capitalisationRatePct: 0,
                              capitalAdjustment: 0,
                              leasedUpMonths: 0,
                            } as IncomeApproachItem)
                          }
                        >
                          <PlusIcon className="h-4 w-4" />
                        </button>

                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((field, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/4">
                          <div className="flex space-x-2">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.itemName` as `incomeApproach.incomeApproachItems.0.itemName`
                              )}
                              defaultValue={field.itemName}
                              type="text"
                              className="block w-full rounded-md border-gray-300 text-left focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/6">
                          <div className="flex space-x-2">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.inflow` as `incomeApproach.incomeApproachItems.0.inflow`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.inflow}
                              step="any"
                              type="number"
                              className="block w-full rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/6">
                          <div className="flex space-x-2">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.outflow` as `incomeApproach.incomeApproachItems.0.outflow`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.outflow}
                              step="any"
                              type="number"
                              className="block w-full rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                          <div className="flex space-x-2 relative rounded-md shadow-sm ">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.vacancyFactorPct` as `incomeApproach.incomeApproachItems.0.vacancyFactorPct`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.vacancyFactorPct}
                              type="number"
                              className="block w-full rounded-md border-gray-300 pl-1 pr-7 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">%</div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                          <div className="flex space-x-2 ">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.leasedUpMonths` as `incomeApproach.incomeApproachItems.0.leasedUpMonths`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.leasedUpMonths}
                              step="any"
                              type="number"
                              className="block w-full rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                          <div className="flex space-x-2 relative rounded-md shadow-sm ">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.capitalisationRatePct` as `incomeApproach.incomeApproachItems.0.capitalisationRatePct`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.capitalisationRatePct}
                              step="any"
                              type="number"
                              className="block w-full rounded-md border-gray-300 pl-1 pr-7 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">%</div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                          <div className="flex space-x-2">
                            <input
                              {...register(
                                `incomeApproach.incomeApproachItems.${index}.capitalAdjustment` as `incomeApproach.incomeApproachItems.0.capitalAdjustment`,
                                { valueAsNumber: true }
                              )}
                              defaultValue={field.capitalAdjustment}
                              step="any"
                              type="number"
                              className="block w-full rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-1 py-2 text-right text-sm font-medium">
                          <button
                            className="pr-3"
                            type="button"
                            onClick={(): void => {
                              remove(index);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-gray-400" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              if (!isSubmitted && isDirty) {
                if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                  return parseInt(id) === 0 ? history('/') : history(-1);
                }
              } else {
                return parseInt(id) === 0 ? history('/') : history(-1);
              }
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button disabled={isSaving} type="submit" className="btn btn-primary">
            {isSaving ? (
              <>
                <span>Saving</span>
                <Loader className="animate-spin w-5 h-5 mx-2" />
              </>
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { BasicTable } from '../../components/BasicTable';
import { currencyFormat, numberFormat, percentFormat } from '../../Format';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset, IncomeApproachItem } from '../../models/Asset';

const IncomeApproachDetail: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: {
      incomeApproach: {
        landRate = NaN,
        landArea = NaN,
        landValue = NaN,
        improvementsValue = NaN,
        improvementsPct = NaN,
        marketValue = NaN,
        incomeApproachItems = [],
      } = {},
    } = {} as Asset,
  } = useAssetById(parseInt(id));

  const columns: Column<IncomeApproachItem>[] = React.useMemo(
    () => [
      {
        Header: 'Item Name',
        accessor: 'itemName',
        className: 'px-1 py-2 text-sm leading-5',
      },
      {
        Header: 'inflow',
        accessor: 'inflow',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'outflow',
        accessor: 'outflow',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'Vacancy',
        accessor: 'vacancyFactorPct',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Leased Up Months',
        accessor: 'leasedUpMonths',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${numberFormat.format(value)} months`,
      },
      {
        Header: 'Cap Rate',
        accessor: 'capitalisationRatePct',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${percentFormat.format(value)}`,
      },
      {
        Header: 'Capital Adj',
        accessor: 'capitalAdjustment',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'valuation',
        accessor: 'valuation',
        headerClassName: 'text-right',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5 text-right',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: '',
        accessor: 'id',
        className: 'px-1 py-2 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): React.ReactElement => (
          <Link to={`/assets/${id}/edit/Valuation`} className="btn-link">
            Edit
          </Link>
        ),
      },
    ],
    []
  );
  return (
    <div className="bg-white p-4">
      <div className="flex flex-col">
        <div className="">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <dl className="my-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-base font-normal text-gray-900">Improvements Value</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className=" text-2xl font-semibold text-indigo-600">
                      {improvementsValue && currencyFormat.format(improvementsValue)}
                    </div>
                    <div className="text-sm font-medium text-gray-500">{currencyFormat.format(landValue)} Land Value</div>
                  </dd>
                </div>
              </div>
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-base font-normal text-gray-900">Improvements Pct</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className=" text-2xl font-semibold text-indigo-600">
                      {improvementsPct && percentFormat.format(improvementsPct)}
                    </div>
                  </dd>
                </div>
              </div>

              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-base font-normal text-gray-900">Market Value</dt>
                  <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                    <div className=" text-2xl font-semibold text-indigo-600">
                      {marketValue && currencyFormat.format(marketValue)}
                    </div>
                  </dd>
                </div>
              </div>
            </dl>
            <BasicTable columns={columns} data={incomeApproachItems} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeApproachDetail;

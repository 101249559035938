import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import ImageModal from './ImageModal';
import { useAuth } from '../../contexts/AuthContext';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ImageDisplay, ImagesImport } from '../../models/Image';
import { useApi } from '../../contexts/ApiContext';
import { bulkUploadImages, convertToBase64, downloadHandler } from '../../Helper';

const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

const Images: React.FC<{}> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [image, setImage] = useState<number>(0);
  const [imageDate, setImageDate] = useState<string>('');
  const { id = '' } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const { data: { images = [] as ImageDisplay[], reference } = {} as Asset, refetch, isRefetching } = useAssetById(parseInt(id));
  const cameraRef = useRef<HTMLInputElement>(null);
  const api = useApi();

  const handleFileUpload = (e) => {
    const selectedFiles: File[] = Array.from(e.target.files as FileList);
    bulkUploadImages(selectedFiles).then((result) => {
      const imageImport: ImagesImport = {
        data: result,
        assetId: id,
      };
      api.post(`/api/Asset/ImportImages`, { images: imageImport.data, assetId: imageImport.assetId }).then(
        ({ data }) => {
          addToast('Image(s) uploaded');
        },
        (error) => {
          addToast('API Fetch Failed', ToastType.Error);
        }
      );
    });
  };

  const exportAllImages = (): void => {
    api.post('/api/Asset/ExportAssetImages', { assetId: reference }, { responseType: 'blob' }).then(
      ({ data }) => {
        downloadHandler(data, `Images for ${reference}.zip`);
      },
      (error) => {
        addToast(`Image Download Failed for ${reference}`, ToastType.Error);
      }
    );
  };

  return (
    <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 m-4 max-h-112 overflow-y-auto px-4">
        {images.map((image, index) => (
          <li key={image.original} className="relative mt-1">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img src={image.thumbnail} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
              <button
                onClick={(): void => {
                  setImage(index);
                  setImageDate(image.date || '');
                  setIsModalOpen(true);
                }}
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">View details for</span>
              </button>
            </div>
            <div className="flex flex-row justify-between">
              <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{image.date}</p>
              <a
                href={image.original}
                className="mt-1 text-indigo-600 hover:text-indigo-900"
                rel="noreferrer"
                target="_blank"
              >
                <CloudArrowDownIcon className="h-6 w-6" />
              </a>
            </div>
          </li>
        ))}
        {/* TODO: Image Upload */}
        <li className="relative pb-7 mt-1">
          <button
            type="button"
            className="relative block h-48 min-h-full w-full text-center  rounded-lg border-2 border-dashed border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
            onClick={(): void => cameraRef.current?.click()}
          >
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              ref={cameraRef}
              id="file-upload"
              className="hidden"
              name="images"
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              multiple
              onChange={(e): void => handleFileUpload(e)}
            />
            <div className="flex justify-center text-xs text-gray-600 sm:text-sm">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 sm:text-sm">PNG, JPG, GIF up to 10MB</p>
            {/* <button
                    type="button"
                    className="mt-2 bg-indigo-600 border rounded-md border-transparent shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700"
                    
                  >
                    Add images
                  </button> */}
          </button>
        </li>
        {images !== undefined && Object.keys(images).length > 0 && (
          <ImageModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} images={images} index={image} />
        )}
      </ul>
      {images !== undefined && Object.keys(images).length > 0 && (
        <div className="flex py-5 px-4 space-x-4 items-center justify-end">
          <button onClick={() => refetch()} className="btn btn-link">
            <ArrowPathIcon className={`flex-shrink-0 mr-1.5 h-5 w-5 font-black ${isRefetching && 'animate-spin'}`} />
          </button>
          <button onClick={(): void => exportAllImages()} className="btn btn-secondary">
            Export Asset Images
          </button>
        </div>
      )}
    </>
  );
};

export default Images;

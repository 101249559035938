import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { currencyFormat, twoDecimalsPercentFormat } from '../Format';
import { Asset } from '../models/Asset';
import { ComponentReplacementCost } from '../models/ReplacementCostSummary';

type ComponentReplacementCostEditProps = {
  index: number;
  componentIndex: number;
  control: Control<Asset, object>;
  nameError?: string | undefined;
  lengthError?: string | undefined;
  widthError?: string | undefined;
  quantityError?: string | undefined;
  watch: UseFormWatch<Asset>;
  register: UseFormRegister<Asset>;
  // defaultValues: Asset;
  remove: (index?: number | number[] | undefined) => void;
  field: FieldArrayWithId<Asset, `components.${number}.replacementCosts`, 'id'>;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
};

export const ComponentReplacementCostEdit: React.FC<ComponentReplacementCostEditProps> = ({
  index,
  componentIndex,
  nameError,
  lengthError,
  widthError,
  quantityError,
  register,
  watch,
  field,
  remove,
  getValues,
  setValue,
  control,
}) => {
  const onChangeWidth = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc: ComponentReplacementCost = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    const area = rc.length * +e.target.value;
    const total = area * rc.quantity;
    const gross = rc.specifiedRate
      ? rc.specifiedRate * total
      : rc.adjustedRate * total * (1 + rc.localityFactorPct / 100) * (1 + rc.indexationPct);
    setValue(`components.${componentIndex}.replacementCosts.${index}.area`, area);
    setValue(`components.${componentIndex}.replacementCosts.${index}.total`, total);
    setValue(`components.${componentIndex}.replacementCosts.${index}.gross`, gross);
  };

  const onChangeLength = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc: ComponentReplacementCost = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    const area = rc.width * +e.target.value;
    const total = area * rc.quantity;
    const gross = rc.specifiedRate
      ? rc.specifiedRate * total
      : rc.adjustedRate * total * (1 + rc.localityFactorPct / 100) * (1 + rc.indexationPct);
    setValue(`components.${componentIndex}.replacementCosts.${index}.area`, area);
    setValue(`components.${componentIndex}.replacementCosts.${index}.total`, total);
    setValue(`components.${componentIndex}.gross`, gross);
  };

  const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    const total = rc.length * rc.width * +e.target.value;
    const gross = rc.specifiedRate
      ? rc.specifiedRate * total
      : rc.adjustedRate * total * (1 + rc.localityFactorPct / 100) * (1 + rc.indexationPct);
    setValue(`components.${componentIndex}.replacementCosts.${index}.total`, total);
    setValue(`components.${componentIndex}.replacementCosts.${index}.gross`, gross);
  };

  const onChangeAdjusted = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc: ComponentReplacementCost = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    const calculatedAdjustment = (+e.target.value / rc.unitRate - 1) * 100;
    setValue(
      `components.${componentIndex}.replacementCosts.${index}.adjustmentPct`,
      Number.isFinite(calculatedAdjustment) ? calculatedAdjustment : 0
    );
    if (!rc.specifiedRate) {
      const gross = (rc.total || 1) * +e.target.value * (1 + rc.localityFactorPct / 100) * (1 + rc.indexationPct);
      setValue(`components.${componentIndex}.replacementCosts.${index}.gross`, gross);
    }
  };

  const onChangeSpecified = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc: ComponentReplacementCost = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    const gross = (rc.total || 1) * +e.target.value * (1 + rc.indexationPct);
    setValue(`components.${componentIndex}.replacementCosts.${index}.gross`, gross);
  };

  const onChangeLocalityFactor = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rc: ComponentReplacementCost = getValues(`components.${componentIndex}.replacementCosts.${index}`);
    if (!rc.specifiedRate) {
      const gross =
        (Number.isNaN(rc.adjustedRate) ? rc.unitRate : rc.adjustedRate) *
        (rc.total || 1) *
        (1 + +e.target.value / 100) *
        (1 + rc.indexationPct);
      setValue(`components.${componentIndex}.replacementCosts.${index}.gross`, gross);
    }
  };

  const watchGross = watch(`components.${componentIndex}.replacementCosts.${index}.gross`);
  const areaOptions: string[] = ['sqm', 'lm', 'cum', 'km', 'No.'];

  return (
    <tr key={field.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-24">
        <input
          type="hidden"
          {...register(`components.${componentIndex}.replacementCosts.${index}.id` as const, { valueAsNumber: true })}
        />

        <div className="flex space-x-2 ">
          <input
            // key={field.id}
            {...register(`components.${componentIndex}.replacementCosts.${index}.name` as const)}
            defaultValue={field.name}
            type="text"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        {/* {nameError && <ErrorMessage>Name is required</ErrorMessage>} */}
      </td>
      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-16">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.length` as const, {
            valueAsNumber: true,
            onChange: (e): void => {
              onChangeLength(e);
            },
          })}
          defaultValue={field.length}
          type="number"
          step="any"
          onFocus={(e): void => e.target.select()}
          // onChange={(e): void => onChangeLength(e)}
          className="block w-full rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {/* {lengthError && <ErrorMessage>Length is required</ErrorMessage>} */}
      </td>
      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-16">
        <input
          // key={field.id}
          {...register(`components.${componentIndex}.replacementCosts.${index}.width` as const, {
            valueAsNumber: true,
            onChange: (e): void => {
              onChangeWidth(e);
            },
          })}
          defaultValue={field.width}
          type="number"
          step="any"
          onFocus={(e): void => e.target.select()}
          className="block w-full rounded-md  border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          // onChange={(e): void => onChangeWidth(e)}
        />
        {/* {widthError && <ErrorMessage>Width is required</ErrorMessage>} */}
      </td>
      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-12">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.area` as const, { valueAsNumber: true })}
          defaultValue={field.area}
          type="text"
          disabled
          className="mx-0 w-full rounded-md border-none bg-transparent px-0 text-right"
        />
      </td>

      <td className="sm:w-9 whitespace-nowrap px-1 py-2 text-sm text-gray-500">
        <div className="flex space-x-2">
          <select
            // key={field.id}
            {...register(`components.${componentIndex}.replacementCosts.${index}.areaType` as const)}
            className="block w-full w-full rounded-md border-gray-300 bg-none text-left text-right shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:px-1 sm:text-sm lg:px-2"
          >
            {areaOptions.map((score) => (
              <option key={score} value={score}>
                {score}
              </option>
            ))}
          </select>
        </div>
      </td>

      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-12">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.quantity` as const, {
            valueAsNumber: true,
            onChange: (e): void => {
              onChangeQuantity(e);
            },
          })}
          defaultValue={field.quantity}
          type="number"
          onFocus={(e): void => e.target.select()}
          // onChange={(e): void => onChangeQuantity(e)}
          className="block w-full rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {/* {quantityError && <ErrorMessage>Quantity is required</ErrorMessage>} */}
      </td>

      <td className="whitespace-nowrap px-1 py-2 text-right text-sm text-gray-500 sm:w-12">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.total` as const, { valueAsNumber: true })}
          defaultValue={field.total}
          type="text"
          disabled
          className="mx-0 w-full rounded-md border-none bg-transparent px-0 text-right"
        />
      </td>

      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-24 lg:w-24">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.specifiedRate` as const, {
            valueAsNumber: true,
            onChange: (e): void => {
              onChangeSpecified(e);
            },
          })}
          defaultValue={field.specifiedRate}
          type="number"
          step="any"
          onFocus={(e): void => e.target.select()}
          // onChange={(e): void => onChangeSpecified(e)}
          className="block w-full rounded-md border-gray-300 pr-2 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </td>

      <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-24 lg:w-24">
        <input
          {...register(`components.${componentIndex}.replacementCosts.${index}.adjustedRate` as const, {
            valueAsNumber: true,
            onChange: (e): void => {
              onChangeAdjusted(e);
            },
          })}
          defaultValue={field.adjustedRate}
          type="number"
          step="any"
          onFocus={(e): void => e.target.select()}
          // onChange={(e): void => onChangeAdjusted(e)}
          className="block w-full rounded-md border-gray-300 pr-2 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </td>

      <td className="whitespace-nowrap px-1 py-2 text-right text-sm text-gray-500 sm:w-16 lg:w-20">
        <div className="flex sm:space-x-2 lg:space-x-2">
          <div className="relative rounded-md shadow-sm ">
            <input
              {...register(`components.${componentIndex}.replacementCosts.${index}.localityFactorPct` as const, {
                valueAsNumber: true,
                onChange: (e): void => {
                  onChangeLocalityFactor(e);
                },
              })}
              defaultValue={field.localityFactorPct}
              type="number"
              step="any"
              // onChange={(e): void => onChangeLocalityFactor(e)}
              className="block w-full rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:pr-4 sm:text-sm lg:pr-5"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-1 lg:pr-2">%</div>
          </div>
        </div>
      </td>

      <td className="space-x-4 whitespace-nowrap px-1 py-2 text-right text-sm text-gray-500 sm:w-16 ">
        <div className="space-x-4">
          {getValues(`components.${componentIndex}.replacementCosts.${index}.indexationPct`) > 0 && (
            <span className="text-xs text-gray-400">
              (
              {twoDecimalsPercentFormat.format(
                getValues(`components.${componentIndex}.replacementCosts.${index}.indexationPct`)
              )}{' '}
              idx)
            </span>
          )}{' '}
          {currencyFormat.format(watchGross)}
        </div>
      </td>

      {/* <td className="whitespace-nowrap px-1 py-2 text-right text-sm text-gray-500 sm:w-20">
        {currencyFormat.format(watchGross)}
      </td> */}

      <td className="whitespace-nowrap px-3 py-2 text-right text-sm font-medium sm:w-12">
        <button
          type="button"
          onClick={(): void => {
            remove(index);
          }}
        >
          <TrashIcon className="h-6 w-6 text-gray-400" />
        </button>
      </td>
    </tr>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ReactComponent as Photo } from '../../icons/PhotoOutline.svg';
import { Client } from '../../models/Client';
import { Group } from '../../models/Group';
import { useApi } from '../../contexts/ApiContext';
import { removeTimezoneOffset } from '../../Helper';

const ClientEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id, subscriptionId, groupId } = useParams<{ id: string; subscriptionId: string; groupId: string }>();
  const history = useNavigate();

  const ClientSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .required('Name for the client is required')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
    displayName: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .required('Display Name for the client is required')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
    start: yup.date().when('groupId', {
      is: (field) => groupId !== undefined,
      then: (schema) => schema.required('Start date is required'),
    }),
    expiry: yup.date().when('groupId', {
      is: (field) => groupId !== undefined,
      then: (schema) => schema.required('Expiry date is required'),
    }),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,

    formState: { errors },
  } = useForm<Client>({
    defaultValues: {
      id: parseInt(id),
      ...(Number(subscriptionId) > 0 && { subscriptionId: parseInt(subscriptionId) }),
      ...(Number(groupId) > 0 && { groupId: parseInt(groupId) }),
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(ClientSchema),
  });

  const [isGroup, setIsGroup] = useState<boolean>(groupId !== undefined);
  useEffect(() => {
    if (parseInt(id) > 0) {
      api
        .get(`/api/Client/Get?id=${id}`)
        .then(({ data }) => {
          reset({
            ...data,
            start: data?.start ? new Date(data.start) : undefined,
            expiry: data?.expiry ? new Date(data.expiry) : undefined,
          });
          setIsGroup(isGroup || data.groupId !== null);
        })
        .catch((error) => {
          addToast(`Unable to get client with ID ${id}`, ToastType.Error);
        });
    }
  }, []);

  const onSubmit = (data: Client): void => {
    console.log('data', data)
    const client = { ...data };
    api
      .post('/api/Client/Update', { client })
      .then(({ data }) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} client ${data.displayName}`);
        history(`/clients/${data.id}`);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} client ${parseInt(id) > 0 && `with ID ${id}`}`,
          ToastType.Error
        );
      });
  };
  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md sm:overflow-hidden space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Client Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <input type="hidden" {...register('id', { valueAsNumber: true })} />
            <input type="hidden" {...register('groupId', { valueAsNumber: true })} />
            <input type="hidden" {...register('subscriptionId', { valueAsNumber: true })} />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('name')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
              {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Display Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('displayName')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            {isGroup && (
              <>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                  <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start Date
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      name="start"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="d MMM yyyy"
                          className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors?.start && <ErrorMessage>{errors.start.message}</ErrorMessage>}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                  <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Expiry Date
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      name="expiry"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="d MMM yyyy"
                          className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors?.expiry && <ErrorMessage>{errors.expiry.message}</ErrorMessage>}
                  </div>
                </div>
              </>
            )}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Currency
              </label>
              <select
                {...register('currency')}
                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              >
                <option>AUD</option>
                <option>USD</option>
                <option>GBP</option>
                <option>EUR</option>
                <option>CAD</option>
                <option>NZD</option>
                <option>FJD</option>
                <option>VUV</option>
                <option>UGX</option>
                <option>SBD</option>
                <option>WST</option>
                <option>PGK</option>
              </select>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Leases Enabled
              </label>
              <input
                {...register('isLeasesEnabled')}
                type="checkbox"
                className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 rounded border-gray-300"
              />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Maintenance Plans Enabled
              </label>
              <input
                {...register('isMaintenancePlansEnabled')}
                type="checkbox"
                className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 rounded border-gray-300"
              />
            </div>
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.name')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Position
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.position')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email address
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.email')}
                  type="email"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Phone Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('contact.phone')}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 1
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine1')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="street_address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address Line 2
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.addressLine2')}
                  type="text"
                  autoComplete="street-address"
                  className="block max-w-lg w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Suburb
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.suburb')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                City
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.city')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                State / Province
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('contact.address.state')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 lg:grid-cols-6 sm:gap-4 sm:items-start">
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 col-span-1 lg:col-span-2"
              >
                Postcode / ZIP
              </label>
              <div className="mt-1 sm:mt-0 col-span-1">
                <input
                  {...register('contact.address.postCode')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 lg:grid-cols-6 sm:gap-4 sm:items-start">
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 col-span-1 lg:col-span-2"
              >
                Country
              </label>
              <div className="mt-1 sm:mt-0 col-span-1">
                <input
                  {...register('contact.address.country')}
                  type="text"
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/clients/${id}`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClientEdit;

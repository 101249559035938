import React from 'react';
import PropTypes from 'prop-types';

import Toast from './Toast';
import { useToast } from '../contexts/ToastContext';

const ToastContainer: React.FC<any> = ({ children }) => {
  const { toasts } = useToast();

  if (toasts && toasts.length === 0) {
    return children;
  }

  return (
    <>
      {children}
      <div className="fixed inset-0 flex flex-col z-20 px-4 py-6 pointer-events-none sm:p-6 sm:justify-end sm:items-center">
        {toasts &&
          toasts.map(({ children, id, type }) => (
            <Toast key={id} id={id} type={type}>
              {children}
            </Toast>
          ))}
      </div>
    </>
  );
};
ToastContainer.propTypes = {
  children: PropTypes.node,
};

export default ToastContainer;

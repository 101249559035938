import React, { useState, useContext, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';

export enum ToastType {
  Warn,
  Error,
  Message,
}

export interface Toast {
  id: number;
  type?: ToastType;
  duration?: number;
  children: React.ReactNode;
}

interface ToastContext {
  addToast: (content: string, type?: ToastType) => void;
  removeToast: (id: number) => void;
  toasts: Toast[];
}

const ToastContext = createContext<ToastContext>({} as ToastContext);

let toastCount = 0;
export const ToastProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (content, type) => {
      const id = toastCount++;
      const newToast = { children: content, id, type };
      setToasts((prevToasts) => [...prevToasts, newToast]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    },
    [setToasts]
  );

  return <ToastContext.Provider value={{ addToast, removeToast, toasts }}>{children}</ToastContext.Provider>;
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export const useToast = (): ToastContext => useContext(ToastContext);

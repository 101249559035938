import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ReactDatePicker from 'react-datepicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';
import { ContactType, ExternalPropertyManager, Landlord, LeaseContact } from '../models/Lease';
import { removeTimezoneOffset } from '../Helper';

type Contact = Landlord | ExternalPropertyManager | LeaseContact;

interface Props {
  contact: Contact;
  isOpen: boolean;
  closeModal(): void;
  url: string;
  assetId: number;
}

export const LeaseContactModal: React.FC<Props> = ({ contact, isOpen, closeModal, assetId }) => {
  const api = useApi();
  const cancelButtonRef = useRef(null);
  const [contactTypeDisplay, setContactTypeDisplay] = useState<string>('');
  const isLandLord = (props: Contact): props is Landlord =>
    // check if the specified property is in the given object
    props.contactType === ContactType.Landlord;
  const isExternalPropertyManager = (props: Contact): props is ExternalPropertyManager =>
    // check if the specified property is in the given object
    props.contactType === ContactType.ExternalPropertyManager;

  const { register, handleSubmit, control, reset } = useForm<Landlord | ExternalPropertyManager | LeaseContact>({
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (contact != undefined) {
      reset({
        ...contact,
        ...(isExternalPropertyManager(contact) && {
          reviewDate: contact.reviewDate ? new Date(contact.reviewDate) : undefined,
          appointedDate: contact.appointedDate ? new Date(contact.appointedDate) : undefined,
        }),
      });
      if (contact.contactType === ContactType.ExternalPropertyManager) {
        setContactTypeDisplay('External Property Manager');
      } else if (contact.contactType === ContactType.InternalPropertyManager) {
        setContactTypeDisplay('Internal Property Manager');
      } else if (contact.contactType === ContactType.Landlord) {
        setContactTypeDisplay('Landlord');
      }
    }
  }, [contact]);

  const onSubmit = (formData: LeaseContact | Landlord | ExternalPropertyManager): void => {
    if (isLandLord(contact)) {
      api
        .post('api/Lease/UpdateLandlord', { Landlord: formData })
        .then(({ data }) => {
          api.post('api/Lease/AddLandlord', { landlordId: data, assetId }).then(() => {
            closeModal();
          });
        })
        .catch((error) => {});
    } else if (isExternalPropertyManager(contact)) {
      const externalPropertyManager = {
        ...formData,
      } as ExternalPropertyManager;
      api
        .post('api/Lease/UpdateExternalPropertyManager', {
          ExternalPropertyManager: {
            ...formData,
            reviewDate:
              externalPropertyManager.reviewDate !== undefined && externalPropertyManager.reviewDate !== null
                ? removeTimezoneOffset(externalPropertyManager.reviewDate)
                : undefined,
            appointedDate:
              externalPropertyManager.appointedDate !== undefined && externalPropertyManager.appointedDate !== null
                ? removeTimezoneOffset(externalPropertyManager.appointedDate)
                : undefined,
          },
        })
        .then(({ data }) => {
          api.post('api/Lease/AddExternalPropertyManager', { externalPropertyManagerId: data, assetId }).then(() => {
            closeModal();
          });
        })
        .catch((error) => {});
    } else {
      api
        .post('api/Lease/UpdateInternalPropertyManager', { InternalPropertyManager: formData })
        .then(({ data }) => {
          api.post('api/Lease/AddInternalPropertyManager', { internalPropertyManagerId: data, assetId }).then(() => {
            closeModal();
          });
        })
        .catch((error) => {});
    }
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg lg:max-w-4xl sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                <div className="space-y-8 sm:space-y-5">
                  <div className="space-y-4">
                    <input type="hidden" {...register('id')} />
                    <input type="hidden" {...register('contactType')} />
                    <div>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {contact.id === 0 ? 'Add' : 'Update'} {contactTypeDisplay}
                      </h2>
                    </div>

                    <div className="md:mt-0 md:col-span-2">
                      <div className="bg-white space-y-6">
                        <div className="grid grid-cols-6 gap-6">
                          {/* <div className="sm:col-span-6">
                            { <select
                              disabled={contact.id !== 0}
                              name="type"
                              ref={register}
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              defaultValue="Landlord"
                            >
                              <option label="Tenant" key={ContactType.Tenant} />
                              <option defaultChecked label="Landlord" key={ContactType.Landlord} />
                              <option label="External Property Manager" key={ContactType.ExternalPropertyManager} />
                              <option label="Property Manager" key={ContactType.PropertyManager} />

                            </select> }
                          </div> */}
                          <div className="sm:col-span-3">
                            <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              {...register('name')}
                              id="type"
                              defaultValue={contact?.name}
                              className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <input
                              type="text"
                              {...register('email')}
                              id="type"
                              defaultValue={contact?.email}
                              className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                              Phone
                            </label>
                            <input
                              type="text"
                              {...register('phone')}
                              id="type"
                              defaultValue={contact?.phone}
                              className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                          </div>
                          {isLandLord(contact) && (
                            <>
                              <div className="sm:col-span-3">
                                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                  Trading Name
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    {...register('tradingName')}
                                    id="type"
                                    defaultValue={contact?.tradingName}
                                    className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                  Head Lease Name
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    {...register('headLeaseName')}
                                    id="type"
                                    defaultValue={contact?.tradingName}
                                    className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                              </div>
                            </>
                          )}
                          {isExternalPropertyManager(contact) && (
                            <>
                              <div className="sm:col-span-3">
                                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                  Fee
                                </label>
                                <input
                                  type="text"
                                  {...register('fee')}
                                  defaultValue={contact?.fee}
                                  className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                              </div>
                              <div className="sm:col-span-3">
                                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                  Review Date
                                </label>
                                <Controller
                                  name="reviewDate"
                                  control={control}
                                  render={({ field: { onChange, onBlur, value, name, ref }}) => (
                                    <ReactDatePicker
                                      dateFormat="d MMM yyyy"
                                      selected={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      className="block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                      placeholderText="Select date"
                                    />
                                  )}
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                                  Appointed Date
                                </label>
                                <Controller
                                  control={control}
                                  name="appointedDate"
                                  render={({ field: { onChange, onBlur, value, name, ref }}) => (
                                    <ReactDatePicker
                                      dateFormat="d MMM yyyy"
                                      selected={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      className="block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                      placeholderText="Select date"
                                    />
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end space-x-3">
                  <button onClick={closeModal} type="button" className="btn btn-secondary">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

import React, { useState, useCallback } from 'react';
import { Column } from 'react-table';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { BasicTable } from '../components/BasicTable';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useComponentSearch } from '../contexts/ComponentSearchContext';
import { Component } from '../models/Component';
import FileInput from '../components/FileInput';
import { downloadHandler } from '../Helper';
import { percentFormat } from '../Format';
import { useApi } from '../contexts/ApiContext';

type FormData = {
  searchText: string;
  job: number;
};

const ComponentSearch: React.FC<{}> = () => {
  const api = useApi();
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const { addToast } = useToast();
  const { jobList, components, searchText, getComponents, currentPage, setCurrentPage, isSearchingComponent } =
    useComponentSearch();

  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/vnd.ms-excel': ['.xlsx'] },
  });

  const { register, getValues, handleSubmit } = useForm<FormData>({
    defaultValues: { searchText },
  });

  const ExportSearch = (text: string): void => {
    api.post('/api/Component/Export', { searchText: text }, { responseType: 'blob' }).then(
      ({ data }) => {
        downloadHandler(data, `ExportComponents.csv`);
      },
      (error) => {
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const columns: Column<Component>[] = React.useMemo(
    () => [
      {
        Header: 'Name ',
        accessor: 'name',
        className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row }): JSX.Element => {
          const ellipsis = row.original.name !== null ? (row.original.name.length > 45 ? '...' : '') : '';
          const summary = row.original.name !== null ? row.original.name.substring(0, 45) + ellipsis : 'Not Available';
          const subName = row.original.componentReference !== null ? row.original.componentReference : '';
          return (
            <div className="div">
              <Link
                to={`/components/${row.original.id}`}
                className="text-indigo-600 hover:text-indigo-900 font-medium text-xs"
              >
                {summary}
              </Link>
              <div className="flex italic text-xs font-light">{subName}</div>
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): JSX.Element => {
          const output = value !== null ? value : 'Not available';
          return <div className="flex italic text-xs font-light">{output}</div>;
        },
      },
      {
        Header: 'Sub Type',
        accessor: 'subType',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): JSX.Element => {
          const output = value !== null ? value : 'Not available';
          return <div className="flex italic text-xs font-light">{output}</div>;
        },
      },
      {
        Header: 'Apportionment',
        accessor: 'apportionmentPct',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): JSX.Element => <div>{percentFormat.format(value)}</div>,
      },
      {
        Header: 'Score',
        accessor: 'consumptionScore',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Depreciation Policy',
        accessor: 'depreciationPolicy',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Asset',
        accessor: 'assetReference',
        className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }) => {
          const assetHierarchySummary = `${row.original.assetClass} \\ ${row.original.assetType} \\ ${row.original.assetSubType}`;
          return (
            <div className="div">
              <div className="font-medium text-xs">{value}</div>
              <div className="flex italic text-xs font-light text-indigo-500">{assetHierarchySummary}</div>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        className: 'px-4 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ value }) => (
          <NavLink className="text-indigo-600 hover:text-indigo-900" to={`/components/${value}/edit`}>
            edit
          </NavLink>
        ),
      },
    ],
    []
  );

  const onSubmit = handleSubmit(({ searchText, job }) => {
    getComponents(searchText, job);
  });

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0 justify-between flex">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Components</h1>
          <div className="mt-5">
            {Object.entries(file).length !== 0 ? (
              <FileInput
                file={file}
                isValid={isValid}
                closeAction={(): void => setFile({} as FileWithPath)}
                url="/api/Component/Import"
              />
            ) : (
              <div
                {...getRootProps()}
                className="btn border-cool-gray-300 leading-5 text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 active:text-cool-gray-800 active:bg-cool-gray-50 transition duration-150 ease-in-out"
              >
                <div className="text-center">
                  <input {...getInputProps()} />
                  <p className="flex">
                    <button
                      type="button"
                      className="font-medium focus:outline-none focus:underline transition duration-150 ease-in-out"
                    >
                      Import
                    </button>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
            <div className="pb-2 flex items-end flex-wrap">
              <div className="flex-grow mr-4">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="m-1 relative min-w-max">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                    {/* Heroicon name: search */}
                    <svg
                      className="mr-3 h-4 w-4 text-gray-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    {...register('searchText')}
                    className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="m-1 sm:col-span-2">
                <select
                  defaultValue={0}
                  id="job"
                  {...register('job')}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value={0}>All</option>
                  {jobList.map((j) => (
                    <option key={j.value} value={j.value}>
                      {j.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-end">
              <div className="mt-4 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  onClick={(): void => ExportSearch(getValues('searchText'))}
                  className="m-1 btn border-gray-300 shadow-sm h-10 self-end text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:order-0 sm:ml-0"
                  // className="m-1 inline-flex items-center px-4 border border-transparent rounded-md text-sm font-medium shadow-sm text-white bg-indigo-700 h-10 self-end hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  Export
                </button>
                <input
                  type="submit"
                  className="m-1 inline-flex items-center px-4 border border-transparent rounded-md text-sm font-medium shadow-sm text-white bg-indigo-700 h-10 self-end hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:ml-3"
                  value="Search"
                />
              </div>

              <div />
            </div>
          </form>
          <div className="mx-3">
            <BasicTable
              columns={columns}
              data={components}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isSearchingComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentSearch;

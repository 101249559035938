import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { Guarantee, LeaseDto } from '../../models/Lease';
import { useUpdateLease, useLeaseById } from '../../hooks/UseLease';
import { removeTimezoneOffset } from '../../Helper';

export const GuaranteeEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    control,
    reset,

    formState: { isDirty, isSubmitted, errors },
  } = useForm<Guarantee>();
  const history = useNavigate();
  const { data: lease = { guarantee: {} } as LeaseDto } = useLeaseById(parseInt(id));
  const updateLease = useUpdateLease();

  const onSubmit = async (submittedData: Guarantee): Promise<void> => {
    const result = {
      ...lease,
      guarantee: {
        ...submittedData,
        date:
          submittedData.date !== undefined && submittedData.date !== null
            ? removeTimezoneOffset(submittedData.date)
            : undefined,
        expiryDate:
          submittedData.expiryDate !== undefined && submittedData.expiryDate !== null
            ? removeTimezoneOffset(submittedData.expiryDate)
            : undefined,
      },
    };
    updateLease.mutateAsync(result).then((leaseId) => {});
  };

  useEffect(() => {
    if (Object.keys(lease).length > 0) {
      const guarantee = lease.guarantee ?? {};
      reset({
        ...guarantee,
        date: guarantee.date ? new Date(guarantee.date) : undefined,
        expiryDate: guarantee.expiryDate ? new Date(guarantee.expiryDate) : undefined,
        // rentStartDate: lease.rentStartDate ? new Date(lease.rentStartDate) : undefined,
      });
    }
  }, [lease]);

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Guarantee Information</h3>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Guarantee Type
                    </label>
                    <input
                      type="text"
                      {...register('type')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Held By
                    </label>
                    <input
                      type="text"
                      {...register('heldBy')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <input
                      type="text"
                      {...register('location')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Guarantee Date
                    </label>
                    <Controller
                      name="date"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.date
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Expiry Date
                    </label>
                    <Controller
                      name="expiryDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.expiryDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          placeholderText="Select date"
                        />
                      )}
                    />
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Security Amount
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        step="any"
                        type="number"
                        {...register('amount', { valueAsNumber: true })}
                        defaultValue=""
                        className="focus:ring-indigo-500 focus:border-indigo-500 text-left block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Does Bond Match Current Rent?
                    </label>
                    <input
                      {...register('isBondEqualRent')}
                      type="checkbox"
                      className="mt-2 focus:ring-indigo-600 h-6 w-6 text-indigo-700 rounded border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={(): void => {
                history(`/leases/${id}`);
              }}
              className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm rounded-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

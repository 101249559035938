import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { currencyFormat, twoDecimalsPercentFormat } from '../../Format';
import { AssetReplacementCost } from '../../models/ReplacementCostSummary';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { useAssetReplacementCostById } from '../../hooks/UseReplacementCost';

const AssetReplacementCostView: React.FC<{}> = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  // const [assetReplacementCost, setAssetReplacementCost] = useState<AssetReplacementCost>({} as AssetReplacementCost);
  const { roles } = useAuth();
  const { refetch, data: assetReplacementCost = {} as AssetReplacementCost } = useAssetReplacementCostById(parseInt(id));

  const { checkAssetClassEditable } = useClient();
  // useEffect(() => {
  //   if (parseInt(id) > 0) {
  //     api
  //       .get(`/api/AssetReplacementCost/Get?id=${id}`)
  //       .then(({ data }) => {
  //         setAssetReplacementCost(data);
  //       })
  //       .catch((error) => {});
  //   }
  // }, [id]);

  const {
    name,
    length,
    width,
    area,
    quantity,
    total,
    unitRate,
    localityFactorPct,
    adoptedRate,
    gross,
    adjustmentPct,
    areaType,
    assetId,
    assetName,
    assetClass,
    accumulatedIndexPct,
  } = assetReplacementCost;
  return (
    <div className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Replacement Cost</h3>
              {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && (
                <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                  <span className="shadow-sm">
                    <Link to={`/assetreplacementcosts/${id}/edit`} className="btn btn-primary">
                      Edit
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Item Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Area Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{areaType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Link
                    to={`/assets/${assetId}`}
                    className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    {assetName}
                  </Link>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Length</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{length}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Width</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{width}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Area</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{area}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{quantity}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{total}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Cost</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Unit Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {currencyFormat.format(unitRate)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Locality Factor</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(localityFactorPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Valuer Adjustment</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(adjustmentPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Adopted Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {currencyFormat.format(adoptedRate)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Indexation</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(accumulatedIndexPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Gross</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {currencyFormat.format(gross)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetReplacementCostView;

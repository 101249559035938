import React, { createContext, useState, useContext, useEffect } from 'react';
import { BBox, GeoJSON } from 'geojson';
import useSupercluster from 'use-supercluster';
import { ToastType, useToast } from './ToastContext';
import { Asset } from '../models/Asset';
import { HierarchyType } from '../models/HierarchyType';
import { useApi } from './ApiContext';

interface Center {
  lat: number;
  lng: number;
}
interface AssetSearchContext {
  assets: Asset[];
  setAssets: (assets: Asset[]) => void;
  getAssets: (
    text: string,
    inspector: string,
    jobId: number,
    assetClassId?: number,
    assetTypeId?: number,
    assetSubTypeId?: number,
    suburb?: string,
    facility?: string,
    subFacility?: string,
    valuer?: string,
    cost?: string,
    income?: string,
    market?: string,
    heritage?: string,
    status?: string,
    controlled?: string,
    inspection?: string,
    valuationPolicy?: string,
    history?: string
  ) => void;
  deleteAssets: (assetIds: number[]) => void;
  exportAssets: (assetIds: number[]) => void;
  isMapView: boolean;
  setIsMapView: (isMapView: boolean) => void;
  defaultCenter: Center;
  setDefaultCenter: (defaultCenter: Center) => void;
  defaultZoom: number;
  setDefaultZoom: (defaultZoom: number) => void;
  latitude: number;
  setLatitude: (latitude: number) => void;
  longitude: number;
  setLongitude: (latitude: number) => void;
  points: GeoJSON[];
  setPoint: (points: GeoJSON[]) => void;
  bounds: BBox;
  setBounds: (bounds: BBox) => void;
  fetchAssetClasses: (jobId: number) => void;
  isDropOpen: boolean;
  setIsDropOpen: (isDropOpen: boolean) => void;
  searchText: string;
  setJobFilter: (job: string) => void;
  setSearchText: (searchText: string) => void;
  jobList: HierarchyType[];
  assetClassList: HierarchyType[];
  isSearchingAsset: boolean;
  setIsSearchingAsset: () => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isDeletingAssets: boolean;
  isExportingAssets: boolean;
  assetsToExport: number[];
  setIsExportingAssets: (open: boolean) => void;
  tablePageSize: number;
  setTablePageSize: (size: number) => void;
  isValuerMode: boolean;
  setIsValuerMode: (toggle: boolean) => void;
  assetClass: HierarchyType;
  setAssetClass: (assetClass: HierarchyType) => void;
  assetType: HierarchyType;
  setAssetType: (assetType: HierarchyType) => void;
  assetSubType: HierarchyType;
  setAssetSubType: (assetSubType: HierarchyType) => void;
  searchedJob: HierarchyType;
  setSearchedJob: (searchedJob: HierarchyType) => void;
  inspector: string;
  setInspector: (inspector: string) => void;
  suburb: string;
  setSuburb: (suburb: string) => void;
  facility: string;
  setFacility: (facility: string) => void;
  subFacility: string;
  setSubFacility: (subFacility: string) => void;
  valuationPolicy: string;
  setValuationPolicy: (valuationPolicy: string) => void;
  valuationType: string;
  setValuationType: (valuationType: string) => void;
  valuer: string;
  setValuer: (valuer: string) => void;
  history: string;
  setHistory: (history: string) => void;
  isHeldForSale: string;
  setIsHeldForSale: (isHeldForSale: string) => void;
  investment: string;
  setInvestment: (investment: string) => void;
  controlled: string;
  setControlled: (controlled: string) => void;
  inspection: string;
  setInspection: (inspection: string) => void;
  status: string;
  setStatus: (status: string) => void;
}

const AssetSearchContext = createContext<AssetSearchContext>({} as AssetSearchContext);

// This component retrieves the strategies from the API so they can be read by all components
export const AssetSearchProvider: React.FC<any> = (props) => {
  const api = useApi();
  const [points, setPoints] = useState<GeoJSON[]>([]);
  const [defaultCenter, setDefaultCenter] = useState<Center>({
    lat: -23.998038,
    lng: 134.322737,
  });
  const [bounds, setBounds] = useState<BBox>();
  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [defaultZoom, setDefaultZoom] = useState<number>(5);
  const [latitude, setLatitude] = useState<number>(-23.998038);
  const [longitude, setLongitude] = useState<number>(134.322737);
  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);
  const [isValuerMode, setIsValuerMode] = useState<boolean>(false);
  const [tablePageSize, setTablePageSize] = useState<number>(10);
  const [assets, setAssets] = useState<Asset[]>([] as Asset[]);
  const [assetsToExport, setAssetsToExport] = useState<number[]>([]);
  const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([]);
  const [jobList, setJobList] = useState<HierarchyType[]>([]);
  const [isSearchingAsset, setIsSearchingAsset] = useState<boolean>(false);
  const [isDeletingAssets, setIsDeletingAssets] = useState<boolean>(false);
  const [isExportingAssets, setIsExportingAssets] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [assetClass, setAssetClass] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetType, setAssetType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetSubType, setAssetSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [searchedJob, setSearchedJob] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [inspector, setInspector] = useState<string>('');
  const [suburb, setSuburb] = useState<string>('');
  const [facility, setFacility] = useState<string>('');
  const [subFacility, setSubFacility] = useState<string>('');
  const [valuationPolicy, setValuationPolicy] = useState<string>('All');
  const [valuationType, setValuationType] = useState<string>('All');
  const [valuer, setValuer] = useState<string>('');
  const [history, setHistory] = useState<string>('All');
  const [isHeldForSale, setIsHeldForSale] = useState<string>('All');
  const [investment, setInvestment] = useState<string>('All');
  const [controlled, setControlled] = useState<string>('All');
  const [inspection, setInspection] = useState<string>('All');
  const [status, setStatus] = useState<string>('All');

  const { addToast } = useToast();

  const median = (coordinates: number[]): number => {
    const midPoint = Math.floor(coordinates.length / 2);
    const nums = [...coordinates].sort((a, b) => a - b);
    return coordinates.length % 2 !== 0 ? nums[midPoint] : nums[midPoint - 1];
  };

  const getAssets = (
    text: string,
    inspector: string,
    jobId: number,
    assetClassId?: number,
    assetTypeId?: number,
    assetSubTypeId?: number,
    suburb?: string,
    facility?: string,
    subFacility?: string,
    valuer?: string,
    valuationPolicy?: string,
    valuationType?: string,
    history?: string,
    heritage?: string,
    sale?: string,
    investment?: string,
    controlled?: string,
    status?: string,
    state?: string
  ): void => {
    setSearchText(text);
    setIsSearchingAsset(true);
    setAssets([]);
    api
      .post<Asset[]>(`/api/Asset/SearchAssets`, {
        searchText: text,
        inspector,
        jobId,
        assetClassId,
        assetTypeId,
        assetSubTypeId,
        suburb,
        valuer,
        facility,
        subFacility,
        valuationPolicy,
        valuationType,
        history,
        heritage,
        sale,
        investment,
        controlled,
        status,
        state,
      })
      .then(({ data }) => {
        setCurrentPage(0);
        setIsSearchingAsset(false);
        setAssets(
          data.map((asset) => ({
            ...asset,
            hasErrors: asset.hasErrors || asset.components.filter((component) => component.hasErrors).length > 0,
            hasWarnings: asset.hasWarnings || asset.components.filter((component) => component.hasWarnings).length > 0,
          }))
        );
        setPoints(
          data
            .filter((asset) => asset?.latitude && asset?.longitude)
            .map(
              (asset) =>
                ({
                  type: 'Feature',
                  properties: { cluster: false, asset },
                  geometry: {
                    type: 'Point',
                    coordinates: [asset.longitude || 0, asset.latitude || 0],
                  },
                } as GeoJSON)
            )
        );
        const medianLat = median(
          data.flatMap(({ latitude }) => latitude).filter((latitude): latitude is number => latitude !== undefined)
        );
        const medianLng = median(
          data.flatMap(({ longitude }) => longitude).filter((longitude): longitude is number => longitude !== undefined)
        );

        if (Math.abs(medianLat) > 0 && Math.abs(medianLng) > 0) {
          setDefaultCenter({
            lat: medianLat,
            lng: medianLng,
          });
          setDefaultZoom(6);
        }
      })
      .catch((error) => {
        setCurrentPage(0);
        setIsSearchingAsset(false);
        addToast('Asset Search Failed', ToastType.Error);
      });
  };

  const deleteAssets = (assetIds: number[]): void => {
    setIsDeletingAssets(true);
    api.post('/api/Asset/BulkDelete', { assetIds }).then(
      ({ data }) => {
        setIsDeletingAssets(false);
        setAssets(assets.filter((asset) => !assetIds.includes(asset.id)));
        addToast(`${data.message}`);
      },
      (error) => {
        setIsDeletingAssets(false);
        if (assetIds.length > 25000) {
          addToast(
            `Unable to delete more than 25000 assets in a single operation please complete split delete operation into smaller parts.`,
            ToastType.Error
          );
        } else {
          addToast('Selected Assets belong to a Job that is read-only.', ToastType.Error);
        }
      }
    );
  };

  const exportAssets = (assetIds: number[]): void => {
    setIsExportingAssets(true);
    setAssetsToExport(assetIds);
  };

  const fetchAssetClasses = (jobId: number): void => {
    api
      .get(`/api/AssetClass/ListByJob?JobId=${jobId}`)
      .then(({ data }) => {
        setAssetClassList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchAssetClasses(0);
  }, [jobList]);

  useEffect(() => {
    api
      .get(`/api/Job/ListOptions?JobStatus=active`)
      .then(({ data }) => {
        setJobList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
      })
      .catch((error) => {});
  }, []);

  return (
    <AssetSearchContext.Provider
      value={{
        assets,
        setAssets,
        getAssets,
        deleteAssets,
        exportAssets,
        isMapView,
        setIsMapView,
        defaultZoom,
        setDefaultZoom,
        defaultCenter,
        setDefaultCenter,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        points,
        setPoints,
        bounds,
        setBounds,
        fetchAssetClasses,
        jobList,
        assetClassList,
        searchText,
        setSearchText,
        isSearchingAsset,
        setIsSearchingAsset,
        currentPage,
        setCurrentPage,
        isExportingAssets,
        isDeletingAssets,
        setIsExportingAssets,
        assetsToExport,
        tablePageSize,
        setTablePageSize,
        isValuerMode,
        setIsValuerMode,
        assetClass,
        setAssetClass,
        assetType,
        setAssetType,
        assetSubType,
        setAssetSubType,
        searchedJob,
        setSearchedJob,
        inspector,
        setInspector,
        suburb,
        setSuburb,
        facility,
        setFacility,
        subFacility,
        setSubFacility,
        valuationPolicy,
        setValuationPolicy,
        valuationType,
        setValuationType,
        valuer,
        setValuer,
        history,
        setHistory,
        isHeldForSale,
        setIsHeldForSale,
        investment,
        setInvestment,
        controlled,
        setControlled,
        inspection,
        setInspection,
        status,
        setStatus,
        isDropOpen,
        setIsDropOpen,
      }}
      {...props}
    />
  );
};

export const useAssetSearch = (): AssetSearchContext => useContext(AssetSearchContext);
export default AssetSearchProvider;

import React, { useEffect, forwardRef, MutableRefObject, Fragment, useState } from 'react';
import { useTable, useSortBy, Column, usePagination, useRowSelect } from 'react-table';
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as Loader } from '../icons/Loader.svg';
import { DeleteItemsConfirmation } from './DeleteItemsConfirmation';

export type EnhancedColumn<T extends object> = Column & { headerClassName?: string };

type TableProps<T extends object> = {
  data: T[];
  columns: EnhancedColumn<T>[];
  currentPage?: number;
  setCurrentPage?(page: number): void;
  isLoading?: boolean;
  deleteAction?(ids: number[] | string[]): void;
  exportAction?(ids: number[] | string[]): void;
  isDeleting?: boolean;
  isExporting?: boolean;
  pageOpts?: number[];
  canColumnFilter?: boolean;
  context?: string;
  showHead?: boolean;
  tablePageSize?: number;
  setTablePageSize?(size: number): void;
};

interface Props {
  indeterminate?: boolean;
  name: string;
}

const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <input
        className="focus:outline-none focus:ring-indigo-500 h-4 w-4 text-indigo-700 border-gray-300 rounded"
        type="checkbox"
        ref={combinedRef}
        {...rest}
      />
    );
  }
);

export function BasicTable<T extends object>({
  columns,
  data,
  showHead = false,
  tablePageSize = 10,
  setTablePageSize,
  currentPage = 0,
  setCurrentPage,
  isLoading,
  deleteAction,
  exportAction,
  isDeleting,
  isExporting,
  context,
  pageOpts = [5, 10, 20, 50],
  canColumnFilter = false,
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, pageSize: tablePageSize },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (deleteAction !== undefined || exportAction !== undefined) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }): JSX.Element => (
              <div className="pl-2">
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            headerClassName: 'px-4',
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }): JSX.Element => (
              <div className="px-4">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);

  const deleteItems = (): void => {
    deleteAction !== undefined && deleteAction(itemsToDelete);
  };

  const resultsFrom = pageIndex * pageSize + 1;
  const resultsTo = resultsFrom + page.length - 1;
  return (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            {' '}
            <div className="shadow-md overflow-hidden rounded-lg">
              <table
                {...getTableProps()}
                className={`min-w-full divide-y divide-gray-200 ${!showHead && !isLoading && data.length === 0 && 'hidden'}`}
              >
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr key={index} className="" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, idx) => {
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        const isActionActive =
                          Object.keys(selectedRowIds).length > 0 &&
                          !(deleteAction === undefined && exportAction === undefined);
                        column.canSort =
                          !(idx === 0 && !(deleteAction === undefined && exportAction === undefined)) &&
                          !(idx === 1 && isActionActive) &&
                          idx !== headerGroup.headers?.length - 1;
                        return (
                          <th
                            key={idx}
                            className={`px-2 py-4 ${
                              column.headers === undefined && 'border-b border-gray-200'
                            } bg-gray-50 text-left text-xs leading-loose font-medium text-gray-500 uppercase tracking-wide ${
                              column.headerClassName
                            }`}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {isActionActive && idx === 1 ? (
                              <span className="hidden lg:flex space-x-6">
                                {exportAction !== undefined && (
                                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                    <button
                                      type="button"
                                      disabled={isExporting}
                                      onClick={(): void => exportAction(selectedFlatRows.map((d) => d.original.id))}
                                      className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm font-xs rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                      {isExporting && <Loader className="animate-spin w-5 h-5 mr-2 -ml-1" />}
                                      {isExporting ? 'Exporting' : 'Export'}
                                    </button>
                                    <Menu as="span" className="-ml-px relative block">
                                      {({ open }): JSX.Element => (
                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-1 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                          >
                                            <Menu.Item key={1}>
                                              {({ active }): JSX.Element => (
                                                <button
                                                  type="button"
                                                  onClick={(): void =>
                                                    exportAction(selectedFlatRows.map((d) => d.original.id))
                                                  }
                                                  className={`${
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                  } w-full block px-4 py-2 text-sm text-left`}
                                                >
                                                  Entity Only
                                                </button>
                                              )}
                                            </Menu.Item>
                                          </Menu.Items>
                                        </Transition>
                                      )}
                                    </Menu>
                                  </span>
                                )}

                                {deleteAction !== undefined && (
                                  <button
                                    onClick={(): void => {
                                      setOpen(true);
                                      setItemsToDelete(selectedFlatRows.map((d) => d.original.id));
                                    }}
                                    type="button"
                                    disabled={isDeleting}
                                    className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm font-xs rounded-md text-red-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                                  >
                                    {isDeleting && <Loader className="animate-spin w-5 h-5 mr-2 -ml-1" />}

                                    {isDeleting ? 'Deleting' : 'Delete'}
                                  </button>
                                )}
                              </span>
                            ) : (
                              column.render('Header')
                            )}
                            {/* {column.render('Header')} */}
                            {column.isSorted !== false &&
                            !isActionActive &&
                            column.id !== 'selection' &&
                            column.id !== 'id' &&
                            column.id !== 'actions' ? (
                              <button
                                type="button"
                                className="m-1 inline-flex items-center border bg-gray-200 border-gray-300 shadow-sm text-sm h-5 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0"
                              >
                                <ChevronUpIcon
                                  className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                                    !column.isSortedDesc && 'rotate-180'
                                  } ease-in-out duration-150`}
                                />
                              </button>
                            ) : (
                              ''
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
                  {isLoading &&
                    [...Array(10)].map((item, index) => (
                      <tr key={index}>
                        {columns.map((column, idx) => (
                          <td key={idx} className="max-w-sm">
                            <div className="px-2 py-4 animate-pulse space-x-4">
                              <div className="h-4 bg-gray-300 rounded w-3/4 delay-100" />
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  {!isLoading &&
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <Transition
                          key={index}
                          {...row.getRowProps({
                            className: `${row.isSelected ? 'bg-gray-450' : 'bg-white'}`,
                          })}
                          as="tr"
                          appear
                          show
                          enter={`transition-opacity duration-300 delay-${index * 5}0`}
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
                          ))}
                        </Transition>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {(canNextPage || canPreviousPage) && (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 shadow-md rounded-md overflow-hidden my-2">
          <div className="flex-1 flex justify-between sm:hidden">
            <a href="/" className="relative btn border-gray-300 text-gray-700 bg-white hover:text-gray-500">
              Previous
            </a>
            <a href="/" className="ml-3 relative btn border-gray-300 text-gray-700 bg-white hover:text-gray-500">
              Next
            </a>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex space-x-4">
              <p className="whitespace-nowrap text-sm text-gray-700 self-center">
                Showing
                <span className="font-medium"> {resultsFrom} </span>
                to
                <span className="font-medium"> {resultsTo} </span>
                of
                <span className="font-medium"> {rows.length} </span>
                results
              </p>
              <select
                value={pageSize}
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  if (setTablePageSize !== undefined) setTablePageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Per Page
                  </option>
                ))}
              </select>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={(): void => {
                    gotoPage(0);
                    setCurrentPage && setCurrentPage(0);
                  }}
                  disabled={!canPreviousPage}
                  className="relative disabled:opacity-50 inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm focus:outline-none font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronDoubleLeftIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={(): void => {
                    previousPage();
                    setCurrentPage && setCurrentPage(currentPage - 1);
                  }}
                  disabled={!canPreviousPage}
                  className="relative disabled:opacity-50 inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm focus:outline-none font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={(): void => {
                    nextPage();
                    setCurrentPage && setCurrentPage(currentPage + 1);
                  }}
                  disabled={!canNextPage}
                  className="relative disabled:opacity-50 inline-flex items-center px-2 py-2 border border-gray-300 focus:outline-none bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={(): void => {
                    gotoPage(pageCount - 1);
                    setCurrentPage && setCurrentPage(pageCount - 1);
                  }}
                  disabled={!canNextPage}
                  className="relative disabled:opacity-50 inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm focus:outline-none font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronDoubleRightIcon className="h-5 w-5" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
      <DeleteItemsConfirmation
        isOpen={isOpen}
        setOpen={setOpen}
        items={itemsToDelete.length}
        deleteConfirm={deleteItems}
        context={context || ''}
      />
    </>
  );
}

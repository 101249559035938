import React, { Fragment, useState } from 'react';

import { useParams, NavLink, Switch, Route } from 'react-router-dom';
import { currencyFormat } from '../../Format';
import { useLeaseById } from '../../hooks/UseLease';
import { LeaseDto } from '../../models/Lease';

export const Guarantee: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { guarantee = {} } = lease;

  if (guarantee == undefined) return <div />;

  return (
    <ul className="flex flex-col ">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8  ">
        <div className="min-w-full">
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">{guarantee.type && guarantee.type}</dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Held By</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.heldBy && guarantee.heldBy}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.location && guarantee.location}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Bond Expiry Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.expiryDate && new Date(guarantee.expiryDate).toDateString()}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Does Bond Match Current Rent</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.isBondEqualRent ? 'Yes' : 'No'}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Bond Term Guarantee Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.date && new Date(guarantee.date).toDateString()}
              </dd>
            </div>
          </div>
          <div className="space-y-4 py-2">
            <div className="sm:grid sm:grid-cols-5  sm:px-4">
              <dt className="text-sm font-medium sm:col-span-2 text-gray-500 pl-2">Security Amount</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 pl-8">
                {guarantee.amount != undefined && currencyFormat.format(guarantee.amount)}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default Guarantee;

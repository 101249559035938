import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { useDropzone, FileWithPath } from 'react-dropzone';
import {
  Disclosure,
  Combobox,
  Switch,
  DisclosureButton,
  DisclosurePanel,
  ComboboxOption,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
} from '@headlessui/react';
import { ChevronDoubleDownIcon, Bars4Icon, TableCellsIcon, MapIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as Loader } from '../icons/Loader.svg';
import { ReactComponent as CheckCircleIcon } from '../icons/CheckCircle.svg';
import { useAssetSearch } from '../contexts/AssetSearchContext';
import { Asset } from '../models/Asset';
import { BasicTable, EnhancedColumn } from '../components/BasicTable';
import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';
import { useClient } from '../contexts/ClientContext';
import ExportAssetsModal from '../components/ExportAssetsModal';
import { HierarchyType } from '../models/HierarchyType';
import { useApi } from '../contexts/ApiContext';
import { ReactComponent as LocationMarker } from '../icons/LocationMarker.svg';
import { useToast, ToastType } from '../contexts/ToastContext';
import Marker from '../components/Marker';
import ClusterMarker from '../components/ClusterMarker';

type FormData = {
  searchText: string;
  inspector: string;
  jobId: number;
  assetClassId: number;
  assetTypeId: number;
  assetSubTypeId: number;
  facility: string;
  suburb: string;
  valuer: string;
  valuationPolicy: string;
  valuationType: string;
  history: string;
  heritage: string;
  sale: string;
  investment: string;
  controlled: string;
  status: string;
  state: string;
};

interface Center {
  lat: number;
  lng: number;
}

const AssetSearch: React.FC<{}> = () => {
  const { roles, client } = useAuth();
  const mapRef = useRef<GoogleMapReact>(null);
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const api = useApi();
  const {
    isMapView,
    setIsMapView,
    defaultZoom,
    setDefaultZoom,
    defaultCenter,
    setDefaultCenter,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    bounds,
    setBounds,
    points,
    jobList,
    assets,
    searchText,
    setSearchText,
    getAssets,
    deleteAssets,
    exportAssets,
    currentPage,
    setCurrentPage,
    isSearchingAsset,
    assetClassList,
    fetchAssetClasses,
    isDeletingAssets,
    isExportingAssets,
    setIsExportingAssets,
    assetsToExport,
    tablePageSize,
    setTablePageSize,
    isValuerMode,
    assetClass,
    setAssetClass,
    assetType,
    setAssetType,
    assetSubType,
    setAssetSubType,
    searchedJob,
    setSearchedJob,
    inspector,
    setInspector,
    suburb,
    setSuburb,
    facility,
    setFacility,
    subFacility,
    setSubFacility,
    valuationPolicy,
    setValuationPolicy,
    valuationType,
    setValuationType,
    valuer,
    setValuer,
    history,
    setHistory,
    isHeldForSale,
    setIsHeldForSale,
    investment,
    setInvestment,
    controlled,
    setControlled,
    inspection,
    setInspection,
    status,
    setStatus,
    isDropOpen,
    setIsDropOpen,
  } = useAssetSearch();

  // const Marker: React.FC<MarkerProps> = ({ name, id }) => {
  //   const navigateLink = isValuerMode ? `/assets/${id}/quickedit` : `/assets/${id}`;
  //   return (
  //     <NavLink to={navigateLink}>
  //       <LocationMarker title={name} className="h-6 w-6 text-red-600" />
  //     </NavLink>
  //   );
  // };
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: defaultZoom,
    options: { radius: 80, maxZoom: defaultZoom, minZoom: 0 },
  });

  const [effect, setEffect] = useState<boolean>(false);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const [selectedAssetClassType, setSelectedAssetClassType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);

  const [assetClassQuery, setAssetClassQuery] = useState<string>('');

  const [selectedJob, setSelectedJob] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [jobQuery, setJobQuery] = useState<string>('');

  const [selectedAssetType, setSelectedAssetType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([]);
  const [assetTypeQuery, setAssetTypeQuery] = useState<string>('');

  const [selectedAssetSubType, setSelectedAssetSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([]);
  const [assetSubTypeQuery, setAssetSubTypeQuery] = useState<string>('');

  const [selectedFacility, setSelectedFacility] = useState<string>('');
  const [facilityList, setFacilityList] = useState<string[]>([]);
  const [facilityQuery, setFacilityQuery] = useState<string>('');

  const [selectedSubFacility, setSelectedSubFacility] = useState<string>('');
  const [subFacilityList, setSubFacilityList] = useState<string[]>([]);
  const [subFacilityQuery, setSubFacilityQuery] = useState<string>('');

  const [selectedInspector, setSelectedInspector] = useState<string>('');
  const [inspectorList, setInspectorList] = useState<string[]>([]);
  const [inspectorQuery, setInspectorQuery] = useState<string>('');

  const [selectedValuer, setSelectedValuer] = useState<string>('');
  const [valuerList, setValuerList] = useState<string[]>([]);
  const [valuerQuery, setValuerQuery] = useState<string>('');

  const [selectedSuburbOrTown, setSelectedSuburbOrTown] = useState<string>('');
  const [suburbOrTownList, setSuburbOrTownList] = useState<string[]>([]);
  const [suburbOrTownQuery, setSuburbOrTownQuery] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      searchText: '',
    },
  });

  const [isSearch, setIsSearch] = useState<boolean>(true);

  const sortAlphaNumeric = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  const filterSubFacilities = (facility: string): void => {
    api
      .get(`/api/Asset/GetAssetSubFacilities?Facility=${facility}`)
      .then(({ data }) => {
        const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
        setSubFacilityList(sortedData);
      })
      .catch((error) => {});
  };

  const resetSearch = (): void => {
    setSelectedAssetClassType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetSubType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedJob({ value: 0, label: 'All' } as HierarchyType);
    setSelectedInspector('');
    setSelectedSuburbOrTown('');
    setSelectedFacility('');
    setSelectedSubFacility('');
    setSelectedValuer('');

    setValue('searchText', '');
    setValue('valuationPolicy', 'All');
    setValue('valuationType', 'All');
    setValue('history', 'All');
    setValue('sale', 'All');
    setValue('investment', 'All');
    setValue('controlled', 'All');
    setValue('state', 'All');
    setValue('status', 'All');

    setSearchText('');
    setAssetClass({ value: 0, label: 'All' } as HierarchyType);
    setAssetType({ value: 0, label: 'All' } as HierarchyType);
    setAssetSubType({ value: 0, label: 'All' } as HierarchyType);
    setSearchedJob({ value: 0, label: 'All' } as HierarchyType);
    setInspector('');
    setSuburb('');
    setFacility('');
    setSubFacility('');
    setValuer('');
    setValuationPolicy('All');
    setValuationType('All');
    setHistory('All');
    setIsHeldForSale('All');
    setInvestment('All');
    setControlled('All');
    setInspection('All');
    setStatus('All');
  };

  useEffect(() => {
    setDefaultCenter({ lat: latitude, lng: longitude });
    setValue('searchText', searchText);
    setSelectedAssetClassType(assetClass);
    setSelectedAssetType(assetType);
    setSelectedAssetSubType(assetSubType);
    setSelectedJob(searchedJob);
    setSelectedInspector(inspector);
    setSelectedSuburbOrTown(suburb);
    setSelectedFacility(facility);
    setSelectedSubFacility(subFacility);
    setSelectedValuer(valuer);

    setValue('valuationPolicy', valuationPolicy);
    setValue('valuationType', valuationType);
    setValue('history', history);
    setValue('sale', isHeldForSale);
    setValue('investment', investment);
    setValue('controlled', controlled);
    setValue('state', status);
    setValue('status', inspection);

    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${0}`)
      .then(({ data }) => {
        setAssetTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});

    if (facilityList.length === 0) {
      api
        .get(`/api/Asset/GetAssetFacilities`)
        .then(({ data }) => {
          const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
          setFacilityList(sortedData);
        })
        .catch((error) => {});
    }

    if (subFacilityList.length === 0) {
      api
        .get(`/api/Asset/GetAssetSubFacilities`)
        .then(({ data }) => {
          const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
          setSubFacilityList(sortedData);
        })
        .catch((error) => {});
    }

    if (inspectorList.length === 0) {
      api
        .get(`/api/Asset/GetAssetInspectors`)
        .then(({ data }) => {
          setInspectorList(data);
        })
        .catch((error) => {});
    }

    if (valuerList.length === 0) {
      api
        .get(`/api/Asset/GetAssetValuers`)
        .then(({ data }) => {
          setValuerList(data);
        })
        .catch((error) => {});
    }

    api
      .get(`/api/Asset/GetAssetSuburbsAndTowns`)
      .then(({ data }) => {
        const sortedData = data.filter((value) => value != null).sort(sortAlphaNumeric.compare);
        setSuburbOrTownList(sortedData);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedAssetClassType && selectedAssetClassType.label !== 'All') {
      api
        .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${selectedAssetClassType.value}`)
        .then(({ data }) => {
          setAssetTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
        })
        .catch((error) => {});
    }
  }, [selectedAssetClassType]);

  useEffect(() => {
    if (selectedAssetType && selectedAssetType.label !== 'All') {
      api
        .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${selectedAssetType.value}`)
        .then(({ data }) => {
          setAssetSubTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data));
        })
        .catch((error) => {});
    }
  }, [selectedAssetType]);

  const filteredJobs =
    jobQuery === ''
      ? jobList
      : jobList.filter((hierarchyType: HierarchyType) => hierarchyType.label.toLowerCase().includes(jobQuery.toLowerCase()));

  const filteredAssetClasses =
    assetClassQuery === ''
      ? assetClassList
      : assetClassList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetClassQuery.toLowerCase())
        );

  const filteredAssetTypes =
    assetTypeQuery === ''
      ? assetTypeList
      : assetTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetTypeQuery.toLowerCase())
        );

  const filteredAssetSubTypes =
    assetSubTypeQuery === ''
      ? assetSubTypeList
      : assetSubTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetSubTypeQuery.toLowerCase())
        );

  const filteredFacilities =
    facilityQuery === ''
      ? facilityList.slice(0, 800)
      : facilityList
          .filter((facility: string) => (facility || '').toLowerCase().includes(facilityQuery.toLowerCase()))
          .slice(0, 300);

  const filteredSubFacilities =
    subFacilityQuery === ''
      ? subFacilityList.slice(0, 800)
      : subFacilityList
          .filter((subFacility: string) => (subFacility || '').toLowerCase().includes(subFacilityQuery.toLowerCase()))
          .slice(0, 300);

  const filteredInspectors =
    inspectorQuery === ''
      ? inspectorList
      : inspectorList.filter((inspector: string) => (inspector || '').toLowerCase().includes(inspectorQuery.toLowerCase()));

  const filteredValuers =
    valuerQuery === ''
      ? valuerList
      : valuerList.filter((valuer: string) => (valuer || '').toLowerCase().includes(valuerQuery.toLowerCase()));

  const filteredSuburbOrTown =
    suburbOrTownQuery === ''
      ? suburbOrTownList.slice(0, 800)
      : suburbOrTownList
          .filter((suburbOrTown: string) => (suburbOrTown || '').toLowerCase().includes(suburbOrTownQuery.toLowerCase()))
          .slice(0, 300);
  const columns: EnhancedColumn<Asset>[] = React.useMemo(
    () => [
      {
        Header: 'Asset ',
        headerClassName: 'px-2 py-2.5 text-sm leading-5 font-medium sm:truncate',
        accessor: 'asset',
        className: 'px-2 py-2.5 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row }): JSX.Element => {
          const ellipsis = row.original.name.length > 45 ? '...' : '';
          const summary = `${row.original.name.substring(0, 45)}${ellipsis}`;
          const navigateLink =
            isValuerMode && checkAssetClassEditable(row.original.class)
              ? `/assets/${row.original.id}/quickedit`
              : `/assets/${row.original.id}`;
          return (
            <>
              <Link to={navigateLink} className="btn-link text-xs">
                {summary}
              </Link>
              <div className="flex italic text-xs font-light">{row.original.reference}</div>
            </>
          );
        },
      },
      {
        Header: 'Class',
        accessor: 'class',
        className: 'px-2 py-2.5 text-sm leading-5',
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: 'px-2 py-2.5 text-sm leading-5',
      },
      {
        Header: 'Sub Type',
        accessor: 'subType',
        className: 'px-2 py-2.5 text-sm leading-5',
      },
      {
        Header: 'Suburb',
        accessor: 'suburb',
        className: 'px-2 py-2.5 text-sm leading-5',
      },
      {
        Header: 'Facility',
        accessor: 'facility',
        className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value = '' }): JSX.Element => {
          const ellipsis = value?.length > 30 ? '...' : '';
          const summary = `${value?.substring(0, 30)}${ellipsis}`;
          return (
            <>
              <div className="whitespace-nowrap text-xs leading-5">{summary}</div>
              <div className="flex italic text-xs font-light">{row.original.subFacility}</div>
            </>
          );
        },
      },
      {
        Header: '',
        accessor: 'inspectionStatus',
        className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): JSX.Element => (
          <div className="flex space-x-1">
            {value === 'Complete' && <EyeIcon className="h-4 w-4 text-green-400" />}
            {value === 'Inspected' && <EyeIcon className="h-4 w-4 text-yellow-400" />}
            {value === 'To Be Inspected' && <EyeIcon className="h-4 w-4 text-red-400" />}
            {/* {value === 'Not Required' && <EyeSlashIcon className="h-4 w-4 text-grey-400" />} */}
            {row.original.hasWarnings && <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400" />}
            {row.original.hasErrors && <ExclamationCircleIcon className="h-4 w-4 text-red-500" />}
            {!row.original.hasErrors && !row.original.hasWarnings && <CheckCircleIcon className="h-4 w-4 text-green-400" />}
          </div>
        ),
      },
      {
        Header: (): JSX.Element => (
          <button
            type="button"
            onClick={(): void => setIsMapView(true)}
            className="inline-flex items-center gap-x-2.5 px-3 py-2 text-sm rounded-md font-semibold text-gray-500 hover:bg-gray-200 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-gray-200"
          >
            <MapIcon className="h-5 w-6 text-gray-600" />
            Map
          </button>
        ),
        accessor: 'id',
        className: 'pr-4 pl-10 py-2.5 whitespace-nowrap text-sm font-medium leading-5 text-left',
        Cell: ({ row, value }): JSX.Element => {
          const navigateLink = isValuerMode ? `/assets/${value}/quickedit` : `/assets/${value}/edit`;
          return (
            <div>
              {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.class) && (
                <NavLink className="btn-link" to={navigateLink}>
                  edit
                </NavLink>
              )}
            </div>
          );
        },
      },
    ],
    [isValuerMode, assets, readOnlyAssetClasses]
  );

  useEffect(() => {
    setSearchComplete(false);
  }, [isDeletingAssets]);

  const onSubmit = handleSubmit(
    ({
      searchText,
      // inspector,
      jobId,
      // assetClassId,
      // assetType,
      // assetSubType,
      // suburb,
      // facility,
      // valuer,
      valuationPolicy,
      valuationType,
      history,
      heritage,
      sale,
      investment,
      controlled,
      status,
      state,
    }) => {
      getAssets(
        searchText,
        selectedInspector,
        selectedJob?.value ?? 0,
        selectedAssetClassType?.value ?? 0,
        selectedAssetType?.value ?? 0,
        selectedAssetSubType?.value ?? 0,
        selectedSuburbOrTown,
        selectedFacility,
        selectedSubFacility,
        selectedValuer,
        valuationPolicy,
        valuationType,
        history,
        heritage,
        sale,
        investment,
        controlled,
        status,
        state
      );
      setSearchComplete(true);
    }
  );
  return (
    <>
      <Disclosure defaultOpen={isDropOpen}>
        {({ open }): JSX.Element => (
          <div className="sm:block">
            <div className="align-middle inline-block min-w-full h-screen w-full flex flex-col">
              <form
                onSubmit={onSubmit}
                className={`${
                  effect && 'transition-opacity ease-in-out duration-200 opacity-0'
                } relative p-5 m-3 bg-white shadow-md rounded-md`}
              >
                <div className="flex-1 min-w-0 mb-3 justify-between flex">
                  <h1 className="text-lg py-2 font-medium leading-6 text-gray-900 sm:truncate">Asset Register</h1>
                  <div className="flex flex-end space-x-3">
                    {!roles.includes(Roles.Viewer) && (
                      <NavLink
                        type="button"
                        to="/assets/0/edit"
                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      >
                        New Asset
                      </NavLink>
                    )}
                  </div>
                </div>
                <div className="flex items-end flex-wrap space-x-2">
                  <div className="flex-grow rounded-md shadow-sm">
                    <div className="relative flex items-stretch focus-within:z-10">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {isSearch ? (
                          <MagnifyingGlassIcon className="mr-3 h-4 w-4 text-gray-600" aria-hidden="true" />
                        ) : (
                          <Bars4Icon className="mr-3 h-4 w-4 text-gray-600" aria-hidden="true" />
                        )}
                      </div>
                      <input
                        type="text"
                        {...register('searchText')}
                        onBlur={(e): void => setSearchText(e.target.value)}
                        className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-9"
                        placeholder="Asset Name/ID/Asset Custodian"
                      />
                    </div>
                  </div>
                  <Combobox
                    name="assetClass"
                    value={selectedAssetClassType}
                    onChange={(value): void => {
                      setSelectedAssetClassType(value);
                      setAssetClass(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Class</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetClassQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetClasses.map((assetClass) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetClass.value}
                            value={assetClass}
                          >
                            {assetClass.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>

                  <Combobox
                    name="assetType"
                    value={selectedAssetType}
                    onChange={(value): void => {
                      setSelectedAssetType(value);
                      setAssetType(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetTypeQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetTypes.map((assetType) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetType.value}
                            value={assetType}
                          >
                            {assetType.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>

                  <Combobox
                    name="assetSubType"
                    value={selectedAssetSubType}
                    onChange={(value): void => {
                      setSelectedAssetSubType(value);
                      setAssetSubType(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Sub Type</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetSubTypeQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetSubTypes.map((assetSubType) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetSubType.value}
                            value={assetSubType}
                          >
                            {assetSubType.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>
                  <button
                    onClick={(): void => {
                      resetSearch();
                      setEffect(true);
                    }}
                    type="button"
                    className="inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-9 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                  >
                    <ArrowPathIcon
                      className={`${effect && 'animate-[spin_1s_linear]'} h-4 w-4 text-gray-600`}
                      onAnimationEnd={() => setEffect(false)}
                    />
                  </button>
                  <DisclosureButton as="div">
                    <button
                      type="button"
                      className="inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-9 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                      onClick={(): void => setIsDropOpen(!isDropOpen)}
                    >
                      <ChevronDoubleDownIcon
                        className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                          !open && 'rotate-180'
                        } ease-in-out duration-150`}
                      />
                    </button>
                  </DisclosureButton>
                  <button value="Search" type="submit" className="btn btn-primary" disabled={isSearchingAsset}>
                    {isSearchingAsset ? <Loader className="animate-spin w-5 h-5 mx-2" /> : <span>Search</span>}
                  </button>
                </div>
                <DisclosurePanel as="div" className="space-y-2">
                  <div className="flex flex-grow justify-between mt-2">
                    <Combobox
                      value={selectedJob}
                      onChange={(value): void => {
                        setSelectedJob(value);
                        setSearchedJob(value);
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Job</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                            onChange={(event): void => setJobQuery(event.target.value)}
                            displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                            onFocus={(e) => e.target.select()}
                            onSelect={(event) => selectedJob && fetchAssetClasses(selectedJob.value)}
                            autoComplete="off"
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {filteredJobs.map((job) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              a-key={job.value}
                              key={job.value}
                              value={job}
                            >
                              {job.label}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>

                    <Combobox
                      name="inspector"
                      value={selectedInspector}
                      onChange={(inspector): void => {
                        setSelectedInspector(inspector || '');
                        setInspector(inspector || '');
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Inspector</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                            onChange={(event): void => setInspectorQuery(event.target.value)}
                            displayValue={(inspector: string) => inspector}
                            onFocus={(e) => e.target.select()}
                            autoComplete="off"
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {filteredInspectors.map((inspector) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={inspector}
                              value={inspector}
                            >
                              {inspector}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                    <Combobox
                      name="suburbOrTown"
                      value={selectedSuburbOrTown}
                      onChange={(suburb): void => {
                        setSelectedSuburbOrTown(suburb || '');
                        setSuburb(suburb || '');
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Suburb/Town</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            onChange={(event): void => {
                              if (event.target.value.length > 2) {
                                setSuburbOrTownQuery(event.target.value);
                              } else if (event.target.value.length === 0) {
                                setSuburbOrTownQuery('');
                              }
                            }}
                            autoComplete="off"
                            displayValue={(suburbOrTown: string) => suburbOrTown}
                            onFocus={(e) => e.target.select()}
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {suburbOrTownQuery.length > 0 && (
                            <ComboboxOption
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-8 pr-4 ${
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={suburbOrTownQuery}
                            >
                              Search "{suburbOrTownQuery}"
                            </ComboboxOption>
                          )}
                          {filteredSuburbOrTown.map((suburbOrTown) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={suburbOrTown}
                              value={suburbOrTown}
                            >
                              {suburbOrTown}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                    {/* <div className="">
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                      Suburb
                    </label>
                    <input
                      ref={register}
                      type="text"
                      name="suburb"
                      className="mt-1 focus:ring-indigo-500 bg-gray-100 text-gray-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-none rounded-md"
                    />
                  </div> */}
                    <Combobox
                      name="facility"
                      value={selectedFacility}
                      onChange={(facility): void => {
                        setSelectedFacility(facility || '');
                        setFacility(facility || '');
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Facility</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            onChange={(event): void => {
                              if (event.target.value.length > 2) {
                                setFacilityQuery(event.target.value);
                              } else if (event.target.value.length === 0) {
                                setFacilityQuery('');
                              }
                            }}
                            onBlur={(event): void => {
                              filterSubFacilities(event.target.value);
                            }}
                            autoComplete="off"
                            displayValue={(facility: string) => facility}
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {facilityQuery.length > 0 && (
                            <ComboboxOption
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-8 pr-4 ${
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={facilityQuery}
                            >
                              Search "{facilityQuery}"
                            </ComboboxOption>
                          )}
                          {filteredFacilities.map((facility) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={facility}
                              value={facility}
                            >
                              {facility}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>

                    <Combobox
                      name="subFacility"
                      value={selectedSubFacility}
                      onChange={(subFacility): void => {
                        setSelectedSubFacility(subFacility || '');
                        setSubFacility(subFacility || '');
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Sub-Facility</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            onChange={(event): void => {
                              if (event.target.value.length > 2) {
                                setSubFacilityQuery(event.target.value);
                              } else if (event.target.value.length === 0) {
                                setSubFacilityQuery('');
                              }
                            }}
                            autoComplete="off"
                            displayValue={(subFacility: string) => subFacility}
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {subFacilityQuery.length > 0 && (
                            <ComboboxOption
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-8 pr-4 ${
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={subFacilityQuery}
                            >
                              Search "{subFacilityQuery}"
                            </ComboboxOption>
                          )}
                          {filteredSubFacilities.map((subFacility) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={subFacility}
                              value={subFacility}
                            >
                              {subFacility}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>

                    {/* <div className="">
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                      Valuer
                    </label>
                    <input
                      ref={register}
                      type="text"
                      name="valuer"
                      className="mt-1 focus:ring-indigo-500 bg-gray-100 text-gray-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-none rounded-md"
                    />
                  </div> */}
                  </div>
                  <div className="flex flex-grow justify-between space-x-4">
                    <div className="">
                      <label htmlFor="valuationPolicy" className="whitespace-nowrap block text-sm font-medium text-gray-700">
                        Valuation Policy
                      </label>
                      <select
                        defaultValue="All"
                        {...register('valuationPolicy')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setValuationPolicy(e.target.value)}
                      >
                        <option>All</option>
                        <option>Not to be Valued</option>
                        <option value="Above Revaluation Threshold">Revalue</option>
                        {/* <option>Historical Cost</option>
                      <option>Indexed Historical Cost</option> */}
                        {/* <option>Undefined</option> */}
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Valuation Type
                      </label>
                      <select
                        defaultValue="All"
                        {...register('valuationType')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setValuationType(e.target.value)}
                      >
                        <option>All</option>
                        <option>Cost</option>
                        <option>Market</option>
                        <option>Income</option>
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        History
                      </label>
                      <select
                        defaultValue="All"
                        {...register('history')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setHistory(e.target.value)}
                      >
                        <option>All</option>
                        <option>Old</option>
                        <option>New</option>
                        <option>Found</option>
                      </select>
                    </div>

                    {/* <div className="">
                      <label htmlFor="location" className="whitespace-nowrap block text-sm font-medium text-gray-700">
                        Heritage
                      </label>
                      <select
                        defaultValue="All"
                        ref={register}
                        name="heritage"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>All</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div> */}

                    <div className="sm:hidden">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Held for Sale
                      </label>
                      <select
                        defaultValue="All"
                        {...register('sale')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setIsHeldForSale(e.target.value)}
                      >
                        <option>All</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Investment
                      </label>
                      <select
                        defaultValue="All"
                        {...register('investment')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setInvestment(e.target.value)}
                      >
                        <option>All</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="controlled" className="block text-sm font-medium text-gray-700">
                        Controlled
                      </label>
                      <select
                        defaultValue="All"
                        {...register('controlled')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setControlled(e.target.value)}
                      >
                        <option>All</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="">
                      <label htmlFor="inpection" className="block text-sm font-medium text-gray-700">
                        Inspection
                      </label>
                      <select
                        defaultValue="All"
                        {...register('status')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setInspection(e.target.value)}
                      >
                        <option>All</option>
                        <option>Not Required</option>
                        <option>To Be Inspected</option>
                        <option>Inspected</option>
                        <option>Complete</option>
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="inpection" className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <select
                        defaultValue="All"
                        {...register('state')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e): void => setStatus(e.target.value)}
                      >
                        <option>All</option>
                        <option>Valid</option>
                        <option>Errors</option>
                        <option>Warnings</option>
                      </select>
                    </div>

                    <Combobox
                      name="valuer"
                      value={selectedValuer}
                      onChange={(valuer) => {
                        setSelectedValuer(valuer || '');
                        setValuer(valuer || '');
                      }}
                    >
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Valuer</label>
                        <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                          <ComboboxInput
                            className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                            onChange={(event): void => setValuerQuery(event.target.value)}
                            displayValue={(valuer: string) => valuer}
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                          />
                          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </ComboboxButton>
                        </div>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {filteredValuers.map((valuer) => (
                            <ComboboxOption
                              className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                              key={valuer}
                              value={valuer}
                            >
                              {valuer}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                  </div>
                </DisclosurePanel>
              </form>

              {!isMapView ? (
                <div className={`mx-3 ${!isSearchingAsset && searchText !== '' && assets.length <= 0 && 'h-50'}`}>
                  {roles.includes(Roles.Viewer) ? (
                    <BasicTable
                      columns={columns}
                      data={assets}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isLoading={isSearchingAsset}
                      exportAction={exportAssets}
                      isExporting={isExportingAssets}
                      tablePageSize={tablePageSize}
                      setTablePageSize={setTablePageSize}
                    />
                  ) : (
                    <BasicTable
                      columns={columns}
                      data={assets}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isLoading={isSearchingAsset}
                      deleteAction={deleteAssets}
                      exportAction={exportAssets}
                      isDeleting={isDeletingAssets}
                      isExporting={isExportingAssets}
                      tablePageSize={tablePageSize}
                      setTablePageSize={setTablePageSize}
                      context="Asset"
                    />
                  )}
                  {!isSearchingAsset && searchComplete && assets.length <= 0 && (
                    <div className="text-center mt-14 pt-3 pb-6">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          vectorEffect="non-scaling-stroke"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        />
                      </svg>
                      <h3 className="mt-2 text-sm font-medium text-gray-900">No Assets Found</h3>
                      <p className="mt-1 text-sm text-gray-500">Please create a new asset or revise search parameters</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative w-full grow px-4 mb-2">
                  <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    options={(map) => ({
                      streetViewControl: true,
                      controlSize: 22,
                      mapTypeControl: true,
                      mapTypeControlOptions: {
                        style: map.MapTypeControlStyle.DROPDOWN,
                        position: map.ControlPosition.TOP_CENTER,
                        mapTypeIds: [map.MapTypeId.ROADMAP, map.MapTypeId.SATELLITE],
                      },
                    })}
                    onGoogleApiLoaded={({ map }) => {
                      mapRef.current = map;
                    }}
                    onChange={({ center, bounds, zoom }) => {
                      setDefaultZoom(zoom);
                      setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
                      setLatitude(center.lat);
                      setLongitude(center.lng);
                    }}
                    bootstrapURLKeys={{ key: 'AIzaSyAIa1oCSlhCZOpu9lAdfqENa_r0oOKuyKU' }}
                    center={defaultCenter}
                    zoom={defaultZoom}
                  >
                    {clusters?.map((cluster) => {
                      const [longitude, latitude] = cluster.geometry.coordinates;
                      const { cluster: isCluster, point_count: pointCount, asset } = cluster.properties;
                      if (isCluster) {
                        return (
                          <ClusterMarker
                            valuerMode={isValuerMode}
                            key={`cluster-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                            pointCount={pointCount}
                            leaves={supercluster.getLeaves(cluster.id, 1000)}
                            zoomToCluster={() => {
                              const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                              mapRef.current.setZoom(expansionZoom);
                              mapRef.current.panTo({ lat: latitude, lng: longitude });
                            }}
                          />
                        );
                      }

                      return (
                        <Marker key={`${asset.id}`} lat={latitude} lng={longitude} asset={asset} valuerMode={isValuerMode} />
                      );
                    })}
                  </GoogleMapReact>
                  <button
                    type="button"
                    onClick={(): void => setIsMapView(false)}
                    title="Swap to asset list"
                    className="absolute top-2 left-5 shadow-sm inline-flex bg-white items-center gap-x-2.5 px-3 py-2 text-sm rounded-md font-semibold text-gray-500 hover:bg-gray-200 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-gray-200"
                  >
                    <ListBulletIcon className="h-5 w-6 text-gray-600" />
                    List
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Disclosure>
      <ExportAssetsModal
        isOpen={isExportingAssets}
        closeModal={setIsExportingAssets}
        assetIds={assetsToExport}
        jobId={selectedJob?.value ?? 0}
        context={client}
      />
    </>
  );
};

export default AssetSearch;

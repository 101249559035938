import React, { useState, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import PapaParse from 'papaparse';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { Menu, Transition } from '@headlessui/react';
import { CloudArrowDownIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { useStrategy } from '../contexts/StrategyContext';
import { useBaselineAssumptions, BaselineStrategy } from '../contexts/BaselineStrategyContext';
import { useToast } from '../contexts/ToastContext';
import { ReactComponent as DownChevron } from '../icons/DownChevron.svg';
import { ReactComponent as Loader } from '../icons/Loader.svg';

import useOnClickOutside from '../components/useOnClickOutside';
import { useApi } from '../contexts/ApiContext';

interface Props {}

interface CSVFile {
  file: FileWithPath;
  isValid: boolean | null;
  assetClass: string;
  closeAction(): void;
}

const UpdateHierarchy: React.FC<Props> = () => {
  const api = useApi();
  const { baselineStrategies: assetHierarchies, isLoadingBaselineStrategies: isLoadingHierarchies } =
    useBaselineAssumptions();

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Update Baseline Strategies</h1>
        </div>
      </div>
      <div className="mt-6 flex flex-col ">
        {isLoadingHierarchies && (
          <div className="flex flex-col justify-center items-center h-screen">
            <Loader className="animate-spin w-6 h-6 mb-3" />
            <p className="text-base font-medium text-gray-500">Loading Baseline Strategies</p>
          </div>
        )}

        {!isLoadingHierarchies && (
          <table className="md:w-3/4 mx-8 min-h-full shadow rounded-md sm:border-b border-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 rounded-l-md text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Asset Class
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 rounded-r-md" />
              </tr>
            </thead>
            <tbody>
              {assetHierarchies &&
                assetHierarchies.map((assetHierarchy, index) => <AssetClassRow key={index} {...assetHierarchy} />)}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const AssetClassRow: React.FC<BaselineStrategy> = ({ id, name, assetClass, lastRefreshed }) => {
  console.log('lastRefreshed', lastRefreshed);
  console.log('assetClass', assetClass);
  console.log('name', name);
  const { createBaselineStrategy, baselineStrategies } = useStrategy();

  const [isCreatingBaseline, setIsCreatingBaseline] = useState<boolean>(false);
  const [isUpdatingHierarchies, setIsUpdatingHierarchies] = useState<boolean>(false);

  const { uploadBaselineAssumptions, downloadBaselineAssumptions } = useBaselineAssumptions();

  const { addToast } = useToast();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean | null>(null);
  const onDrop = useCallback((acceptedFiles) => {
    console.log('parse', acceptedFiles[0]);
    uploadBaselineAssumptions(id, acceptedFiles[0]);
    setIsValid(true);
    setIsMenuOpen(false);
  }, []);

  const isExisting = baselineStrategies.findIndex((strategy) => strategy.assetClass === name) >= 0;
  const actionText = isExisting ? 'Update Hierarchy' : 'Import Hierarchy';
  const ref = useRef<HTMLInputElement | null>(null);

  useOnClickOutside(ref, () => {
    setIsMenuOpen(false);
  });

  return (
    <tr className="bg-white">
      <td className="px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">{name}</td>

      <td className="px-6 py-2 whitespace-nowrap flex items-center justify-start leading-5 font-medium">
        <div className="relative inline-block text-left flex flex-row w-full">
          <button
            disabled={!isExisting}
            onClick={(): void => downloadBaselineAssumptions(id, name)}
            className="btn btn-link"
          >
            <CloudArrowDownIcon className="mr-3 h-5 w-5 text-indigo-600 group-hover:text-indigo-800" aria-hidden="true" />
          </button>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }): JSX.Element => (
              <div {...getRootProps()} className="flex">
                <input {...getInputProps()} />
                <button disabled={!isExisting} className="btn btn-link">
                  <CloudArrowUpIcon
                    className="mr-3 h-5 w-5 text-indigo-600 group-hover:text-indigo-800"
                    aria-hidden="true"
                  />
                </button>
              </div>
            )}
          </Dropzone>

          <button
            disabled={isExisting}
            onClick={(): void => createBaselineStrategy(id, setIsCreatingBaseline)}
            className="btn btn-link grow flex-row justify-center"
          >
            {isCreatingBaseline ? <Loader className="animate-spin w-4 h-4 " /> : 'Create Baseline Strategy'}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default UpdateHierarchy;

const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const authority = `${import.meta.env.VITE_AUTH}`;

const configuration = {
  client_id: 'spa',
  redirect_uri: `${host}/login-callback`,
  post_logout_redirect_uri: `${host}/`,
  scope: 'openid profile api offline_access',
  authority,
  token_renew_mode: 'access_token_invalid',
  refresh_time_before_tokens_expiration_in_second: 70,
};

export default configuration;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { BasicTable } from '../../components/BasicTable';
import { currencyFormat, numberFormat, percentFormat } from '../../Format';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';

const MarketApproachDetail: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: {
      marketApproach: {
        propertyValue = NaN,
        improvementsPct = NaN,
        indexationPct = NaN,
        type = '',
        landArea = NaN,
        landAreaUnit = '',
        landRate = NaN,
        landTotal = NaN,
        marketValue = NaN,
      } = {},
    } = {} as Asset,
  } = useAssetById(parseInt(id));

  const isLand = type === 'Land Only';
  const isItem = type === 'Item Only';
  const isImprovements = type === 'Improvements On Land';
  console.log('marketApproach', landAreaUnit)
  return (
    <div>
      <dl>
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Type</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{type}</dd>
        </div>
        {isItem && (
          <>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Property Value</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(propertyValue)}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-bold text-gray-900">Market Value</dt>
              <dd className="mt-1 font-bold text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(marketValue)}</dd>
            </div>
          </>
        )}
        {isLand && (
          <>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Rate</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(landRate)}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Area</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{numberFormat.format(landArea)} {landAreaUnit}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Total</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(landTotal)}</dd>
            </div>
          </>
        )}

        {isImprovements && (
          <>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Property Value</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(propertyValue)}</dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Rate</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(landRate)}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Area</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{numberFormat.format(landArea)} {landAreaUnit}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Land Total</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(landTotal)}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Improvements Allocation</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{percentFormat.format(improvementsPct)}</dd>
            </div>
          </>
        )}
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Indexation Pct</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{percentFormat.format(indexationPct)}</dd>
        </div>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="font-bold text-gray-900">Market Value</dt>
          <dd className="mt-1 font-bold text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(marketValue)}</dd>
        </div>
      </dl>
    </div>
  );
};

export default MarketApproachDetail;

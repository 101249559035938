import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Asset, DropDownItem, LocationHierachy } from '../models/Asset';

type Props = {
  labels: string[];
  placeholder?: string;
};

type PropsWithId = {
  options: DropDownItem[];
  placeholder?: string;
};

export const AutoCompleteWithControl = (props: Props & UseControllerProps<Asset>) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController(props);
  const [query, setQuery] = useState('');
  const { labels, name } = props;
  const filteredLabels =
    query === '' ? labels.slice(0, 800) : labels.filter((locationKey) => locationKey.toLowerCase().includes(query.toLowerCase())).slice(0, 300);
  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative mt-1">
        <div>
          <ComboboxInput
            className={`w-full rounded-md ${
              errors[name] ? 'border-rose-600' : ''
            } border border-gray-300 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
            onChange={(event) => {
              if (event.target.value.length > 2) {
                setQuery(event.target.value);
              } else if (event.target.value.length === 0) {
                setQuery('');
              }
            }}
            displayValue={(label: string) => label}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {query.length > 0 && (
              <ComboboxOption
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={query}
              >
                Add "{query}"
              </ComboboxOption>
            )}
            {filteredLabels.map((obj) => (
              <ComboboxOption
                key={obj}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={obj}
              >
                {obj}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
};

export const AutoComplete = (props: Props & UseControllerProps<any>) => {
  const {
    field: { value, onChange },
  } = useController(props);
  const [query, setQuery] = useState('');
  const { labels, placeholder } = props;
  const filteredLabels =
    query === '' ? labels : labels.filter((locationKey) => locationKey.toLowerCase().includes(query.toLowerCase()));
  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative mt-2">
        <div>
          <ComboboxInput
            className="w-full rounded-full border border-gray-300 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(label: string) => label}
            placeholder={placeholder}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {query.length > 0 && (
              <ComboboxOption
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={query}
              >
                Search "{query}"
              </ComboboxOption>
            )}
            {filteredLabels.map((obj) => (
              <ComboboxOption
                key={obj}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={obj}
              >
                {obj}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
};

export const AutoCompleteMini = (props: PropsWithId & UseControllerProps<any>) => {
  const {
    field: { value, onChange },
  } = useController(props);
  const [query, setQuery] = useState('');
  const { options, placeholder } = props;
  const filteredLabels =
    query === '' ? options : options.filter((nameKey) => nameKey.label.toLowerCase().includes(query.toLowerCase()));
  return (
    <Combobox value={value} onChange={onChange}>
      <div className="flex-grow relative mr-2">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left ">
          <ComboboxInput
            className="w-full rounded-full border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(label: DropDownItem) => label.label}
            placeholder={placeholder}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredLabels.map((obj) => (
              <ComboboxOption
                key={obj.value}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={obj}
              >
                {obj.label}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
}
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from '../models/User';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';
import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';
import { useQueryClient } from '@tanstack/react-query';

interface Invite {
  email: string;
  role: string;
  clientId?: number;
  groupId?: number;
}
interface Props {
  isOpen: boolean;
  closeModal(): void;
  clientId?: number;
  groupId?: number;
  hasUpdated(): void;
  setAdmin: boolean;
  clientAdmin?: boolean;
  groupAdmin?: boolean;
}
const AddUserModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  clientId,
  groupId,
  hasUpdated,
  setAdmin = false,
  clientAdmin = false,
  groupAdmin = false,
}) => {
  const queryClient = useQueryClient();
  const [dbUsers, setDbUsers] = useState<User[]>([] as User[]);
  const [users, setUsers] = useState<User[]>([] as User[]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { roles } = useAuth();
  const { addToast } = useToast();
  const api = useApi();
  const InviteSchema = yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().when('setAdmin', { is: false, then: (schema) => schema.required() }),
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,

    formState: { errors },
  } = useForm<Invite>({
    defaultValues: {
      clientId,
      groupId,
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(InviteSchema),
  });

  useEffect(() => {
    const whichGroup = roles.includes(Roles.Administrator) ? 0 : groupId;
    api
      .get(`/api/User/List?groupId=${whichGroup}&admin=${roles.includes(Roles.Administrator)}`)
      .then(({ data }) => {
        setDbUsers(data.filter((user: User) => user.userName !== 'support@assetvaluer.net'));
      })
      .catch((error) => {});
  }, [isOpen]);

  useEffect(() => {
    setUsers(dbUsers);
  }, [dbUsers]);

  useEffect(() => {
    setUsers(
      dbUsers.filter(
        (user) =>
          user.firstName?.includes(searchQuery) ||
          user.lastName?.includes(searchQuery) ||
          user.email.includes(searchQuery) ||
          user.userName.includes(searchQuery)
      )
    );
  }, [searchQuery]);

  useEffect(() => {
    reset({
      ...getValues(),
      clientId,
      groupId,
    });
  }, [clientId, groupId]);

  const addUser = (user: User): void => {
    const url = groupAdmin
      ? `/api/Group/AddAdministrator`
      : clientAdmin
      ? `/api/Client/AddAdministrator`
      : `/api/Client/AddUser`;
    api
      .post(url, { userId: user.id, clientId, groupId })
      .then(({ data }) => {
        clientId && queryClient.invalidateQueries(["Client", clientId.toString()]);
        hasUpdated();
        closeModal();
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const onSubmit = (invite: Invite): void => {
    api
      .post('/api/User/Invite', { ...invite, role: clientAdmin ? Roles.CAdministrator : invite.role })
      .then(({ data }) => {
        const result = data.isFailed === true ? data.reasons[0].message : `Sent invite to ${invite.email}`;
        addToast(result, data.isFailed === true ? ToastType.Error : ToastType.Message);
        clientId && queryClient.invalidateQueries(["Client", clientId.toString()]);
        groupId && queryClient.invalidateQueries(["Group", groupId.toString()]);
        closeModal();
      })
      .catch((error) => {
        addToast(`Unable to invite ${invite.email}. ${error}`, ToastType.Error);
      });
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0" open={isOpen} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom max-h-144 overflow-y-hidden bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-144 sm:max-w-3xl sm:w-full sm:p-6">
              <div className="flex flex-col">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="h-44">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-8 w-8 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Add users</h2>
                    <p className="mt-1 text-sm text-gray-500">As the administrator of this client, you can invite users.</p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex">
                    <input type="hidden" {...register('clientId', { valueAsNumber: true })} />
                    <input type="hidden" {...register('groupId', { valueAsNumber: true })} />
                    <label htmlFor="email" className="sr-only">
                      Email address
                    </label>
                    <input
                      type="email"
                      {...register('email')}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Enter an email"
                    />
                    <button type="submit" className="ml-4 btn btn-primary flex-shrink-0">
                      Send invite
                    </button>
                  </form>
                  <div className="flex space-x-4 mt-1 ml-2">
                    {/* {setAdmin && (
                      <div className="flex items-center">
                        <input
                          ref={register}
                          name="role"
                          type="radio"
                          value="Administrator"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="administrator" className="ml-3 block text-sm font-medium text-gray-700">
                          Administrator
                        </label>
                      </div>
                    )} */}
                    {!setAdmin && (
                      <>
                        <div className="flex items-center">
                          <input
                            {...register('role')}
                            name="role"
                            type="radio"
                            value="Valuer"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="editor" className="ml-3 block text-sm font-medium text-gray-700">
                            Valuer
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            {...register('role')}
                            name="role"
                            type="radio"
                            value="Viewer"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                            Viewer
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {(!groupAdmin || groupId === 2 || groupId === 126) && (
                  <div className="max-h-92 overflow-y-auto">
                    {users !== null && users.length > 0 && (
                      <h3 className="mt-10 text-xs font-semibold text-gray-500 uppercase tracking-wide">
                        Or alternatively you can add an existing user to this client
                      </h3>
                    )}
                    <ul className="mt-4 divide-y divide-gray-200 mr-4">
                      {users.map(
                        (user) =>
                          user.isSearchable && (
                            <li key={user.email} className="py-4 flex justify-between">
                              <div className="flex items-center">
                                <div className="ml-3 w-56">
                                  <p className="text-sm font-medium text-gray-900">
                                    {user.firstName} {user.lastName}
                                  </p>
                                  <p className="text-xs text-gray-500">{user.email}</p>
                                </div>
                                <div className="flex space-x-4 m-2">
                                  {Object.keys(user).length >= 0 &&
                                    user.roles.map((role) => (
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 h-6">
                                        {role.name}
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <button
                                onClick={(): void => addUser(user)}
                                className="text-indigo-600 hover:text-indigo-900 mr-3 text-right text-sm font-medium"
                              >
                                Add User
                              </button>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddUserModal;

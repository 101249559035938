import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Asset } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';
import { ImageDisplay, ImagesImport } from '../../models/Image';
import { useApi } from '../../contexts/ApiContext';
import { useToast } from '../../contexts/ToastContext';

const ImageViewer: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const api = useApi();
  const { addToast } = useToast();
  const { refetch, data: { images } = {} as Asset } = useAssetById(parseInt(id));
  const [assetImages, setAssetImages] = useState<ImageDisplay[]>([]);
  const deleteImage = (image: ImageDisplay): void => {
    setAssetImages(assetImages.filter((img) => img.original !== image.original));
    api
      .post(`/api/Asset/DeleteImage`, {
        imagePath: image.original,
        assetId: image.assetId,
        contentName: '',
      })
      .then(({ data }) => {
        addToast('Deleted Image');
      })
      .catch(({ error }) => {
        refetch()
          .then((data) => {
            setAssetImages(data.data?.images || []);
          })
          .catch((error) => console.error('Unable to get images', error));
        return addToast('An error has occurred');
      });
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setAssetImages(images);
    }
  }, [images]);

  return (
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 m-4 max-h-112 overflow-y-auto">
        {assetImages.map((image, index) => (
          <li key={image.original} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img src={image.original} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
            </div>
            <div className="mt-2 flex justify-between">
              <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{image.date}</p>
              <button
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200  sm:text-sm"
                onClick={(): void => deleteImage(image)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageViewer;

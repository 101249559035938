import React from 'react';
import GoogleMapReact from 'google-map-react';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useParams } from 'react-router-dom';
import { ReactComponent as LocationMarker } from '../../icons/LocationMarker.svg';
import { Asset } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';

interface MarkerProps {
  lat: number;
  lng: number;
  text: string;
}
const Marker: React.FC<MarkerProps> = ({ text }) => (
  <div className="absolute top-1/2 left-1/2 pb-6 select-none transform -translate-x-1/2 -translate-y-1/2">
    <LocationMarker className="h-7 w-7 text-red-600" />
  </div>
);

const Location: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();

  const { data: { notes, latitude, longitude, streetAddress, suburb, city, state, postCode, country } = {} as Asset } =
    useAssetById(parseInt(id));

  const locationNote: string | undefined = notes?.find((note) => note.tag === 'Location')?.detail;

  const center = {
    lat: latitude && latitude > -90 && latitude < 90 ? latitude : null,
    lng: longitude && longitude > -180 && longitude < 180 ? longitude : null,
  };
  const zoom = 15;
  return (
    <>
      {' '}
      {locationNote !== undefined && (
        <div className="m-2 rounded-md bg-indigo-50 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-indigo-700">{locationNote}</p>
              {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <a
                    href="#"
                    className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600"
                  >
                    Details <span aria-hidden="true">&rarr;</span>
                  </a>
                </p> */}
            </div>
          </div>
        </div>
      )}
      {center.lat && center.lng ? (
        <div className="w-full h-80 relative">
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            options={(map) => ({
              streetViewControl: true,
              controlSize: 22,
              mapTypeControl: true,
              mapTypeId: map.MapTypeId.SATELLITE,
              mapTypeControlOptions: {
                style: map.MapTypeControlStyle.DROPDOWN,
                position: map.ControlPosition.TOP_CENTER,
                mapTypeIds: [map.MapTypeId.ROADMAP, map.MapTypeId.SATELLITE],
              },
            })}
            bootstrapURLKeys={{ key: 'AIzaSyAIa1oCSlhCZOpu9lAdfqENa_r0oOKuyKU' }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            <Marker lat={center.lat} lng={center.lng} text="test" />
          </GoogleMapReact>
        </div>
      ) : (
        <div className="rounded-md bg-yellow-50 p-4 m-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Unable to show map view due to invalid or empty coordinates</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white p-4 text-sm font-medium">
        <div className="">{streetAddress}</div>
        <div className="">{suburb}</div>
        <div className="">
          {city} {state} {postCode}
        </div>
      </div>
    </>
  );
};

export default Location;

import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { LeaseDto, LeaseOptionExpiry } from '../../models/Lease';
import { useUpdateLease, useLeaseById } from '../../hooks/UseLease';
import { removeTimezoneOffset } from '../../Helper';

export const LeaseEditor: React.FC = () => {
  const { id = '', assetId = '' } = useParams<{ id: string; assetId: string }>();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,

    formState: { isSubmitted, isDirty, errors },
  } = useForm<LeaseDto>({
    defaultValues: {
      id: 0,
      assetId: parseInt(assetId),
    },
  });
  // Add to database eventually
  const updateLease = useUpdateLease();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'leaseOptionExpiries',
    keyName: 'leaseOptionId',
  });

  const history = useNavigate();
  useEffect(() => {
    if (Object.keys(lease).length > 0) {
      reset({
        ...lease,
        assetId: lease.assetId > 0 ? lease.assetId : parseInt(assetId),
        takeUpOptionDate: lease.takeUpOptionDate ? new Date(lease.takeUpOptionDate) : undefined,
        leaseStartDate: lease.leaseStartDate ? new Date(lease.leaseStartDate) : undefined,
        leaseExpiryDate: lease.leaseExpiryDate ? new Date(lease.leaseExpiryDate) : undefined,
        rentStartDate: lease.rentStartDate ? new Date(lease.rentStartDate) : undefined,
        leaseOptionExpiries: [
          ...lease.leaseOptionExpiries.map((leaseOptionExpiry) => ({
            date: leaseOptionExpiry.date ? new Date(leaseOptionExpiry.date) : undefined,
          })),
        ],
      });
    }
  }, [lease]);

  const onSubmit = async (submittedData: LeaseDto): Promise<void> => {
    const result = {
      ...lease,
      ...submittedData,
      leaseStartDate:
        submittedData.leaseStartDate !== undefined && submittedData.leaseStartDate !== null
          ? removeTimezoneOffset(submittedData.leaseStartDate)
          : undefined,
      leaseExpiryDate:
        submittedData.leaseExpiryDate !== undefined && submittedData.leaseExpiryDate !== null
          ? removeTimezoneOffset(submittedData.leaseExpiryDate)
          : undefined,
      rentStartDate:
        submittedData.rentStartDate !== undefined && submittedData.rentStartDate !== null
          ? removeTimezoneOffset(submittedData.rentStartDate)
          : undefined,
      takeUpOptionDate:
        submittedData.takeUpOptionDate !== undefined && submittedData.takeUpOptionDate !== null
          ? removeTimezoneOffset(submittedData.takeUpOptionDate)
          : undefined,
    };
    console.log('result', result);
    updateLease.mutateAsync(result).then((leaseId) => {
      if (id === '0' && leaseId > 0) {
        history(`/leases/${leaseId}/edit`);
      }
    });
  };

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('assetId', { valueAsNumber: true })} />
      <input type="hidden" {...register('id', { valueAsNumber: true })} />
      <div className="mb-4 space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">General</h3>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      type="text"
                      {...register('name')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Occupancy
                    </label>
                    <input
                      type="text"
                      {...register('occupancy')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <input
                      type="text"
                      {...register('occupancyType')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Rent PA
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        step="any"
                        {...register('annualRent', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500  text-left block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder="0.00"
                        defaultValue={0}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Area
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="number"
                        step="any"
                        {...register('area', { valueAsNumber: true })}
                        defaultValue=""
                        className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Gross
                    </label>
                    <input
                      {...register('isGross')}
                      type="checkbox"
                      className="mt-2 focus:ring-indigo-600 h-6 w-6 text-indigo-700 rounded border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Details</h3>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Lease Type
                    </label>
                    <select
                      {...register('leaseType')}
                      className="block w-full rounded-md border-gray-300 bg-none text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:px-1 sm:text-sm lg:px-2"
                    >
                      <option>Retail</option>
                      <option>Other</option>
                      <option>Residential</option>
                      <option>Commercial</option>
                    </select>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Category
                    </label>
                    <select
                      {...register('leaseCategory')}
                      className="block w-full rounded-md border-gray-300 bg-none text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:px-1 sm:text-sm lg:px-2"
                    >
                      <option>Lease</option>
                      <option>License to Occupy</option>
                      <option>Occupancy Agreement</option>
                      <option>General Tenancy Agreement</option>
                      <option>Deed of Amendment</option>
                      <option>Sub Lease</option>
                      <option>License Agreement</option>
                      <option>Other</option>
                    </select>
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Registered With Titles Office?
                    </label>
                    <input
                      {...register('isRegisteredWithTitlesOffice')}
                      type="checkbox"
                      className="mt-2 focus:ring-indigo-600 h-6 w-6 text-indigo-700 rounded border-gray-300"
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Original Lease Held By
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      {...register('originalExecutedLeaseHeldBy')}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Owned By
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      {...register('ownedBy')}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Make Good Clause
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      {...register('makeGood')}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Terms</h3>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Lease Commencement Date
                      </label>
                    </div>
                    <Controller
                      name="leaseStartDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`mt-1 block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.leaseStartDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Lease Expiry Date
                      </label>
                    </div>
                    <Controller
                      name="leaseExpiryDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`mt-1 block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.leaseExpiryDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Rent Commencement
                      </label>
                      <span className="text-sm text-gray-500" id="email-optional">
                        Optional
                      </span>
                    </div>
                    <Controller
                      name="rentStartDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`mt-1 block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.rentStartDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>

                  <div className="sm:col-span-3" />
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Outgoing Expense Item
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        type="text"
                        defaultValue=""
                        {...register('outgoingExpenseItem')}
                        className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Outgoing Expense Amount
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        type="text"
                        {...register('outgoingExpense')}
                        defaultValue=""
                        id="amount"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500  text-left block w-full py-2 px-3 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="price-currency"
                      />
                    </div>
                  </div>

                  

                  <div className="sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Take Up Option Months
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        type="text"
                        {...register('takeUpOptionMonths')}
                        defaultValue=""
                        id="amount"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500  text-left block w-full py-2 px-3 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="price-currency"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="outgoingStart" className="block text-sm font-medium text-gray-700">
                        Take Up Option Date
                      </label>
                    </div>
                    <Controller
                      name="takeUpOptionDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`mt-1 block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.takeUpOptionDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                        Option Period Expiries
                      </label>
                      <button
                        type="button"
                        className="text-indigo-600 hover:text-indigo-900 whitespace-nowrap  text-sm  font-medium"
                        onClick={(): void =>
                          append({
                            date: new Date(),
                          } as LeaseOptionExpiry)
                        }
                      >
                        Add
                      </button>
                    </div>

                    <div className="relative">
                      <table className="min-w-full">
                        <tbody>
                          {fields.map((field, index) => (
                            <tr key={field.leaseOptionId} className="">
                              <td className="py-2 w-4 whitespace-nowrap text-sm text-gray-500">
                                <Controller
                                  name={`leaseOptionExpiries.${index}.date`}
                                  control={control}
                                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <ReactDatePicker
                                      dateFormat="dd/MM/yyyy"
                                      className="shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:text-sm border-gray-300"
                                      selected={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                    />
                                  )}
                                />
                              </td>
                              <td className="py-2 whitespace-nowrap text-right text-sm font-medium">
                                <div className="justify-end flex space-x-3">
                                  <button
                                    type="button"
                                    className="text-indigo-600 hover:text-indigo-900"
                                    onClick={(): void => {
                                      remove(index);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 pb-2">
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { LeaseDto } from '../../models/Lease';
import { useLeaseById, useUpdateLease } from '../../hooks/UseLease';
import { removeTimezoneOffset } from '../../Helper';

export const InsuranceEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const updateLease = useUpdateLease();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,

    formState: { isDirty, isSubmitted, errors },
  } = useForm<LeaseDto>();
  const history = useNavigate();
  useEffect(() => {
    if (Object.keys(lease).length > 0) {
      reset({
        ...lease,
        insuranceStartDate: lease.insuranceStartDate ? new Date(lease.insuranceStartDate) : undefined,
        insuranceEndDate: lease.insuranceEndDate ? new Date(lease.insuranceEndDate) : undefined,
      });
    }
  }, [lease]);

  const onSubmit = async (submittedData: LeaseDto): Promise<void> => {
    const result = {
      ...lease,
      ...submittedData,
      insuranceStartDate:
        submittedData.insuranceStartDate !== undefined && submittedData.insuranceStartDate !== null
          ? removeTimezoneOffset(submittedData.insuranceStartDate)
          : undefined,
      insuranceEndDate:
        submittedData.insuranceEndDate !== undefined && submittedData.insuranceEndDate !== null
          ? removeTimezoneOffset(submittedData.insuranceEndDate)
          : undefined,
    };
    updateLease.mutateAsync(result).then((leaseId) => {});
  };

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Insurance Information</h3>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Insurer
                    </label>
                    <input
                      type="text"
                      {...register('currentInsurer')}
                      id="currentInsurer"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <input
                      type="text"
                      {...register('insuranceType')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Insurance Start Date
                      </label>
                    </div>
                    <Controller
                      name="insuranceStartDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.insuranceStartDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Insurance Expiry Date
                      </label>
                    </div>
                    <Controller
                      name="insuranceEndDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className={`block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:text-sm border-gray-300 ${
                            errors?.insuranceEndDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          wrapperClassName="w-full"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={(): void => {
                history(`/leases/${id}`);
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

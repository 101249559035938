import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import { EllipsisVerticalIcon, PaperClipIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import { DocumentTextIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { LeaseContactModal } from '../../components/LeaseContactModal';
import { ContactType, ExternalPropertyManager, Landlord, LeaseContact, LeaseDto } from '../../models/Lease';
import { useLeaseById } from '../../hooks/UseLease';
import { ExisitingLeaseContactModal } from '../../components/ExistingLeaseContactModal';
import { useApi } from '../../contexts/ApiContext';
import { EntityType } from '../../models/EntityType';
import DocumentSlide from '../../components/DocumentSlide';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { useToast } from '../../contexts/ToastContext';

const tabs = [
  { name: 'Lease', href: '.', hidden: false },
  { name: 'Tenant', href: 'tenant', hidden: false },
  { name: 'Guarantee', href: 'guarantee', hidden: false },
  { name: 'Insurance', href: 'insurance', hidden: false },
  { name: 'Review', href: 'review', hidden: false },
];

export const LeaseView: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { refetch, data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { addToast } = useToast();
  const { roles } = useAuth();
  const api = useApi();
  const history = useNavigate();
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [isLeaseModalOpen, setIsLeaseModalOpen] = useState<boolean>(false);
  const [isExistingLeaseContactModalOpen, setIsExistingLeaseContactModalOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<Landlord | LeaseContact | ExternalPropertyManager>(
    {} as LeaseContact
  );
  useEffect(() => {
    if (!isLeaseModalOpen) {
      refetch();
    }
  }, [isLeaseModalOpen]);

  useEffect(() => {
    if (!isExistingLeaseContactModalOpen) {
      refetch();
    }
  }, [isExistingLeaseContactModalOpen]);

  const deleteLease = (): void => {
    api.post(`api/Lease/Delete?LeaseId=${id}`).then(() => {
      addToast(`Removed Lease: ${lease.name}`);
      history(`/leases`);
    });
  };

  const [selectedContactType, setSelectedContactType] = useState<string>('');
  const openAddExistingContactModal = (): void => {
    setIsExistingLeaseContactModalOpen(true);
  };
  const removeLeaseContact = (leaseContactId: number, assetId: number): void => {
    api.post('api/Lease/RemoveLeaseContact', { leaseContactId, assetId }).then(() => refetch());
  };

  return (
    <div className="m-4 space-y-8  ">
      <div className="bg-white overflow-hidden shadow rounded-md">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="text-center sm:pt-1 sm:text-left">
                <h2 className="text-lg font-bold leading-normal text-gray-900 sm:text-xl">{lease.name}</h2>
                <p className="text-sm font-semibold leading-normal text-gray-500">{lease.addressLine1}</p>
                <p className="text-sm font-semibold leading-normal text-gray-500">{lease.suburb}</p>
                <NavLink to={`/assets/${lease.assetId}`} className="text-indigo-600 hover:text-indigo-900 text-sm font-bold">
                  {lease.assetReference}
                </NavLink>
              </div>
            </div>
            <div className="mt-5 flex justify-between sm:mt-0">
              <button
                type="button"
                className="w-full inline-flex justify-center px-4 py-2 font-medium text-red-700 text-red-600 hover:underline focus:outline-none  sm:w-auto sm:text-sm"
                onClick={(): void => {
                  setDeleteConfirmationOpen(true);
                }}
              >
                Delete
              </button>
              <NavLink to={`/leases/${id}/edit`} className="sm:ml-3 btn btn-primary">
                Edit
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="max-w-7xl mx-auto my-4 px-4 sm:px-6 lg:px-8 ">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block ">
            <div className=" border-gray-200 ">
              <nav className="pb-4 -mb-px flex justify-around" aria-label="Tabs">
                {tabs
                  .filter((tab) => !tab.hidden)
                  .map((tab, index) => (
                    <NavLink
                      key={index}
                      to={`${tab.href}`}
                      end={tab.name === 'Lease'}
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                          isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                        }`
                      }
                      // activeClassName="border-indigo-500 hover:border-indigo-500"
                    >
                      {tab.name}
                    </NavLink>
                  ))}
              </nav>
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <ul className="flex justify-between space-x-4">
        {lease.landlord != null ? (
          <li className="col-span-1 flex flex-col min-w-[30%] space-y-4 bg-white rounded-lg shadow divide-y divide-gray-200  ">
            <dd className="mt-3 flex flex-row justify-center">
              <span className="px-2 py-1 text-indigo-800 text-center grow text-lg font-medium w-full rounded-full">
                Landlord
              </span>
              {!roles.includes(Roles.Viewer) && (
                <Menu as="div" className="relative inline-block text-left self-center mx-2">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 mx-3 origin-top absolute -left-28 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => {
                                setSelectedContact(lease.landlord);
                                setIsLeaseModalOpen(true);
                              }}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Edit
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => removeLeaseContact(lease.landlord.id, lease.assetId)}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Remove
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </dd>
            <div className=" flex-1 px-4 py-2 text-center">
              {/* <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={person.imageUrl} alt="" /> */}
              <h3 className="mt-6 text-gray-900 text-sm font-medium">{lease.landlord.name}</h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Role</dt>
                {lease.landlord.email && (
                  <a href={`mailto:${lease.landlord.email}`} className="mt-1 text-sm text-gray-900">
                    <span className="ml-3">{lease.landlord.email}</span>
                  </a>
                )}
                {lease.landlord.phone && <dd className="mt-1 text-sm text-gray-900">{lease.landlord.phone}</dd>}
              </dl>
            </div>
            <div className="px-4 pt-2 place-content-evenly ">
              {lease.landlord?.tradingName && (
                <dd className="text-left  mt-1 text-xs  text-gray-900">
                  <span className="font-bold">Trading Name: </span>{' '}
                  <span className="mt-1 sm:mt-0 sm:col-span-3 pl-2">{lease.landlord?.tradingName}</span>
                </dd>
              )}
              {lease.landlord?.headLeaseName && (
                <dd className="text-left  mt-1 text-xs  text-gray-900">
                  <span className="font-bold">Head Lease Name: </span>{' '}
                  <span className="mt-1 sm:mt-0 sm:col-span-3 pl-2">{lease.landlord?.headLeaseName}</span>
                </dd>
              )}
            </div>
            <div />
          </li>
        ) : (
          <div className="col-span-1 flex flex-col space-y-4  text-center bg-white rounded-lg sm:w-1/3 md:1/4 lg:w-1/5 shadow p-4 text-center flex-col items-center">
            <UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-medium text-gray-900">Landlord</span>

            <div className="flex flex-row jusify-between">
              <div className="mt-6 mr-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(): void => {
                    setSelectedContact({
                      id: 0,
                      contactType: ContactType.Landlord,
                      name: '',
                      email: '',
                      phone: '',
                      tradingName: '',
                    } as LeaseContact);
                    setIsLeaseModalOpen(true);
                  }}
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  New
                </button>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={(): void => {
                    openAddExistingContactModal();
                    setSelectedContactType('Landlord');
                  }}
                  className="btn btn-secondary"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Existing
                </button>
              </div>
            </div>
          </div>
        )}
        {lease.internalPropertyManager != undefined ? (
          <li className="col-span-1 flex flex-col space-y-4 min-w-[30%] text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <dd className="mt-3 flex flex-row justify-center">
              <span className="px-2 py-1 text-indigo-800 text-center grow text-lg font-medium w-full rounded-full">
                Internal Property Manager
              </span>
              {!roles.includes(Roles.Viewer) && (
                <Menu as="div" className="relative inline-block text-left self-center mx-2">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 mx-3 origin-top absolute -left-28 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => {
                                setSelectedContact(lease.internalPropertyManager);
                                setIsLeaseModalOpen(true);
                              }}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Edit
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => removeLeaseContact(lease.internalPropertyManager.id, lease.assetId)}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Remove
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </dd>
            <div className=" flex-1  px-4 py-2">
              {/* <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={person.imageUrl} alt="" /> */}
              <h3 className="mt-6 text-gray-900 text-sm font-medium">{lease.internalPropertyManager.name}</h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Role</dt>
                {lease.internalPropertyManager.tradingName && (
                  <dd className="mt-1 text-sm text-gray-900 ">{lease.internalPropertyManager.tradingName}</dd>
                )}
                {lease.internalPropertyManager.email && (
                  <a href={`mailto:${lease.internalPropertyManager.email}`} className="mt-1 text-sm text-gray-900">
                    <span className="ml-3">{lease.internalPropertyManager.email}</span>
                  </a>
                )}
                {lease.internalPropertyManager.email && (
                  <dd className="mt-1 text-sm text-gray-900">{lease.internalPropertyManager.phone}</dd>
                )}
              </dl>
            </div>
            <div />
          </li>
        ) : (
          <div className="col-span-1 flex flex-col space-y-4  text-center bg-white rounded-lg sm:w-1/3 md:1/4 lg:w-1/5 shadow p-4 text-center flex-col items-center">
            <UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-medium text-gray-900">Internal Property Manager</span>
            <div className="flex flex-row jusify-between">
              <div className="mt-6 mr-2">
                <button
                  type="button"
                  onClick={(): void => {
                    setSelectedContact({
                      id: 0,
                      contactType: ContactType.InternalPropertyManager,
                      name: '',
                      email: '',
                      phone: '',
                      tradingName: '',
                    } as LeaseContact);
                    setIsLeaseModalOpen(true);
                  }}
                  className="btn btn-secondary"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  New
                </button>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={(): void => {
                    openAddExistingContactModal();
                    setSelectedContactType('Internal Property Manager');
                  }}
                  className="btn btn-secondary"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Existing
                </button>
              </div>
            </div>
          </div>
        )}
        {lease.externalPropertyManager != undefined ? (
          <li className="col-span-1 flex flex-col space-y-4 min-w-[30%] text-center bg-white rounded-lg shadow divide-y divide-gray-200 min-w ">
            <dd className="mt-3 flex flex-row justify-center">
              <span className="px-2 py-1 text-indigo-800 text-center grow text-lg font-medium w-full rounded-full">
                External Property Manager
              </span>
              {!roles.includes(Roles.Viewer) && (
                <Menu as="div" className="relative inline-block text-left self-center mx-2">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 mx-3 origin-top absolute -left-28 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => {
                                setSelectedContact(lease.externalPropertyManager);
                                setIsLeaseModalOpen(true);
                              }}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Edit
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(): void => removeLeaseContact(lease.externalPropertyManager.id, lease.assetId)}
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            >
                              Remove
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </dd>
            <div className="flex-1 flex flex-col px-4 py-2">
              {/* <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={person.imageUrl} alt="" /> */}
              <h3 className="mt-6 text-gray-900 text-sm font-medium">{lease.externalPropertyManager?.name}</h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Role</dt>
                {lease.externalPropertyManager?.email && (
                  <dd className="mt-1 text-sm text-gray-900">{lease.externalPropertyManager?.email}</dd>
                )}
                {lease.externalPropertyManager?.phone && (
                  <dd className="mt-1 text-sm text-gray-900">{lease.externalPropertyManager?.phone}</dd>
                )}
              </dl>
            </div>
            <div className="px-4 pt-2 place-content-evenly ">
              {lease.externalPropertyManager?.fee && (
                <dd className="text-left  mt-1 text-xs  text-gray-900">
                  <span className="font-bold">Fee: </span>{' '}
                  <span className="mt-1 sm:mt-0 sm:col-span-3 pl-2">{lease.externalPropertyManager?.fee}</span>
                </dd>
              )}
              {lease.externalPropertyManager?.reviewDate && (
                <dd className="text-left mt-1 text-xs text-gray-900">
                  <span className="font-bold">Review: </span>{' '}
                  <span className="mt-1 sm:mt-0 sm:col-span-3 pl-2">
                    {new Date(lease.externalPropertyManager?.reviewDate).toDateString()}
                  </span>
                </dd>
              )}

              {lease.externalPropertyManager?.appointedDate && (
                <dd className="text-left mt-1 text-xs text-gray-900">
                  <span className="font-bold">Appointed: </span>{' '}
                  <span className="mt-1 sm:mt-0 sm:col-span-3 pl-2">
                    {new Date(lease.externalPropertyManager?.appointedDate).toDateString()}
                  </span>
                </dd>
              )}
            </div>
            <div />
          </li>
        ) : (
          <div className="col-span-1 flex flex-col space-y-4  text-center bg-white rounded-lg sm:w-1/3 md:1/4 lg:w-1/5 shadow p-4 text-center flex-col items-center">
            <UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-medium text-gray-900">External Property Manager</span>
            <div className="flex flex-row jusify-between">
              <div className="mt-6 mr-2">
                <button
                  type="button"
                  onClick={(): void => {
                    setSelectedContact({
                      id: 0,
                      contactType: ContactType.ExternalPropertyManager,
                      name: '',
                      email: '',
                      phone: '',
                      tradingName: '',
                    } as LeaseContact);
                    setIsLeaseModalOpen(true);
                  }}
                  className="btn btn-secondary"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  New
                </button>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={(): void => {
                    openAddExistingContactModal();
                    setSelectedContactType('External Property Manager');
                  }}
                  className="btn btn-secondary"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Existing
                </button>
              </div>
            </div>
          </div>
        )}
      </ul>

      {/* <ul className=" ">
        <div className="pb-4 bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 sm:px-6 pt-6 pb-6 ">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500 pb-2">Documents</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <u className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-200" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">leases_details.pdf</span>
                  </div>
                  <div className="ml-4">
                    <button type="button" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      <span>Delete</span>
                    </button>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-200" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">leases_tenant_details.pdf</span>
                  </div>
                  <div className="ml-4">
                    <button type="button" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      <span>Delete</span>
                    </button>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
              </u>
            </dd>
          </div>
        </div>
      </ul> */}
      <button type="button" onClick={(): void => setOpen(true)} className="document-tab">
        <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <DocumentSlide entity={EntityType.Lease} entityId={parseInt(id)} open={open} setOpen={setOpen} />
      <ExisitingLeaseContactModal
        closeModal={(): void => setIsExistingLeaseContactModalOpen(false)}
        isOpen={isExistingLeaseContactModalOpen}
        assetId={lease.assetId}
        contact={selectedContactType}
      />
      <LeaseContactModal
        closeModal={(): void => setIsLeaseModalOpen(false)}
        isOpen={isLeaseModalOpen}
        url="/api/Lease/UpdateLeaseContact"
        assetId={lease.assetId}
        contact={selectedContact}
      />
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        itemType={lease.name ?? 'Lease'}
        deleteAction={deleteLease}
      />
    </div>
  );
};

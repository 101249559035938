import { OidcUserStatus, useOidc, useOidcUser } from '@axa-fr/react-oidc';
import React, { useState, createContext, ReactNode, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useApi } from './ApiContext';

interface AuthContext {
  userName: string;
  userId: number;
  client: string;
  clientGuid: string;
  clientId: number;
  groupId: number;
  isLoading: boolean;
  isLoggedIn: boolean;
  roles: string[];
  isAdminOfClient: boolean;
  fullName: string;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);
export const AuthProvider: React.FC<ReactNode> = ({ children }) => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const { login, isAuthenticated } = useOidc();
  const [userId, setUserId] = useState<number>(0);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(oidcUserLoadingState === OidcUserStatus.Loading);
  const [userName, setUserName] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [client, setClient] = useState<string>('');
  const [clientGuid, setClientGuid] = useState<string>('');
  const [clientId, setClientId] = useState<number>(0);
  const [groupId, setGroupId] = useState<number>(0);
  const [roles, setRoles] = useState<string[]>([]);
  const [isAdminOfClient, setIsAdminOfClient] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && oidcUser) {
      setIsLoggedIn(true);
      setUserName(oidcUser.name ?? userName);
      setUserId(parseInt(oidcUser.sub) ?? userId);
      setClient(oidcUser['Client Title']);
      setClientId(parseInt(oidcUser['Client ID']));
      setClientGuid(oidcUser['Client External ID']);
      setGroupId(oidcUser['Group ID']);
      setIsAdminOfClient(oidcUser.IsAdminOfClient === 'True');
      setRoles(oidcUser.role);
      setFullName(oidcUser['Full Name']);
      Sentry.setUser({ email: oidcUser.name, clientName: oidcUser['Client Title'] as string });
    }
  }, [isAuthenticated, oidcUser]);

  useEffect(() => {
    if (!isAuthenticated) {
      login();
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        userName,
        userId,
        client,
        clientGuid,
        isLoading,
        isLoggedIn,
        roles,
        isAdminOfClient,
        clientId,
        groupId,
        fullName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContext => useContext(AuthContext);

import React, { useMemo, useRef, useState } from 'react';
import { formatRelative } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useStrategy, Strategy, BaselineStrategy } from '../contexts/StrategyContext';
import CopyStrategyModal from '../components/CopyStrategyModal';
import useOnClickOutside from '../components/useOnClickOutside';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'dd/MM/yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token): void => formatRelativeLocale[token],
};

const Strategies: React.FC<{}> = () => {
  const { strategies = [] as Strategy[], baselineStrategies = [] as Strategy[] } = useStrategy();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  console.log('strategies', strategies);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-6">
      <div className="border-b border-gray-200 py-4 sm:flex sm:items-center sm:justify-between">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Strategies</h1>
        </div>
      </div>
      <div className="">
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">Baseline Strategies</h2>
        <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
          {baselineStrategies &&
            baselineStrategies.length > 0 &&
            baselineStrategies.map((strat, index) => (
              <BaselineStrategyRow key={index} strategy={strat} toggleCopyModal={(): void => setIsModalOpen(true)} />
            ))}
        </ul>
      </div>
      {/* <!-- Projects table (small breakpoint and up) --> */}
      <div className="hidden mt-8 sm:block rounded-md">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            {' '}
            {/* //TODO: Enable sorting by clicking headers. */}
            <table className="table min-w-full">
              <thead>
                <tr className="border-t rounded-md border-gray-200">
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">Strategy</span>
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Base Strategy
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Components
                  </th>
                  <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Last updated
                  </th>
                  <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" />
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {strategies.length > 0 &&
                  strategies
                    .filter((strat) => strat.baselineStrategyId !== strat.id)
                    .map((strat, index) => (
                      <StrategyRow key={index} strategy={strat} toggleCopyModal={(): void => setIsModalOpen(true)} />
                    ))}

                {/* <!-- More project rows... --> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CopyStrategyModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} />
    </div>
  );
};

interface BaselineStrategyProps {
  strategy: BaselineStrategy;
  toggleCopyModal(): void;
}

const BaselineStrategyRow: React.FC<BaselineStrategyProps> = ({ strategy: { assetClass, id }, toggleCopyModal }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const abbreviation = assetClass ? assetClass.split(' ').map((i) => i.toUpperCase().charAt(0)) : '';
  const [isPinnedMenu, setIsPinnedMenu] = useState<boolean>(false);
  const { setActive } = useStrategy();
  const deleteAndClearMenu = (): void => {
    setIsPinnedMenu(false);
  };

  useOnClickOutside(ref, () => {
    setIsPinnedMenu(false);
  });

  const setActiveAndCopy = (strategyId: number): void => {
    setActive(strategyId);
    setIsPinnedMenu(false);
    toggleCopyModal();
  };

  return (
    <li key={assetClass} className="col-span-1 flex rounded-md shadow-sm">
      <div className="bg-indigo-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
        {abbreviation}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <Link to="/" className="font-medium text-gray-900 hover:text-gray-600">
            {assetClass}
          </Link>
        </div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(): void => setActiveAndCopy(id)}
          >
            <span className="sr-only">Open options</span>
            <DocumentDuplicateIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </li>
  );
};

interface Props {
  strategy: Strategy;
  toggleCopyModal(): void;
}

const StrategyRow: React.FC<Props> = ({
  strategy: { id, name, numberOfComponents, baselineStrategyName, lastRefreshed },
  toggleCopyModal,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isCanvasMenu, setIsCanvasMenu] = useState<boolean>(false);
  const { deleteStrategy, setActive, activeStrategy } = useStrategy();

  useOnClickOutside(ref, () => {
    setIsCanvasMenu(false);
  });

  const deleteAndClearMenu = () => {
    setIsCanvasMenu(false);
    deleteStrategy(id);
  };

  const setActiveAndCopy = (strategyId: number): void => {
    setActive(strategyId);
    setIsCanvasMenu(false);
    toggleCopyModal();
  };

  return (
    <tr>
      <td className="px-6 py-3 max-w-0 w-5/12 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <Link to={`/Strategy/${id}`} className="truncate hover:text-gray-600">
            <span>{name}</span>
          </Link>
        </div>
      </td>
      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-medium">
        <div className="flex items-center space-x-2">
          <span className="flex-shrink-0 text-sm leading-5 font-medium">{baselineStrategyName}</span>
        </div>
      </td>
      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-medium text-right">
        <span className="flex-shrink-0 text-sm leading-5">{numberOfComponents}</span>
      </td>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 font-medium text-right">
        {formatRelative(new Date(lastRefreshed), new Date(), { locale })}
      </td>
      <td className="pr-6">
        <div className="relative flex justify-end items-center">
          <button
            onClick={(): void => setIsCanvasMenu(!isCanvasMenu)}
            id="project-options-menu-0"
            aria-haspopup="true"
            type="button"
            className="w-8 h-8 inline-flex items-center justify-center text-gray-400 bg-transparent hover:text-gray-500 rounded-md focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>

          <Transition
            show={isCanvasMenu}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div ref={ref} className="mx-3 origin-top-right rounded-md absolute right-7 top-0 w-48 mt-1 shadow-lg">
              <div
                className="z-10 bg-white shadow-xs rounded-md"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="project-options-menu-0"
              >
                <div className="py-1">
                  <Link
                    to={`/Strategy/${id}`}
                    className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    <svg
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Edit
                  </Link>
                  <a
                    onClick={() => setActiveAndCopy(id)}
                    className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    <svg
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                      <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                    </svg>
                    Copy
                  </a>
                </div>
                <div className="border-t border-gray-100" />
                <div className="py-1">
                  <a
                    onClick={() => deleteAndClearMenu()}
                    className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    <svg
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </td>
    </tr>
  );
};

export default Strategies;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useLeaseById } from '../../hooks/UseLease';
import { LeaseDto } from '../../models/Lease';

export const TenantList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const { tenants } = lease;

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 mt-4 ">
      {tenants &&
        tenants.map((tenant, index) => (
          <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg py-4  ">
            <div className="text-center space-y-4 ">
              {tenant.isPrimary ? (
                <dd className="mt-3">
                  <span className="px-2 py-1 text-indigo-800 text-xs font-medium bg-indigo-100 rounded-full">
                    Primary Tenant
                  </span>
                </dd>
              ) : (
                <dd className="mt-3">
                  <span className="px-2 py-1 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">Tenant</span>
                </dd>
              )}
              {tenant.name && <div className="text-sm text-gray-900 sm:mt-0">{tenant.name}</div>}
              {tenant.tradingName && <div className="text-sm font-medium text-gray-500">{tenant.tradingName}</div>}

              {tenant.phone && <div className=" text-sm text-gray-500 sm:mt-0">{tenant.phone}</div>}
              {tenant.email && <div className=" text-sm text-gray-500 sm:mt-0">{tenant.email}</div>}
            </div>
          </div>
        ))}
    </ul>
  );
};

export default TenantList;

import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { useApi } from '../../contexts/ApiContext';

import { AssetAssumptions } from '../../models/AssetAssumptions';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClassId: number;
  assetTypeId: number;
  assetSubTypeId: number;
  valuationClassId: number;
};

const AssetAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const {
    assetAssumptions,
    assetAssumptionsSearchText,
    getAssetAssumptions,
    assetAssumptionsCurrentPage,
    setAssetAssumptionsCurrentPage,
    isSearchingAssetAssumptions,
    assetClassList,
    tableAssetAssumptionsPageSize,
    setAssetAssumptionsTablePageSize,
  } = useAssumptionsSearch();

  // const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [valuationClassList, setValuationClassList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [selectedAssetClass, setSelectedAssetClass] = useState<number>(0);
  const [selectedAssetType, setSelectedAssetType] = useState<number>(0);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<FormData>();
  const columns: Column<AssetAssumptions>[] = React.useMemo(
    () => [
      {
        Header: 'Asset Class',
        headerClassName: 'px-3 py-3',
        accessor: 'assetClass',
        className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Type',
        headerClassName: 'px-3 py-3',
        accessor: 'assetType',
        className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Sub Type',
        headerClassName: 'px-3 py-3',
        accessor: 'assetSubType',
        className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'No. of Assets',
        headerClassName: 'px-3.5 py-3',
        accessor: 'assetsCount',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Financial Hierarchy',
        headerClassName: 'px-3.5 py-3',
        accessor: 'financialAssetClass',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): JSX.Element => (
          <>
            <div className="whitespace-nowrap text-sm leading-5">{value}</div>
            {row.original.financialAssetSubClass && (
              <div className="flex italic text-xs font-light">{row.original.financialAssetSubClass}</div>
            )}
          </>
        ),
      },
      {
        Header: 'Valuation Class',
        headerClassName: 'px-3.5 py-3',
        accessor: 'valuationClass.name',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Unit Rate',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'unitRate',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${currencyFormat.format(value)}`,
      },
      {
        Header: 'Locality Factor',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'localityFactorPct',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: 'Index Yr 1',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'indices[0]',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: 'Index Yr 2',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'indices[1]',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: 'Index Yr 3',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'indices[2]',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: 'Index Yr 4',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'indices[3]',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: 'Index Yr 5',
        headerClassName: 'px-3.5 py-3 text-right',
        accessor: 'indices[4]',
        className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): string => `${twoDecimalsPercentFormat.format(value)}`,
      },
      {
        Header: '',
        accessor: 'edit ',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ row }): JSX.Element => (
          <div>
            {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass) && (
              <NavLink className="btn-link" to={`/assetassumptions/${row.original.id}/edit`}>
                edit
              </NavLink>
            )}
          </div>
        ),
      },
    ],
    [readOnlyAssetClasses]
  );

  const onSubmit = handleSubmit(({ searchText, assetClassId, assetTypeId, assetSubTypeId, valuationClassId }) => {
    getAssetAssumptions(searchText, assetClassId, assetTypeId, assetSubTypeId, valuationClassId);
    setSearchComplete(true);
  });

  // const fetchAssetClasses = (jobId: number): void => {
  //   api
  //     .get(`/api/AssetClass/ListByJob?JobId=${jobId}`)
  //     .then(({ data }) => {
  //       setAssetClassList(data);
  //     })
  //     .catch((error) => {});
  // };

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList(data);
      })
      .catch((error) => {});
  };

  const fetchValuationClasses = (): void => {
    api
      .post('/api/ValuationClass/List', {})
      .then(({ data }) => {
        setValuationClassList(data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClass > 0) {
      fetchAssetTypes(selectedAssetClass);
    }
  }, [selectedAssetClass]);

  useEffect(() => {
    if (selectedAssetType > 0) {
      fetchAssetSubTypes(selectedAssetType);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    fetchValuationClasses();
  }, []);

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <>
          {/* <!-- Projects table (small breakpoint and up) --> */}
          <div className="hidden sm:block">
            <div className="align-middle inline-block min-w-full">
              <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
                <div className="flex-1 min-w-0 justify-between flex">
                  <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Asset Assumptions</h1>
                </div>

                <div className="pb-2 flex items-end flex-wrap">
                  <div className="flex-grow mr-3">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="mt-1 relative min-w-max">
                      <div
                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        aria-hidden="true"
                      >
                        {/* Heroicon name: search */}
                        <svg
                          className="mr-3 h-4 w-4 text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        {...register('searchText')}
                        id="searchText"
                        defaultValue={assetAssumptionsSearchText}
                        className="py-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                        placeholder="Search" />
                    </div>
                  </div>
                  <div className="pt-1 mx-2">
                    <label className="block text-sm font-medium text-gray-700">Asset Class</label>
                    <select
                      defaultValue="All"
                      {...register('assetClassId')}
                      onChange={(e): void => setSelectedAssetClass(Number(e.target.value))}
                      className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      {assetClassList.map((ac) => (
                        <option key={ac.value} value={ac.value}>
                          {ac.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pt-1 mx-2">
                    <label className="block text-sm font-medium text-gray-700">Asset Type</label>
                    <select
                      defaultValue="All"
                      {...register('assetTypeId')}
                      onChange={(e): void => setSelectedAssetType(Number(e.target.value))}
                      className="mt-1 block min-w-16 w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value={0}>All</option>
                      {assetTypeList.map((ac) => (
                        <option key={ac.value} value={ac.value}>
                          {ac.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pt-1 mx-2">
                    <label className="block text-sm font-medium text-gray-700">Asset Sub Type</label>
                    <select
                      defaultValue="All"
                      {...register('assetSubTypeId')}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value={0}>All</option>
                      {assetSubTypeList.map((ac) => (
                        <option key={ac.value} value={ac.value}>
                          {ac.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pt-1 mx-2">
                    <label className="block text-sm font-medium text-gray-700">Valuation Class</label>
                    <select
                      defaultValue="All"
                      {...register('valuationClassId')}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value={0}>All</option>
                      {valuationClassList.map((ac) => (
                        <option key={ac.value} value={ac.value}>
                          {ac.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input value="Search" type="submit" className="mt-1 ml-1 mr-3 btn btn-primary" />

                  {/* <Disclosure.Button as="div">
                    <button
                      type="button"
                      className="m-1  inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-10 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                    >
                      <ChevronDoubleDownIcon
                        className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                          !open && 'rotate-180'
                        } ease-in-out duration-150`}
                      />
                    </button>
                  </Disclosure.Button> */}
                </div>
                {/* <div className="flex justify-end" /> */}
              </form>
              <div
                className={`mx-3 ${
                  !isSearchingAssetAssumptions && assetAssumptionsSearchText !== '' && assetAssumptions.length <= 0
                }`}
              >
                <BasicTable
                  columns={columns}
                  data={assetAssumptions}
                  currentPage={assetAssumptionsCurrentPage}
                  setCurrentPage={setAssetAssumptionsCurrentPage}
                  isLoading={isSearchingAssetAssumptions}
                  tablePageSize={tableAssetAssumptionsPageSize}
                  setTablePageSize={setAssetAssumptionsTablePageSize}
                />

                {!isSearchingAssetAssumptions && searchComplete && assetAssumptions.length <= 0 && (
                  <div className="text-center mt-14 pt-3 pb-6">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Asset Assumptions Found</h3>
                    <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default AssetAssumptionsSearch;

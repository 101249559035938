import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';

import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { Tenant, LeaseDto } from '../../models/Lease';
import { useLeaseById, useUpdateLease } from '../../hooks/UseLease';

export const TenantEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    getValues,

    formState: { isDirty, isSubmitted, errors },
  } = useForm<LeaseDto>({});
  const { data: lease = {} as LeaseDto } = useLeaseById(parseInt(id));
  const updateLease = useUpdateLease();

  const { fields, append, remove } = useFieldArray({
    name: 'tenants',
    control,
  });
  const history = useNavigate();
  useEffect(() => {
    if (Object.keys(lease).length > 0) {
      reset({ ...lease });
    }
  }, [lease]);

  // Add to database eventually
  const onSubmit = async (submittedData): Promise<void> => {
    const result = { ...lease, ...submittedData };
    updateLease.mutateAsync(result).then((leaseId) => {});
  };

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <ul>
        {fields.map((data, index) => (
          <li key={data.id} className="mb-4 space-y-6">
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Tenant</h3>
                </div>
                <div className="md:mt-0 md:col-span-2">
                  <div className="bg-white space-y-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="text"
                          {...register(`tenants.${index}.name`)}
                          defaultValue={data.name}
                          className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          {...register(`tenants.${index}.phone`)}
                          defaultValue={data.phone}
                          className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="sm:col-span-3">
                        <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="text"
                          {...register(`tenants.${index}.email`)}
                          defaultValue={data.email}
                          className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                          Trading Name
                        </label>
                        <input
                          type="text"
                          {...register(`tenants.${index}.tradingName`)}
                          defaultValue={data.tradingName}
                          className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="flex justify-between sm:col-span-6">
                        <div className="mt-4 flex items-center h-5">
                          <input
                            {...register(`tenants.${index}.isPrimary`)}
                            type="checkbox"
                            defaultChecked={data.isPrimary}
                            className="focus:ring-indigo-500 rounded-full h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <div className="ml-3 text-sm">
                            <label className="font-medium text-gray-700">Primary Tenant</label>
                          </div>
                        </div>
                        {index !== 0 && (
                          <button
                            type="button"
                            className="mr-3 py-2 px-4 font-medium text-red-600 hover:underline focus:outline-none"
                            onClick={(): void => {
                              remove(index);
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        <div className="my-4 pb-2">
          <div className="flex justify-between">
            <button
              type="button"
              className="btn btn-link"
              onClick={(): void =>
                append({
                  name: '',
                  phone: '',
                  email: '',
                  tradingName: '',
                  isPrimary: false,
                } as Tenant)
              }
            >
              Add Tenant
            </button>
            <div className="space-x-4">
              <button
                type="button"
                onClick={(): void => {
                  history(`/leases/${id}`);
                }}
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </ul>
    </form>
  );
};

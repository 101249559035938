import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { BasicTable, EnhancedColumn } from '../../components/BasicTable';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { currencyFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { AssetBrief, AssetSummary, AssetWithIndices } from '../../models/AssetSummary';
import { AssetAssumptions } from '../../models/AssetAssumptions';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

export const AssetAssumptionsView: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assetAssumptions, setAssetAssumptions] = useState<AssetAssumptions>({
    valuationClass: {},
  } as AssetAssumptions);
  useEffect(() => {
    api
      .get(`/api/Assumptions/GetAssetAssumptionsWithAssets?id=${id}`)
      .then(({ data }) => {
        setAssetAssumptions(data);
        setIsLoading(false);
      })
      .catch((error) => {
        addToast('Error retrieving Asset Assumptions', ToastType.Error);
      });
  }, [id]);

  const {
    assetClass,
    assetType,
    assetSubType,
    financialAssetClass,
    financialAssetSubClass,
    unitRate,
    localityFactorPct,
    indices = [],
    valuationClass: { name, valuationLevel, valuationTechnique },
    assetsCount,
    assets = [] as AssetBrief[],
  } = { ...assetAssumptions, valuationClass: assetAssumptions.valuationClass || {} };
  const columns: EnhancedColumn<AssetWithIndices>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        headerClassName: 'px-3.5 py-3',
        accessor: 'name',
        className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ row, value }): JSX.Element => (
          <Link to={`/assets/${row.original.id}`} className="btn-link">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asset Id',
        headerClassName: 'px-3.5 py-3',
        accessor: 'reference',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Financial Asset Sub Class',
        headerClassName: 'px-3.5 py-3',
        accessor: 'financialAssetSubClass',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Financial Asset Class',
        headerClassName: 'px-3.5 py-3',
        accessor: 'financialAssetClass',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },

      {
        Header: 'Valuation Class',
        headerClassName: 'px-3.5 py-3',
        accessor: 'valuationClassName',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },

      {
        Header: '',
        accessor: 'edit',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        Cell: ({ row }) => {
          return (
            <>
              {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass) && (
                <Link className="btn-link" to={`/assets/${row.original.id}/edit`}>
                  edit
                </Link>
              )}
            </>
          );
        },
      },
    ],
    [readOnlyAssetClasses]
  );

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Assumption</p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Asset Assumptions</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && (
                  <span className="shadow-sm">
                    <Link to={`/assetassumptions/${id}/edit`} className="btn btn-primary">
                      Edit
                    </Link>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Number of Assets</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetsCount}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Class</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetClass}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Sub Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetSubType}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Financial Hierarchy</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Financial Asset Class</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{financialAssetClass}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Financial Asset Sub Class</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{financialAssetSubClass}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Valuation Class</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name ?? ''}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Technique</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{valuationTechnique ?? ''}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Level</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{valuationLevel ?? ''}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Cost</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Unit Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {currencyFormat.format(unitRate)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Locality Factor</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(localityFactorPct)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Indexation</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Year 1</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(indices[0])}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Year 2</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(indices[1])}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Year 3</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(indices[2])}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Year 4</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(indices[3])}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Year 5</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {twoDecimalsPercentFormat.format(indices[4])}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="m-4">
        <BasicTable data={assets} columns={columns} />
      </div>
    </div>
  );
};

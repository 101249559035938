import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import useOnClickOutside from './useOnClickOutside';
import { useApi } from '../contexts/ApiContext';

export type FilterForm = {
  assetType: string;
  assetSubType: string;
  component: string;
  componentType: string;
  componentSubType: string;
  assetId: string;
};

interface Props {
  strategyId: number;
  assetClass: string;
  setFilters: (filter: FilterForm) => void;
  isFilterMenu: boolean;
  setIsFilterMenu: (open: boolean) => void;
}

const initialState: string[] = [''];
const loadingState: string[] = ['Loading...'];

export const Filter: React.FC<Props> = ({ strategyId, assetClass, setFilters, isFilterMenu, setIsFilterMenu }) => {
  const api = useApi();
  const [isLoadingAssetType, setIsLoadingAssetType] = useState<boolean>(false);
  const [isLoadingAssetSubType, setIsLoadingAssetSubType] = useState<boolean>(false);
  const [isLoadingComponent, setIsLoadingComponent] = useState<boolean>(false);
  const [isLoadingComponentType, setIsLoadingComponentType] = useState<boolean>(false);
  const [isLoadingComponentSubType, setIsLoadingComponentSubType] = useState<boolean>(false);
  const [isLoadingAssetId, setIsLoadingAssetId] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement | null>(null);
  const [assetTypes, setAssetTypes] = useState<string[]>(initialState);
  const [assetSubTypes, setAssetSubTypes] = useState<string[]>(initialState);
  const [components, setComponents] = useState<string[]>(initialState);
  const [componentTypes, setComponentTypes] = useState<string[]>(initialState);
  const [componentSubTypes, setComponentSubTypes] = useState<string[]>(initialState);
  const [assetIds, setAssetIds] = useState<string[]>(initialState);

  const navigate = useNavigate();

  const { register, handleSubmit, getValues, setValue, watch } = useForm<FilterForm>({
    reValidateMode: 'onBlur',
  });

  const watchAssetType = watch('assetType')
  const watchAssetSubType = watch('assetSubType')
  const watchComponent = watch('component')
  const watchComponentType = watch('componentType')
  const watchComponentSubType = watch('componentSubType')

  const getAllData = async (): Promise<Array<AxiosResponse>> => {
    const responses = await Promise.all([
      api.post('/api/Filter/AssetType', { strategyId, assetClass }),
      api.post('/api/Filter/AssetSubType', { strategyId, assetClass }),
      api.post('/api/Filter/Component', { strategyId, assetClass }),
      api.post('/api/Filter/ComponentType', { strategyId, assetClass }),
      api.post('/api/Filter/ComponentSubType', { strategyId, assetClass }),
      api.post('/api/Filter/AssetId', { strategyId, assetClass }),
    ]);
    return responses;
  };

  const refreshAssetTypeChildren = (): void => {
    const { assetType } = getValues();
    const parameters = { strategyId, assetClass, assetType };
    setAssetSubTypes(loadingState);
    setComponents(loadingState);
    setComponentTypes(loadingState);
    setComponentSubTypes(loadingState);
    setAssetIds(loadingState);

    setIsLoadingAssetSubType(true);
    setIsLoadingComponent(true);
    setIsLoadingComponentType(true);
    setIsLoadingComponentSubType(true);
    setIsLoadingAssetId(true);

    Promise.all([
      api.post('/api/Filter/AssetSubType', parameters),
      api.post('/api/Filter/Component', parameters),
      api.post('/api/Filter/ComponentType', parameters),
      api.post('/api/Filter/ComponentSubType', parameters),
      api.post('/api/Filter/AssetId', parameters),
    ]).then(
      ([assetSubTypeResponse, componentResponse, componentTypeResponse, componentSubTypeResponse, assetIdResponse]) => {
        setAssetSubTypes([...initialState, ...assetSubTypeResponse.data]);
        setComponents([...initialState, ...componentResponse.data]);
        setComponentTypes([...initialState, ...componentTypeResponse.data]);
        setComponentSubTypes([...initialState, ...componentSubTypeResponse.data]);
        setAssetIds([...initialState, ...assetIdResponse.data]);
        setValue('assetSubType', '');
        setValue('component', '');
        setValue('componentType', '');
        setValue('componentSubType', '');
        setValue('assetId', '');
        setIsLoadingAssetSubType(false);
        setIsLoadingComponent(false);
        setIsLoadingComponentType(false);
        setIsLoadingComponentSubType(false);
        setIsLoadingAssetId(false);
      }
    );
  };

  const refreshAssetSubTypeChildren = (): void => {
    const { assetType, assetSubType } = getValues();
    const parameters = { strategyId, assetClass, assetType, assetSubType };
    setComponents(loadingState);
    setComponentTypes(loadingState);
    setComponentSubTypes(loadingState);
    setAssetIds(loadingState);

    setIsLoadingComponent(true);
    setIsLoadingComponentType(true);
    setIsLoadingComponentSubType(true);
    setIsLoadingAssetId(true);

    Promise.all([
      api.post('/api/Filter/Component', parameters),
      api.post('/api/Filter/ComponentType', parameters),
      api.post('/api/Filter/ComponentSubType', parameters),
      api.post('/api/Filter/AssetId', parameters),
    ]).then(([componentResponse, componentTypeResponse, componentSubTypeResponse, assetIdResponse]) => {
      setComponents([...initialState, ...componentResponse.data]);
      setComponentTypes([...initialState, ...componentTypeResponse.data]);
      setComponentSubTypes([...initialState, ...componentSubTypeResponse.data]);
      setAssetIds([...initialState, ...assetIdResponse.data]);
      setValue('component', '');
      setValue('componentType', '');
      setValue('componentSubType', '');
      setValue('assetId', '');

      setIsLoadingComponent(false);
      setIsLoadingComponentType(false);
      setIsLoadingComponentSubType(false);
      setIsLoadingAssetId(false);
    });
  };

  const refreshComponentChildren = (): void => {
    const { assetType, assetSubType, component } = getValues();
    const parameters = { strategyId, assetType, assetSubType, component };

    setComponentTypes(loadingState);
    setComponentSubTypes(loadingState);
    setAssetIds(loadingState);

    setIsLoadingComponentType(true);
    setIsLoadingComponentSubType(true);
    setIsLoadingAssetId(true);

    Promise.all([
      api.post('/api/Filter/ComponentType', parameters),
      api.post('/api/Filter/ComponentSubType', parameters),
      api.post('/api/Filter/AssetId', parameters),
    ]).then(([componentTypeResponse, componentSubTypeResponse, assetIdResponse]) => {
      setComponentTypes([...initialState, ...componentTypeResponse.data]);
      setComponentSubTypes([...initialState, ...componentSubTypeResponse.data]);
      setAssetIds([...initialState, ...assetIdResponse.data]);
      setValue('componentType', '');
      setValue('componentSubType', '');
      setValue('assetId', '');

      setIsLoadingComponentType(false);
      setIsLoadingComponentSubType(false);
      setIsLoadingAssetId(false);
    });
  };

  const refreshComponentTypeChildren = (): void => {
    const { assetType, assetSubType, component, componentType } = getValues();
    const parameters = {
      strategyId,
      assetClass,
      assetType,
      assetSubType,
      component,
      componentType,
    };

    setComponentSubTypes(loadingState);
    setAssetIds(loadingState);

    setIsLoadingComponentSubType(true);
    setIsLoadingAssetId(true);

    Promise.all([api.post('/api/Filter/ComponentSubType', parameters), api.post('/api/Filter/AssetId', parameters)]).then(
      ([componentSubTypeResponse, assetIdResponse]) => {
        setComponentSubTypes([...initialState, ...componentSubTypeResponse.data]);
        setAssetIds([...initialState, ...assetIdResponse.data]);
        setValue('componentSubType', '');
        setValue('assetId', '');

        setIsLoadingComponentSubType(false);
        setIsLoadingAssetId(false);
      }
    );
  };

  const refreshComponentSubTypeChildren = (): void => {
    const { assetType, assetSubType, component, componentType, componentSubType } = getValues();
    const parameters = {
      strategyId,
      assetClass,
      assetType,
      assetSubType,
      component,
      componentType,
      componentSubType,
    };

    setAssetIds(loadingState);
    setIsLoadingAssetId(true);

    Promise.all([api.post('/api/Filter/AssetId', parameters)]).then(([assetIdResponse]) => {
      setAssetIds([...initialState, ...assetIdResponse.data]);
      setValue('assetId', '');
      setIsLoadingAssetId(false);
    });
  };

  useEffect(() => {
    if (assetClass !== undefined) {
      setAssetTypes(loadingState);
      setAssetSubTypes(loadingState);
      setComponents(loadingState);
      setComponentTypes(loadingState);
      setComponentSubTypes(loadingState);
      setAssetIds(loadingState);

      setIsLoadingAssetId(true);
      setIsLoadingAssetType(true);
      setIsLoadingAssetSubType(true);
      setIsLoadingComponent(true);
      setIsLoadingComponentType(true);
      setIsLoadingComponentSubType(true);
      getAllData().then(
        ([
          assetTypeResponse,
          assetSubTypeResponse,
          componentResponse,
          componentTypeResponse,
          componentSubTypeResponse,
          assetIdResponse,
        ]) => {
          setAssetTypes([...initialState, ...assetTypeResponse.data]);
          setAssetSubTypes([...initialState, ...assetSubTypeResponse.data]);
          setComponents([...initialState, ...componentResponse.data]);
          setComponentTypes([...initialState, ...componentTypeResponse.data]);
          setComponentSubTypes([...initialState, ...componentSubTypeResponse.data]);
          setAssetIds([...initialState, ...assetIdResponse.data]);
          setIsLoadingAssetId(false);
          setIsLoadingAssetType(false);
          setIsLoadingAssetSubType(false);
          setIsLoadingComponent(false);
          setIsLoadingComponentType(false);
          setIsLoadingComponentSubType(false);
        }
      );
    }
  }, [assetClass]);

  useEffect(() => {
    refreshAssetTypeChildren()
  }, [watchAssetType])
  
  useEffect(() => {
    refreshAssetSubTypeChildren()
  }, [watchAssetSubType])

  useEffect(() => {
    refreshComponentChildren()
  }, [watchComponent])

  useEffect(() => {
    refreshComponentTypeChildren()
  }, [watchComponentType])

  useEffect(() => {
    refreshComponentSubTypeChildren()
  }, [watchComponentSubType])

  useOnClickOutside(ref, () => {
    navigate(-1);
  });

  const onSubmit = (data: FilterForm): void => {
    setIsFilterMenu(false);
    setFilters(data);
  };
  return (
    <>
      <Transition
        show={isFilterMenu}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition>
      <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
        <Transition
          show={isFilterMenu}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div ref={ref} className="w-screen max-w-md">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" min-h-screen h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
            >
              <div className="min-h-0 flex-1 flex flex-col py-6 space-y-6 overflow-y-scroll">
                <header className="px-4 sm:px-6">
                  <div className="flex items-start justify-between space-x-3">
                    <h2 className="text-lg leading-7 font-medium text-gray-900">Report Filters</h2>
                    <div className="h-7 flex items-center" />
                  </div>
                </header>
                <div className="relative flex-1 px-4 sm:px-6">
                  <div className="absolute inset-0 px-4 sm:px-6">
                    <div>
                      <div>
                        <div className="mt-6 flex flex-col">
                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="assetType" className="block text-sm font-medium leading-5 text-gray-700">
                              Asset Type
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                disabled={isLoadingAssetType}
                                id="assetType"
                                {...register('assetType')}
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {assetTypes.map((assetType) => (
                                  <option key={assetType} value={assetType}>
                                    {assetType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">
                              Asset Sub-Type
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                disabled={isLoadingAssetSubType}
                                {...register('assetSubType')}
                                id="assetSubType"
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {assetSubTypes.map((assetSubType) => (
                                  <option key={assetSubType} value={assetSubType}>
                                    {assetSubType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">
                              Component
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                disabled={isLoadingComponent}
                                {...register('component')}
                                id="component"
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {components.map((component) => (
                                  <option key={component} value={component}>
                                    {component}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">
                              Component Type
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                {...register('componentType')}
                                disabled={isLoadingComponentType}
                                id="componentType"
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {componentTypes.map((componentType) => (
                                  <option key={componentType} value={componentType}>
                                    {componentType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">
                              Component Sub-Type
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                disabled={isLoadingComponentSubType}
                                {...register('componentSubType')}
                                id="componentSubType"
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {componentSubTypes.map((componentSubType) => (
                                  <option key={componentSubType} value={componentSubType}>
                                    {componentSubType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-3 pb-5">
                            <label htmlFor="assetId" className="block text-sm font-medium leading-5 text-gray-700">
                              Asset ID
                            </label>
                            <div className="mt-1 shadow-sm">
                              <select
                                disabled={isLoadingAssetId}
                                id="assetId"
                                {...register('assetId')}
                                className="block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {assetIds.map((assetId) => (
                                  <option key={assetId} value={assetId}>
                                    {assetId}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /End replace */}
                </div>
              </div>
              <div className="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                <span className="inline-flex shadow-sm">
                  <button onClick={(): void => setIsFilterMenu(false)} type="button" className="btn btn-secondary">
                    Cancel
                  </button>
                </span>
                <span className="inline-flex shadow-sm">
                  <button
                    disabled={
                      isLoadingAssetId ||
                      isLoadingAssetSubType ||
                      isLoadingAssetType ||
                      isLoadingComponent ||
                      isLoadingComponentType ||
                      isLoadingAssetSubType
                    }
                    type="submit"
                    className="btn btn-primary"
                  >
                    Run
                  </button>
                </span>
              </div>
            </form>
          </div>
        </Transition>
      </section>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { Subscription } from '../../models/Subscription';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

const SubscriptionView: React.FC<{}> = () => {
  const api = useApi();
  const { id = '' } = useParams<{ id: string }>();

  const { isFetching, data: subscription = {} as Subscription } = useQuery<Subscription, Error>(
    ['Subscription', id],
    async () => api.get(`/api/Subscription/Get?id=${id}`).then(({ data }) => data)
  );

  if (isFetching) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6  mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Subscription...</p>
      </div>
    );
  }

  const { name, type, start, expiry, fee, client, group, contact, due, currency } = subscription;

  return (
    <>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Information</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link to={`/subscriptions/${id}/edit`} className="btn btn-primary">
                    Edit
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{type}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Currency</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currency !== null ? currency : 'N/A'}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Start Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{format(new Date(start), 'PP')}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Expiry Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{format(new Date(expiry), 'PP')}</dd>
              </div>
              {type === 'Client' && (
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Client</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {client === null ? (
                      <Link to={`/subscriptions/${id}/clients/0/edit`} className="btn btn-secondary">
                        Add Client
                      </Link>
                    ) : (
                      <Link to={`/clients/${client?.id}`} className="btn btn-link">
                        {client?.name}
                      </Link>
                    )}
                  </dd>
                </div>
              )}

              {type === 'Group' && (
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Group</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {group === null ? (
                      <Link to={`/subscriptions/${id}/groups/0/edit`} className="btn btn-secondary">
                        Add Group
                      </Link>
                    ) : (
                      <Link to={`/groups/${group?.id}`} className="btn-link">
                        {group?.name}
                      </Link>
                    )}
                  </dd>
                </div>
              )}

              {/* {type === 'External' && (
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">External Client</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Link
                      to="/clients/0/edit"
                      className="inline-flex items-center px-2.5 py-1.5 rounded-md border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create
                    </Link>
                  </dd>
                </div>
              )} */}
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Billing Information</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Fee</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{fee}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Frequency</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Annually</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Next Billing Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{format(new Date(due), 'PP')}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="p-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Postion</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.position}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.email}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.phone}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Address Line 1</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.addressLine1}</dd>
              </div>
              {contact?.address?.addressLine2 && (
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address Line 2</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.addressLine2}</dd>
                </div>
              )}

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Suburb</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.suburb}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">City</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.city}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">State / Province</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.state}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Postcode / Zip</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.postCode}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      {/* <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Documents</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link
                    to={`/subscriptions/${id}/edit`}
                    className="inline-flex items-center px-4 py-2 rounded-md border border-transparent text-sm font-medium shadow-sm text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    Add Document
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <svg
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="ml-2 flex-1 w-0 truncate">AVP_Valuers_Signed_Contract.pdf</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Download
                        </a>
                        <span className="mx-4 text-gray-300">|</span>
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Remove
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SubscriptionView;

/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon, CameraIcon as OutlineCamera, XMarkIcon } from '@heroicons/react/24/outline';
import { ImageDisplay } from '../models/Image';
import { useAuth } from '../contexts/AuthContext';

interface Props {
  isOpen: boolean;
  closeModal(): void;
  imageUrls?: string[];
  setImageToDelete: React.Dispatch<React.SetStateAction<string>>;
  setIsImageDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ContentImagesModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  imageUrls,
  setImageToDelete,
  setIsImageDeleteModalOpen,
}) => {
  const { clientGuid } = useAuth();
  const cameraRef = useRef<HTMLInputElement>(null);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={closeModal}>
        <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div onClick={closeModal} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom px-5 py-5 overflow-auto max-h-[75vh] bg-white rounded-lg text-center shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
              <h3 className="text-left text-lg font-medium leading-6 text-gray-900 pb-4">Content Images</h3>
              <ul className="grid sm:gap-x-4 sm:gap-y-4 sm:grid-cols-3 lg:grid-cols-3 lg:gap-x-3 xl:gap-x-6">
                
                {imageUrls &&
                  imageUrls.map((url) => (
                    <li className="group h-auto relative block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                      <img
                        src={`${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${url}`}
                        alt=""
                        className="pointer-events-none object-cover group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute top-0 left-0 p-0.5 w-6 h-6 m-2 bg-red-100 rounded-full"
                        onClick={(): void => {
                          setImageToDelete(url);
                          setIsImageDeleteModalOpen(true);
                        }}
                      >
                        <XMarkIcon className="text-red-700 " />
                      </button>
                    </li>
                  ))}
              </ul>

              {/* {imageUrls == undefined && (
                <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                  Date taken: {images[currentImage].date}
                </p>
              )} */}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContentImagesModal;

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import * as yup from 'yup';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Component } from '../../models/Component';
import { useApi } from '../../contexts/ApiContext';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ErrorMessage } from '../../components/ErrorMessage';
import { HierarchyType } from '../../models/HierarchyType';
import { removeTimezoneOffset } from '../../Helper';
import { ComponentAssumptions } from '../../models/ComponentAssumptions';
import { useUpdateComponent } from '../../hooks/UseAsset';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

export const ComponentEdit: React.FC = () => {
  const api = useApi();
  const { addToast } = useToast();
  const history = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const updateComponent = useUpdateComponent();
  const ComponentSchema = yup.object().shape({
    subTypeId: yup.number().min(1, 'Component Sub Type is required').typeError('Component Sub Type is required'),
    apportionmentPct: yup.number().min(0).max(100, 'Apportionment cannot be greater than 100%'),
    consumptionScore: yup
      .number()
      .transform((value): number | undefined => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .min(0)
      .max(5, 'Score cannot be greater than 5'),
    longLifePct: yup
      .number()
      .min(0)
      .typeError('Long Life Pct must at least be 0')
      .max(100, 'Long Life Pct must at least be 0'),
    ulLong: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when(['longLifePct', 'depreciationPolicy'], ([longLifePct, depreciationPolicy], schema) => {
        if (longLifePct > 0 && depreciationPolicy !== 'Do Not Apply') {
          return schema
            .moreThan(0, 'Long life must be greater than 0 when Long Life Pct is greater than 0%')
            .required('Long Life is required');
        }
        return schema.transform((value) => (Number.isNaN(value) ? undefined : value)).nullable();
      }),
    ulShortMin: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when('depreciationPolicy', ([depreciationPolicy], schema) => {
        if (depreciationPolicy !== 'Do Not Apply') {
          return schema.min(1).required('UL Short Min is required');
        }
        return schema.transform((value) => (Number.isNaN(value) ? undefined : value)).nullable();
      }),
    ulShortMax: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when('depreciationPolicy', ([depreciationPolicy], schema) => {
        if (depreciationPolicy !== 'Do Not Apply') {
          return schema
            .min(yup.ref('ulShortMin'), 'UL Short Max must be greater than or equal to UL Short Min')
            .required('UL Short Max is required');
        }
        return schema.transform((value) => (Number.isNaN(value) ? undefined : value)).nullable();
      }),
    acquisitionDate: yup.date().nullable(),
    decommissionDate: yup
      .date()
      .nullable()
      .when('acquisitionDate', ([acquisitionDate], schema) => {
        if (acquisitionDate) {
          return schema.min(acquisitionDate, 'Decomission date must be after Acquisition date');
        }
        return schema;
      }),
    rvPctShort: yup
      .number()
      .min(0, 'RV % Short must be between 0 - 100')
      .max(100, 'RV % Short must be between 0 - 100')
      .required(),
    rvPctLong: yup
      .number()
      .min(0, 'RV % Short must be between 0 - 100')
      .max(100, 'RV % Short must be between 0 - 100')
      .required(),
  });
  const { id = '', assetId = '' } = useParams<{ id: string; assetId: string }>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Component>({
    resolver: yupResolver(ComponentSchema),
  });

  const subTypeIdWatch = watch('subTypeId');
  const typeIdWatch = watch('typeId');
  const nameIdWatch = watch('nameId');
  const componentWatch = watch();

  const fetchComponent = async (componentId: number): Promise<Component> =>
    api.get<Component>(`/api/Component/GetEdit?ComponentId=${componentId}`).then(({ data }) => data);

  const fetchComponentNames = async (): Promise<HierarchyType[]> =>
    api
      .get<HierarchyType[]>(`/api/ComponentHierarchy/GetComponentNamesByAsset?assetId=${assetId}`)
      .then(({ data }) => [{ label: '', value: null }, ...data] as HierarchyType[]);

  const fetchComponentTypes = async (componentNameId: number): Promise<HierarchyType[]> =>
    api
      .get<HierarchyType[]>(`/api/Lookup/ComponentType?componentNameId=${componentNameId}`)
      .then(({ data }) => [{ label: '', value: null }, ...data] as HierarchyType[]);

  const fetchComponentSubTypes = async (componentTypeId: number): Promise<HierarchyType[]> =>
    api
      .get<HierarchyType[]>(`/api/Lookup/ComponentSubType?ComponentTypeId=${componentTypeId}`)
      .then(({ data }) => [{ label: '', value: null }, ...data] as HierarchyType[]);

  const { data: componentNameList = [] as HierarchyType[] } = useQuery<HierarchyType[], Error>(
    ['Component Names List', parseInt(assetId)],
    async () => fetchComponentNames(),
    {
      enabled: parseInt(assetId) > 0,
    }
  );

  const { data: component = {} as Component } = useQuery<Component, Error>(
    ['componentedit', parseInt(id)],
    async () => fetchComponent(parseInt(id)),
    {
      enabled: parseInt(id) > 0,
    }
  );

  const {
    refetch: refetchComponentTypes,
    data: componentTypesList = [{ value: component.typeId, label: component.type }] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(['Component Types List', nameIdWatch], async () => fetchComponentTypes(nameIdWatch), {
    enabled: Object.keys(component).length > 1,
  });

  const {
    refetch: refetchComponentSubTypes,
    data: componentSubTypesList = [{ value: component.subTypeId, label: component.subType }] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(
    ['Component Sub Types List', typeIdWatch],
    async () => fetchComponentSubTypes(typeIdWatch || 0),
    {
      enabled: false,
    }
  );

  const onChangeLongLifePct = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newShortLife = 100 - +e.currentTarget.value;
    setValue('shortLifePct', newShortLife || 0);
  };

  useEffect(() => {
    if (Object.keys(component).length > 0 && Number(id) !== 0) {
      reset({
        ...component,
        apportionmentPct: component?.apportionmentPct * 100,
        longLifePct: component?.longLifePct ? component?.longLifePct * 100 : undefined,
        shortLifePct: component?.shortLifePct ? component?.shortLifePct * 100 : undefined,
        rvPctLong: component?.rvPctLong >= 0 ? component?.rvPctLong * 100 : undefined,
        rvPctShort: component?.rvPctShort >= 0 ? component?.rvPctShort * 100 : undefined,
        acquisitionDate: component.acquisitionDate ? new Date(component.acquisitionDate) : undefined,
        decommissionDate: component.decommissionDate ? new Date(component.decommissionDate) : undefined,
      } as Component);
    }
  }, [component]);

  useEffect(() => {
    if (nameIdWatch > 0 && Number(id) > 0) {
      refetchComponentTypes();
    } else if (nameIdWatch > 0 && Number(id) === 0) {
      refetchComponentTypes();
    }
  }, [nameIdWatch]);

  useEffect(() => {
    if (typeIdWatch && typeIdWatch > 0) {
      refetchComponentSubTypes();
    }
  }, [typeIdWatch]);

  useEffect(() => {
    if (subTypeIdWatch && subTypeIdWatch > 0) {
      console.log('llpct', getValues('longLifePct'));
      api
        .get<ComponentAssumptions>(`/api/Assumptions/GetComponentAssumptionsBySubType?ComponentSubTypeId=${subTypeIdWatch}`)
        .then(({ data }) => {
          reset({
            ...getValues(),
            ...((getValues('longLifePct') === null || getValues('longLifePct') === undefined) && {
              longLifePct: data.longLifePct * 100,
            }),
            ...((getValues('ulShortMin') === null || getValues('ulShortMin') === undefined) && {
              ulShortMin: data.ulShortMin,
            }),
            ...((getValues('ulShortMax') === null || getValues('ulShortMax') === undefined) && {
              ulShortMax: data.ulShortMax,
            }),
            ...((getValues('ulLong') === null || getValues('ulLong') === undefined) && {
              ulLong: data.ulLong,
            }),
            ...((getValues('rvPctShort') === null || getValues('rvPctShort') === undefined) && {
              rvPctShort: data.rvPctShort * 100,
            }),
            ...((getValues('rvPctLong') === null || getValues('rvPctLong') === undefined) && {
              rvPctLong: data.rvPctLong * 100,
            }),
            ...(Number.isNaN(getValues('apportionmentPct')) && { apportionmentPct: data.apportionmentPct * 100 }),
            componentAssumptions: data,
          });
          setValue('shortLifePct', 100 - data.longLifePct * 100);
        });
    }
  }, [subTypeIdWatch]);

  const onSubmit = (submittedData: Component): void => {
    setIsSaving(true);
    const formattedComponent: Component = {
      ...submittedData,
      ...(Number.isNaN(getValues('id')) && { id: undefined }),
      ...(Number.isNaN(getValues('clientScore')) && { clientScore: undefined }),
      ...(Number.isNaN(getValues('typeId')) && { typeId: undefined }),
      ...(Number.isNaN(getValues('subTypeId')) && { subTypeId: undefined }),
      acquisitionDate:
        submittedData.acquisitionDate !== undefined && submittedData.acquisitionDate !== null
          ? removeTimezoneOffset(submittedData.acquisitionDate)
          : undefined,
      decommissionDate:
        submittedData.decommissionDate !== undefined && submittedData.decommissionDate !== null
          ? removeTimezoneOffset(submittedData.decommissionDate)
          : undefined,
      apportionmentPct: submittedData.apportionmentPct / 100,
      longLifePct: submittedData.longLifePct / 100,
      rvPctLong: submittedData.rvPctLong / 100,
      rvPctShort: submittedData.rvPctShort / 100,
    };

    updateComponent
      .mutateAsync({
        component: formattedComponent,
        ...(Number(id) === 0 && { assetId: Number(assetId) }),
      })
      .then((data) => {
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} Component`);
        history(`/components/${data}`);
        setIsSaving(false);
      })
      .catch((error) => {
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} component ${
            parseInt(id) > 0 && `with ID ${id}. Please check for validation errors`
          }`,
          ToastType.Error
        );
        setIsSaving(false);
      });
  };
  return (
    <>
      <div className="m-4 flex justify-between">
        {Number(id) > 0 ? (
          <>
            <h3 className="inline-block align-middle text-lg leading-6 font-medium text-gray-900">{component.name}</h3>
            <button
              type="button"
              onClick={(): void => {
                history(-1);
              }}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              View
            </button>
          </>
        ) : (
          <>
            <h3 className="inline-block align-middle text-lg leading-6 font-medium text-gray-900">New Component</h3>
            <NavLink
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              to={`/assets/${assetId}`}
            >
              View
            </NavLink>
          </>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow p-4 m-4 bg-white rounded-md">
          <div className="">
            <div className="sm:pt-4 ">
              <div className="space-y-2 divide-y divide-gray-200">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Component Id
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="hidden" {...register('id', { valueAsNumber: true })} />

                    <input
                      type="text"
                      {...register('componentId', { valueAsNumber: false })}
                      className="max-w-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4 pt-2">
                  <label
                    htmlFor="apportionmentPct"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Apportionment
                  </label>
                  <div>
                    <div className="flex space-x-2 items-baseline">
                      <div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            step="any"
                            type="number"
                            {...register('apportionmentPct', { valueAsNumber: true })}
                            className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pr-7 sm:text-sm border-gray-300 rounded-md lg:w-20"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">%</span>
                          </div>
                        </div>
                      </div>
                      <span className="items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                        {component?.componentAssumptions?.apportionmentPct}%
                      </span>
                    </div>
                    {errors?.apportionmentPct && <ErrorMessage>{errors.apportionmentPct.message}</ErrorMessage>}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Score
                  </label>
                  <div>
                    <div className="flex space-x-2 items-baseline">
                      <div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            step="any"
                            type="number"
                            {...register('consumptionScore', { valueAsNumber: true })}
                            className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pr-3 sm:text-sm border-gray-300 rounded-md lg:w-20"
                          />
                        </div>
                      </div>
                      {/* TODO: Add previous score as a badge */}
                      {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-gray-100 text-gray-800">
                  5
                </span> */}
                    </div>
                    {errors?.consumptionScore && <ErrorMessage>{errors.consumptionScore.message}</ErrorMessage>}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Client Score
                  </label>
                  <div className="flex space-x-2 items-baseline">
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        step="any"
                        type="number"
                        {...register('clientScore', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-right pr-3 sm:text-sm border-gray-300 rounded-md lg:w-20"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Acquisition Date
                  </label>
                  <div>
                    <Controller
                      name="acquisitionDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }): JSX.Element => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Decommission Date
                  </label>
                  <div>
                    <Controller
                      name="decommissionDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }): JSX.Element => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors?.decommissionDate && <ErrorMessage>{errors.decommissionDate.message}</ErrorMessage>}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Hierarchy</h3>
              </div>
              <div className="mt-5 space-y-5 lg:space-y-2">
                {Number(id) === 0 && (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                    <label
                      htmlFor="country"
                      className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Component Names
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register('nameId', { valueAsNumber: true })}
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      >
                        {componentNameList.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Type
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register('typeId', { valueAsNumber: true })}
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      {componentTypesList.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sub Type
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register('subTypeId', { valueAsNumber: true })}
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      {componentSubTypesList.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    {errors?.subTypeId && <ErrorMessage>{errors.subTypeId.message}</ErrorMessage>}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Valuation Parameters</h3>
              </div>
              <div className="mt-5 space-y-2 lg:space-y-2">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                  <label
                    htmlFor="country"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Depreciation Policy
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register('depreciationPolicy')}
                      className="max-w-md block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option>Apply UL</option>
                      <option>Apply RUL</option>
                      <option>Do Not Apply</option>
                      <option>Match Pattern</option>
                    </select>
                  </div>
                </div>
                {componentWatch?.componentAssumptions != undefined && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                      <label
                        htmlFor="first_name"
                        className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Short Life/Long Life Split
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex space-x-8">
                        <div>
                          <label className="text-sm font-medium text-gray-700">Short Life</label>

                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  disabled
                                  step="any"
                                  type="number"
                                  {...register('shortLifePct', { valueAsNumber: true })}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full pr-7 text-right sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="opacity-50 text-gray-500 sm:text-sm">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">Long Life</label>

                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  step="any"
                                  type="number"
                                  {...register('longLifePct', { valueAsNumber: true })}
                                  disabled={componentWatch?.componentAssumptions?.longLifePct === undefined}
                                  onChange={(e): void => onChangeLongLifePct(e)}
                                  defaultValue={0}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full text-right pr-7 sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">%</span>
                                </div>
                              </div>
                              {errors?.longLifePct && <ErrorMessage>{errors.longLifePct.message}</ErrorMessage>}
                            </div>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.longLifePct !== undefined
                                ? componentWatch?.componentAssumptions?.longLifePct * 100
                                : undefined}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                      <label
                        htmlFor="first_name"
                        className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Useful Life
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex space-x-8">
                        <div>
                          <label className="text-sm font-medium text-gray-700">Short Life Min</label>

                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  step="any"
                                  type="number"
                                  disabled={componentWatch?.componentAssumptions?.ulShortMin === undefined}
                                  {...register('ulShortMin', { valueAsNumber: true })}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full text-right pr-10 sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  {/* Heroicon name: solid/question-mark-circle */}
                                  <span className="text-gray-500 sm:text-sm">yrs</span>
                                </div>
                              </div>
                              {errors?.ulShortMin && <ErrorMessage>{errors.ulShortMin.message}</ErrorMessage>}
                            </div>
                            <span className="inline-flex flex-grow lg:w-24 items-center px-2 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.ulShortMin} yrs
                            </span>
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">Long Life</label>

                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  step="any"
                                  type="number"
                                  disabled={componentWatch?.componentAssumptions?.ulLong === undefined}
                                  {...register('ulLong', { valueAsNumber: true })}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full text-right pr-10 sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  {/* Heroicon name: solid/question-mark-circle */}
                                  <span className="text-gray-500 sm:text-sm">yrs</span>
                                </div>
                              </div>
                              {errors?.ulLong && <ErrorMessage>{errors.ulLong.message}</ErrorMessage>}
                            </div>
                            <span className="inline-flex flex-grow lg:w-24 items-center px-2 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.ulLong} yrs
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                      <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" />
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex space-x-8">
                        <div>
                          <label className="text-sm font-medium text-gray-700">Short Life Max</label>

                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  type="text"
                                  disabled={componentWatch?.componentAssumptions?.ulShortMax === undefined}
                                  {...register('ulShortMax', { valueAsNumber: true })}
                                  id="account_number"
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full text-right pr-10 sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">yrs</span>
                                </div>
                              </div>
                              {errors?.ulShortMax && <ErrorMessage>{errors.ulShortMax.message}</ErrorMessage>}
                            </div>
                            <span className="inline-flex lg:w-24 items-center px-2 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.ulShortMax} yrs
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                      <label
                        htmlFor="first_name"
                        className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Residual Value
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex space-x-8">
                        <div>
                          <label className="text-sm font-medium text-gray-700">Short Life</label>
                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  type="text"
                                  disabled={componentWatch?.componentAssumptions?.rvPctShort === undefined}
                                  {...register('rvPctShort', { valueAsNumber: true })}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full pr-7 text-right sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">%</span>
                                </div>
                              </div>
                              {errors?.rvPctShort && <ErrorMessage>{errors.rvPctShort.message}</ErrorMessage>}
                            </div>
                            <span className="inline-flex lg:w-24 items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.rvPctShort}%
                            </span>
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">Long Life</label>
                          <div className="flex space-x-2 items-baseline lg:w-44">
                            <div>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  type="number"
                                  disabled={componentWatch?.componentAssumptions?.rvPctLong === undefined}
                                  {...register('rvPctLong', { valueAsNumber: true })}
                                  className="focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 block w-full pr-7 text-right sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">%</span>
                                </div>
                              </div>
                              {errors?.rvPctLong && <ErrorMessage>{errors.rvPctLong.message}</ErrorMessage>}
                            </div>

                            <span className="inline-flex lg:w-24 items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800 justify-end">
                              {componentWatch?.componentAssumptions?.rvPctLong}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 pt-2">
                  <label
                    htmlFor="first_name"
                    className="flex h-full items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Part ID
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2 flex space-x-8">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Short Life</label>

                      <div className="flex space-x-2 items-baseline lg:w-44">
                        <div>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              {...register('idShort')}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-700">Long Life</label>
                      <div className="flex space-x-2 items-baseline lg:w-44">
                        <div>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              {...register('idLong')}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mr-4">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={(): void => {
                history(-1);
              }}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button disabled={isSaving} type="submit" className="btn btn-primary">
              {isSaving ? (
                <>
                  <span>Saving</span>
                  <Loader className="animate-spin w-5 h-5 mx-2" />
                </>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ComponentAssumptionsWithHierarchy } from '../../models/ComponentAssumptions';
import { HierarchyType } from '../../models/HierarchyType';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

const depreciationPolicies = [
  { label: 'Apply UL', value: 'Apply UL' },
  { label: 'Do Not Apply', value: 'Do Not Apply' },
  { label: 'Apply RUL', value: 'Apply RUL' },
  { label: 'Match Pattern', value: 'Match Pattern' },
];

const ComponentAssumptionsEdit: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [componentAssumptions, setComponentAssumptions] = useState<ComponentAssumptionsWithHierarchy>(
    {} as ComponentAssumptionsWithHierarchy
  );

  const {
    isLoading,
    isSuccess,
    error,
    data: obsolescenceProfiles = [] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(['valuationProfiles'], () =>
    api.post('/api/Lookup/ValuationProfile', {}).then((res) => res.data)
  );

  const ComponentAssumptionsSchema = yup.object().shape({
    unitRate: yup.number().min(0, 'Cannot be a negative number.').required('Unit Rate is required'),
    localityFactorPct: yup
      .number()
      .min(0, 'Cannot be a negative number.')
      .max(100, 'Cannot greater than 100%.')
      .required('Locality Factor is required'),
    longLifePct: yup.number().min(0).typeError('LL % must at least be 0').max(100, 'LL % must at least be 0'),
    ulLong: yup.number().when('longLifePct', ([longLifePct], schema) => {
      if (longLifePct > 0) {
        return schema.moreThan(0, 'LL must be greater than 0 when LL % is greater than 0%');
      }
      return schema;
    }),
    ulShortMin: yup.number().min(1).required(),
    ulShortMax: yup.number().min(yup.ref('ulShortMin'), 'UL Short Max must be greater than or equal to UL Short Min'),
    rvPctShort: yup
      .number()
      .min(0, 'RV % Short must be between 0 - 100')
      .max(100, 'RV % Short must be between 0 - 100')
      .required(),
    rvPctLong: yup
      .number()
      .min(0, 'RV % Short must be between 0 - 100')
      .max(100, 'RV % Short must be between 0 - 100')
      .required(),
    obsolescenceProfileId: yup.number().typeError('Obsolescence Profile error').required('Obsolescence Profile is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ComponentAssumptionsWithHierarchy>({
    defaultValues: {
      id: parseInt(id),
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(ComponentAssumptionsSchema),
  });

  useEffect(() => {
    if (id) {
      api
        .get(`/api/Assumptions/GetComponentAssumptions?id=${id}`)
        .then(({ data }) => {
          setComponentAssumptions(data);
          reset({
            ...data,
            localityFactorPct: data.localityFactorPct * 100,
            longLifePct: data.longLifePct * 100,
            rvPctShort: data.rvPctShort * 100,
            rvPctLong: data.rvPctLong * 100,
          });
        })
        .catch((error) => {
          addToast(`Unable to get asset assumptions with ID ${id}`, ToastType.Error);
        });
    }
  }, [id]);
  const onSubmit = (data: ComponentAssumptionsWithHierarchy): void => {
    const componentAssumptions = {
      ...data,
      localityFactorPct: data.localityFactorPct / 100,
      longLifePct: data.longLifePct / 100,
      rvPctShort: data.rvPctShort / 100,
      rvPctLong: data.rvPctLong / 100,
    };
    setIsSaving(true);
    api
      .post('/api/Assumptions/UpdateComponentAssumptions', { componentAssumptions })
      .then(() => {
        setIsSaving(false);
        addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} Component Assumptions`);
        history(`/componentassumptions/${componentAssumptions.id}`);
        queryClient.invalidateQueries(['asset']);
        queryClient.invalidateQueries(['component']);
      })
      .catch((error) => {
        setIsSaving(false);
        addToast(
          `Unable to ${parseInt(id) === 0 ? 'create new' : 'update'} Component Assumptions ${
            parseInt(id) > 0 && `with ID ${id}`
          }`,
          ToastType.Error
        );
      });
  };

  console.log('errors', errors);
  const { assetClass, assetType, assetSubType, componentName, componentType, componentSubType } = componentAssumptions;
  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('id', { valueAsNumber: true })} />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Component Assumptions</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-600">
                  Asset Class
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetClass}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetType}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Asset Sub Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{assetSubType}</dd>
              </div>
            </div>
          </div>
        </div>
        <div className="md:grid md:grid-cols-3 md:gap-6 mt-2">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-600">
                  Component Name
                </label>
                <dd className="mt-1 text-lg text-gray-900">{componentName}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Component Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{componentType}</dd>
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                  Component Sub Type
                </label>
                <dd className="mt-1 text-lg text-gray-900">{componentSubType}</dd>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Cost</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Unit Rate
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    {...register('unitRate', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Locality Factor
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('localityFactorPct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Useful Life and Residual Value</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Long Life Split
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('longLifePct', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  UL Short Min
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('ulShortMin', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      years
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  UL Short Max
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('ulShortMax', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      years
                    </span>
                  </div>
                </div>
                {errors?.ulShortMax && <ErrorMessage>{errors.ulShortMax.message}</ErrorMessage>}
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Residual Value Short
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('rvPctShort', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  UL Long
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('ulLong', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      years
                    </span>
                  </div>
                </div>
                {errors?.ulLong && <ErrorMessage>{errors.ulLong.message}</ErrorMessage>}
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Residual Value Long
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                  <input
                    type="text"
                    {...register('rvPctLong', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Profiles</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Depreciation Policy
                </label>
                <select
                  {...register('depreciationPolicy')}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {depreciationPolicies.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Obsolescence Profile
                </label>
                <select
                  {...register('obsolescenceProfileId', { valueAsNumber: true })}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {obsolescenceProfiles.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
                {errors?.obsolescenceProfileId && <ErrorMessage>{errors.obsolescenceProfileId.message}</ErrorMessage>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/componentassumptions/${id}`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {!isSaving ? (
              <span>Save</span>
            ) : (
              <>
                <span>Saving</span>
                <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ComponentAssumptionsEdit;

import React, { createContext, useState, useContext, useEffect } from 'react';
import { FileWithPath } from 'react-dropzone';
import { ToastType, useToast } from './ToastContext';
import { downloadHandler } from '../Helper';
import { useApi } from './ApiContext';

export interface BaselineStrategy {
  id: number;
  name: string;
  jobDate?: Date;
  newJob?: Date;
  updatedBy?: string;
  lastRefreshed: Date;
  isCreated?: boolean;
  assetClass: string;
}

interface BaselineStrategyContext {
  baselineStrategies: BaselineStrategy[];
  isLoadingBaselineStrategies: boolean;
  isUploading: boolean;
  updateBaselineStrategy: (assetClass: string, loading: Function) => void;
  getBaselineStrategy: (assetClass: string) => BaselineStrategy;
  createBaselineStrategy: (assetClassId: number) => void;
  downloadBaselineAssumptions: (assetClassId: number, name: string) => void;
  uploadBaselineAssumptions: (assetClassId: number, file: FileWithPath) => void;
}

const BaselineStrategyContext = createContext<BaselineStrategyContext>({} as BaselineStrategyContext);

// This component retrieves the strategies from the API so they can be read by all components
export const BaselineStrategyProvider: React.FC<any> = (props) => {
  const api = useApi();
  const { addToast } = useToast();

  const [baselineStrategies, setBaselineStrategies] = useState<BaselineStrategy[]>([]);
  const [isLoadingBaselineStrategies, setIsLoadingBaselineStrategies] = useState<boolean>(false);

  const [isUploading, setIsUploading] = useState(false);

  const setHierarchy = (strategy: BaselineStrategy): void => {
    const newStrategy = baselineStrategies.map((baselineStrategy) =>
      baselineStrategy.name === strategy.name ? strategy : baselineStrategy
    );
    setBaselineStrategies([...newStrategy]);
  };

  const updateBaselineStrategy = (assetClass: string, loading: Function): void => {
    loading(true);
    api.post('/api/BaselineAssumptions/Refresh', { assetClass }).then(
      ({ data }) => {
        setHierarchy(data.result);
        loading(false);
        addToast(data.message);
      },
      (error) => {
        loading(false);
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const downloadBaselineAssumptions = (assetClassId: number, name: string): void => {
    setIsLoadingBaselineStrategies(true);
    api.post('/api/BaselineAssumptions/Export', { assetClassId }, { responseType: 'blob' }).then(
      ({ data }) => {
        setIsLoadingBaselineStrategies(false);
        downloadHandler(data, `${name}.xlsx`);
      },
      (error) => {
        setIsLoadingBaselineStrategies(false);
        addToast(`Unable to retrieve ${name} assumptions`, ToastType.Error);
      }
    );
  };

  const createBaselineStrategy = (assetClassId: number): void => {
    api.post('/api/Strategy/CreateBaseline', { assetClassId }).then(
      ({ data }) => {
        addToast(`Successfully Created Baseline Strategy`);
      },
      (error) => {
        addToast(`Unable to create Baseline Strategy`, ToastType.Error);
      }
    );
  };

  const uploadBaselineAssumptions = (assetClassId: number, file: FileWithPath): void => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('assetClassId', assetClassId.toString());
    setIsUploading(true);
    api
      .post('/api/BaselineAssumptions/Import', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(
        ({ data }) => {
          setIsUploading(false);
          addToast(data.message);
        },
        (error) => {
          setIsUploading(false);
          addToast('API Fetch Failed', ToastType.Error);
        }
      );
  };

  const getBaselineStrategy = (name: string): BaselineStrategy => {
    const result = baselineStrategies.find((assetHierarchy) => assetHierarchy.name === name);
    if (baselineStrategies.length === 0) {
      return {} as BaselineStrategy;
    }
    if (result === undefined || result === null) {
      throw new TypeError(`Cannot find ${name} strategy`);
    }
    return result;
  };

  useEffect(() => {
    setIsLoadingBaselineStrategies(true);
    api
      .get('/api/BaselineAssumptions/GetList')
      .then(({ data }) => {
        setIsLoadingBaselineStrategies(false);
        setBaselineStrategies(data);
      })
      .catch((error) => {
        addToast('Timed out getting hierarchies', ToastType.Error);
        setIsLoadingBaselineStrategies(false);
      });
  }, []);

  return (
    <BaselineStrategyContext.Provider
      value={{
        baselineStrategies,
        updateBaselineStrategy,
        isLoadingBaselineStrategies,
        isUploading,
        getBaselineStrategy,
        downloadBaselineAssumptions,
        createBaselineStrategy,
        uploadBaselineAssumptions,
      }}
      {...props}
    />
  );
};

export const useBaselineAssumptions = (): BaselineStrategyContext => useContext(BaselineStrategyContext);
export default BaselineStrategyProvider;

import React, { useEffect } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Asset } from '../models/Asset';

type IncomeEdit = {
  index: number;
  control: Control<Asset, object>;
  watch: UseFormWatch<Asset>;
  register: UseFormRegister<Asset>;
  remove: (index?: number | number[] | undefined) => void;
  field: FieldArrayWithId<Asset, 'incomeApproach.incomeApproachItems', 'id'>;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
};

export const IncomeEdit: React.FC<IncomeEdit> = ({ index, register, watch, field, remove, getValues, setValue }) => (
  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/4">
      <div className="flex justify-end">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.itemName` as const)}
          defaultValue={field.itemName}
          type="text"
          className="block w-full rounded-md border-gray-300 text-left focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm shadow-sm"
        />
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/6">
      <div className="flex justify-end">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.inflow` as const, { valueAsNumber: true })}
          defaultValue={field.inflow}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm shadow-sm"
        />
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-1/6">
      <div className="flex justify-end">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.outflow` as const, { valueAsNumber: true })}
          defaultValue={field.outflow}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm shadow-sm"
        />
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
      <div className="flex justify-end relative">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.vacancyFactorPct` as const, { valueAsNumber: true })}
          defaultValue={field.vacancyFactorPct}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:pr-6 sm:text-sm lg:pr-5 shadow-sm"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-3 lg:pr-2">%</div>
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
      <div className="flex justify-end">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.leasedUpMonths` as const, { valueAsNumber: true })}
          defaultValue={field.leasedUpMonths}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm shadow-sm"
        />
      </div>
    </td>
    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
      <div className="flex justify-end relative">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.capitalisationRatePct` as const, {
            valueAsNumber: true,
          })}
          defaultValue={field.capitalisationRatePct}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:pr-6 sm:text-sm lg:pr-5 shadow-sm"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-3 lg:pr-2">%</div>
      </div>
    </td>

    <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 ">
      <div className="flex justify-end">
        <input
          {...register(`incomeApproach.incomeApproachItems.${index}.capitalAdjustment` as const, {
            valueAsNumber: true,
          })}
          defaultValue={field.capitalAdjustment}
          type="text"
          className="block sm:w-3/4 lg:w-4/5 rounded-md border-gray-300 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm shadow-sm"
        />
      </div>
    </td>

    

    <td className="whitespace-nowrap px-1 py-2 text-right text-sm font-medium">
      <button
        className="pr-4"
        type="button"
        onClick={(): void => {
          remove(index);
        }}
      >
        <TrashIcon className="h-6 w-6 text-gray-400" />
      </button>
    </td>
  </tr>
);
